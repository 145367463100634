export default {
  "0030d5e1": [() => import(/* webpackChunkName: "0030d5e1" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-rule.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-rule.mdx")],
  "0053616d": [() => import(/* webpackChunkName: "0053616d" */ "@site/docs/api/access/schemas/schema-relative-to-date-time.mdx"), "@site/docs/api/access/schemas/schema-relative-to-date-time.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-relative-to-date-time.mdx")],
  "006a6095": [() => import(/* webpackChunkName: "006a6095" */ "@site/docs/api/lusid/schemas/schema-update-fee-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-fee-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-fee-type-request.mdx")],
  "0073850e": [() => import(/* webpackChunkName: "0073850e" */ "@site/docs/api/lusid/schemas/schema-query-bucketed-cash-flows-request.mdx"), "@site/docs/api/lusid/schemas/schema-query-bucketed-cash-flows-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-query-bucketed-cash-flows-request.mdx")],
  "007aea22": [() => import(/* webpackChunkName: "007aea22" */ "@site/docs/api/lusid/schemas/schema-fixed-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-fixed-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fixed-schedule.mdx")],
  "007fb53d": [() => import(/* webpackChunkName: "007fb53d" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-configuration-recipe.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-configuration-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-configuration-recipe.mdx")],
  "00a2c80b": [() => import(/* webpackChunkName: "00a2c80b" */ "@site/docs/api/horizon/schemas/schema-audit-file-type.mdx"), "@site/docs/api/horizon/schemas/schema-audit-file-type.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-file-type.mdx")],
  "00a6168f": [() => import(/* webpackChunkName: "00a6168f" */ "@site/docs/api/lusid/schemas/schema-cash-flow-value.mdx"), "@site/docs/api/lusid/schemas/schema-cash-flow-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-flow-value.mdx")],
  "00cf9f06": [() => import(/* webpackChunkName: "00cf9f06" */ "@site/docs/api/lusid/schemas/schema-merger-event.mdx"), "@site/docs/api/lusid/schemas/schema-merger-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-merger-event.mdx")],
  "00dbb957": [() => import(/* webpackChunkName: "00dbb957" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-list-corporate-action-sources.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-list-corporate-action-sources.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-list-corporate-action-sources.mdx")],
  "00e54881": [() => import(/* webpackChunkName: "00e54881" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-view-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-view-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-view-design-to-sql.mdx")],
  "00eba8a5": [() => import(/* webpackChunkName: "00eba8a5" */ "@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituent-properties.mdx"), "@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituent-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituent-properties.mdx")],
  "00f56fb3": [() => import(/* webpackChunkName: "00f56fb3" */ "@site/docs/api/access/endpoints/policies/operation-page-policies.mdx"), "@site/docs/api/access/endpoints/policies/operation-page-policies.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-page-policies.mdx")],
  "0104a6fb": [() => import(/* webpackChunkName: "0104a6fb" */ "@site/docs/api/lusid/schemas/schema-chart-of-accounts-request.mdx"), "@site/docs/api/lusid/schemas/schema-chart-of-accounts-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-chart-of-accounts-request.mdx")],
  "0112d701": [() => import(/* webpackChunkName: "0112d701" */ "@site/docs/api/access/schemas/schema-resource-list-of-policy-template-response.mdx"), "@site/docs/api/access/schemas/schema-resource-list-of-policy-template-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-list-of-policy-template-response.mdx")],
  "013d4a37": [() => import(/* webpackChunkName: "013d4a37" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-upsertable-portfolio-cash-flows.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-upsertable-portfolio-cash-flows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-upsertable-portfolio-cash-flows.mdx")],
  "01640b53": [() => import(/* webpackChunkName: "01640b53" */ "@site/docs/api/workflow/schemas/schema-resource-id.mdx"), "@site/docs/api/workflow/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-resource-id.mdx")],
  "0169d565": [() => import(/* webpackChunkName: "0169d565" */ "@site/docs/api/lusid/endpoints/conventions/operation-get-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-get-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-get-flow-conventions.mdx")],
  "01a1dcd5": [() => import(/* webpackChunkName: "01a1dcd5" */ "@site/docs/api/lusid/schemas/schema-result-value-bool.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-bool.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-bool.mdx")],
  "01a541a7": [() => import(/* webpackChunkName: "01a541a7" */ "@site/docs/api/web-app/schemas/schema-absolute-rule-instrument.mdx"), "@site/docs/api/web-app/schemas/schema-absolute-rule-instrument.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-absolute-rule-instrument.mdx")],
  "01af7fc1": [() => import(/* webpackChunkName: "01af7fc1" */ "@site/docs/api/horizon/schemas/schema-create-instance-request.mdx"), "@site/docs/api/horizon/schemas/schema-create-instance-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-create-instance-request.mdx")],
  "01f5e936": [() => import(/* webpackChunkName: "01f5e936" */ "@site/docs/api/identity/endpoints/identity-provider/operation-remove-scim.mdx"), "@site/docs/api/identity/endpoints/identity-provider/operation-remove-scim.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/identity-provider/operation-remove-scim.mdx")],
  "0205b7b5": [() => import(/* webpackChunkName: "0205b7b5" */ "@site/docs/api/lusid/schemas/schema-opaque-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-opaque-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-opaque-model-options.mdx")],
  "0219658c": [() => import(/* webpackChunkName: "0219658c" */ "@site/docs/api/horizon/schemas/schema-lusid-entity.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-entity.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-entity.mdx")],
  "02548aa2": [() => import(/* webpackChunkName: "02548aa2" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-create-ticket.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-create-ticket.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-create-ticket.mdx")],
  "026452a9": [() => import(/* webpackChunkName: "026452a9" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-synopsis.mdx")],
  "02a60e8e": [() => import(/* webpackChunkName: "02a60e8e" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-output-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-output-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-output-transaction.mdx")],
  "02b1a200": [() => import(/* webpackChunkName: "02b1a200" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-request.mdx")],
  "02d57e13": [() => import(/* webpackChunkName: "02d57e13" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-commands.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-commands.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-commands.mdx")],
  "0316cd4c": [() => import(/* webpackChunkName: "0316cd4c" */ "@site/docs/api/lusid/schemas/schema-block-and-orders.mdx"), "@site/docs/api/lusid/schemas/schema-block-and-orders.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-and-orders.mdx")],
  "0345d4df": [() => import(/* webpackChunkName: "0345d4df" */ "@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result-request.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result-request.mdx")],
  "034a0152": [() => import(/* webpackChunkName: "034a0152" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-get-all-custom-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-get-all-custom-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-get-all-custom-entity-access-metadata.mdx")],
  "036bdcbf": [() => import(/* webpackChunkName: "036bdcbf" */ "@site/docs/api/lusid/endpoints/fee-types/operation-list-fee-types.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-list-fee-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-list-fee-types.mdx")],
  "038c1bb4": [() => import(/* webpackChunkName: "038c1bb4" */ "@site/docs/api/lusid/schemas/schema-update-derived-property-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-derived-property-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-derived-property-definition-request.mdx")],
  "0392505c": [() => import(/* webpackChunkName: "0392505c" */ "@site/docs/api/lusid/schemas/schema-dividend-option-event.mdx"), "@site/docs/api/lusid/schemas/schema-dividend-option-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dividend-option-event.mdx")],
  "03a924cc": [() => import(/* webpackChunkName: "03a924cc" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-xml.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-xml.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-xml.mdx")],
  "03ab5f94": [() => import(/* webpackChunkName: "03ab5f94" */ "@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-response.mdx"), "@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-response.mdx")],
  "03bcb62d": [() => import(/* webpackChunkName: "03bcb62d" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-to-sql.mdx")],
  "0406e078": [() => import(/* webpackChunkName: "0406e078" */ "@site/docs/api/web-app/schemas/schema-valuation-manifest-response.mdx"), "@site/docs/api/web-app/schemas/schema-valuation-manifest-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-valuation-manifest-response.mdx")],
  "041f243f": [() => import(/* webpackChunkName: "041f243f" */ "@site/docs/api/scheduler/endpoints/jobs/operation-get-run-history.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-get-run-history.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-get-run-history.mdx")],
  "04252a16": [() => import(/* webpackChunkName: "04252a16" */ "@site/docs/api/lusid/endpoints/funds/operation-create-fund.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-create-fund.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-create-fund.mdx")],
  "043d1d08": [() => import(/* webpackChunkName: "043d1d08" */ "@site/docs/api/lusid/schemas/schema-generated-event-diagnostics.mdx"), "@site/docs/api/lusid/schemas/schema-generated-event-diagnostics.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-generated-event-diagnostics.mdx")],
  "044700c7": [() => import(/* webpackChunkName: "044700c7" */ "@site/docs/api/lusid/schemas/schema-upsert-instrument-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-instrument-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-instrument-properties-response.mdx")],
  "0452659c": [() => import(/* webpackChunkName: "0452659c" */ "@site/docs/api/horizon/endpoints/integrations/operation-get-instance-optional-property-mapping.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-get-instance-optional-property-mapping.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-get-instance-optional-property-mapping.mdx")],
  "04668bb8": [() => import(/* webpackChunkName: "04668bb8" */ "@site/docs/api/lusid/schemas/schema-transaction-type.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type.mdx")],
  "049787a3": [() => import(/* webpackChunkName: "049787a3" */ "@site/docs/api/lusid/endpoints/persons/operation-list-all-persons.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-list-all-persons.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-list-all-persons.mdx")],
  "049a9831": [() => import(/* webpackChunkName: "049a9831" */ "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-returns.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-returns.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-returns.mdx")],
  "04aaa361": [() => import(/* webpackChunkName: "04aaa361" */ "@site/docs/api/lusid/endpoints/conventions/operation-upsert-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-upsert-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-upsert-flow-conventions.mdx")],
  "04afd2ef": [() => import(/* webpackChunkName: "04afd2ef" */ "@site/docs/api/insights/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/insights/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-id-selector-definition.mdx")],
  "04d4a535": [() => import(/* webpackChunkName: "04d4a535" */ "@site/docs/api/lusid/endpoints/data-types/operation-list-data-types.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-list-data-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-list-data-types.mdx")],
  "04dce00f": [() => import(/* webpackChunkName: "04dce00f" */ "@site/docs/api/workflow/schemas/schema-task-definition-version.mdx"), "@site/docs/api/workflow/schemas/schema-task-definition-version.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-definition-version.mdx")],
  "04eec836": [() => import(/* webpackChunkName: "04eec836" */ "@site/docs/api/luminesce/schemas/schema-field-design.mdx"), "@site/docs/api/luminesce/schemas/schema-field-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-field-design.mdx")],
  "05080aa1": [() => import(/* webpackChunkName: "05080aa1" */ "@site/docs/api/web-app/schemas/schema-resource-list-roadmap-item.mdx"), "@site/docs/api/web-app/schemas/schema-resource-list-roadmap-item.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-resource-list-roadmap-item.mdx")],
  "0508badf": [() => import(/* webpackChunkName: "0508badf" */ "@site/docs/api/web-app/schemas/schema-hubspot-contact.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-contact.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-contact.mdx")],
  "05289701": [() => import(/* webpackChunkName: "05289701" */ "@site/docs/api/lusid/schemas/schema-get-quotes-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-quotes-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-quotes-response.mdx")],
  "0549f2d6": [() => import(/* webpackChunkName: "0549f2d6" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-group-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-group-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-group-properties.mdx")],
  "0578b953": [() => import(/* webpackChunkName: "0578b953" */ "@site/docs/api/luminesce/intro.md"), "@site/docs/api/luminesce/intro.md", require.resolveWeak("@site/docs/api/luminesce/intro.md")],
  "058c6500": [() => import(/* webpackChunkName: "058c6500" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-sql-to-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-sql-to-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-sql-to-design.mdx")],
  "059d6b2a": [() => import(/* webpackChunkName: "059d6b2a" */ "@site/docs/api/workflow/endpoints/event-handlers/operation-delete-event-handler.mdx"), "@site/docs/api/workflow/endpoints/event-handlers/operation-delete-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/event-handlers/operation-delete-event-handler.mdx")],
  "05b36be3": [() => import(/* webpackChunkName: "05b36be3" */ "@site/docs/api/lusid/schemas/schema-valuation-request.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-request.mdx")],
  "05c46360": [() => import(/* webpackChunkName: "05c46360" */ "@site/docs/api/scheduler/schemas/schema-create-schedule-request.mdx"), "@site/docs/api/scheduler/schemas/schema-create-schedule-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-create-schedule-request.mdx")],
  "05ec264c": [() => import(/* webpackChunkName: "05ec264c" */ "@site/docs/api/lusid/schemas/schema-relative-date-offset.mdx"), "@site/docs/api/lusid/schemas/schema-relative-date-offset.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-relative-date-offset.mdx")],
  "05fba6f1": [() => import(/* webpackChunkName: "05fba6f1" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-models.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-models.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-models.mdx")],
  "0636ce1e": [() => import(/* webpackChunkName: "0636ce1e" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-priority-codes.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-priority-codes.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-priority-codes.mdx")],
  "066ad6eb": [() => import(/* webpackChunkName: "066ad6eb" */ "@site/docs/api/horizon/schemas/schema-process-update-result.mdx"), "@site/docs/api/horizon/schemas/schema-process-update-result.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-process-update-result.mdx")],
  "067a26c6": [() => import(/* webpackChunkName: "067a26c6" */ "@site/docs/api/notification/endpoints/subscriptions/operation-create-subscription.mdx"), "@site/docs/api/notification/endpoints/subscriptions/operation-create-subscription.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/subscriptions/operation-create-subscription.mdx")],
  "068c013c": [() => import(/* webpackChunkName: "068c013c" */ "@site/docs/api/web-app/schemas/schema-property-spec.mdx"), "@site/docs/api/web-app/schemas/schema-property-spec.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-property-spec.mdx")],
  "0693f8ed": [() => import(/* webpackChunkName: "0693f8ed" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-repository.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-repository.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-repository.mdx")],
  "06aeb951": [() => import(/* webpackChunkName: "06aeb951" */ "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio.mdx")],
  "06b2b119": [() => import(/* webpackChunkName: "06b2b119" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definition.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definition.mdx")],
  "06c84fb8": [() => import(/* webpackChunkName: "06c84fb8" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relationships.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relationships.mdx")],
  "06e3070a": [() => import(/* webpackChunkName: "06e3070a" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-valuation.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-valuation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-valuation.mdx")],
  "06f89874": [() => import(/* webpackChunkName: "06f89874" */ "@site/docs/api/configuration/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/configuration/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-id-selector-definition.mdx")],
  "06fc025b": [() => import(/* webpackChunkName: "06fc025b" */ "@site/docs/api/identity/schemas/schema-update-password-policy-request-lockout.mdx"), "@site/docs/api/identity/schemas/schema-update-password-policy-request-lockout.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-password-policy-request-lockout.mdx")],
  "070b6695": [() => import(/* webpackChunkName: "070b6695" */ "@site/docs/api/lusid/schemas/schema-tax-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-tax-rule-set.mdx")],
  "070e6e1a": [() => import(/* webpackChunkName: "070e6e1a" */ "@site/docs/api/lusid/schemas/schema-exchange-traded-option.mdx"), "@site/docs/api/lusid/schemas/schema-exchange-traded-option.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-exchange-traded-option.mdx")],
  "070e85fb": [() => import(/* webpackChunkName: "070e85fb" */ "@site/docs/api/lusid/endpoints/persons/operation-list-persons.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-list-persons.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-list-persons.mdx")],
  "07187c9d": [() => import(/* webpackChunkName: "07187c9d" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-create-fund-configuration.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-create-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-create-fund-configuration.mdx")],
  "07301ed1": [() => import(/* webpackChunkName: "07301ed1" */ "@site/docs/excel/functions/Corporate Actions.mdx"), "@site/docs/excel/functions/Corporate Actions.mdx", require.resolveWeak("@site/docs/excel/functions/Corporate Actions.mdx")],
  "07669d5d": [() => import(/* webpackChunkName: "07669d5d" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-upsert-rebalance-portfolio-mapping.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-upsert-rebalance-portfolio-mapping.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-upsert-rebalance-portfolio-mapping.mdx")],
  "0788fc5b": [() => import(/* webpackChunkName: "0788fc5b" */ "@site/docs/api/lusid/endpoints/custom-entity-types/operation-update-custom-entity-type.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-types/operation-update-custom-entity-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-types/operation-update-custom-entity-type.mdx")],
  "07cb20cc": [() => import(/* webpackChunkName: "07cb20cc" */ "@site/docs/api/scheduler/schemas/schema-argument-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-argument-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-argument-definition.mdx")],
  "07e8b1d5": [() => import(/* webpackChunkName: "07e8b1d5" */ "@site/docs/api/workflow/schemas/schema-create-event-handler-request.mdx"), "@site/docs/api/workflow/schemas/schema-create-event-handler-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-event-handler-request.mdx")],
  "07fefd9d": [() => import(/* webpackChunkName: "07fefd9d" */ "@site/docs/api/lusid/endpoints/blocks/operation-upsert-blocks.mdx"), "@site/docs/api/lusid/endpoints/blocks/operation-upsert-blocks.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/blocks/operation-upsert-blocks.mdx")],
  "084f2d50": [() => import(/* webpackChunkName: "084f2d50" */ "@site/docs/api/lusid/schemas/schema-fx-forward.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward.mdx")],
  "087199da": [() => import(/* webpackChunkName: "087199da" */ "@site/docs/excel/functions/Instruments.mdx"), "@site/docs/excel/functions/Instruments.mdx", require.resolveWeak("@site/docs/excel/functions/Instruments.mdx")],
  "0885bcb5": [() => import(/* webpackChunkName: "0885bcb5" */ "@site/docs/api/lusid/schemas/schema-valuations-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-valuations-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuations-reconciliation-request.mdx")],
  "08a7bcb1": [() => import(/* webpackChunkName: "08a7bcb1" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template-specification.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template-specification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template-specification.mdx")],
  "08ae131d": [() => import(/* webpackChunkName: "08ae131d" */ "@site/docs/api/configuration/schemas/schema-resource-id.mdx"), "@site/docs/api/configuration/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-resource-id.mdx")],
  "08cbd561": [() => import(/* webpackChunkName: "08cbd561" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-scope-definition.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-scope-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-scope-definition.mdx")],
  "0928b306": [() => import(/* webpackChunkName: "0928b306" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule.mdx")],
  "0930e31b": [() => import(/* webpackChunkName: "0930e31b" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-item.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-item.mdx")],
  "09376b58": [() => import(/* webpackChunkName: "09376b58" */ "@site/docs/api/lusid/schemas/schema-set-person-identifiers-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-person-identifiers-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-person-identifiers-request.mdx")],
  "09b78603": [() => import(/* webpackChunkName: "09b78603" */ "@site/docs/api/workflow/schemas/schema-sleep.mdx"), "@site/docs/api/workflow/schemas/schema-sleep.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-sleep.mdx")],
  "09ccecec": [() => import(/* webpackChunkName: "09ccecec" */ "@site/docs/api/lusid/schemas/schema-journal-entry-lines-query-parameters.mdx"), "@site/docs/api/lusid/schemas/schema-journal-entry-lines-query-parameters.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-journal-entry-lines-query-parameters.mdx")],
  "09e13feb": [() => import(/* webpackChunkName: "09e13feb" */ "@site/docs/api/lusid/schemas/schema-upsert-transaction-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-transaction-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-transaction-properties-response.mdx")],
  "09f0e5c7": [() => import(/* webpackChunkName: "09f0e5c7" */ "@site/docs/api/access/endpoints/policies/operation-update-policy.mdx"), "@site/docs/api/access/endpoints/policies/operation-update-policy.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-update-policy.mdx")],
  "0a2b5a3a": [() => import(/* webpackChunkName: "0a2b5a3a" */ "@site/docs/api/luminesce/schemas/schema-available-parameter.mdx"), "@site/docs/api/luminesce/schemas/schema-available-parameter.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-available-parameter.mdx")],
  "0a5af93a": [() => import(/* webpackChunkName: "0a5af93a" */ "@site/docs/api/lusid/endpoints/staging-rule-set/operation-create-staging-rule-set.mdx"), "@site/docs/api/lusid/endpoints/staging-rule-set/operation-create-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staging-rule-set/operation-create-staging-rule-set.mdx")],
  "0a911a29": [() => import(/* webpackChunkName: "0a911a29" */ "@site/docs/api/lusid/endpoints/workspace/operation-list-personal-items.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-list-personal-items.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-list-personal-items.mdx")],
  "0a9400e7": [() => import(/* webpackChunkName: "0a9400e7" */ "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-returns.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-returns.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-returns.mdx")],
  "0ad31c0e": [() => import(/* webpackChunkName: "0ad31c0e" */ "@site/docs/api/horizon/schemas/schema-open-figi-perm-id-result.mdx"), "@site/docs/api/horizon/schemas/schema-open-figi-perm-id-result.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-open-figi-perm-id-result.mdx")],
  "0aeefd1e": [() => import(/* webpackChunkName: "0aeefd1e" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-set-configuration-transaction-types.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-set-configuration-transaction-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-set-configuration-transaction-types.mdx")],
  "0b677930": [() => import(/* webpackChunkName: "0b677930" */ "@site/docs/api/lusid/schemas/schema-update-custom-entity-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-custom-entity-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-custom-entity-type-request.mdx")],
  "0bba9406": [() => import(/* webpackChunkName: "0bba9406" */ "@site/docs/api/lusid/schemas/schema-multi-currency-amounts.mdx"), "@site/docs/api/lusid/schemas/schema-multi-currency-amounts.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-multi-currency-amounts.mdx")],
  "0bc0483f": [() => import(/* webpackChunkName: "0bc0483f" */ "@site/docs/api/lusid/schemas/schema-upsert-portfolio-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-portfolio-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-portfolio-access-metadata-request.mdx")],
  "0bc823ce": [() => import(/* webpackChunkName: "0bc823ce" */ "@site/docs/api/luminesce/schemas/schema-source-type.mdx"), "@site/docs/api/luminesce/schemas/schema-source-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-source-type.mdx")],
  "0c00cbba": [() => import(/* webpackChunkName: "0c00cbba" */ "@site/docs/api/notification/endpoints/notifications/operation-delete-notification.mdx"), "@site/docs/api/notification/endpoints/notifications/operation-delete-notification.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/notifications/operation-delete-notification.mdx")],
  "0c020000": [() => import(/* webpackChunkName: "0c020000" */ "@site/docs/api/lusid/schemas/schema-credit-premium-cash-flow-event.mdx"), "@site/docs/api/lusid/schemas/schema-credit-premium-cash-flow-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-credit-premium-cash-flow-event.mdx")],
  "0c03c1f7": [() => import(/* webpackChunkName: "0c03c1f7" */ "@site/docs/api/lusid/schemas/schema-upsert-credit-support-annex-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-credit-support-annex-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-credit-support-annex-request.mdx")],
  "0c31a5ab": [() => import(/* webpackChunkName: "0c31a5ab" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-relationship.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-relationship.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-relationship.mdx")],
  "0c362cdf": [() => import(/* webpackChunkName: "0c362cdf" */ "@site/docs/api/lusid/schemas/schema-reconcile-date-time-rule.mdx"), "@site/docs/api/lusid/schemas/schema-reconcile-date-time-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconcile-date-time-rule.mdx")],
  "0c46a673": [() => import(/* webpackChunkName: "0c46a673" */ "@site/docs/api/lusid/endpoints/translation/operation-translate-trade-tickets.mdx"), "@site/docs/api/lusid/endpoints/translation/operation-translate-trade-tickets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/translation/operation-translate-trade-tickets.mdx")],
  "0c52a6db": [() => import(/* webpackChunkName: "0c52a6db" */ "@site/docs/api/access/endpoints/roles/operation-add-policy-collection-to-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-add-policy-collection-to-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-add-policy-collection-to-role.mdx")],
  "0c76858e": [() => import(/* webpackChunkName: "0c76858e" */ "@site/docs/api/drive/schemas/schema-update-folder.mdx"), "@site/docs/api/drive/schemas/schema-update-folder.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-update-folder.mdx")],
  "0c819b62": [() => import(/* webpackChunkName: "0c819b62" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity-access-metadata.mdx")],
  "0c9f08b7": [() => import(/* webpackChunkName: "0c9f08b7" */ "@site/docs/api/notification/endpoints/event-types/operation-get-event-type.mdx"), "@site/docs/api/notification/endpoints/event-types/operation-get-event-type.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/event-types/operation-get-event-type.mdx")],
  "0caf30a0": [() => import(/* webpackChunkName: "0caf30a0" */ "@site/docs/api/luminesce/schemas/schema-aggregation.mdx"), "@site/docs/api/luminesce/schemas/schema-aggregation.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-aggregation.mdx")],
  "0cc894ee": [() => import(/* webpackChunkName: "0cc894ee" */ "@site/docs/api/lusid/schemas/schema-transaction-template-specification.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-template-specification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-template-specification.mdx")],
  "0cdeaf2e": [() => import(/* webpackChunkName: "0cdeaf2e" */ "@site/docs/api/web-app/endpoints/reports/operation-get-security-token.mdx"), "@site/docs/api/web-app/endpoints/reports/operation-get-security-token.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/reports/operation-get-security-token.mdx")],
  "0ce10e46": [() => import(/* webpackChunkName: "0ce10e46" */ "@site/docs/api/web-app/schemas/schema-tolerance-rule.mdx"), "@site/docs/api/web-app/schemas/schema-tolerance-rule.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tolerance-rule.mdx")],
  "0d0572ff": [() => import(/* webpackChunkName: "0d0572ff" */ "@site/docs/api/lusid/schemas/schema-instrument-id-value.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-id-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-id-value.mdx")],
  "0d2155d7": [() => import(/* webpackChunkName: "0d2155d7" */ "@site/docs/api/access/endpoints/policies/operation-get-policy.mdx"), "@site/docs/api/access/endpoints/policies/operation-get-policy.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-get-policy.mdx")],
  "0d217bd3": [() => import(/* webpackChunkName: "0d217bd3" */ "@site/docs/api/identity/endpoints/tokens/operation-invalidate-token.mdx"), "@site/docs/api/identity/endpoints/tokens/operation-invalidate-token.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/tokens/operation-invalidate-token.mdx")],
  "0d26c717": [() => import(/* webpackChunkName: "0d26c717" */ "@site/docs/api/lusid/schemas/schema-bond.mdx"), "@site/docs/api/lusid/schemas/schema-bond.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond.mdx")],
  "0d26f514": [() => import(/* webpackChunkName: "0d26f514" */ "@site/docs/api/lusid/endpoints/funds/operation-delete-valuation-point.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-delete-valuation-point.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-delete-valuation-point.mdx")],
  "0d2ca40d": [() => import(/* webpackChunkName: "0d2ca40d" */ "@site/docs/api/lusid/schemas/schema-fx-forward-tenor-pips-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-tenor-pips-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-tenor-pips-curve-data.mdx")],
  "0d773fe6": [() => import(/* webpackChunkName: "0d773fe6" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-instrument-events.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-instrument-events.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-instrument-events.mdx")],
  "0d88e43a": [() => import(/* webpackChunkName: "0d88e43a" */ "@site/docs/api/lusid/schemas/schema-security-election.mdx"), "@site/docs/api/lusid/schemas/schema-security-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-security-election.mdx")],
  "0daddad9": [() => import(/* webpackChunkName: "0daddad9" */ "@site/docs/api/web-app/schemas/schema-link-type.mdx"), "@site/docs/api/web-app/schemas/schema-link-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-link-type.mdx")],
  "0db12769": [() => import(/* webpackChunkName: "0db12769" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-access-metadata-value-of.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-access-metadata-value-of.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-access-metadata-value-of.mdx")],
  "0db1d30e": [() => import(/* webpackChunkName: "0db1d30e" */ "@site/docs/api/lusid/schemas/schema-cds-protection-detail-specification.mdx"), "@site/docs/api/lusid/schemas/schema-cds-protection-detail-specification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cds-protection-detail-specification.mdx")],
  "0db6d6f1": [() => import(/* webpackChunkName: "0db6d6f1" */ "@site/docs/api/lusid/endpoints/participations/operation-list-participations.mdx"), "@site/docs/api/lusid/endpoints/participations/operation-list-participations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/participations/operation-list-participations.mdx")],
  "0dd912e2": [() => import(/* webpackChunkName: "0dd912e2" */ "@site/docs/api/lusid/endpoints/workspace/operation-create-shared-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-create-shared-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-create-shared-workspace.mdx")],
  "0dee44a1": [() => import(/* webpackChunkName: "0dee44a1" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-order-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-order-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-order-detail.mdx")],
  "0df317ce": [() => import(/* webpackChunkName: "0df317ce" */ "@site/docs/api/insights/schemas/schema-bucket.mdx"), "@site/docs/api/insights/schemas/schema-bucket.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-bucket.mdx")],
  "0e13b5f6": [() => import(/* webpackChunkName: "0e13b5f6" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-upsert-fund-configuration-properties.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-upsert-fund-configuration-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-upsert-fund-configuration-properties.mdx")],
  "0e14c112": [() => import(/* webpackChunkName: "0e14c112" */ "@site/docs/api/luminesce/schemas/schema-inlined-property-item.mdx"), "@site/docs/api/luminesce/schemas/schema-inlined-property-item.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-inlined-property-item.mdx")],
  "0e22c09d": [() => import(/* webpackChunkName: "0e22c09d" */ "@site/docs/api/web-app/schemas/schema-aggregation-op.mdx"), "@site/docs/api/web-app/schemas/schema-aggregation-op.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-aggregation-op.mdx")],
  "0e52061b": [() => import(/* webpackChunkName: "0e52061b" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-script.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-script.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-script.mdx")],
  "0e79cc1e": [() => import(/* webpackChunkName: "0e79cc1e" */ "@site/docs/api/identity/schemas/schema-list-users-response.mdx"), "@site/docs/api/identity/schemas/schema-list-users-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-list-users-response.mdx")],
  "0ea44652": [() => import(/* webpackChunkName: "0ea44652" */ "@site/docs/api/identity/schemas/schema-link.mdx"), "@site/docs/api/identity/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-link.mdx")],
  "0ea6810d": [() => import(/* webpackChunkName: "0ea6810d" */ "@site/docs/api/lusid/endpoints/persons/operation-get-all-person-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-all-person-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-all-person-access-metadata.mdx")],
  "0eae672c": [() => import(/* webpackChunkName: "0eae672c" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-id-type-descriptor.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-id-type-descriptor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-id-type-descriptor.mdx")],
  "0eb58b4f": [() => import(/* webpackChunkName: "0eb58b4f" */ "@site/docs/api/luminesce/schemas/schema-certificate-status.mdx"), "@site/docs/api/luminesce/schemas/schema-certificate-status.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-certificate-status.mdx")],
  "0edb3fc8": [() => import(/* webpackChunkName: "0edb3fc8" */ "@site/docs/api/lusid/schemas/schema-swap-principal-event.mdx"), "@site/docs/api/lusid/schemas/schema-swap-principal-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-swap-principal-event.mdx")],
  "0f05acba": [() => import(/* webpackChunkName: "0f05acba" */ "@site/docs/api/lusid/schemas/schema-create-portfolio-details.mdx"), "@site/docs/api/lusid/schemas/schema-create-portfolio-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-portfolio-details.mdx")],
  "0f1620de": [() => import(/* webpackChunkName: "0f1620de" */ "@site/docs/api/workflow/schemas/schema-trigger-parent-task-action-response.mdx"), "@site/docs/api/workflow/schemas/schema-trigger-parent-task-action-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-trigger-parent-task-action-response.mdx")],
  "0f1b88ef": [() => import(/* webpackChunkName: "0f1b88ef" */ "@site/docs/api/lusid/endpoints/workspace/operation-update-shared-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-update-shared-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-update-shared-workspace.mdx")],
  "0f3b6b54": [() => import(/* webpackChunkName: "0f3b6b54" */ "@site/docs/api/lusid/schemas/schema-reconciliation.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation.mdx")],
  "0f3c40d5": [() => import(/* webpackChunkName: "0f3c40d5" */ "@site/docs/api/lusid/schemas/schema-fx-forward-tenor-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-tenor-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-tenor-curve-data.mdx")],
  "0f55a68b": [() => import(/* webpackChunkName: "0f55a68b" */ "@site/docs/api/lusid/schemas/schema-lineage-member.mdx"), "@site/docs/api/lusid/schemas/schema-lineage-member.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lineage-member.mdx")],
  "0f66c551": [() => import(/* webpackChunkName: "0f66c551" */ "@site/docs/api/access/schemas/schema-point-in-time-specification.mdx"), "@site/docs/api/access/schemas/schema-point-in-time-specification.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-point-in-time-specification.mdx")],
  "0f73ba8e": [() => import(/* webpackChunkName: "0f73ba8e" */ "@site/docs/api/drive/schemas/schema-create-folder.mdx"), "@site/docs/api/drive/schemas/schema-create-folder.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-create-folder.mdx")],
  "0f7913b2": [() => import(/* webpackChunkName: "0f7913b2" */ "@site/docs/api/identity/endpoints/authentication/operation-get-authentication-information.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-get-authentication-information.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-get-authentication-information.mdx")],
  "0fb256db": [() => import(/* webpackChunkName: "0fb256db" */ "@site/docs/api/horizon/schemas/schema-trigger.mdx"), "@site/docs/api/horizon/schemas/schema-trigger.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-trigger.mdx")],
  "0fb7bc6e": [() => import(/* webpackChunkName: "0fb7bc6e" */ "@site/docs/api/lusid/endpoints/allocations/operation-delete-allocation.mdx"), "@site/docs/api/lusid/endpoints/allocations/operation-delete-allocation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/allocations/operation-delete-allocation.mdx")],
  "0fb7c7d3": [() => import(/* webpackChunkName: "0fb7c7d3" */ "@site/docs/api/lusid/endpoints/aggregation/operation-generate-configuration-recipe.mdx"), "@site/docs/api/lusid/endpoints/aggregation/operation-generate-configuration-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/aggregation/operation-generate-configuration-recipe.mdx")],
  "0fcd46a3": [() => import(/* webpackChunkName: "0fcd46a3" */ "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-runs.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-runs.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-runs.mdx")],
  "0fe54df9": [() => import(/* webpackChunkName: "0fe54df9" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template-specification.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template-specification.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template-specification.mdx")],
  "0fefeae1": [() => import(/* webpackChunkName: "0fefeae1" */ "@site/docs/api/lusid/schemas/schema-address-key-option-definition.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-option-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-option-definition.mdx")],
  "1026eb32": [() => import(/* webpackChunkName: "1026eb32" */ "@site/docs/api/luminesce/schemas/schema-intellisense-type.mdx"), "@site/docs/api/luminesce/schemas/schema-intellisense-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-intellisense-type.mdx")],
  "102dcf9b": [() => import(/* webpackChunkName: "102dcf9b" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-execution.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-execution.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-execution.mdx")],
  "105cfb59": [() => import(/* webpackChunkName: "105cfb59" */ "@site/docs/api/lusid/endpoints/workspace/operation-get-shared-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-get-shared-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-get-shared-item.mdx")],
  "10677dc1": [() => import(/* webpackChunkName: "10677dc1" */ "@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity-response.mdx"), "@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity-response.mdx")],
  "10960999": [() => import(/* webpackChunkName: "10960999" */ "@site/docs/api/lusid/schemas/schema-inflation-swap.mdx"), "@site/docs/api/lusid/schemas/schema-inflation-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inflation-swap.mdx")],
  "1099fd60": [() => import(/* webpackChunkName: "1099fd60" */ "@site/docs/api/lusid/endpoints/abor/operation-create-abor.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-create-abor.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-create-abor.mdx")],
  "10a9a30d": [() => import(/* webpackChunkName: "10a9a30d" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-account.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-account.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-account.mdx")],
  "10bde69c": [() => import(/* webpackChunkName: "10bde69c" */ "@site/docs/api/horizon/schemas/schema-lusid-field.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-field.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-field.mdx")],
  "10db6dce": [() => import(/* webpackChunkName: "10db6dce" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-cleardown-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-cleardown-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-cleardown-module.mdx")],
  "10ef1fa8": [() => import(/* webpackChunkName: "10ef1fa8" */ "@site/docs/api/luminesce/schemas/schema-background-query-cancel-response.mdx"), "@site/docs/api/luminesce/schemas/schema-background-query-cancel-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-query-cancel-response.mdx")],
  "110c9e60": [() => import(/* webpackChunkName: "110c9e60" */ "@site/docs/api/lusid/endpoints/search/operation-search-properties.mdx"), "@site/docs/api/lusid/endpoints/search/operation-search-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/search/operation-search-properties.mdx")],
  "11273d8e": [() => import(/* webpackChunkName: "11273d8e" */ "@site/docs/api/lusid/schemas/schema-upsert-counterparty-agreement-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-counterparty-agreement-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-counterparty-agreement-request.mdx")],
  "115b30d0": [() => import(/* webpackChunkName: "115b30d0" */ "@site/docs/api/lusid/schemas/schema-address-key-definition.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-definition.mdx")],
  "117039bc": [() => import(/* webpackChunkName: "117039bc" */ "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task-definition.mdx"), "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task-definition.mdx")],
  "11af73fd": [() => import(/* webpackChunkName: "11af73fd" */ "@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-changes.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-changes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-changes.mdx")],
  "11b63fa3": [() => import(/* webpackChunkName: "11b63fa3" */ "@site/docs/api/web-app/schemas/schema-roadmap-item-category.mdx"), "@site/docs/api/web-app/schemas/schema-roadmap-item-category.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-roadmap-item-category.mdx")],
  "11b826c3": [() => import(/* webpackChunkName: "11b826c3" */ "@site/docs/api/drive/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/drive/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-access-controlled-resource.mdx")],
  "11c754d8": [() => import(/* webpackChunkName: "11c754d8" */ "@site/docs/api/scheduler/schemas/schema-action-id.mdx"), "@site/docs/api/scheduler/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-action-id.mdx")],
  "11fe40ae": [() => import(/* webpackChunkName: "11fe40ae" */ "@site/docs/api/lusid/endpoints/conventions/operation-delete-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-delete-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-delete-flow-conventions.mdx")],
  "120f3538": [() => import(/* webpackChunkName: "120f3538" */ "@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-response.mdx"), "@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-response.mdx")],
  "122b5452": [() => import(/* webpackChunkName: "122b5452" */ "@site/docs/api/horizon/schemas/schema-update-instance-request.mdx"), "@site/docs/api/horizon/schemas/schema-update-instance-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-update-instance-request.mdx")],
  "123bc050": [() => import(/* webpackChunkName: "123bc050" */ "@site/docs/api/lusid/schemas/schema-chart-of-accounts-properties.mdx"), "@site/docs/api/lusid/schemas/schema-chart-of-accounts-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-chart-of-accounts-properties.mdx")],
  "12424b23": [() => import(/* webpackChunkName: "12424b23" */ "@site/docs/api/lusid/schemas/schema-share-class-dealing-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-dealing-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-dealing-breakdown.mdx")],
  "1259d4bc": [() => import(/* webpackChunkName: "1259d4bc" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-adjust-holdings.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-adjust-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-adjust-holdings.mdx")],
  "125cb7f3": [() => import(/* webpackChunkName: "125cb7f3" */ "@site/docs/api/lusid/schemas/schema-inflation-linked-bond.mdx"), "@site/docs/api/lusid/schemas/schema-inflation-linked-bond.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inflation-linked-bond.mdx")],
  "12698e10": [() => import(/* webpackChunkName: "12698e10" */ "@site/docs/api/web-app/schemas/schema-algorithm-metadata.mdx"), "@site/docs/api/web-app/schemas/schema-algorithm-metadata.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-algorithm-metadata.mdx")],
  "127ae919": [() => import(/* webpackChunkName: "127ae919" */ "@site/docs/api/workflow/endpoints/workers/operation-delete-worker.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-delete-worker.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-delete-worker.mdx")],
  "127b8c63": [() => import(/* webpackChunkName: "127b8c63" */ "@site/docs/api/web-app/schemas/schema-custom-value.mdx"), "@site/docs/api/web-app/schemas/schema-custom-value.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-custom-value.mdx")],
  "127bf60d": [() => import(/* webpackChunkName: "127bf60d" */ "@site/docs/api/lusid/schemas/schema-create-group-reconciliation-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-group-reconciliation-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-group-reconciliation-definition-request.mdx")],
  "128121e8": [() => import(/* webpackChunkName: "128121e8" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval.mdx")],
  "1285194a": [() => import(/* webpackChunkName: "1285194a" */ "@site/docs/api/lusid/schemas/schema-staged-modification.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modification.mdx")],
  "129ebee1": [() => import(/* webpackChunkName: "129ebee1" */ "@site/docs/api/luminesce/schemas/schema-expression-with-alias.mdx"), "@site/docs/api/luminesce/schemas/schema-expression-with-alias.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-expression-with-alias.mdx")],
  "12a019d4": [() => import(/* webpackChunkName: "12a019d4" */ "@site/docs/api/lusid/schemas/schema-fee-rule-upsert-request.mdx"), "@site/docs/api/lusid/schemas/schema-fee-rule-upsert-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-rule-upsert-request.mdx")],
  "12c7970e": [() => import(/* webpackChunkName: "12c7970e" */ "@site/docs/api/scheduler/endpoints/jobs/operation-get-job-console-output.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-get-job-console-output.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-get-job-console-output.mdx")],
  "12e8139e": [() => import(/* webpackChunkName: "12e8139e" */ "@site/docs/api/lusid/endpoints/abor/operation-add-diary-entry.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-add-diary-entry.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-add-diary-entry.mdx")],
  "1357d007": [() => import(/* webpackChunkName: "1357d007" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-values.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-values.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-values.mdx")],
  "1362cfe0": [() => import(/* webpackChunkName: "1362cfe0" */ "@site/docs/api/lusid/endpoints/persons/operation-get-person-relations.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-person-relations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-person-relations.mdx")],
  "1369ceb4": [() => import(/* webpackChunkName: "1369ceb4" */ "@site/docs/api/insights/schemas/schema-audit-process.mdx"), "@site/docs/api/insights/schemas/schema-audit-process.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-process.mdx")],
  "1390d080": [() => import(/* webpackChunkName: "1390d080" */ "@site/docs/api/lusid/schemas/schema-tender-event.mdx"), "@site/docs/api/lusid/schemas/schema-tender-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-tender-event.mdx")],
  "13a44f43": [() => import(/* webpackChunkName: "13a44f43" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-get-fund-configuration.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-get-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-get-fund-configuration.mdx")],
  "13d1542e": [() => import(/* webpackChunkName: "13d1542e" */ "@site/docs/api/lusid/schemas/schema-dialect-schema.mdx"), "@site/docs/api/lusid/schemas/schema-dialect-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dialect-schema.mdx")],
  "13e321ca": [() => import(/* webpackChunkName: "13e321ca" */ "@site/docs/api/horizon/schemas/schema-instance-identifier.mdx"), "@site/docs/api/horizon/schemas/schema-instance-identifier.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-instance-identifier.mdx")],
  "13f10b6d": [() => import(/* webpackChunkName: "13f10b6d" */ "@site/docs/api/workflow/schemas/schema-deleted-entity-response.mdx"), "@site/docs/api/workflow/schemas/schema-deleted-entity-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-deleted-entity-response.mdx")],
  "1418a235": [() => import(/* webpackChunkName: "1418a235" */ "@site/docs/api/lusid/schemas/schema-side-configuration-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-side-configuration-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-side-configuration-data-request.mdx")],
  "141ab4d0": [() => import(/* webpackChunkName: "141ab4d0" */ "@site/docs/api/luminesce/schemas/schema-background-query-state.mdx"), "@site/docs/api/luminesce/schemas/schema-background-query-state.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-query-state.mdx")],
  "1456f5ea": [() => import(/* webpackChunkName: "1456f5ea" */ "@site/docs/api/lusid/schemas/schema-target-tax-lot.mdx"), "@site/docs/api/lusid/schemas/schema-target-tax-lot.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-target-tax-lot.mdx")],
  "146ecd43": [() => import(/* webpackChunkName: "146ecd43" */ "@site/docs/api/lusid/schemas/schema-cash-offer-election.mdx"), "@site/docs/api/lusid/schemas/schema-cash-offer-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-offer-election.mdx")],
  "1472e5a7": [() => import(/* webpackChunkName: "1472e5a7" */ "@site/docs/api/web-app/schemas/schema-hubspot-identity.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-identity.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-identity.mdx")],
  "1473050f": [() => import(/* webpackChunkName: "1473050f" */ "@site/docs/api/access/endpoints/roles/operation-get-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-get-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-get-role.mdx")],
  "14974e8e": [() => import(/* webpackChunkName: "14974e8e" */ "@site/docs/api/lusid/schemas/schema-transaction-configuration-data.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-configuration-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-configuration-data.mdx")],
  "14d06e36": [() => import(/* webpackChunkName: "14d06e36" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-pipe.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-pipe.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-pipe.mdx")],
  "14d2a82e": [() => import(/* webpackChunkName: "14d2a82e" */ "@site/docs/api/lusid/schemas/schema-stock-dividend-event.mdx"), "@site/docs/api/lusid/schemas/schema-stock-dividend-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-stock-dividend-event.mdx")],
  "14dbc79a": [() => import(/* webpackChunkName: "14dbc79a" */ "@site/docs/api/identity/schemas/schema-error-detail.mdx"), "@site/docs/api/identity/schemas/schema-error-detail.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-error-detail.mdx")],
  "14e79c37": [() => import(/* webpackChunkName: "14e79c37" */ "@site/docs/api/identity/endpoints/users/operation-delete-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-delete-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-delete-user.mdx")],
  "14f83a15": [() => import(/* webpackChunkName: "14f83a15" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity.mdx")],
  "1508decb": [() => import(/* webpackChunkName: "1508decb" */ "@site/docs/api/drive/endpoints/folders/operation-delete-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-delete-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-delete-folder.mdx")],
  "15195abe": [() => import(/* webpackChunkName: "15195abe" */ "@site/docs/api/lusid/schemas/schema-open-event.mdx"), "@site/docs/api/lusid/schemas/schema-open-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-open-event.mdx")],
  "1526042c": [() => import(/* webpackChunkName: "1526042c" */ "@site/docs/api/lusid/schemas/schema-scrip-dividend-event.mdx"), "@site/docs/api/lusid/schemas/schema-scrip-dividend-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-scrip-dividend-event.mdx")],
  "1558b430": [() => import(/* webpackChunkName: "1558b430" */ "@site/docs/api/lusid/schemas/schema-inline-valuation-request.mdx"), "@site/docs/api/lusid/schemas/schema-inline-valuation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inline-valuation-request.mdx")],
  "1564ac95": [() => import(/* webpackChunkName: "1564ac95" */ "@site/docs/api/lusid/schemas/schema-instrument-list.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-list.mdx")],
  "15656a23": [() => import(/* webpackChunkName: "15656a23" */ "@site/docs/api/lusid/endpoints/placements/operation-list-placements.mdx"), "@site/docs/api/lusid/endpoints/placements/operation-list-placements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/placements/operation-list-placements.mdx")],
  "15a07702": [() => import(/* webpackChunkName: "15a07702" */ "@site/docs/api/luminesce/schemas/schema-multi-query-definition-type.mdx"), "@site/docs/api/luminesce/schemas/schema-multi-query-definition-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-multi-query-definition-type.mdx")],
  "15b4a6c4": [() => import(/* webpackChunkName: "15b4a6c4" */ "@site/docs/api/workflow/schemas/schema-worker-configuration.mdx"), "@site/docs/api/workflow/schemas/schema-worker-configuration.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-worker-configuration.mdx")],
  "15eece7a": [() => import(/* webpackChunkName: "15eece7a" */ "@site/docs/api/lusid/schemas/schema-upsert-result-values-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-result-values-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-result-values-data-request.mdx")],
  "1606da1a": [() => import(/* webpackChunkName: "1606da1a" */ "@site/docs/api/horizon/endpoints/vendor/operation-set-optional-mappings-for-product-entity.mdx"), "@site/docs/api/horizon/endpoints/vendor/operation-set-optional-mappings-for-product-entity.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/vendor/operation-set-optional-mappings-for-product-entity.mdx")],
  "161baf7a": [() => import(/* webpackChunkName: "161baf7a" */ "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-create-custom-entity-definition.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-create-custom-entity-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-create-custom-entity-definition.mdx")],
  "168a02dd": [() => import(/* webpackChunkName: "168a02dd" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-values.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-values.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-core-attribute-values.mdx")],
  "1691cb0e": [() => import(/* webpackChunkName: "1691cb0e" */ "@site/docs/api/drive/endpoints/files/operation-update-file-metadata.mdx"), "@site/docs/api/drive/endpoints/files/operation-update-file-metadata.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-update-file-metadata.mdx")],
  "16974d95": [() => import(/* webpackChunkName: "16974d95" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json-proper.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json-proper.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json-proper.mdx")],
  "16a3443e": [() => import(/* webpackChunkName: "16a3443e" */ "@site/docs/api/drive/endpoints/folders/operation-create-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-create-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-create-folder.mdx")],
  "16b6ea80": [() => import(/* webpackChunkName: "16b6ea80" */ "@site/docs/api/lusid/schemas/schema-compliance-template-variation.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-template-variation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-template-variation.mdx")],
  "16bc387b": [() => import(/* webpackChunkName: "16bc387b" */ "@site/docs/api/lusid/endpoints/executions/operation-delete-execution.mdx"), "@site/docs/api/lusid/endpoints/executions/operation-delete-execution.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/executions/operation-delete-execution.mdx")],
  "16c1ee85": [() => import(/* webpackChunkName: "16c1ee85" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-get-multiple-property-definitions.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-get-multiple-property-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-get-multiple-property-definitions.mdx")],
  "16dc87a9": [() => import(/* webpackChunkName: "16dc87a9" */ "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-access-metadata-rules.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-access-metadata-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-access-metadata-rules.mdx")],
  "1712b7a6": [() => import(/* webpackChunkName: "1712b7a6" */ "@site/docs/api/lusid/schemas/schema-credit-spread-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-credit-spread-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-credit-spread-curve-data.mdx")],
  "1728dec4": [() => import(/* webpackChunkName: "1728dec4" */ "@site/docs/api/identity/schemas/schema-support-roles-response.mdx"), "@site/docs/api/identity/schemas/schema-support-roles-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-roles-response.mdx")],
  "173e312c": [() => import(/* webpackChunkName: "173e312c" */ "@site/docs/api/lusid/endpoints/persons/operation-delete-person-properties.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-delete-person-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-delete-person-properties.mdx")],
  "17558372": [() => import(/* webpackChunkName: "17558372" */ "@site/docs/api/scheduler/schemas/schema-image.mdx"), "@site/docs/api/scheduler/schemas/schema-image.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-image.mdx")],
  "1763f337": [() => import(/* webpackChunkName: "1763f337" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-group-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-group-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-group-properties.mdx")],
  "1765b564": [() => import(/* webpackChunkName: "1765b564" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-modules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-modules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-modules.mdx")],
  "1779090e": [() => import(/* webpackChunkName: "1779090e" */ "@site/docs/api/lusid/schemas/schema-transaction-type-movement.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type-movement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type-movement.mdx")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "1789b918": [() => import(/* webpackChunkName: "1789b918" */ "@site/docs/api/access/endpoints/policies/operation-page-policy-collections.mdx"), "@site/docs/api/access/endpoints/policies/operation-page-policy-collections.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-page-policy-collections.mdx")],
  "17b94947": [() => import(/* webpackChunkName: "17b94947" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-rebalance-portfolio-mapping.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-rebalance-portfolio-mapping.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-rebalance-portfolio-mapping.mdx")],
  "17cc2fb5": [() => import(/* webpackChunkName: "17cc2fb5" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-counterparty-agreement-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-counterparty-agreement-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-counterparty-agreement-response.mdx")],
  "17ce0657": [() => import(/* webpackChunkName: "17ce0657" */ "@site/docs/api/scheduler/endpoints/jobs/operation-get-schedules-for-a-job.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-get-schedules-for-a-job.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-get-schedules-for-a-job.mdx")],
  "18045dc7": [() => import(/* webpackChunkName: "18045dc7" */ "@site/docs/api/lusid/schemas/schema-book-transactions-request.mdx"), "@site/docs/api/lusid/schemas/schema-book-transactions-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-book-transactions-request.mdx")],
  "180a9d71": [() => import(/* webpackChunkName: "180a9d71" */ "@site/docs/api/lusid/endpoints/persons/operation-get-person-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-person-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-person-property-time-series.mdx")],
  "183acd50": [() => import(/* webpackChunkName: "183acd50" */ "@site/docs/api/lusid/schemas/schema-complete-relation.mdx"), "@site/docs/api/lusid/schemas/schema-complete-relation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complete-relation.mdx")],
  "18aa1be4": [() => import(/* webpackChunkName: "18aa1be4" */ "@site/docs/api/scheduler/endpoints/jobs/operation-list-jobs.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-list-jobs.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-list-jobs.mdx")],
  "18b55c73": [() => import(/* webpackChunkName: "18b55c73" */ "@site/docs/api/lusid/schemas/schema-result-data-schema.mdx"), "@site/docs/api/lusid/schemas/schema-result-data-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-data-schema.mdx")],
  "18be7d93": [() => import(/* webpackChunkName: "18be7d93" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-sqlite.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-sqlite.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-sqlite.mdx")],
  "18c5adcf": [() => import(/* webpackChunkName: "18c5adcf" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-metadata.mdx")],
  "18d5b0cc": [() => import(/* webpackChunkName: "18d5b0cc" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-result.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-result.mdx")],
  "18ebcf94": [() => import(/* webpackChunkName: "18ebcf94" */ "@site/docs/api/lusid/schemas/schema-unknown.mdx"), "@site/docs/api/lusid/schemas/schema-unknown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-unknown.mdx")],
  "18f207cd": [() => import(/* webpackChunkName: "18f207cd" */ "@site/docs/api/lusid/schemas/schema-set-transaction-configuration-alias.mdx"), "@site/docs/api/lusid/schemas/schema-set-transaction-configuration-alias.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-transaction-configuration-alias.mdx")],
  "18f95660": [() => import(/* webpackChunkName: "18f95660" */ "@site/docs/api/lusid/schemas/schema-side-configuration-data.mdx"), "@site/docs/api/lusid/schemas/schema-side-configuration-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-side-configuration-data.mdx")],
  "191b199f": [() => import(/* webpackChunkName: "191b199f" */ "@site/docs/api/scheduler/endpoints/schedules/operation-get-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-get-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-get-schedule.mdx")],
  "1977c94f": [() => import(/* webpackChunkName: "1977c94f" */ "@site/docs/api/lusid/schemas/schema-instrument-event-instruction.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event-instruction.mdx")],
  "198004be": [() => import(/* webpackChunkName: "198004be" */ "@site/docs/api/lusid/schemas/schema-update-cut-label-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-cut-label-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-cut-label-definition-request.mdx")],
  "199cd57f": [() => import(/* webpackChunkName: "199cd57f" */ "@site/docs/api/insights/schemas/schema-request-log.mdx"), "@site/docs/api/insights/schemas/schema-request-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-request-log.mdx")],
  "199e58b3": [() => import(/* webpackChunkName: "199e58b3" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-trial-balance.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-trial-balance.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-trial-balance.mdx")],
  "19b6b1b0": [() => import(/* webpackChunkName: "19b6b1b0" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-instrument-event-instruction.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-instrument-event-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-instrument-event-instruction.mdx")],
  "19e9d54f": [() => import(/* webpackChunkName: "19e9d54f" */ "@site/docs/api/lusid/endpoints/persons/operation-get-person-relationships.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-person-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-person-relationships.mdx")],
  "1a4d9fcd": [() => import(/* webpackChunkName: "1a4d9fcd" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-start-query.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-start-query.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-start-query.mdx")],
  "1a70d18a": [() => import(/* webpackChunkName: "1a70d18a" */ "@site/docs/api/lusid/schemas/schema-fund-details.mdx"), "@site/docs/api/lusid/schemas/schema-fund-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-details.mdx")],
  "1a9d18b1": [() => import(/* webpackChunkName: "1a9d18b1" */ "@site/docs/api/lusid/schemas/schema-property-key-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-property-key-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-key-compliance-parameter.mdx")],
  "1ab12583": [() => import(/* webpackChunkName: "1ab12583" */ "@site/docs/api/lusid/schemas/schema-inline-valuations-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-inline-valuations-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inline-valuations-reconciliation-request.mdx")],
  "1abfcd25": [() => import(/* webpackChunkName: "1abfcd25" */ "@site/docs/api/lusid/schemas/schema-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-request.mdx")],
  "1afc5bd9": [() => import(/* webpackChunkName: "1afc5bd9" */ "@site/docs/api/lusid/schemas/schema-component-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-component-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-component-transaction.mdx")],
  "1b117ae4": [() => import(/* webpackChunkName: "1b117ae4" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-design-to-sql.mdx")],
  "1b1fd706": [() => import(/* webpackChunkName: "1b1fd706" */ "@site/docs/api/lusid/schemas/schema-upsert-single-structured-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-single-structured-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-single-structured-data-response.mdx")],
  "1b35f95c": [() => import(/* webpackChunkName: "1b35f95c" */ "@site/docs/api/lusid/endpoints/custom-entity-types/operation-get-custom-entity-type.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-types/operation-get-custom-entity-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-types/operation-get-custom-entity-type.mdx")],
  "1b388440": [() => import(/* webpackChunkName: "1b388440" */ "@site/docs/api/lusid/endpoints/complex-market-data/operation-get-complex-market-data.mdx"), "@site/docs/api/lusid/endpoints/complex-market-data/operation-get-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/complex-market-data/operation-get-complex-market-data.mdx")],
  "1b4edeca": [() => import(/* webpackChunkName: "1b4edeca" */ "@site/docs/api/lusid/endpoints/instrument-events/operation-query-cash-flows.mdx"), "@site/docs/api/lusid/endpoints/instrument-events/operation-query-cash-flows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-events/operation-query-cash-flows.mdx")],
  "1b906607": [() => import(/* webpackChunkName: "1b906607" */ "@site/docs/api/access/endpoints/policies/operation-create-policy.mdx"), "@site/docs/api/access/endpoints/policies/operation-create-policy.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-create-policy.mdx")],
  "1b9712d3": [() => import(/* webpackChunkName: "1b9712d3" */ "@site/docs/api/access/endpoints/roles/operation-create-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-create-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-create-role.mdx")],
  "1bb0a916": [() => import(/* webpackChunkName: "1bb0a916" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-rule.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-attribute-rule.mdx")],
  "1bbf3fd5": [() => import(/* webpackChunkName: "1bbf3fd5" */ "@site/docs/api/lusid/endpoints/entities/operation-get-data-type-by-entity-unique-id.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-data-type-by-entity-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-data-type-by-entity-unique-id.mdx")],
  "1bc4f789": [() => import(/* webpackChunkName: "1bc4f789" */ "@site/docs/api/lusid/schemas/schema-placement-update-request.mdx"), "@site/docs/api/lusid/schemas/schema-placement-update-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-placement-update-request.mdx")],
  "1be27783": [() => import(/* webpackChunkName: "1be27783" */ "@site/docs/api/access/schemas/schema-user-role-creation-request.mdx"), "@site/docs/api/access/schemas/schema-user-role-creation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-user-role-creation-request.mdx")],
  "1bed9fc1": [() => import(/* webpackChunkName: "1bed9fc1" */ "@site/docs/api/lusid/endpoints/orders/operation-delete-order.mdx"), "@site/docs/api/lusid/endpoints/orders/operation-delete-order.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/orders/operation-delete-order.mdx")],
  "1c0e4a49": [() => import(/* webpackChunkName: "1c0e4a49" */ "@site/docs/api/luminesce/error-codes.mdx"), "@site/docs/api/luminesce/error-codes.mdx", require.resolveWeak("@site/docs/api/luminesce/error-codes.mdx")],
  "1c5dd3ac": [() => import(/* webpackChunkName: "1c5dd3ac" */ "@site/docs/api/lusid/schemas/schema-result-value.mdx"), "@site/docs/api/lusid/schemas/schema-result-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value.mdx")],
  "1c61217d": [() => import(/* webpackChunkName: "1c61217d" */ "@site/docs/excel/functions/Portfolios.mdx"), "@site/docs/excel/functions/Portfolios.mdx", require.resolveWeak("@site/docs/excel/functions/Portfolios.mdx")],
  "1c6c00af": [() => import(/* webpackChunkName: "1c6c00af" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown-request.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown-request.mdx")],
  "1c9540b6": [() => import(/* webpackChunkName: "1c9540b6" */ "@site/docs/api/lusid/schemas/schema-upsert-flow-conventions-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-flow-conventions-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-flow-conventions-request.mdx")],
  "1c9ca623": [() => import(/* webpackChunkName: "1c9ca623" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-match-key.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-match-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-match-key.mdx")],
  "1ca66ee3": [() => import(/* webpackChunkName: "1ca66ee3" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-holding-contributor.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-holding-contributor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-holding-contributor.mdx")],
  "1cab825f": [() => import(/* webpackChunkName: "1cab825f" */ "@site/docs/api/lusid/endpoints/order-management/operation-update-placements.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-update-placements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-update-placements.mdx")],
  "1cc161c3": [() => import(/* webpackChunkName: "1cc161c3" */ "@site/docs/api/workflow/schemas/schema-sleep-response.mdx"), "@site/docs/api/workflow/schemas/schema-sleep-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-sleep-response.mdx")],
  "1cf0b06e": [() => import(/* webpackChunkName: "1cf0b06e" */ "@site/docs/api/insights/schemas/schema-audit-data-summary.mdx"), "@site/docs/api/insights/schemas/schema-audit-data-summary.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-data-summary.mdx")],
  "1cfe6bc9": [() => import(/* webpackChunkName: "1cfe6bc9" */ "@site/docs/api/lusid/schemas/schema-decimal-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-decimal-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-decimal-list-compliance-parameter.mdx")],
  "1d55a17a": [() => import(/* webpackChunkName: "1d55a17a" */ "@site/docs/api/lusid/schemas/schema-a-2-b-movement-record.mdx"), "@site/docs/api/lusid/schemas/schema-a-2-b-movement-record.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-a-2-b-movement-record.mdx")],
  "1d68c2f5": [() => import(/* webpackChunkName: "1d68c2f5" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-identifiers.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-identifiers.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-identifiers.mdx")],
  "1d896315": [() => import(/* webpackChunkName: "1d896315" */ "@site/docs/api/horizon/schemas/schema-onboard-instrument-request.mdx"), "@site/docs/api/horizon/schemas/schema-onboard-instrument-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-onboard-instrument-request.mdx")],
  "1da3abbd": [() => import(/* webpackChunkName: "1da3abbd" */ "@site/docs/api/notification/endpoints/notifications/operation-create-notification.mdx"), "@site/docs/api/notification/endpoints/notifications/operation-create-notification.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/notifications/operation-create-notification.mdx")],
  "1dcdda2b": [() => import(/* webpackChunkName: "1dcdda2b" */ "@site/docs/api/notification/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/notification/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-access-controlled-action.mdx")],
  "1dcf9eba": [() => import(/* webpackChunkName: "1dcf9eba" */ "@site/docs/api/lusid/schemas/schema-reconcile-string-rule.mdx"), "@site/docs/api/lusid/schemas/schema-reconcile-string-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconcile-string-rule.mdx")],
  "1dec3219": [() => import(/* webpackChunkName: "1dec3219" */ "@site/docs/api/notification/schemas/schema-matching-pattern.mdx"), "@site/docs/api/notification/schemas/schema-matching-pattern.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-matching-pattern.mdx")],
  "1df93b7f": [() => import(/* webpackChunkName: "1df93b7f" */ "@site/src/pages/index.tsx"), "@site/src/pages/index.tsx", require.resolveWeak("@site/src/pages/index.tsx")],
  "1e0a9285": [() => import(/* webpackChunkName: "1e0a9285" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-instrument-events.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-instrument-events.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-instrument-events.mdx")],
  "1e10e39b": [() => import(/* webpackChunkName: "1e10e39b" */ "@site/docs/api/lusid/schemas/schema-pricing-context.mdx"), "@site/docs/api/lusid/schemas/schema-pricing-context.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-pricing-context.mdx")],
  "1e232440": [() => import(/* webpackChunkName: "1e232440" */ "@site/docs/api/lusid/schemas/schema-quote.mdx"), "@site/docs/api/lusid/schemas/schema-quote.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote.mdx")],
  "1e2fb32d": [() => import(/* webpackChunkName: "1e2fb32d" */ "@site/docs/api/lusid/schemas/schema-settlement-cycle.mdx"), "@site/docs/api/lusid/schemas/schema-settlement-cycle.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-settlement-cycle.mdx")],
  "1e51af55": [() => import(/* webpackChunkName: "1e51af55" */ "@site/docs/api/identity/endpoints/roles/operation-get-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-get-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-get-role.mdx")],
  "1e65943d": [() => import(/* webpackChunkName: "1e65943d" */ "@site/docs/api/lusid/endpoints/address-key-definition/operation-create-address-key-definition.mdx"), "@site/docs/api/lusid/endpoints/address-key-definition/operation-create-address-key-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/address-key-definition/operation-create-address-key-definition.mdx")],
  "1e787642": [() => import(/* webpackChunkName: "1e787642" */ "@site/docs/api/configuration/schemas/schema-personal-access-token.mdx"), "@site/docs/api/configuration/schemas/schema-personal-access-token.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-personal-access-token.mdx")],
  "1e88f9a6": [() => import(/* webpackChunkName: "1e88f9a6" */ "@site/docs/api/lusid/endpoints/instrument-events/operation-query-bucketed-cash-flows.mdx"), "@site/docs/api/lusid/endpoints/instrument-events/operation-query-bucketed-cash-flows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-events/operation-query-bucketed-cash-flows.mdx")],
  "1e8a6009": [() => import(/* webpackChunkName: "1e8a6009" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-adjust-holdings.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-adjust-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-adjust-holdings.mdx")],
  "1e8dad8e": [() => import(/* webpackChunkName: "1e8dad8e" */ "@site/docs/api/lusid/schemas/schema-branch-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-branch-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-branch-step-request.mdx")],
  "1eadb3ae": [() => import(/* webpackChunkName: "1eadb3ae" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition-property-time-series.mdx")],
  "1eb86960": [() => import(/* webpackChunkName: "1eb86960" */ "@site/docs/api/scheduler/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/scheduler/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-lusid-validation-problem-details.mdx")],
  "1f002cba": [() => import(/* webpackChunkName: "1f002cba" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-patch-fund-configuration.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-patch-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-patch-fund-configuration.mdx")],
  "1f271c72": [() => import(/* webpackChunkName: "1f271c72" */ "@site/docs/api/horizon/endpoints/runs/operation-get-run-results.mdx"), "@site/docs/api/horizon/endpoints/runs/operation-get-run-results.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/runs/operation-get-run-results.mdx")],
  "1f2b2150": [() => import(/* webpackChunkName: "1f2b2150" */ "@site/docs/api/lusid/schemas/schema-aggregation-options.mdx"), "@site/docs/api/lusid/schemas/schema-aggregation-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregation-options.mdx")],
  "1f391288": [() => import(/* webpackChunkName: "1f391288" */ "@site/docs/api/luminesce/schemas/schema-task-status.mdx"), "@site/docs/api/luminesce/schemas/schema-task-status.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-task-status.mdx")],
  "1f4371f4": [() => import(/* webpackChunkName: "1f4371f4" */ "@site/docs/api/identity/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/identity/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-identifier-part-schema.mdx")],
  "1f45382d": [() => import(/* webpackChunkName: "1f45382d" */ "@site/docs/api/horizon/schemas/schema-field-mapping.mdx"), "@site/docs/api/horizon/schemas/schema-field-mapping.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-field-mapping.mdx")],
  "1f55b687": [() => import(/* webpackChunkName: "1f55b687" */ "@site/docs/api/lusid/endpoints/order-management/operation-create-orders.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-create-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-create-orders.mdx")],
  "1f5afa50": [() => import(/* webpackChunkName: "1f5afa50" */ "@site/docs/api/lusid/schemas/schema-custodian-account-properties.mdx"), "@site/docs/api/lusid/schemas/schema-custodian-account-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custodian-account-properties.mdx")],
  "1f6a0c48": [() => import(/* webpackChunkName: "1f6a0c48" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-fee-rule.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-fee-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-fee-rule.mdx")],
  "1f7d4ad1": [() => import(/* webpackChunkName: "1f7d4ad1" */ "@site/docs/api/scheduler/schemas/schema-vulnerability.mdx"), "@site/docs/api/scheduler/schemas/schema-vulnerability.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-vulnerability.mdx")],
  "1f7e4925": [() => import(/* webpackChunkName: "1f7e4925" */ "@site/docs/api/scheduler/schemas/schema-repository.mdx"), "@site/docs/api/scheduler/schemas/schema-repository.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-repository.mdx")],
  "1f8f89cd": [() => import(/* webpackChunkName: "1f8f89cd" */ "@site/docs/api/scheduler/endpoints/jobs/operation-update-job.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-update-job.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-update-job.mdx")],
  "1f913f85": [() => import(/* webpackChunkName: "1f913f85" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-chart-of-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-chart-of-accounts.mdx")],
  "1fa9cc1b": [() => import(/* webpackChunkName: "1fa9cc1b" */ "@site/docs/api/lusid/schemas/schema-credit-default-swap.mdx"), "@site/docs/api/lusid/schemas/schema-credit-default-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-credit-default-swap.mdx")],
  "1faa6774": [() => import(/* webpackChunkName: "1faa6774" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-translation-script-id.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-translation-script-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-translation-script-id.mdx")],
  "1fc2ff11": [() => import(/* webpackChunkName: "1fc2ff11" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-parquet.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-parquet.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-parquet.mdx")],
  "20015ac8": [() => import(/* webpackChunkName: "20015ac8" */ "@site/docs/api/luminesce/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/luminesce/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "2005ab6e": [() => import(/* webpackChunkName: "2005ab6e" */ "@site/docs/api/lusid/schemas/schema-book-transactions-response.mdx"), "@site/docs/api/lusid/schemas/schema-book-transactions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-book-transactions-response.mdx")],
  "200f6bfe": [() => import(/* webpackChunkName: "200f6bfe" */ "@site/docs/api/lusid/schemas/schema-set-person-properties-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-person-properties-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-person-properties-request.mdx")],
  "201a3d2e": [() => import(/* webpackChunkName: "201a3d2e" */ "@site/docs/api/horizon/endpoints/vendor/operation-get-core-field-mappings-for-product-entity.mdx"), "@site/docs/api/horizon/endpoints/vendor/operation-get-core-field-mappings-for-product-entity.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/vendor/operation-get-core-field-mappings-for-product-entity.mdx")],
  "202152b8": [() => import(/* webpackChunkName: "202152b8" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relationships.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relationships.mdx")],
  "20228e52": [() => import(/* webpackChunkName: "20228e52" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-structured-result-data.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-structured-result-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-get-structured-result-data.mdx")],
  "20595321": [() => import(/* webpackChunkName: "20595321" */ "@site/docs/api/horizon/intro.md"), "@site/docs/api/horizon/intro.md", require.resolveWeak("@site/docs/api/horizon/intro.md")],
  "2079d42e": [() => import(/* webpackChunkName: "2079d42e" */ "@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation-of-weighted-instruments.mdx"), "@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation-of-weighted-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation-of-weighted-instruments.mdx")],
  "20c51d1f": [() => import(/* webpackChunkName: "20c51d1f" */ "@site/docs/api/web-app/schemas/schema-cash-rules.mdx"), "@site/docs/api/web-app/schemas/schema-cash-rules.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-cash-rules.mdx")],
  "20dbb27e": [() => import(/* webpackChunkName: "20dbb27e" */ "@site/docs/api/lusid/schemas/schema-data-type-entity.mdx"), "@site/docs/api/lusid/schemas/schema-data-type-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-type-entity.mdx")],
  "20fe5376": [() => import(/* webpackChunkName: "20fe5376" */ "@site/docs/api/lusid/schemas/schema-basket.mdx"), "@site/docs/api/lusid/schemas/schema-basket.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-basket.mdx")],
  "211b4413": [() => import(/* webpackChunkName: "211b4413" */ "@site/docs/api/lusid/schemas/schema-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-model-options.mdx")],
  "21668df0": [() => import(/* webpackChunkName: "21668df0" */ "@site/docs/api/lusid/schemas/schema-upsert-structured-result-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-structured-result-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-structured-result-data-request.mdx")],
  "216e7e6f": [() => import(/* webpackChunkName: "216e7e6f" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-payment-diary.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-payment-diary.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-payment-diary.mdx")],
  "219a2c42": [() => import(/* webpackChunkName: "219a2c42" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-cancel-query.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-cancel-query.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-cancel-query.mdx")],
  "21dd9748": [() => import(/* webpackChunkName: "21dd9748" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-aggregated-returns-dispersion-metrics.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-aggregated-returns-dispersion-metrics.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-aggregated-returns-dispersion-metrics.mdx")],
  "21f2b72a": [() => import(/* webpackChunkName: "21f2b72a" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-parquet.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-parquet.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-parquet.mdx")],
  "21f6e090": [() => import(/* webpackChunkName: "21f6e090" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-create-scheduled-reconciliation.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-create-scheduled-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-create-scheduled-reconciliation.mdx")],
  "223bf0e7": [() => import(/* webpackChunkName: "223bf0e7" */ "@site/docs/api/lusid/schemas/schema-data-map-key.mdx"), "@site/docs/api/lusid/schemas/schema-data-map-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-map-key.mdx")],
  "2248eafd": [() => import(/* webpackChunkName: "2248eafd" */ "@site/docs/api/lusid/schemas/schema-result-value-int.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-int.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-int.mdx")],
  "2288a2f9": [() => import(/* webpackChunkName: "2288a2f9" */ "@site/docs/api/access/schemas/schema-role-response.mdx"), "@site/docs/api/access/schemas/schema-role-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-role-response.mdx")],
  "2291659f": [() => import(/* webpackChunkName: "2291659f" */ "@site/docs/api/lusid/schemas/schema-create-trade-tickets-response.mdx"), "@site/docs/api/lusid/schemas/schema-create-trade-tickets-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-trade-tickets-response.mdx")],
  "229c7dc6": [() => import(/* webpackChunkName: "229c7dc6" */ "@site/docs/api/notification/schemas/schema-webhook-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-webhook-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-webhook-notification-type.mdx")],
  "22a09f60": [() => import(/* webpackChunkName: "22a09f60" */ "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-rules.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-rules.mdx")],
  "22a56093": [() => import(/* webpackChunkName: "22a56093" */ "@site/docs/api/drive/endpoints/files/operation-update-file-contents.mdx"), "@site/docs/api/drive/endpoints/files/operation-update-file-contents.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-update-file-contents.mdx")],
  "22b1418f": [() => import(/* webpackChunkName: "22b1418f" */ "@site/docs/api/lusid/schemas/schema-cleardown-module-response.mdx"), "@site/docs/api/lusid/schemas/schema-cleardown-module-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cleardown-module-response.mdx")],
  "22e47f56": [() => import(/* webpackChunkName: "22e47f56" */ "@site/docs/api/lusid/schemas/schema-share-class-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-breakdown.mdx")],
  "23043d08": [() => import(/* webpackChunkName: "23043d08" */ "@site/docs/api/lusid/endpoints/reference-portfolio/operation-create-reference-portfolio.mdx"), "@site/docs/api/lusid/endpoints/reference-portfolio/operation-create-reference-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-portfolio/operation-create-reference-portfolio.mdx")],
  "23170adb": [() => import(/* webpackChunkName: "23170adb" */ "@site/docs/api/workflow/schemas/schema-trigger-schema.mdx"), "@site/docs/api/workflow/schemas/schema-trigger-schema.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-trigger-schema.mdx")],
  "232881e0": [() => import(/* webpackChunkName: "232881e0" */ "@site/docs/api/lusid/schemas/schema-realised-gain-loss.mdx"), "@site/docs/api/lusid/schemas/schema-realised-gain-loss.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-realised-gain-loss.mdx")],
  "23304b05": [() => import(/* webpackChunkName: "23304b05" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-rules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-rules.mdx")],
  "233c8ee9": [() => import(/* webpackChunkName: "233c8ee9" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-close-ticket.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-close-ticket.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-close-ticket.mdx")],
  "2378520f": [() => import(/* webpackChunkName: "2378520f" */ "@site/docs/api/lusid/schemas/schema-output-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-output-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-output-transaction.mdx")],
  "23a98000": [() => import(/* webpackChunkName: "23a98000" */ "@site/docs/api/web-app/schemas/schema-cash-rule.mdx"), "@site/docs/api/web-app/schemas/schema-cash-rule.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-cash-rule.mdx")],
  "23d32456": [() => import(/* webpackChunkName: "23d32456" */ "@site/docs/api/lusid/schemas/schema-rounding-configuration-component.mdx"), "@site/docs/api/lusid/schemas/schema-rounding-configuration-component.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-rounding-configuration-component.mdx")],
  "23df4cf2": [() => import(/* webpackChunkName: "23df4cf2" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-transactions-for-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-transactions-for-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-transactions-for-portfolio-group.mdx")],
  "23e74454": [() => import(/* webpackChunkName: "23e74454" */ "@site/docs/api/lusid/endpoints/staging-rule-set/operation-update-staging-rule-set.mdx"), "@site/docs/api/lusid/endpoints/staging-rule-set/operation-update-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staging-rule-set/operation-update-staging-rule-set.mdx")],
  "23eb7cf0": [() => import(/* webpackChunkName: "23eb7cf0" */ "@site/docs/api/lusid/schemas/schema-discount-factor-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-discount-factor-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-discount-factor-curve-data.mdx")],
  "2408cfd2": [() => import(/* webpackChunkName: "2408cfd2" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-accounts.mdx")],
  "24354e18": [() => import(/* webpackChunkName: "24354e18" */ "@site/docs/api/lusid/schemas/schema-fund-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-configuration.mdx")],
  "24599423": [() => import(/* webpackChunkName: "24599423" */ "@site/docs/api/lusid/schemas/schema-transaction-query-parameters.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-query-parameters.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-query-parameters.mdx")],
  "2481963a": [() => import(/* webpackChunkName: "2481963a" */ "@site/docs/api/lusid/schemas/schema-instrument-match.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-match.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-match.mdx")],
  "248e6989": [() => import(/* webpackChunkName: "248e6989" */ "@site/docs/api/web-app/schemas/schema-delete-shared-view-request.mdx"), "@site/docs/api/web-app/schemas/schema-delete-shared-view-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-delete-shared-view-request.mdx")],
  "249ca29e": [() => import(/* webpackChunkName: "249ca29e" */ "@site/docs/api/scheduler/endpoints/jobs/operation-create-job.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-create-job.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-create-job.mdx")],
  "24c54721": [() => import(/* webpackChunkName: "24c54721" */ "@site/docs/api/lusid/endpoints/packages/operation-upsert-packages.mdx"), "@site/docs/api/lusid/endpoints/packages/operation-upsert-packages.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/packages/operation-upsert-packages.mdx")],
  "24d56b6f": [() => import(/* webpackChunkName: "24d56b6f" */ "@site/docs/api/insights/schemas/schema-create-audit-entry.mdx"), "@site/docs/api/insights/schemas/schema-create-audit-entry.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-create-audit-entry.mdx")],
  "24d64d7f": [() => import(/* webpackChunkName: "24d64d7f" */ "@site/docs/api/luminesce/schemas/schema-cursor-position.mdx"), "@site/docs/api/luminesce/schemas/schema-cursor-position.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-cursor-position.mdx")],
  "24fa553c": [() => import(/* webpackChunkName: "24fa553c" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-calendar-date.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-calendar-date.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-calendar-date.mdx")],
  "25164567": [() => import(/* webpackChunkName: "25164567" */ "@site/docs/api/lusid/endpoints/persons/operation-get-person-access-metadata-by-key.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-person-access-metadata-by-key.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-person-access-metadata-by-key.mdx")],
  "252c356a": [() => import(/* webpackChunkName: "252c356a" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modification.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modification.mdx")],
  "252e55c1": [() => import(/* webpackChunkName: "252e55c1" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-batch-upsert-corporate-actions.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-batch-upsert-corporate-actions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-batch-upsert-corporate-actions.mdx")],
  "255840bb": [() => import(/* webpackChunkName: "255840bb" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-access-metadata-by-key.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-access-metadata-by-key.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-access-metadata-by-key.mdx")],
  "256a95b4": [() => import(/* webpackChunkName: "256a95b4" */ "@site/docs/api/lusid/endpoints/abor/operation-re-open-periods.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-re-open-periods.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-re-open-periods.mdx")],
  "256f2713": [() => import(/* webpackChunkName: "256f2713" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-pipe.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-pipe.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-pipe.mdx")],
  "2572b86c": [() => import(/* webpackChunkName: "2572b86c" */ "@site/docs/api/access/endpoints/policy-templates/operation-create-policy-template.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-create-policy-template.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-create-policy-template.mdx")],
  "2584804a": [() => import(/* webpackChunkName: "2584804a" */ "@site/docs/api/identity/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/identity/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-access-controlled-action.mdx")],
  "259af9b4": [() => import(/* webpackChunkName: "259af9b4" */ "@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-response.mdx"), "@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-response.mdx")],
  "25cb1cde": [() => import(/* webpackChunkName: "25cb1cde" */ "@site/docs/api/lusid/schemas/schema-mbs-principal-write-off-event.mdx"), "@site/docs/api/lusid/schemas/schema-mbs-principal-write-off-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mbs-principal-write-off-event.mdx")],
  "25e6bc49": [() => import(/* webpackChunkName: "25e6bc49" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definitions.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-side-definitions.mdx")],
  "25ed504a": [() => import(/* webpackChunkName: "25ed504a" */ "@site/docs/api/scheduler/intro.md"), "@site/docs/api/scheduler/intro.md", require.resolveWeak("@site/docs/api/scheduler/intro.md")],
  "260a13b9": [() => import(/* webpackChunkName: "260a13b9" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-details.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-details.mdx")],
  "260a9c89": [() => import(/* webpackChunkName: "260a9c89" */ "@site/docs/api/lusid/endpoints/funds/operation-delete-fund.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-delete-fund.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-delete-fund.mdx")],
  "2649aacb": [() => import(/* webpackChunkName: "2649aacb" */ "@site/docs/api/lusid/schemas/schema-re-open-period-diary-entry-request.mdx"), "@site/docs/api/lusid/schemas/schema-re-open-period-diary-entry-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-re-open-period-diary-entry-request.mdx")],
  "2657eb6e": [() => import(/* webpackChunkName: "2657eb6e" */ "@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-instruments.mdx"), "@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-instruments.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-instruments.mdx")],
  "265b4555": [() => import(/* webpackChunkName: "265b4555" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-sequence-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-sequence-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-sequence-definition.mdx")],
  "267bedce": [() => import(/* webpackChunkName: "267bedce" */ "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json"), "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json", require.resolveWeak("/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-pages/default/plugin-route-context-module-100.json")],
  "26927e67": [() => import(/* webpackChunkName: "26927e67" */ "@site/docs/api/lusid/endpoints/executions/operation-list-executions.mdx"), "@site/docs/api/lusid/endpoints/executions/operation-list-executions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/executions/operation-list-executions.mdx")],
  "26a1b8f1": [() => import(/* webpackChunkName: "26a1b8f1" */ "@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap-history.mdx"), "@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap-history.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap-history.mdx")],
  "26ae64d7": [() => import(/* webpackChunkName: "26ae64d7" */ "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-properties.mdx")],
  "26f6f191": [() => import(/* webpackChunkName: "26f6f191" */ "@site/docs/api/notification/endpoints/subscriptions/operation-list-subscriptions.mdx"), "@site/docs/api/notification/endpoints/subscriptions/operation-list-subscriptions.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/subscriptions/operation-list-subscriptions.mdx")],
  "2737ae01": [() => import(/* webpackChunkName: "2737ae01" */ "@site/docs/api/identity/schemas/schema-password-policy-response-conditions.mdx"), "@site/docs/api/identity/schemas/schema-password-policy-response-conditions.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-password-policy-response-conditions.mdx")],
  "273b84b7": [() => import(/* webpackChunkName: "273b84b7" */ "@site/docs/api/access/schemas/schema-non-transitive-supervisor-role-resource.mdx"), "@site/docs/api/access/schemas/schema-non-transitive-supervisor-role-resource.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-non-transitive-supervisor-role-resource.mdx")],
  "2755ccf3": [() => import(/* webpackChunkName: "2755ccf3" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-run-results.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-run-results.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-run-results.mdx")],
  "2757fc5f": [() => import(/* webpackChunkName: "2757fc5f" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entity-access-metadata.mdx")],
  "2764262c": [() => import(/* webpackChunkName: "2764262c" */ "@site/docs/api/workflow/schemas/schema-task-transition-definition.mdx"), "@site/docs/api/workflow/schemas/schema-task-transition-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-transition-definition.mdx")],
  "276ef1d8": [() => import(/* webpackChunkName: "276ef1d8" */ "@site/docs/api/lusid/schemas/schema-reconciliation-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-configuration.mdx")],
  "278d7a8b": [() => import(/* webpackChunkName: "278d7a8b" */ "@site/docs/api/lusid/schemas/schema-bonus-issue-event.mdx"), "@site/docs/api/lusid/schemas/schema-bonus-issue-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bonus-issue-event.mdx")],
  "27926a5c": [() => import(/* webpackChunkName: "27926a5c" */ "@site/docs/api/lusid/schemas/schema-a-2-b-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-a-2-b-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-a-2-b-breakdown.mdx")],
  "2792ebcf": [() => import(/* webpackChunkName: "2792ebcf" */ "@site/docs/api/lusid/endpoints/sequences/operation-next.mdx"), "@site/docs/api/lusid/endpoints/sequences/operation-next.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/sequences/operation-next.mdx")],
  "2794f556": [() => import(/* webpackChunkName: "2794f556" */ "@site/docs/api/lusid/schemas/schema-calendar-date.mdx"), "@site/docs/api/lusid/schemas/schema-calendar-date.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-calendar-date.mdx")],
  "27b7f37d": [() => import(/* webpackChunkName: "27b7f37d" */ "@site/docs/api/luminesce/schemas/schema-query-designer-binary-operator.mdx"), "@site/docs/api/luminesce/schemas/schema-query-designer-binary-operator.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-query-designer-binary-operator.mdx")],
  "282cfec2": [() => import(/* webpackChunkName: "282cfec2" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-side-definition.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-side-definition.mdx")],
  "285310de": [() => import(/* webpackChunkName: "285310de" */ "@site/docs/api/horizon/schemas/schema-perm-id-data.mdx"), "@site/docs/api/horizon/schemas/schema-perm-id-data.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-perm-id-data.mdx")],
  "287bf912": [() => import(/* webpackChunkName: "287bf912" */ "@site/docs/api/web-app/schemas/schema-run-block-job-request.mdx"), "@site/docs/api/web-app/schemas/schema-run-block-job-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-run-block-job-request.mdx")],
  "28879e3a": [() => import(/* webpackChunkName: "28879e3a" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-portfolio-mappings.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-portfolio-mappings.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-portfolio-mappings.mdx")],
  "28a5df23": [() => import(/* webpackChunkName: "28a5df23" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transaction-properties.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transaction-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transaction-properties.mdx")],
  "28f20a38": [() => import(/* webpackChunkName: "28f20a38" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group.mdx")],
  "2901e4da": [() => import(/* webpackChunkName: "2901e4da" */ "@site/docs/api/lusid/schemas/schema-performance-returns-metric.mdx"), "@site/docs/api/lusid/schemas/schema-performance-returns-metric.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-performance-returns-metric.mdx")],
  "2915fafe": [() => import(/* webpackChunkName: "2915fafe" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-instruction.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-instruction.mdx")],
  "29a94d6d": [() => import(/* webpackChunkName: "29a94d6d" */ "@site/docs/api/lusid/endpoints/search/operation-search-portfolio-groups.mdx"), "@site/docs/api/lusid/endpoints/search/operation-search-portfolio-groups.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/search/operation-search-portfolio-groups.mdx")],
  "29b3325c": [() => import(/* webpackChunkName: "29b3325c" */ "@site/docs/api/lusid/schemas/schema-portfolio-properties.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-properties.mdx")],
  "29c3efe9": [() => import(/* webpackChunkName: "29c3efe9" */ "@site/docs/api/lusid/schemas/schema-delete-relation-request.mdx"), "@site/docs/api/lusid/schemas/schema-delete-relation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-relation-request.mdx")],
  "29cb87c1": [() => import(/* webpackChunkName: "29cb87c1" */ "@site/docs/api/lusid/schemas/schema-compliance-template-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-template-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-template-parameter.mdx")],
  "29cf3cc9": [() => import(/* webpackChunkName: "29cf3cc9" */ "@site/docs/api/horizon/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-validation-problem-details.mdx")],
  "29d01f45": [() => import(/* webpackChunkName: "29d01f45" */ "@site/docs/api/lusid/schemas/schema-lock-period-diary-entry-request.mdx"), "@site/docs/api/lusid/schemas/schema-lock-period-diary-entry-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lock-period-diary-entry-request.mdx")],
  "29d3faf4": [() => import(/* webpackChunkName: "29d3faf4" */ "@site/docs/api/notification/schemas/schema-email-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-email-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-email-notification-type-response.mdx")],
  "29e75ec4": [() => import(/* webpackChunkName: "29e75ec4" */ "@site/docs/api/lusid/schemas/schema-cleardown-module-rules-updated-response.mdx"), "@site/docs/api/lusid/schemas/schema-cleardown-module-rules-updated-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cleardown-module-rules-updated-response.mdx")],
  "29f2a71f": [() => import(/* webpackChunkName: "29f2a71f" */ "@site/docs/api/access/schemas/schema-date-quality.mdx"), "@site/docs/api/access/schemas/schema-date-quality.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-date-quality.mdx")],
  "2a2daf20": [() => import(/* webpackChunkName: "2a2daf20" */ "@site/docs/api/lusid/schemas/schema-loan-period.mdx"), "@site/docs/api/lusid/schemas/schema-loan-period.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-loan-period.mdx")],
  "2a51014b": [() => import(/* webpackChunkName: "2a51014b" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-virtual-row.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-virtual-row.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-virtual-row.mdx")],
  "2a7169d7": [() => import(/* webpackChunkName: "2a7169d7" */ "@site/docs/excel/functions/Orders.mdx"), "@site/docs/excel/functions/Orders.mdx", require.resolveWeak("@site/docs/excel/functions/Orders.mdx")],
  "2a74e378": [() => import(/* webpackChunkName: "2a74e378" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-update-derived-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-update-derived-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-update-derived-property-definition.mdx")],
  "2a959dbb": [() => import(/* webpackChunkName: "2a959dbb" */ "@site/docs/api/web-app/schemas/schema-tolerance-rule-block.mdx"), "@site/docs/api/web-app/schemas/schema-tolerance-rule-block.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tolerance-rule-block.mdx")],
  "2afef593": [() => import(/* webpackChunkName: "2afef593" */ "@site/docs/api/lusid/schemas/schema-translate-entities-response.mdx"), "@site/docs/api/lusid/schemas/schema-translate-entities-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-entities-response.mdx")],
  "2b7ad939": [() => import(/* webpackChunkName: "2b7ad939" */ "@site/docs/api/notification/schemas/schema-manual-event-body.mdx"), "@site/docs/api/notification/schemas/schema-manual-event-body.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-manual-event-body.mdx")],
  "2b861490": [() => import(/* webpackChunkName: "2b861490" */ "@site/docs/api/luminesce/endpoints/binary-downloading/operation-download-binary.mdx"), "@site/docs/api/luminesce/endpoints/binary-downloading/operation-download-binary.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/binary-downloading/operation-download-binary.mdx")],
  "2ba587b3": [() => import(/* webpackChunkName: "2ba587b3" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-cds-flow-conventions-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-cds-flow-conventions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-cds-flow-conventions-response.mdx")],
  "2bb9d275": [() => import(/* webpackChunkName: "2bb9d275" */ "@site/docs/api/notification/endpoints/notifications/operation-get-notification.mdx"), "@site/docs/api/notification/endpoints/notifications/operation-get-notification.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/notifications/operation-get-notification.mdx")],
  "2bc8ab04": [() => import(/* webpackChunkName: "2bc8ab04" */ "@site/docs/api/lusid/schemas/schema-template-field.mdx"), "@site/docs/api/lusid/schemas/schema-template-field.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-template-field.mdx")],
  "2bcd33df": [() => import(/* webpackChunkName: "2bcd33df" */ "@site/docs/api/lusid/schemas/schema-cleardown-module-details.mdx"), "@site/docs/api/lusid/schemas/schema-cleardown-module-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cleardown-module-details.mdx")],
  "2bd47825": [() => import(/* webpackChunkName: "2bd47825" */ "@site/docs/api/configuration/schemas/schema-configuration-set.mdx"), "@site/docs/api/configuration/schemas/schema-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-configuration-set.mdx")],
  "2bee85de": [() => import(/* webpackChunkName: "2bee85de" */ "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides.mdx")],
  "2c0df71b": [() => import(/* webpackChunkName: "2c0df71b" */ "@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent.mdx"), "@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent.mdx")],
  "2c1e47cc": [() => import(/* webpackChunkName: "2c1e47cc" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-extract-scalar-parameters.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-extract-scalar-parameters.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-extract-scalar-parameters.mdx")],
  "2c311314": [() => import(/* webpackChunkName: "2c311314" */ "@site/docs/api/workflow/endpoints/tasks/operation-list-tasks.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-list-tasks.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-list-tasks.mdx")],
  "2c35f1a8": [() => import(/* webpackChunkName: "2c35f1a8" */ "@site/docs/api/lusid/schemas/schema-new-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-new-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-new-instrument.mdx")],
  "2c3a5ae4": [() => import(/* webpackChunkName: "2c3a5ae4" */ "@site/docs/api/access/endpoints/user-roles/operation-remove-policy-collection-from-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-remove-policy-collection-from-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-remove-policy-collection-from-user-role.mdx")],
  "2c40cc95": [() => import(/* webpackChunkName: "2c40cc95" */ "@site/docs/api/lusid/endpoints/executions/operation-get-execution.mdx"), "@site/docs/api/lusid/endpoints/executions/operation-get-execution.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/executions/operation-get-execution.mdx")],
  "2c45a9ff": [() => import(/* webpackChunkName: "2c45a9ff" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-properties-from-transaction.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-properties-from-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-properties-from-transaction.mdx")],
  "2c8f280a": [() => import(/* webpackChunkName: "2c8f280a" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-reconciliation-break.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-reconciliation-break.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-reconciliation-break.mdx")],
  "2c978fbc": [() => import(/* webpackChunkName: "2c978fbc" */ "@site/docs/api/lusid/schemas/schema-transaction-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type-request.mdx")],
  "2c9b5ed4": [() => import(/* webpackChunkName: "2c9b5ed4" */ "@site/docs/api/lusid/schemas/schema-data-type.mdx"), "@site/docs/api/lusid/schemas/schema-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-type.mdx")],
  "2ce7df91": [() => import(/* webpackChunkName: "2ce7df91" */ "@site/docs/api/lusid/schemas/schema-otc-confirmation.mdx"), "@site/docs/api/lusid/schemas/schema-otc-confirmation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-otc-confirmation.mdx")],
  "2cf8fe00": [() => import(/* webpackChunkName: "2cf8fe00" */ "@site/docs/api/lusid/schemas/schema-adjust-holding-request.mdx"), "@site/docs/api/lusid/schemas/schema-adjust-holding-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-adjust-holding-request.mdx")],
  "2d177fba": [() => import(/* webpackChunkName: "2d177fba" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modifications-requested-change-interval.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modifications-requested-change-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staged-modifications-requested-change-interval.mdx")],
  "2d22b789": [() => import(/* webpackChunkName: "2d22b789" */ "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-request.mdx"), "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-request.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-request.mdx")],
  "2d23f476": [() => import(/* webpackChunkName: "2d23f476" */ "@site/docs/api/identity/endpoints/applications/operation-get-application.mdx"), "@site/docs/api/identity/endpoints/applications/operation-get-application.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/applications/operation-get-application.mdx")],
  "2d2c794a": [() => import(/* webpackChunkName: "2d2c794a" */ "@site/docs/api/lusid/schemas/schema-calendar-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-calendar-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-calendar-dependency.mdx")],
  "2d34f87e": [() => import(/* webpackChunkName: "2d34f87e" */ "@site/docs/api/web-app/schemas/schema-fractional-rule.mdx"), "@site/docs/api/web-app/schemas/schema-fractional-rule.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-fractional-rule.mdx")],
  "2d46dfa0": [() => import(/* webpackChunkName: "2d46dfa0" */ "@site/docs/api/lusid/schemas/schema-performance-return.mdx"), "@site/docs/api/lusid/schemas/schema-performance-return.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-performance-return.mdx")],
  "2d59af0d": [() => import(/* webpackChunkName: "2d59af0d" */ "@site/docs/api/workflow/schemas/schema-health-check.mdx"), "@site/docs/api/workflow/schemas/schema-health-check.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-health-check.mdx")],
  "2d794535": [() => import(/* webpackChunkName: "2d794535" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-group-reconciliation-definition.mdx")],
  "2d859519": [() => import(/* webpackChunkName: "2d859519" */ "@site/docs/api/lusid/schemas/schema-trial-balance.mdx"), "@site/docs/api/lusid/schemas/schema-trial-balance.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-trial-balance.mdx")],
  "2d85dcae": [() => import(/* webpackChunkName: "2d85dcae" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition.mdx")],
  "2d879608": [() => import(/* webpackChunkName: "2d879608" */ "@site/docs/api/luminesce/schemas/schema-mappable-field.mdx"), "@site/docs/api/luminesce/schemas/schema-mappable-field.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-mappable-field.mdx")],
  "2d8d953f": [() => import(/* webpackChunkName: "2d8d953f" */ "@site/docs/api/lusid/schemas/schema-translate-entities-inlined-request.mdx"), "@site/docs/api/lusid/schemas/schema-translate-entities-inlined-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-entities-inlined-request.mdx")],
  "2dedd5b6": [() => import(/* webpackChunkName: "2dedd5b6" */ "@site/docs/api/identity/schemas/schema-role-response.mdx"), "@site/docs/api/identity/schemas/schema-role-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-role-response.mdx")],
  "2dfae42c": [() => import(/* webpackChunkName: "2dfae42c" */ "@site/docs/api/lusid/schemas/schema-fund-properties.mdx"), "@site/docs/api/lusid/schemas/schema-fund-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-properties.mdx")],
  "2dff07b8": [() => import(/* webpackChunkName: "2dff07b8" */ "@site/docs/api/lusid/schemas/schema-order-instruction-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-order-instruction-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-instruction-set-request.mdx")],
  "2e15e87f": [() => import(/* webpackChunkName: "2e15e87f" */ "@site/docs/api/insights/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/insights/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "2e15faea": [() => import(/* webpackChunkName: "2e15faea" */ "@site/docs/api/notification/schemas/schema-webhook-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-webhook-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-webhook-notification-type-response.mdx")],
  "2e172ab3": [() => import(/* webpackChunkName: "2e172ab3" */ "@site/docs/api/lusid/schemas/schema-upsert-complex-market-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-complex-market-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-complex-market-data-request.mdx")],
  "2e2990e3": [() => import(/* webpackChunkName: "2e2990e3" */ "@site/docs/api/web-app/schemas/schema-okta-config.mdx"), "@site/docs/api/web-app/schemas/schema-okta-config.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-okta-config.mdx")],
  "2e3359be": [() => import(/* webpackChunkName: "2e3359be" */ "@site/docs/api/workflow/schemas/schema-update-worker-request.mdx"), "@site/docs/api/workflow/schemas/schema-update-worker-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-worker-request.mdx")],
  "2e35cd45": [() => import(/* webpackChunkName: "2e35cd45" */ "@site/docs/api/lusid/schemas/schema-version.mdx"), "@site/docs/api/lusid/schemas/schema-version.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-version.mdx")],
  "2e3896bf": [() => import(/* webpackChunkName: "2e3896bf" */ "@site/docs/api/lusid/schemas/schema-allocation-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-allocation-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-allocation-set-request.mdx")],
  "2e64f76b": [() => import(/* webpackChunkName: "2e64f76b" */ "@site/docs/api/lusid/schemas/schema-inflation-fixing-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-inflation-fixing-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inflation-fixing-dependency.mdx")],
  "2e890e00": [() => import(/* webpackChunkName: "2e890e00" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-json.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-json.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-json.mdx")],
  "2e8970aa": [() => import(/* webpackChunkName: "2e8970aa" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-action-source.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-action-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-action-source.mdx")],
  "2e9e4b56": [() => import(/* webpackChunkName: "2e9e4b56" */ "@site/docs/api/notification/schemas/schema-delivery.mdx"), "@site/docs/api/notification/schemas/schema-delivery.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-delivery.mdx")],
  "2e9f2bff": [() => import(/* webpackChunkName: "2e9f2bff" */ "@site/docs/api/notification/endpoints/deliveries/operation-list-deliveries.mdx"), "@site/docs/api/notification/endpoints/deliveries/operation-list-deliveries.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/deliveries/operation-list-deliveries.mdx")],
  "2ea119ba": [() => import(/* webpackChunkName: "2ea119ba" */ "@site/docs/api/lusid/schemas/schema-portfolio-group-properties.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group-properties.mdx")],
  "2ecffdc2": [() => import(/* webpackChunkName: "2ecffdc2" */ "@site/docs/api/lusid/schemas/schema-cash-flow-value-set.mdx"), "@site/docs/api/lusid/schemas/schema-cash-flow-value-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-flow-value-set.mdx")],
  "2ed99279": [() => import(/* webpackChunkName: "2ed99279" */ "@site/docs/api/insights/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/insights/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "2f1ac019": [() => import(/* webpackChunkName: "2f1ac019" */ "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-templates.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-templates.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-list-compliance-templates.mdx")],
  "2f2b6c20": [() => import(/* webpackChunkName: "2f2b6c20" */ "@site/docs/api/notification/schemas/schema-manual-event.mdx"), "@site/docs/api/notification/schemas/schema-manual-event.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-manual-event.mdx")],
  "2f3572ad": [() => import(/* webpackChunkName: "2f3572ad" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-change-history.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-change-history.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-change-history.mdx")],
  "2f364375": [() => import(/* webpackChunkName: "2f364375" */ "@site/docs/api/access/schemas/schema-operator.mdx"), "@site/docs/api/access/schemas/schema-operator.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-operator.mdx")],
  "2f36c419": [() => import(/* webpackChunkName: "2f36c419" */ "@site/docs/api/lusid/schemas/schema-transaction-configuration-type-alias.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-configuration-type-alias.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-configuration-type-alias.mdx")],
  "2f584f41": [() => import(/* webpackChunkName: "2f584f41" */ "@site/docs/api/lusid/schemas/schema-order-instruction.mdx"), "@site/docs/api/lusid/schemas/schema-order-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-instruction.mdx")],
  "2f5dd9ee": [() => import(/* webpackChunkName: "2f5dd9ee" */ "@site/docs/api/luminesce/schemas/schema-background-multi-query-response.mdx"), "@site/docs/api/luminesce/schemas/schema-background-multi-query-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-multi-query-response.mdx")],
  "2f6f2602": [() => import(/* webpackChunkName: "2f6f2602" */ "@site/docs/api/lusid/schemas/schema-fee-rule.mdx"), "@site/docs/api/lusid/schemas/schema-fee-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-rule.mdx")],
  "2f8e3a61": [() => import(/* webpackChunkName: "2f8e3a61" */ "@site/docs/api/identity/schemas/schema-set-password-response.mdx"), "@site/docs/api/identity/schemas/schema-set-password-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-set-password-response.mdx")],
  "2fa4c962": [() => import(/* webpackChunkName: "2fa4c962" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-list-configuration-transaction-types.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-list-configuration-transaction-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-list-configuration-transaction-types.mdx")],
  "2fbf971a": [() => import(/* webpackChunkName: "2fbf971a" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-data-map.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-data-map.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-get-data-map.mdx")],
  "2fc53d22": [() => import(/* webpackChunkName: "2fc53d22" */ "@site/docs/api/lusid/schemas/schema-get-flow-conventions-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-flow-conventions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-flow-conventions-response.mdx")],
  "2fd5a29f": [() => import(/* webpackChunkName: "2fd5a29f" */ "@site/docs/api/web-app/schemas/schema-hubspot-identity-profile.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-identity-profile.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-identity-profile.mdx")],
  "300722c5": [() => import(/* webpackChunkName: "300722c5" */ "@site/docs/api/luminesce/endpoints/certificate-management/operation-manage-certificate.mdx"), "@site/docs/api/luminesce/endpoints/certificate-management/operation-manage-certificate.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/certificate-management/operation-manage-certificate.mdx")],
  "301120a9": [() => import(/* webpackChunkName: "301120a9" */ "@site/docs/api/lusid/schemas/schema-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument.mdx")],
  "30510eae": [() => import(/* webpackChunkName: "30510eae" */ "@site/docs/api/access/schemas/schema-effective-range.mdx"), "@site/docs/api/access/schemas/schema-effective-range.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-effective-range.mdx")],
  "305988f9": [() => import(/* webpackChunkName: "305988f9" */ "@site/docs/api/insights/schemas/schema-audit-data.mdx"), "@site/docs/api/insights/schemas/schema-audit-data.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-data.mdx")],
  "306f79a2": [() => import(/* webpackChunkName: "306f79a2" */ "@site/docs/api/lusid/schemas/schema-weighted-instruments.mdx"), "@site/docs/api/lusid/schemas/schema-weighted-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-weighted-instruments.mdx")],
  "309693d9": [() => import(/* webpackChunkName: "309693d9" */ "@site/docs/api/lusid/schemas/schema-branch-step.mdx"), "@site/docs/api/lusid/schemas/schema-branch-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-branch-step.mdx")],
  "3097cc8f": [() => import(/* webpackChunkName: "3097cc8f" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-portfolio-entity-ids.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-portfolio-entity-ids.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-portfolio-entity-ids.mdx")],
  "309aabf2": [() => import(/* webpackChunkName: "309aabf2" */ "@site/docs/api/lusid/endpoints/aggregation/operation-get-queryable-keys.mdx"), "@site/docs/api/lusid/endpoints/aggregation/operation-get-queryable-keys.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/aggregation/operation-get-queryable-keys.mdx")],
  "30a15551": [() => import(/* webpackChunkName: "30a15551" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-update-ticket.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-update-ticket.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-update-ticket.mdx")],
  "30ba923b": [() => import(/* webpackChunkName: "30ba923b" */ "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-cancel-multi-query.mdx"), "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-cancel-multi-query.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/multi-query-execution/operation-cancel-multi-query.mdx")],
  "30c1bb48": [() => import(/* webpackChunkName: "30c1bb48" */ "@site/docs/api/lusid/schemas/schema-upsert-valuation-point-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-valuation-point-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-valuation-point-request.mdx")],
  "30dff29a": [() => import(/* webpackChunkName: "30dff29a" */ "@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument.mdx")],
  "30e4ac41": [() => import(/* webpackChunkName: "30e4ac41" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace.mdx")],
  "30ebf02f": [() => import(/* webpackChunkName: "30ebf02f" */ "@site/docs/api/access/endpoints/user-roles/operation-remove-policy-from-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-remove-policy-from-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-remove-policy-from-user-role.mdx")],
  "30f6d0b9": [() => import(/* webpackChunkName: "30f6d0b9" */ "@site/docs/api/lusid/schemas/schema-custom-entity-id.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-id.mdx")],
  "312c9ccc": [() => import(/* webpackChunkName: "312c9ccc" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-writer-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-writer-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-writer-design.mdx")],
  "315f9831": [() => import(/* webpackChunkName: "315f9831" */ "@site/docs/api/lusid/schemas/schema-upsert-legal-entity-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-legal-entity-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-legal-entity-access-metadata-request.mdx")],
  "316f73c3": [() => import(/* webpackChunkName: "316f73c3" */ "@site/docs/api/web-app/schemas/schema-resource-id.mdx"), "@site/docs/api/web-app/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-resource-id.mdx")],
  "319dc6b3": [() => import(/* webpackChunkName: "319dc6b3" */ "@site/docs/api/lusid/endpoints/compliance/operation-create-compliance-template.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-create-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-create-compliance-template.mdx")],
  "31b548f5": [() => import(/* webpackChunkName: "31b548f5" */ "@site/docs/api/notification/schemas/schema-resource-list-of-subscription.mdx"), "@site/docs/api/notification/schemas/schema-resource-list-of-subscription.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-list-of-subscription.mdx")],
  "31b91480": [() => import(/* webpackChunkName: "31b91480" */ "@site/docs/api/lusid/schemas/schema-reconciliation-left-right-address-key-pair.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-left-right-address-key-pair.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-left-right-address-key-pair.mdx")],
  "31e88039": [() => import(/* webpackChunkName: "31e88039" */ "@site/docs/api/workflow/schemas/schema-task-instance-field.mdx"), "@site/docs/api/workflow/schemas/schema-task-instance-field.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-instance-field.mdx")],
  "3215af21": [() => import(/* webpackChunkName: "3215af21" */ "@site/docs/api/lusid/schemas/schema-instrument-payment-diary.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-payment-diary.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-payment-diary.mdx")],
  "322048b2": [() => import(/* webpackChunkName: "322048b2" */ "@site/docs/api/lusid/schemas/schema-participation-request.mdx"), "@site/docs/api/lusid/schemas/schema-participation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-participation-request.mdx")],
  "32285f55": [() => import(/* webpackChunkName: "32285f55" */ "@site/docs/api/lusid/schemas/schema-cash-flow-lineage.mdx"), "@site/docs/api/lusid/schemas/schema-cash-flow-lineage.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-flow-lineage.mdx")],
  "322f74c3": [() => import(/* webpackChunkName: "322f74c3" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-corporate-action-source.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-corporate-action-source.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-corporate-action-source.mdx")],
  "325c7f31": [() => import(/* webpackChunkName: "325c7f31" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-movement-record.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-movement-record.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-movement-record.mdx")],
  "32687d03": [() => import(/* webpackChunkName: "32687d03" */ "@site/docs/api/notification/endpoints/event-types/operation-list-event-types.mdx"), "@site/docs/api/notification/endpoints/event-types/operation-list-event-types.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/event-types/operation-list-event-types.mdx")],
  "3274e36f": [() => import(/* webpackChunkName: "3274e36f" */ "@site/docs/api/lusid/schemas/schema-currency-and-amount.mdx"), "@site/docs/api/lusid/schemas/schema-currency-and-amount.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-currency-and-amount.mdx")],
  "32b42352": [() => import(/* webpackChunkName: "32b42352" */ "@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity.mdx"), "@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-matching-tasks-activity.mdx")],
  "32bf3d9c": [() => import(/* webpackChunkName: "32bf3d9c" */ "@site/docs/api/lusid/schemas/schema-transaction-type-calculation.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type-calculation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type-calculation.mdx")],
  "32c1c638": [() => import(/* webpackChunkName: "32c1c638" */ "@site/docs/api/lusid/schemas/schema-portfolio-id-list.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-id-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-id-list.mdx")],
  "32d382d0": [() => import(/* webpackChunkName: "32d382d0" */ "@site/docs/api/drive/endpoints/files/operation-get-file.mdx"), "@site/docs/api/drive/endpoints/files/operation-get-file.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-get-file.mdx")],
  "32faa567": [() => import(/* webpackChunkName: "32faa567" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-response.mdx")],
  "331960d6": [() => import(/* webpackChunkName: "331960d6" */ "@site/docs/api/lusid/schemas/schema-error-detail.mdx"), "@site/docs/api/lusid/schemas/schema-error-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-error-detail.mdx")],
  "333779dd": [() => import(/* webpackChunkName: "333779dd" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-group-reconciliation-definition.mdx")],
  "339a0665": [() => import(/* webpackChunkName: "339a0665" */ "@site/docs/api/lusid/schemas/schema-group-filter-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-group-filter-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-filter-step-request.mdx")],
  "33f413b2": [() => import(/* webpackChunkName: "33f413b2" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-upsert-property-definition-properties.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-upsert-property-definition-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-upsert-property-definition-properties.mdx")],
  "3402ff61": [() => import(/* webpackChunkName: "3402ff61" */ "@site/docs/api/luminesce/schemas/schema-feedback-event-args.mdx"), "@site/docs/api/luminesce/schemas/schema-feedback-event-args.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-feedback-event-args.mdx")],
  "34138b78": [() => import(/* webpackChunkName: "34138b78" */ "@site/docs/api/lusid/schemas/schema-query-trade-tickets-request.mdx"), "@site/docs/api/lusid/schemas/schema-query-trade-tickets-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-query-trade-tickets-request.mdx")],
  "349c00be": [() => import(/* webpackChunkName: "349c00be" */ "@site/docs/api/lusid/schemas/schema-translation-input.mdx"), "@site/docs/api/lusid/schemas/schema-translation-input.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translation-input.mdx")],
  "34b629e9": [() => import(/* webpackChunkName: "34b629e9" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type.mdx")],
  "34cfa32f": [() => import(/* webpackChunkName: "34cfa32f" */ "@site/docs/api/identity/schemas/schema-role-id.mdx"), "@site/docs/api/identity/schemas/schema-role-id.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-role-id.mdx")],
  "34de26d4": [() => import(/* webpackChunkName: "34de26d4" */ "@site/docs/api/workflow/schemas/schema-action-id.mdx"), "@site/docs/api/workflow/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-action-id.mdx")],
  "34f3e53e": [() => import(/* webpackChunkName: "34f3e53e" */ "@site/docs/api/workflow/schemas/schema-update-task-definition-request.mdx"), "@site/docs/api/workflow/schemas/schema-update-task-definition-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-task-definition-request.mdx")],
  "3506bb37": [() => import(/* webpackChunkName: "3506bb37" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-general-ledger-profile-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-general-ledger-profile-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-general-ledger-profile-response.mdx")],
  "3545b1b7": [() => import(/* webpackChunkName: "3545b1b7" */ "@site/docs/api/lusid/schemas/schema-group-by-step.mdx"), "@site/docs/api/lusid/schemas/schema-group-by-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-by-step.mdx")],
  "356ff4d7": [() => import(/* webpackChunkName: "356ff4d7" */ "@site/docs/api/luminesce/schemas/schema-query-design.mdx"), "@site/docs/api/luminesce/schemas/schema-query-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-query-design.mdx")],
  "359930e9": [() => import(/* webpackChunkName: "359930e9" */ "@site/docs/api/lusid/endpoints/workspace/operation-list-shared-workspaces.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-list-shared-workspaces.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-list-shared-workspaces.mdx")],
  "35f6cb0d": [() => import(/* webpackChunkName: "35f6cb0d" */ "@site/docs/api/insights/schemas/schema-audit-entry.mdx"), "@site/docs/api/insights/schemas/schema-audit-entry.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-entry.mdx")],
  "35fa5bce": [() => import(/* webpackChunkName: "35fa5bce" */ "@site/docs/api/workflow/schemas/schema-worker-configuration-response.mdx"), "@site/docs/api/workflow/schemas/schema-worker-configuration-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-worker-configuration-response.mdx")],
  "362b7d07": [() => import(/* webpackChunkName: "362b7d07" */ "@site/docs/api/lusid/endpoints/portfolios/operation-update-portfolio.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-update-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-update-portfolio.mdx")],
  "362c80dd": [() => import(/* webpackChunkName: "362c80dd" */ "@site/docs/api/lusid/schemas/schema-transition-event.mdx"), "@site/docs/api/lusid/schemas/schema-transition-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transition-event.mdx")],
  "3653b16a": [() => import(/* webpackChunkName: "3653b16a" */ "@site/docs/api/access/schemas/schema-metadata-expression.mdx"), "@site/docs/api/access/schemas/schema-metadata-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-metadata-expression.mdx")],
  "366dea25": [() => import(/* webpackChunkName: "366dea25" */ "@site/docs/api/lusid/schemas/schema-block-and-orders-request.mdx"), "@site/docs/api/lusid/schemas/schema-block-and-orders-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-and-orders-request.mdx")],
  "36749697": [() => import(/* webpackChunkName: "36749697" */ "@site/docs/api/lusid/schemas/schema-create-sequence-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-sequence-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-sequence-request.mdx")],
  "367dd0fe": [() => import(/* webpackChunkName: "367dd0fe" */ "@site/docs/api/workflow/schemas/schema-result-matching-pattern.mdx"), "@site/docs/api/workflow/schemas/schema-result-matching-pattern.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-result-matching-pattern.mdx")],
  "3691ba53": [() => import(/* webpackChunkName: "3691ba53" */ "@site/docs/api/access/schemas/schema-action-id.mdx"), "@site/docs/api/access/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-action-id.mdx")],
  "36952bf8": [() => import(/* webpackChunkName: "36952bf8" */ "@site/docs/api/lusid/endpoints/quotes/operation-get-quotes.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-get-quotes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-get-quotes.mdx")],
  "36bf4e5e": [() => import(/* webpackChunkName: "36bf4e5e" */ "@site/docs/api/lusid/schemas/schema-relationship-definition.mdx"), "@site/docs/api/lusid/schemas/schema-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-relationship-definition.mdx")],
  "36ca5798": [() => import(/* webpackChunkName: "36ca5798" */ "@site/docs/api/lusid/schemas/schema-update-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-reconciliation-request.mdx")],
  "36d06d47": [() => import(/* webpackChunkName: "36d06d47" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval-with-order-management-detail.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval-with-order-management-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-change-interval-with-order-management-detail.mdx")],
  "36e3b538": [() => import(/* webpackChunkName: "36e3b538" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio.mdx")],
  "36e58e4b": [() => import(/* webpackChunkName: "36e58e4b" */ "@site/docs/api/lusid/endpoints/search/operation-search-portfolios.mdx"), "@site/docs/api/lusid/endpoints/search/operation-search-portfolios.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/search/operation-search-portfolios.mdx")],
  "3712cc01": [() => import(/* webpackChunkName: "3712cc01" */ "@site/docs/api/lusid/endpoints/funds/operation-finalise-candidate-valuation-point.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-finalise-candidate-valuation-point.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-finalise-candidate-valuation-point.mdx")],
  "37167157": [() => import(/* webpackChunkName: "37167157" */ "@site/docs/api/lusid/endpoints/instruments/operation-query-instrument-capabilities.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-query-instrument-capabilities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-query-instrument-capabilities.mdx")],
  "3786cdb9": [() => import(/* webpackChunkName: "3786cdb9" */ "@site/docs/api/access/schemas/schema-policy-id-role-resource.mdx"), "@site/docs/api/access/schemas/schema-policy-id-role-resource.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-id-role-resource.mdx")],
  "37a7139c": [() => import(/* webpackChunkName: "37a7139c" */ "@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-rule.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-rule.mdx")],
  "37a829f3": [() => import(/* webpackChunkName: "37a829f3" */ "@site/docs/api/lusid/schemas/schema-transaction-diagnostics.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-diagnostics.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-diagnostics.mdx")],
  "37b7915d": [() => import(/* webpackChunkName: "37b7915d" */ "@site/docs/api/identity/schemas/schema-user-schema-response.mdx"), "@site/docs/api/identity/schemas/schema-user-schema-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-user-schema-response.mdx")],
  "37e8630f": [() => import(/* webpackChunkName: "37e8630f" */ "@site/docs/api/workflow/schemas/schema-transition-trigger-definition.mdx"), "@site/docs/api/workflow/schemas/schema-transition-trigger-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-transition-trigger-definition.mdx")],
  "37e9f502": [() => import(/* webpackChunkName: "37e9f502" */ "@site/docs/api/lusid/endpoints/workspace/operation-list-personal-workspaces.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-list-personal-workspaces.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-list-personal-workspaces.mdx")],
  "37f95c48": [() => import(/* webpackChunkName: "37f95c48" */ "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-get-progress-of-multi-query.mdx"), "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-get-progress-of-multi-query.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/multi-query-execution/operation-get-progress-of-multi-query.mdx")],
  "382a2208": [() => import(/* webpackChunkName: "382a2208" */ "@site/docs/api/intro.mdx"), "@site/docs/api/intro.mdx", require.resolveWeak("@site/docs/api/intro.mdx")],
  "382b6ab6": [() => import(/* webpackChunkName: "382b6ab6" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-list-task-definitions.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-list-task-definitions.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-list-task-definitions.mdx")],
  "3845797b": [() => import(/* webpackChunkName: "3845797b" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument.mdx")],
  "38c64dfb": [() => import(/* webpackChunkName: "38c64dfb" */ "@site/docs/api/insights/schemas/schema-audit-entry-note.mdx"), "@site/docs/api/insights/schemas/schema-audit-entry-note.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-entry-note.mdx")],
  "38cd2015": [() => import(/* webpackChunkName: "38cd2015" */ "@site/docs/api/access/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/access/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-id-selector-definition.mdx")],
  "38e2b1b6": [() => import(/* webpackChunkName: "38e2b1b6" */ "@site/docs/api/lusid/schemas/schema-string-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-string-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-string-compliance-parameter.mdx")],
  "38ea544c": [() => import(/* webpackChunkName: "38ea544c" */ "@site/docs/api/luminesce/schemas/schema-options-parquet.mdx"), "@site/docs/api/luminesce/schemas/schema-options-parquet.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-options-parquet.mdx")],
  "38ebe5ed": [() => import(/* webpackChunkName: "38ebe5ed" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-output-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-output-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-output-transaction.mdx")],
  "3905c0d2": [() => import(/* webpackChunkName: "3905c0d2" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-type.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-type.mdx")],
  "391105a6": [() => import(/* webpackChunkName: "391105a6" */ "@site/docs/api/lusid/schemas/schema-portfolio-id-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-id-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-id-compliance-parameter.mdx")],
  "3922967e": [() => import(/* webpackChunkName: "3922967e" */ "@site/docs/api/scheduler/endpoints/jobs/operation-get-history.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-get-history.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-get-history.mdx")],
  "392b7459": [() => import(/* webpackChunkName: "392b7459" */ "@site/docs/api/lusid/schemas/schema-get-credit-support-annex-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-credit-support-annex-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-credit-support-annex-response.mdx")],
  "39342f5a": [() => import(/* webpackChunkName: "39342f5a" */ "@site/docs/api/lusid/schemas/schema-get-data-map-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-data-map-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-data-map-response.mdx")],
  "3974613f": [() => import(/* webpackChunkName: "3974613f" */ "@site/docs/api/luminesce/endpoints/binary-downloading/operation-get-binary-versions.mdx"), "@site/docs/api/luminesce/endpoints/binary-downloading/operation-get-binary-versions.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/binary-downloading/operation-get-binary-versions.mdx")],
  "39818287": [() => import(/* webpackChunkName: "39818287" */ "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule-result.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule-result.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule-result.mdx")],
  "39919296": [() => import(/* webpackChunkName: "39919296" */ "@site/docs/api/lusid/schemas/schema-comparison-attribute-value-pair.mdx"), "@site/docs/api/lusid/schemas/schema-comparison-attribute-value-pair.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-comparison-attribute-value-pair.mdx")],
  "3991ab24": [() => import(/* webpackChunkName: "3991ab24" */ "@site/docs/api/lusid/schemas/schema-date-or-diary-entry.mdx"), "@site/docs/api/lusid/schemas/schema-date-or-diary-entry.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-date-or-diary-entry.mdx")],
  "3991cef0": [() => import(/* webpackChunkName: "3991cef0" */ "@site/docs/api/lusid/endpoints/abor/operation-delete-abor.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-delete-abor.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-delete-abor.mdx")],
  "39929284": [() => import(/* webpackChunkName: "39929284" */ "@site/docs/api/lusid/endpoints/persons/operation-patch-person-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-patch-person-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-patch-person-access-metadata.mdx")],
  "39944e9a": [() => import(/* webpackChunkName: "39944e9a" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-get-task-definition.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-get-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-get-task-definition.mdx")],
  "39951f0a": [() => import(/* webpackChunkName: "39951f0a" */ "@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-create-derived-portfolio.mdx"), "@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-create-derived-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-create-derived-portfolio.mdx")],
  "39c00c0e": [() => import(/* webpackChunkName: "39c00c0e" */ "@site/docs/api/lusid/schemas/schema-get-recipe-composer-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-recipe-composer-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-recipe-composer-response.mdx")],
  "39cf0497": [() => import(/* webpackChunkName: "39cf0497" */ "@site/docs/api/web-app/schemas/schema-link.mdx"), "@site/docs/api/web-app/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-link.mdx")],
  "3a3b71ac": [() => import(/* webpackChunkName: "3a3b71ac" */ "@site/docs/api/access/schemas/schema-text-operator.mdx"), "@site/docs/api/access/schemas/schema-text-operator.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-text-operator.mdx")],
  "3a41b2b1": [() => import(/* webpackChunkName: "3a41b2b1" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-list-complex-market-data-with-meta-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-list-complex-market-data-with-meta-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-list-complex-market-data-with-meta-data-response.mdx")],
  "3a51d731": [() => import(/* webpackChunkName: "3a51d731" */ "@site/docs/api/horizon/schemas/schema-integration-rerun-response.mdx"), "@site/docs/api/horizon/schemas/schema-integration-rerun-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-rerun-response.mdx")],
  "3a7a546e": [() => import(/* webpackChunkName: "3a7a546e" */ "@site/docs/api/luminesce/schemas/schema-available-field.mdx"), "@site/docs/api/luminesce/schemas/schema-available-field.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-available-field.mdx")],
  "3ac187c6": [() => import(/* webpackChunkName: "3ac187c6" */ "@site/docs/api/lusid/endpoints/fee-types/operation-delete-fee-type.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-delete-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-delete-fee-type.mdx")],
  "3acb782e": [() => import(/* webpackChunkName: "3acb782e" */ "@site/docs/api/lusid/schemas/schema-transaction-configuration-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-configuration-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-configuration-data-request.mdx")],
  "3adc3623": [() => import(/* webpackChunkName: "3adc3623" */ "@site/docs/api/lusid/endpoints/quotes/operation-delete-quote-access-metadata-rule.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-delete-quote-access-metadata-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-delete-quote-access-metadata-rule.mdx")],
  "3aeb1cc6": [() => import(/* webpackChunkName: "3aeb1cc6" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-data-record.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-data-record.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-a-2-b-data-record.mdx")],
  "3b06e6e1": [() => import(/* webpackChunkName: "3b06e6e1" */ "@site/docs/api/lusid/endpoints/translation/operation-translate-instrument-definitions.mdx"), "@site/docs/api/lusid/endpoints/translation/operation-translate-instrument-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/translation/operation-translate-instrument-definitions.mdx")],
  "3b112349": [() => import(/* webpackChunkName: "3b112349" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-breached-order-info.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-breached-order-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-breached-order-info.mdx")],
  "3b22ad52": [() => import(/* webpackChunkName: "3b22ad52" */ "@site/docs/api/scheduler/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/scheduler/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-identifier-part-schema.mdx")],
  "3b2d3bd7": [() => import(/* webpackChunkName: "3b2d3bd7" */ "@site/docs/api/lusid/schemas/schema-custodian-account-request.mdx"), "@site/docs/api/lusid/schemas/schema-custodian-account-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custodian-account-request.mdx")],
  "3b35d674": [() => import(/* webpackChunkName: "3b35d674" */ "@site/docs/api/lusid/schemas/schema-reconciliation-transactions.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-transactions.mdx")],
  "3b4dabe4": [() => import(/* webpackChunkName: "3b4dabe4" */ "@site/docs/api/lusid/endpoints/conventions/operation-list-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-list-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-list-flow-conventions.mdx")],
  "3b60a0b7": [() => import(/* webpackChunkName: "3b60a0b7" */ "@site/docs/api/insights/schemas/schema-vendor-response.mdx"), "@site/docs/api/insights/schemas/schema-vendor-response.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-vendor-response.mdx")],
  "3b8db0cb": [() => import(/* webpackChunkName: "3b8db0cb" */ "@site/docs/api/web-app/schemas/schema-dashboard.mdx"), "@site/docs/api/web-app/schemas/schema-dashboard.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-dashboard.mdx")],
  "3b916656": [() => import(/* webpackChunkName: "3b916656" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-create-side-definition.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-create-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-create-side-definition.mdx")],
  "3b934f4c": [() => import(/* webpackChunkName: "3b934f4c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-definition.mdx")],
  "3b9b6957": [() => import(/* webpackChunkName: "3b9b6957" */ "@site/docs/api/lusid/schemas/schema-portfolio-cash-flow.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-cash-flow.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-cash-flow.mdx")],
  "3bbe4b8c": [() => import(/* webpackChunkName: "3bbe4b8c" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-value-type.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-value-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-value-type.mdx")],
  "3bc4f9dc": [() => import(/* webpackChunkName: "3bc4f9dc" */ "@site/docs/api/horizon/endpoints/instrument/operation-search-open-figi.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-search-open-figi.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-search-open-figi.mdx")],
  "3bd6c63d": [() => import(/* webpackChunkName: "3bd6c63d" */ "@site/docs/api/lusid/schemas/schema-compliance-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-step-request.mdx")],
  "3bf9937f": [() => import(/* webpackChunkName: "3bf9937f" */ "@site/docs/api/luminesce/schemas/schema-aggregate-function.mdx"), "@site/docs/api/luminesce/schemas/schema-aggregate-function.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-aggregate-function.mdx")],
  "3c01b043": [() => import(/* webpackChunkName: "3c01b043" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-property.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-property.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-property.mdx")],
  "3c04305f": [() => import(/* webpackChunkName: "3c04305f" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-access-metadata-by-key.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-access-metadata-by-key.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-access-metadata-by-key.mdx")],
  "3c075925": [() => import(/* webpackChunkName: "3c075925" */ "@site/docs/api/lusid/schemas/schema-instrument-entity.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-entity.mdx")],
  "3c5d741c": [() => import(/* webpackChunkName: "3c5d741c" */ "@site/docs/api/lusid/schemas/schema-holding-adjustment.mdx"), "@site/docs/api/lusid/schemas/schema-holding-adjustment.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-adjustment.mdx")],
  "3c6240b7": [() => import(/* webpackChunkName: "3c6240b7" */ "@site/docs/api/lusid/endpoints/workspace/operation-update-shared-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-update-shared-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-update-shared-item.mdx")],
  "3cb63f8c": [() => import(/* webpackChunkName: "3cb63f8c" */ "@site/docs/api/lusid/endpoints/persons/operation-upsert-persons.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-upsert-persons.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-upsert-persons.mdx")],
  "3cbc9bdb": [() => import(/* webpackChunkName: "3cbc9bdb" */ "@site/docs/api/scheduler/schemas/schema-update-job-request.mdx"), "@site/docs/api/scheduler/schemas/schema-update-job-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-update-job-request.mdx")],
  "3ceddc96": [() => import(/* webpackChunkName: "3ceddc96" */ "@site/docs/api/access/endpoints/policy-templates/operation-delete-policy-template.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-delete-policy-template.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-delete-policy-template.mdx")],
  "3d09e57c": [() => import(/* webpackChunkName: "3d09e57c" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolios-access-metadata-by-key.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolios-access-metadata-by-key.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolios-access-metadata-by-key.mdx")],
  "3d1146d1": [() => import(/* webpackChunkName: "3d1146d1" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-list-portfolio-groups.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-list-portfolio-groups.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-list-portfolio-groups.mdx")],
  "3d2d5a40": [() => import(/* webpackChunkName: "3d2d5a40" */ "@site/docs/api/lusid/schemas/schema-ex-dividend-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-ex-dividend-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-ex-dividend-configuration.mdx")],
  "3d652683": [() => import(/* webpackChunkName: "3d652683" */ "@site/docs/api/notification/endpoints/subscriptions/operation-update-subscription.mdx"), "@site/docs/api/notification/endpoints/subscriptions/operation-update-subscription.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/subscriptions/operation-update-subscription.mdx")],
  "3d6aad44": [() => import(/* webpackChunkName: "3d6aad44" */ "@site/docs/api/lusid/schemas/schema-link.mdx"), "@site/docs/api/lusid/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-link.mdx")],
  "3d6af26e": [() => import(/* webpackChunkName: "3d6af26e" */ "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-list-tax-rule-sets.mdx"), "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-list-tax-rule-sets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/tax-rule-sets/operation-list-tax-rule-sets.mdx")],
  "3d99623a": [() => import(/* webpackChunkName: "3d99623a" */ "@site/docs/api/lusid/schemas/schema-update-tax-rule-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-tax-rule-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-tax-rule-set-request.mdx")],
  "3df3b3d6": [() => import(/* webpackChunkName: "3df3b3d6" */ "@site/docs/api/lusid/endpoints/abor/operation-lock-period.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-lock-period.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-lock-period.mdx")],
  "3df6aaf5": [() => import(/* webpackChunkName: "3df6aaf5" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instruments.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instruments.mdx")],
  "3e0224db": [() => import(/* webpackChunkName: "3e0224db" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-block-and-orders.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-block-and-orders.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-block-and-orders.mdx")],
  "3e1435c5": [() => import(/* webpackChunkName: "3e1435c5" */ "@site/docs/api/access/schemas/schema-template-selection.mdx"), "@site/docs/api/access/schemas/schema-template-selection.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-template-selection.mdx")],
  "3e20b355": [() => import(/* webpackChunkName: "3e20b355" */ "@site/docs/api/lusid/schemas/schema-match-criterion.mdx"), "@site/docs/api/lusid/schemas/schema-match-criterion.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-match-criterion.mdx")],
  "3e3d9e10": [() => import(/* webpackChunkName: "3e3d9e10" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-structured-result-data.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-structured-result-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-structured-result-data.mdx")],
  "3e589823": [() => import(/* webpackChunkName: "3e589823" */ "@site/docs/api/lusid/schemas/schema-update-relationship-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-relationship-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-relationship-definition-request.mdx")],
  "3e75632f": [() => import(/* webpackChunkName: "3e75632f" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-csv.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-csv.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-csv.mdx")],
  "3e80f89f": [() => import(/* webpackChunkName: "3e80f89f" */ "@site/docs/api/lusid/schemas/schema-fund-previous-nav.mdx"), "@site/docs/api/lusid/schemas/schema-fund-previous-nav.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-previous-nav.mdx")],
  "3e8205af": [() => import(/* webpackChunkName: "3e8205af" */ "@site/docs/api/lusid/schemas/schema-valuation-point-data-query-parameters.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-point-data-query-parameters.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-point-data-query-parameters.mdx")],
  "3e943589": [() => import(/* webpackChunkName: "3e943589" */ "@site/docs/api/lusid/schemas/schema-portfolio-holding.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-holding.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-holding.mdx")],
  "3ea5552f": [() => import(/* webpackChunkName: "3ea5552f" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-commands.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-commands.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-commands.mdx")],
  "3ecbe3b2": [() => import(/* webpackChunkName: "3ecbe3b2" */ "@site/docs/api/lusid/schemas/schema-create-relation-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-relation-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-relation-definition-request.mdx")],
  "3edd823f": [() => import(/* webpackChunkName: "3edd823f" */ "@site/docs/api/workflow/endpoints/event-handlers/operation-get-event-handler.mdx"), "@site/docs/api/workflow/endpoints/event-handlers/operation-get-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/event-handlers/operation-get-event-handler.mdx")],
  "3f16fdd6": [() => import(/* webpackChunkName: "3f16fdd6" */ "@site/docs/api/lusid/schemas/schema-instrument-event-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event-configuration.mdx")],
  "3f29ce39": [() => import(/* webpackChunkName: "3f29ce39" */ "@site/docs/api/lusid/schemas/schema-cds-index.mdx"), "@site/docs/api/lusid/schemas/schema-cds-index.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cds-index.mdx")],
  "3f2d1bed": [() => import(/* webpackChunkName: "3f2d1bed" */ "@site/docs/api/lusid/schemas/schema-update-property-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-property-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-property-definition-request.mdx")],
  "3f306061": [() => import(/* webpackChunkName: "3f306061" */ "@site/docs/api/lusid/schemas/schema-close-event.mdx"), "@site/docs/api/lusid/schemas/schema-close-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-close-event.mdx")],
  "3f33632e": [() => import(/* webpackChunkName: "3f33632e" */ "@site/docs/api/scheduler/schemas/schema-tag.mdx"), "@site/docs/api/scheduler/schemas/schema-tag.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-tag.mdx")],
  "3f33f700": [() => import(/* webpackChunkName: "3f33f700" */ "@site/docs/api/workflow/endpoints/tasks/operation-get-task-history.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-get-task-history.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-get-task-history.mdx")],
  "3f3584f5": [() => import(/* webpackChunkName: "3f3584f5" */ "@site/docs/api/lusid/schemas/schema-reconciliation-id.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-id.mdx")],
  "3f3d9f0d": [() => import(/* webpackChunkName: "3f3d9f0d" */ "@site/docs/api/horizon/schemas/schema-audit-file-details.mdx"), "@site/docs/api/horizon/schemas/schema-audit-file-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-file-details.mdx")],
  "3f43e4e5": [() => import(/* webpackChunkName: "3f43e4e5" */ "@site/docs/api/lusid/schemas/schema-workspace-update-request.mdx"), "@site/docs/api/lusid/schemas/schema-workspace-update-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace-update-request.mdx")],
  "3f4e5b59": [() => import(/* webpackChunkName: "3f4e5b59" */ "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-rule.mdx")],
  "3f5092d0": [() => import(/* webpackChunkName: "3f5092d0" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity.mdx")],
  "3f5b31cf": [() => import(/* webpackChunkName: "3f5b31cf" */ "@site/docs/api/access/schemas/schema-policy-selector-definition.mdx"), "@site/docs/api/access/schemas/schema-policy-selector-definition.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-selector-definition.mdx")],
  "3f670eae": [() => import(/* webpackChunkName: "3f670eae" */ "@site/docs/api/workflow/schemas/schema-update-task-request.mdx"), "@site/docs/api/workflow/schemas/schema-update-task-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-task-request.mdx")],
  "3f927573": [() => import(/* webpackChunkName: "3f927573" */ "@site/docs/api/lusid/endpoints/persons/operation-upsert-person-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-upsert-person-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-upsert-person-access-metadata.mdx")],
  "3fb360ba": [() => import(/* webpackChunkName: "3fb360ba" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-configuration-recipes.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-configuration-recipes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-configuration-recipes.mdx")],
  "3fb6547b": [() => import(/* webpackChunkName: "3fb6547b" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-inline.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-inline.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-inline.mdx")],
  "3fbf5548": [() => import(/* webpackChunkName: "3fbf5548" */ "@site/docs/api/lusid/endpoints/workspace/operation-get-personal-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-get-personal-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-get-personal-workspace.mdx")],
  "3fcef6cb": [() => import(/* webpackChunkName: "3fcef6cb" */ "@site/docs/api/lusid/schemas/schema-component-filter.mdx"), "@site/docs/api/lusid/schemas/schema-component-filter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-component-filter.mdx")],
  "3fd22ccc": [() => import(/* webpackChunkName: "3fd22ccc" */ "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-request.mdx")],
  "3fd2f36a": [() => import(/* webpackChunkName: "3fd2f36a" */ "@site/docs/api/lusid/schemas/schema-chart-of-accounts.mdx"), "@site/docs/api/lusid/schemas/schema-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-chart-of-accounts.mdx")],
  "3fdd2379": [() => import(/* webpackChunkName: "3fdd2379" */ "@site/docs/api/lusid/schemas/schema-cds-flow-conventions.mdx"), "@site/docs/api/lusid/schemas/schema-cds-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cds-flow-conventions.mdx")],
  "3fe75af9": [() => import(/* webpackChunkName: "3fe75af9" */ "@site/docs/api/lusid/endpoints/complex-market-data/operation-delete-complex-market-data.mdx"), "@site/docs/api/lusid/endpoints/complex-market-data/operation-delete-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/complex-market-data/operation-delete-complex-market-data.mdx")],
  "3fe7e862": [() => import(/* webpackChunkName: "3fe7e862" */ "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-list-own-api-keys.mdx"), "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-list-own-api-keys.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-list-own-api-keys.mdx")],
  "4003b82e": [() => import(/* webpackChunkName: "4003b82e" */ "@site/docs/api/lusid/schemas/schema-check-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-check-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-check-step-request.mdx")],
  "4013c862": [() => import(/* webpackChunkName: "4013c862" */ "@site/docs/api/horizon/endpoints/integrations/operation-get-execution-ids-for-instance.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-get-execution-ids-for-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-get-execution-ids-for-instance.mdx")],
  "401fb117": [() => import(/* webpackChunkName: "401fb117" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts-properties.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts-properties.mdx")],
  "4039915b": [() => import(/* webpackChunkName: "4039915b" */ "@site/docs/api/notification/schemas/schema-subscription.mdx"), "@site/docs/api/notification/schemas/schema-subscription.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-subscription.mdx")],
  "4040604e": [() => import(/* webpackChunkName: "4040604e" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-patch-legal-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-patch-legal-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-patch-legal-entity-access-metadata.mdx")],
  "404db6e0": [() => import(/* webpackChunkName: "404db6e0" */ "@site/docs/api/lusid/schemas/schema-fx-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-fx-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-dependency.mdx")],
  "404f6ac1": [() => import(/* webpackChunkName: "404f6ac1" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule-result.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-rule-result.mdx")],
  "405c8aaf": [() => import(/* webpackChunkName: "405c8aaf" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-writer-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-writer-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-writer-design-to-sql.mdx")],
  "4085bbdc": [() => import(/* webpackChunkName: "4085bbdc" */ "@site/docs/api/insights/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/insights/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-lusid-problem-details.mdx")],
  "40b192ab": [() => import(/* webpackChunkName: "40b192ab" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-upsert-transactions.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-upsert-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-upsert-transactions.mdx")],
  "40d0ac65": [() => import(/* webpackChunkName: "40d0ac65" */ "@site/docs/api/lusid/endpoints/calendars/operation-get-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-get-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-get-calendar.mdx")],
  "40e99d34": [() => import(/* webpackChunkName: "40e99d34" */ "@site/docs/api/lusid/schemas/schema-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-schedule.mdx")],
  "41121ddf": [() => import(/* webpackChunkName: "41121ddf" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-data-type.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-data-type.mdx")],
  "4150e609": [() => import(/* webpackChunkName: "4150e609" */ "@site/docs/api/web-app/schemas/schema-label-value-set.mdx"), "@site/docs/api/web-app/schemas/schema-label-value-set.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-label-value-set.mdx")],
  "41656ec6": [() => import(/* webpackChunkName: "41656ec6" */ "@site/docs/api/lusid/schemas/schema-fee-properties.mdx"), "@site/docs/api/lusid/schemas/schema-fee-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-properties.mdx")],
  "416dcab8": [() => import(/* webpackChunkName: "416dcab8" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-moved-order-to-different-block-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-moved-order-to-different-block-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-moved-order-to-different-block-response.mdx")],
  "41e72d05": [() => import(/* webpackChunkName: "41e72d05" */ "@site/docs/api/lusid/endpoints/complex-market-data/operation-list-complex-market-data.mdx"), "@site/docs/api/lusid/endpoints/complex-market-data/operation-list-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/complex-market-data/operation-list-complex-market-data.mdx")],
  "41ec0a41": [() => import(/* webpackChunkName: "41ec0a41" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions-v-2.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions-v-2.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions-v-2.mdx")],
  "41f0c489": [() => import(/* webpackChunkName: "41f0c489" */ "@site/docs/api/lusid/schemas/schema-inflation-leg.mdx"), "@site/docs/api/lusid/schemas/schema-inflation-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inflation-leg.mdx")],
  "42285210": [() => import(/* webpackChunkName: "42285210" */ "@site/docs/api/insights/endpoints/requests/operation-list-request-logs.mdx"), "@site/docs/api/insights/endpoints/requests/operation-list-request-logs.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/requests/operation-list-request-logs.mdx")],
  "425088c0": [() => import(/* webpackChunkName: "425088c0" */ "@site/docs/api/lusid/schemas/schema-staging-rule-match-criteria.mdx"), "@site/docs/api/lusid/schemas/schema-staging-rule-match-criteria.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staging-rule-match-criteria.mdx")],
  "425e682b": [() => import(/* webpackChunkName: "425e682b" */ "@site/docs/api/lusid/schemas/schema-composite-breakdown-request.mdx"), "@site/docs/api/lusid/schemas/schema-composite-breakdown-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-composite-breakdown-request.mdx")],
  "42bf4bf7": [() => import(/* webpackChunkName: "42bf4bf7" */ "@site/docs/api/lusid/schemas/schema-portfolio-group-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group-search-result.mdx")],
  "42c97d7c": [() => import(/* webpackChunkName: "42c97d7c" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-with-warnings-of-portfolio-holding.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-with-warnings-of-portfolio-holding.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-with-warnings-of-portfolio-holding.mdx")],
  "430b984b": [() => import(/* webpackChunkName: "430b984b" */ "@site/docs/api/lusid/schemas/schema-index-projection-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-index-projection-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-index-projection-dependency.mdx")],
  "431e0e8f": [() => import(/* webpackChunkName: "431e0e8f" */ "@site/docs/api/notification/schemas/schema-resource-id.mdx"), "@site/docs/api/notification/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-id.mdx")],
  "4351d475": [() => import(/* webpackChunkName: "4351d475" */ "@site/docs/api/workflow/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/workflow/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "435eb3bc": [() => import(/* webpackChunkName: "435eb3bc" */ "@site/docs/api/lusid/schemas/schema-cancel-orders-response.mdx"), "@site/docs/api/lusid/schemas/schema-cancel-orders-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancel-orders-response.mdx")],
  "4367701a": [() => import(/* webpackChunkName: "4367701a" */ "@site/docs/api/workflow/endpoints/workers/operation-run-worker.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-run-worker.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-run-worker.mdx")],
  "436da31d": [() => import(/* webpackChunkName: "436da31d" */ "@site/docs/api/notification/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/notification/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-identifier-part-schema.mdx")],
  "437e389b": [() => import(/* webpackChunkName: "437e389b" */ "@site/docs/api/lusid/schemas/schema-custodian-accounts-upsert-response.mdx"), "@site/docs/api/lusid/schemas/schema-custodian-accounts-upsert-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custodian-accounts-upsert-response.mdx")],
  "4387588c": [() => import(/* webpackChunkName: "4387588c" */ "@site/docs/api/workflow/schemas/schema-field-mapping.mdx"), "@site/docs/api/workflow/schemas/schema-field-mapping.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-field-mapping.mdx")],
  "438fa8c7": [() => import(/* webpackChunkName: "438fa8c7" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-valuation-point-overview.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-valuation-point-overview.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-valuation-point-overview.mdx")],
  "439c0a4e": [() => import(/* webpackChunkName: "439c0a4e" */ "@site/docs/api/web-app/schemas/schema-custom-values.mdx"), "@site/docs/api/web-app/schemas/schema-custom-values.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-custom-values.mdx")],
  "43add263": [() => import(/* webpackChunkName: "43add263" */ "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placement-children.mdx"), "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placement-children.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placement-children.mdx")],
  "43ba04a5": [() => import(/* webpackChunkName: "43ba04a5" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument.mdx")],
  "43bcfd73": [() => import(/* webpackChunkName: "43bcfd73" */ "@site/docs/api/lusid/schemas/schema-equity-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-equity-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-model-options.mdx")],
  "43c692b9": [() => import(/* webpackChunkName: "43c692b9" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-placement-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-placement-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-placement-synopsis.mdx")],
  "43d610d7": [() => import(/* webpackChunkName: "43d610d7" */ "@site/docs/api/web-app/schemas/schema-fractional-rule-value.mdx"), "@site/docs/api/web-app/schemas/schema-fractional-rule-value.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-fractional-rule-value.mdx")],
  "43e36196": [() => import(/* webpackChunkName: "43e36196" */ "@site/docs/api/lusid/schemas/schema-create-cut-label-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-cut-label-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-cut-label-definition-request.mdx")],
  "43ef6112": [() => import(/* webpackChunkName: "43ef6112" */ "@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-result.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-result.mdx")],
  "43f6b91b": [() => import(/* webpackChunkName: "43f6b91b" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-transaction.mdx")],
  "43faa9f6": [() => import(/* webpackChunkName: "43faa9f6" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation-mapping.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation-mapping.mdx")],
  "4404d648": [() => import(/* webpackChunkName: "4404d648" */ "@site/docs/api/lusid/endpoints/blocks/operation-get-block.mdx"), "@site/docs/api/lusid/endpoints/blocks/operation-get-block.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/blocks/operation-get-block.mdx")],
  "44541b02": [() => import(/* webpackChunkName: "44541b02" */ "@site/docs/api/lusid/schemas/schema-package-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-package-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-package-set-request.mdx")],
  "447eb968": [() => import(/* webpackChunkName: "447eb968" */ "@site/docs/api/lusid/schemas/schema-create-unit-definition.mdx"), "@site/docs/api/lusid/schemas/schema-create-unit-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-unit-definition.mdx")],
  "4488ce73": [() => import(/* webpackChunkName: "4488ce73" */ "@site/docs/api/identity/endpoints/users/operation-reset-factors.mdx"), "@site/docs/api/identity/endpoints/users/operation-reset-factors.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-reset-factors.mdx")],
  "4491fa6b": [() => import(/* webpackChunkName: "4491fa6b" */ "@site/docs/api/lusid/endpoints/abor/operation-upsert-abor-properties.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-upsert-abor-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-upsert-abor-properties.mdx")],
  "449273d7": [() => import(/* webpackChunkName: "449273d7" */ "@site/docs/api/lusid/schemas/schema-previous-nav.mdx"), "@site/docs/api/lusid/schemas/schema-previous-nav.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-previous-nav.mdx")],
  "4496be16": [() => import(/* webpackChunkName: "4496be16" */ "@site/docs/api/lusid/schemas/schema-create-address-key-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-address-key-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-address-key-definition-request.mdx")],
  "44cdf739": [() => import(/* webpackChunkName: "44cdf739" */ "@site/docs/api/horizon/schemas/schema-lusid-property-to-vendor-field-mapping.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-property-to-vendor-field-mapping.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-property-to-vendor-field-mapping.mdx")],
  "44ce53c7": [() => import(/* webpackChunkName: "44ce53c7" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-get-transaction-configuration-source.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-get-transaction-configuration-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-get-transaction-configuration-source.mdx")],
  "44d956ab": [() => import(/* webpackChunkName: "44d956ab" */ "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-cancel-history.mdx"), "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-cancel-history.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-cancel-history.mdx")],
  "44fc7381": [() => import(/* webpackChunkName: "44fc7381" */ "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-delete-tax-rule-set.mdx"), "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-delete-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/tax-rule-sets/operation-delete-tax-rule-set.mdx")],
  "45426f63": [() => import(/* webpackChunkName: "45426f63" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-get-property-definition.mdx")],
  "45483256": [() => import(/* webpackChunkName: "45483256" */ "@site/docs/api/lusid/schemas/schema-query-instrument-events-request.mdx"), "@site/docs/api/lusid/schemas/schema-query-instrument-events-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-query-instrument-events-request.mdx")],
  "455ccea6": [() => import(/* webpackChunkName: "455ccea6" */ "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-get-cut-label-definition.mdx"), "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-get-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/cut-label-definitions/operation-get-cut-label-definition.mdx")],
  "455d5d10": [() => import(/* webpackChunkName: "455d5d10" */ "@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-transactions.mdx"), "@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-transactions.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/global-search/operation-global-search-list-global-search-transactions.mdx")],
  "457dd45d": [() => import(/* webpackChunkName: "457dd45d" */ "@site/docs/api/lusid/endpoints/workspace/operation-get-shared-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-get-shared-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-get-shared-workspace.mdx")],
  "458a66c0": [() => import(/* webpackChunkName: "458a66c0" */ "@site/docs/api/lusid/schemas/schema-custom-entity-response.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-response.mdx")],
  "459fd344": [() => import(/* webpackChunkName: "459fd344" */ "@site/docs/api/lusid/endpoints/custom-entity-types/operation-list-custom-entity-types.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-types/operation-list-custom-entity-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-types/operation-list-custom-entity-types.mdx")],
  "45a1f507": [() => import(/* webpackChunkName: "45a1f507" */ "@site/docs/api/identity/schemas/schema-temporary-password.mdx"), "@site/docs/api/identity/schemas/schema-temporary-password.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-temporary-password.mdx")],
  "45a7c110": [() => import(/* webpackChunkName: "45a7c110" */ "@site/docs/api/lusid/schemas/schema-custom-entity-definition.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-definition.mdx")],
  "45b0eda8": [() => import(/* webpackChunkName: "45b0eda8" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-module-rules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-module-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-module-rules.mdx")],
  "45b667e8": [() => import(/* webpackChunkName: "45b667e8" */ "@site/docs/api/lusid/endpoints/funds/operation-list-funds.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-list-funds.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-list-funds.mdx")],
  "45c281bc": [() => import(/* webpackChunkName: "45c281bc" */ "@site/docs/api/workflow/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/workflow/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-lusid-validation-problem-details.mdx")],
  "45fe0f3b": [() => import(/* webpackChunkName: "45fe0f3b" */ "@site/docs/api/lusid/endpoints/placements/operation-get-placement.mdx"), "@site/docs/api/lusid/endpoints/placements/operation-get-placement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/placements/operation-get-placement.mdx")],
  "46035d7e": [() => import(/* webpackChunkName: "46035d7e" */ "@site/docs/api/notification/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/notification/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "4614798d": [() => import(/* webpackChunkName: "4614798d" */ "@site/docs/api/lusid/schemas/schema-fee-accrual.mdx"), "@site/docs/api/lusid/schemas/schema-fee-accrual.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-accrual.mdx")],
  "4624d51a": [() => import(/* webpackChunkName: "4624d51a" */ "@site/docs/api/workflow/schemas/schema-create-new-task-activity.mdx"), "@site/docs/api/workflow/schemas/schema-create-new-task-activity.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-new-task-activity.mdx")],
  "4632b7aa": [() => import(/* webpackChunkName: "4632b7aa" */ "@site/docs/api/horizon/schemas/schema-query-specification.mdx"), "@site/docs/api/horizon/schemas/schema-query-specification.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-query-specification.mdx")],
  "4638db7e": [() => import(/* webpackChunkName: "4638db7e" */ "@site/docs/api/identity/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/identity/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-lusid-validation-problem-details.mdx")],
  "469c8ce8": [() => import(/* webpackChunkName: "469c8ce8" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-file-read-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-file-read-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-file-read-design.mdx")],
  "46a53dac": [() => import(/* webpackChunkName: "46a53dac" */ "@site/docs/api/lusid/schemas/schema-quote-series-id.mdx"), "@site/docs/api/lusid/schemas/schema-quote-series-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote-series-id.mdx")],
  "46a87636": [() => import(/* webpackChunkName: "46a87636" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-algorithms.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-algorithms.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-algorithms.mdx")],
  "46aaecaf": [() => import(/* webpackChunkName: "46aaecaf" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-posting-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-posting-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-posting-module.mdx")],
  "46ca73f6": [() => import(/* webpackChunkName: "46ca73f6" */ "@site/docs/api/lusid/schemas/schema-upsert-structured-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-structured-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-structured-data-response.mdx")],
  "46fe684a": [() => import(/* webpackChunkName: "46fe684a" */ "@site/docs/api/lusid/schemas/schema-reference-list-response.mdx"), "@site/docs/api/lusid/schemas/schema-reference-list-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-list-response.mdx")],
  "4719c2db": [() => import(/* webpackChunkName: "4719c2db" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response.mdx")],
  "47316c0f": [() => import(/* webpackChunkName: "47316c0f" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-portfolio-group-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-portfolio-group-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-portfolio-group-access-metadata.mdx")],
  "4772f106": [() => import(/* webpackChunkName: "4772f106" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-general-ledger-profile.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-general-ledger-profile.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-general-ledger-profile.mdx")],
  "477ba83d": [() => import(/* webpackChunkName: "477ba83d" */ "@site/docs/api/lusid/endpoints/abor-configuration/operation-delete-abor-configuration.mdx"), "@site/docs/api/lusid/endpoints/abor-configuration/operation-delete-abor-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor-configuration/operation-delete-abor-configuration.mdx")],
  "477d5803": [() => import(/* webpackChunkName: "477d5803" */ "@site/docs/api/lusid/schemas/schema-simple-cash-flow-loan.mdx"), "@site/docs/api/lusid/schemas/schema-simple-cash-flow-loan.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-simple-cash-flow-loan.mdx")],
  "479403d9": [() => import(/* webpackChunkName: "479403d9" */ "@site/docs/api/lusid/schemas/schema-holding-pricing-info.mdx"), "@site/docs/api/lusid/schemas/schema-holding-pricing-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-pricing-info.mdx")],
  "47b72551": [() => import(/* webpackChunkName: "47b72551" */ "@site/docs/api/lusid/endpoints/abor/operation-get-journal-entry-lines.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-get-journal-entry-lines.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-get-journal-entry-lines.mdx")],
  "47bf60a3": [() => import(/* webpackChunkName: "47bf60a3" */ "@site/docs/api/scheduler/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/scheduler/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-access-controlled-resource.mdx")],
  "47cc8c5a": [() => import(/* webpackChunkName: "47cc8c5a" */ "@site/docs/api/lusid/schemas/schema-annul-structured-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-annul-structured-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-annul-structured-data-response.mdx")],
  "47f083f8": [() => import(/* webpackChunkName: "47f083f8" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation.mdx")],
  "47f109c7": [() => import(/* webpackChunkName: "47f109c7" */ "@site/docs/api/lusid/endpoints/conventions/operation-delete-index-convention.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-delete-index-convention.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-delete-index-convention.mdx")],
  "481290e4": [() => import(/* webpackChunkName: "481290e4" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-set-holdings.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-set-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-set-holdings.mdx")],
  "485f0996": [() => import(/* webpackChunkName: "485f0996" */ "@site/docs/api/lusid/schemas/schema-holding-ids-request.mdx"), "@site/docs/api/lusid/schemas/schema-holding-ids-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-ids-request.mdx")],
  "4887c2bb": [() => import(/* webpackChunkName: "4887c2bb" */ "@site/docs/api/lusid/schemas/schema-fx-forward-curve-by-quote-reference.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-curve-by-quote-reference.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-curve-by-quote-reference.mdx")],
  "488ecab7": [() => import(/* webpackChunkName: "488ecab7" */ "@site/docs/api/web-app/schemas/schema-run-block-job-response.mdx"), "@site/docs/api/web-app/schemas/schema-run-block-job-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-run-block-job-response.mdx")],
  "48b7bc2e": [() => import(/* webpackChunkName: "48b7bc2e" */ "@site/docs/excel/functions/Quotes.mdx"), "@site/docs/excel/functions/Quotes.mdx", require.resolveWeak("@site/docs/excel/functions/Quotes.mdx")],
  "48cb69e3": [() => import(/* webpackChunkName: "48cb69e3" */ "@site/docs/api/lusid/endpoints/order-instructions/operation-get-order-instruction.mdx"), "@site/docs/api/lusid/endpoints/order-instructions/operation-get-order-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-instructions/operation-get-order-instruction.mdx")],
  "48cbea97": [() => import(/* webpackChunkName: "48cbea97" */ "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-blocks.mdx"), "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-blocks.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-blocks.mdx")],
  "48f42957": [() => import(/* webpackChunkName: "48f42957" */ "@site/docs/api/lusid/schemas/schema-instrument-cash-flow.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-cash-flow.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-cash-flow.mdx")],
  "4923db46": [() => import(/* webpackChunkName: "4923db46" */ "@site/docs/api/lusid/endpoints/schemas/operation-list-entities.mdx"), "@site/docs/api/lusid/endpoints/schemas/operation-list-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/schemas/operation-list-entities.mdx")],
  "4925cd00": [() => import(/* webpackChunkName: "4925cd00" */ "@site/docs/api/access/endpoints/policies/operation-list-policies.mdx"), "@site/docs/api/access/endpoints/policies/operation-list-policies.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-list-policies.mdx")],
  "49443039": [() => import(/* webpackChunkName: "49443039" */ "@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-item.mdx")],
  "4952f4da": [() => import(/* webpackChunkName: "4952f4da" */ "@site/docs/api/horizon/schemas/schema-query-request.mdx"), "@site/docs/api/horizon/schemas/schema-query-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-query-request.mdx")],
  "49557772": [() => import(/* webpackChunkName: "49557772" */ "@site/docs/api/lusid/schemas/schema-virtual-document-row.mdx"), "@site/docs/api/lusid/schemas/schema-virtual-document-row.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-virtual-document-row.mdx")],
  "49b7a2e3": [() => import(/* webpackChunkName: "49b7a2e3" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-key-from-portfolio-group-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-key-from-portfolio-group-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-key-from-portfolio-group-access-metadata.mdx")],
  "4a09ff3f": [() => import(/* webpackChunkName: "4a09ff3f" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-properties.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-properties.mdx")],
  "4a212b43": [() => import(/* webpackChunkName: "4a212b43" */ "@site/docs/api/lusid/schemas/schema-previous-share-class-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-previous-share-class-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-previous-share-class-breakdown.mdx")],
  "4a3c587a": [() => import(/* webpackChunkName: "4a3c587a" */ "@site/docs/api/workflow/schemas/schema-task-summary.mdx"), "@site/docs/api/workflow/schemas/schema-task-summary.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-summary.mdx")],
  "4a4c3099": [() => import(/* webpackChunkName: "4a4c3099" */ "@site/docs/api/lusid/schemas/schema-fx-tenor-convention.mdx"), "@site/docs/api/lusid/schemas/schema-fx-tenor-convention.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-tenor-convention.mdx")],
  "4a4db517": [() => import(/* webpackChunkName: "4a4db517" */ "@site/docs/api/lusid/endpoints/allocations/operation-upsert-allocations.mdx"), "@site/docs/api/lusid/endpoints/allocations/operation-upsert-allocations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/allocations/operation-upsert-allocations.mdx")],
  "4a4e67b7": [() => import(/* webpackChunkName: "4a4e67b7" */ "@site/docs/api/lusid/schemas/schema-market-data-options.mdx"), "@site/docs/api/lusid/schemas/schema-market-data-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-data-options.mdx")],
  "4a5d3590": [() => import(/* webpackChunkName: "4a5d3590" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-get-transaction-template.mdx")],
  "4aa1f1e2": [() => import(/* webpackChunkName: "4aa1f1e2" */ "@site/docs/api/lusid/endpoints/relations/operation-delete-relation.mdx"), "@site/docs/api/lusid/endpoints/relations/operation-delete-relation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relations/operation-delete-relation.mdx")],
  "4aa75fbe": [() => import(/* webpackChunkName: "4aa75fbe" */ "@site/docs/api/lusid/schemas/schema-basket-identifier.mdx"), "@site/docs/api/lusid/schemas/schema-basket-identifier.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-basket-identifier.mdx")],
  "4ab86409": [() => import(/* webpackChunkName: "4ab86409" */ "@site/docs/api/identity/schemas/schema-support-access-expiry-with-role.mdx"), "@site/docs/api/identity/schemas/schema-support-access-expiry-with-role.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-access-expiry-with-role.mdx")],
  "4abc1770": [() => import(/* webpackChunkName: "4abc1770" */ "@site/docs/api/scheduler/schemas/schema-schedule-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-schedule-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-schedule-definition.mdx")],
  "4ad3adca": [() => import(/* webpackChunkName: "4ad3adca" */ "@site/docs/api/lusid/endpoints/relationship-definitions/operation-get-relationship-definition.mdx"), "@site/docs/api/lusid/endpoints/relationship-definitions/operation-get-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationship-definitions/operation-get-relationship-definition.mdx")],
  "4af2dad1": [() => import(/* webpackChunkName: "4af2dad1" */ "@site/docs/api/lusid/endpoints/schemas/operation-get-value-types.mdx"), "@site/docs/api/lusid/endpoints/schemas/operation-get-value-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/schemas/operation-get-value-types.mdx")],
  "4af43a86": [() => import(/* webpackChunkName: "4af43a86" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-charts-of-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-charts-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-charts-of-accounts.mdx")],
  "4b060512": [() => import(/* webpackChunkName: "4b060512" */ "@site/docs/api/lusid/schemas/schema-close-period-diary-entry-request.mdx"), "@site/docs/api/lusid/schemas/schema-close-period-diary-entry-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-close-period-diary-entry-request.mdx")],
  "4b35ac09": [() => import(/* webpackChunkName: "4b35ac09" */ "@site/docs/api/luminesce/schemas/schema-inlined-property-design.mdx"), "@site/docs/api/luminesce/schemas/schema-inlined-property-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-inlined-property-design.mdx")],
  "4b8c8b2a": [() => import(/* webpackChunkName: "4b8c8b2a" */ "@site/docs/api/access/endpoints/roles/operation-delete-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-delete-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-delete-role.mdx")],
  "4b953abe": [() => import(/* webpackChunkName: "4b953abe" */ "@site/docs/api/lusid/schemas/schema-upsert-person-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-person-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-person-access-metadata-request.mdx")],
  "4b99305d": [() => import(/* webpackChunkName: "4b99305d" */ "@site/docs/api/access/schemas/schema-selector-definition.mdx"), "@site/docs/api/access/schemas/schema-selector-definition.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-selector-definition.mdx")],
  "4ba54aeb": [() => import(/* webpackChunkName: "4ba54aeb" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-to-format.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-to-format.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-query-to-format.mdx")],
  "4bb3a186": [() => import(/* webpackChunkName: "4bb3a186" */ "@site/docs/api/access/schemas/schema-key-value-pair-of-string-to-string.mdx"), "@site/docs/api/access/schemas/schema-key-value-pair-of-string-to-string.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-key-value-pair-of-string-to-string.mdx")],
  "4bc5e3dd": [() => import(/* webpackChunkName: "4bc5e3dd" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-property-time-series.mdx")],
  "4be064e9": [() => import(/* webpackChunkName: "4be064e9" */ "@site/docs/api/lusid/schemas/schema-placement.mdx"), "@site/docs/api/lusid/schemas/schema-placement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-placement.mdx")],
  "4bead83b": [() => import(/* webpackChunkName: "4bead83b" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-check-access-token-exists.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-check-access-token-exists.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-check-access-token-exists.mdx")],
  "4bf006dd": [() => import(/* webpackChunkName: "4bf006dd" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor-configuration.mdx")],
  "4bf81887": [() => import(/* webpackChunkName: "4bf81887" */ "@site/docs/api/lusid/schemas/schema-mbs-principal-event.mdx"), "@site/docs/api/lusid/schemas/schema-mbs-principal-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mbs-principal-event.mdx")],
  "4bfc9f95": [() => import(/* webpackChunkName: "4bfc9f95" */ "@site/docs/api/lusid/schemas/schema-share-class-amount.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-amount.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-amount.mdx")],
  "4c0209ba": [() => import(/* webpackChunkName: "4c0209ba" */ "@site/docs/api/lusid/endpoints/instrument-events/operation-query-trade-tickets.mdx"), "@site/docs/api/lusid/endpoints/instrument-events/operation-query-trade-tickets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-events/operation-query-trade-tickets.mdx")],
  "4c13e344": [() => import(/* webpackChunkName: "4c13e344" */ "@site/docs/api/lusid/endpoints/data-types/operation-get-data-type.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-get-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-get-data-type.mdx")],
  "4c5d9b2a": [() => import(/* webpackChunkName: "4c5d9b2a" */ "@site/docs/api/lusid/endpoints/reference-portfolio/operation-get-reference-portfolio-constituents.mdx"), "@site/docs/api/lusid/endpoints/reference-portfolio/operation-get-reference-portfolio-constituents.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-portfolio/operation-get-reference-portfolio-constituents.mdx")],
  "4c652331": [() => import(/* webpackChunkName: "4c652331" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custodian-account.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custodian-account.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custodian-account.mdx")],
  "4c93f2ef": [() => import(/* webpackChunkName: "4c93f2ef" */ "@site/docs/api/luminesce/schemas/schema-column-info.mdx"), "@site/docs/api/luminesce/schemas/schema-column-info.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-column-info.mdx")],
  "4cc4ebef": [() => import(/* webpackChunkName: "4cc4ebef" */ "@site/docs/api/lusid/endpoints/complex-market-data/operation-upsert-complex-market-data.mdx"), "@site/docs/api/lusid/endpoints/complex-market-data/operation-upsert-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/complex-market-data/operation-upsert-complex-market-data.mdx")],
  "4ce64264": [() => import(/* webpackChunkName: "4ce64264" */ "@site/docs/api/access/endpoints/policies/operation-create-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-create-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-create-policy-collection.mdx")],
  "4d26efaf": [() => import(/* webpackChunkName: "4d26efaf" */ "@site/docs/api/lusid/schemas/schema-upsert-translation-script-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-translation-script-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-translation-script-request.mdx")],
  "4d36aa1b": [() => import(/* webpackChunkName: "4d36aa1b" */ "@site/docs/api/identity/endpoints/users/operation-send-activation-email.mdx"), "@site/docs/api/identity/endpoints/users/operation-send-activation-email.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-send-activation-email.mdx")],
  "4d371bca": [() => import(/* webpackChunkName: "4d371bca" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-currencies.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-currencies.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-currencies.mdx")],
  "4d726fad": [() => import(/* webpackChunkName: "4d726fad" */ "@site/docs/api/lusid/endpoints/allocations/operation-get-allocation.mdx"), "@site/docs/api/lusid/endpoints/allocations/operation-get-allocation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/allocations/operation-get-allocation.mdx")],
  "4d8c3bc6": [() => import(/* webpackChunkName: "4d8c3bc6" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-properties.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-set-legal-entity-properties.mdx")],
  "4db7858f": [() => import(/* webpackChunkName: "4db7858f" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document.mdx")],
  "4dc1f6ca": [() => import(/* webpackChunkName: "4dc1f6ca" */ "@site/docs/api/web-app/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/web-app/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-lusid-validation-problem-details.mdx")],
  "4dc93322": [() => import(/* webpackChunkName: "4dc93322" */ "@site/docs/api/lusid/schemas/schema-upsert-index-convention-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-index-convention-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-index-convention-request.mdx")],
  "4de756ee": [() => import(/* webpackChunkName: "4de756ee" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "4dfc21f5": [() => import(/* webpackChunkName: "4dfc21f5" */ "@site/docs/api/lusid/schemas/schema-allocation-request.mdx"), "@site/docs/api/lusid/schemas/schema-allocation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-allocation-request.mdx")],
  "4e12c1e5": [() => import(/* webpackChunkName: "4e12c1e5" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entities.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entities.mdx")],
  "4e14123b": [() => import(/* webpackChunkName: "4e14123b" */ "@site/docs/api/web-app/schemas/schema-get-dashboards-response.mdx"), "@site/docs/api/web-app/schemas/schema-get-dashboards-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-get-dashboards-response.mdx")],
  "4e41b003": [() => import(/* webpackChunkName: "4e41b003" */ "@site/docs/api/lusid/endpoints/calendars/operation-add-date-to-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-add-date-to-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-add-date-to-calendar.mdx")],
  "4e41e791": [() => import(/* webpackChunkName: "4e41e791" */ "@site/docs/api/lusid/schemas/schema-update-amortisation-rule-set-details-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-amortisation-rule-set-details-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-amortisation-rule-set-details-request.mdx")],
  "4e681220": [() => import(/* webpackChunkName: "4e681220" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-update-task-definition.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-update-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-update-task-definition.mdx")],
  "4e8f52f4": [() => import(/* webpackChunkName: "4e8f52f4" */ "@site/docs/api/drive/intro.md"), "@site/docs/api/drive/intro.md", require.resolveWeak("@site/docs/api/drive/intro.md")],
  "4e9e5cd6": [() => import(/* webpackChunkName: "4e9e5cd6" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-ladder.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-ladder.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-ladder.mdx")],
  "4ebe7922": [() => import(/* webpackChunkName: "4ebe7922" */ "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-upsert-portfolio-access-metadata.mdx")],
  "4edefdc0": [() => import(/* webpackChunkName: "4edefdc0" */ "@site/docs/api/lusid/schemas/schema-instrument-definition-format.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-definition-format.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-definition-format.mdx")],
  "4ee7856a": [() => import(/* webpackChunkName: "4ee7856a" */ "@site/docs/api/lusid/schemas/schema-create-data-map-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-data-map-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-data-map-request.mdx")],
  "4f0d9ff9": [() => import(/* webpackChunkName: "4f0d9ff9" */ "@site/docs/api/lusid/schemas/schema-create-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-reconciliation-request.mdx")],
  "4f1a436f": [() => import(/* webpackChunkName: "4f1a436f" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition-search-result.mdx")],
  "4f784354": [() => import(/* webpackChunkName: "4f784354" */ "@site/docs/api/identity/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/identity/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-id-selector-definition.mdx")],
  "4fb4966d": [() => import(/* webpackChunkName: "4fb4966d" */ "@site/docs/api/lusid/schemas/schema-quote-id.mdx"), "@site/docs/api/lusid/schemas/schema-quote-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote-id.mdx")],
  "4fb73740": [() => import(/* webpackChunkName: "4fb73740" */ "@site/docs/api/access/endpoints/policies/operation-list-policy-collections.mdx"), "@site/docs/api/access/endpoints/policies/operation-list-policy-collections.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-list-policy-collections.mdx")],
  "4fc90fbc": [() => import(/* webpackChunkName: "4fc90fbc" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-dialect.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-dialect.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-upsert-translation-dialect.mdx")],
  "4fe1f949": [() => import(/* webpackChunkName: "4fe1f949" */ "@site/docs/api/lusid/schemas/schema-bond-conversion-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-bond-conversion-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond-conversion-schedule.mdx")],
  "501c0fda": [() => import(/* webpackChunkName: "501c0fda" */ "@site/docs/api/web-app/schemas/schema-target-weight.mdx"), "@site/docs/api/web-app/schemas/schema-target-weight.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-target-weight.mdx")],
  "50274766": [() => import(/* webpackChunkName: "50274766" */ "@site/docs/api/lusid/schemas/schema-futures-contract-details.mdx"), "@site/docs/api/lusid/schemas/schema-futures-contract-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-futures-contract-details.mdx")],
  "503fb9ef": [() => import(/* webpackChunkName: "503fb9ef" */ "@site/docs/api/identity/endpoints/me/operation-set-password.mdx"), "@site/docs/api/identity/endpoints/me/operation-set-password.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/me/operation-set-password.mdx")],
  "506949c3": [() => import(/* webpackChunkName: "506949c3" */ "@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-run-block-job.mdx"), "@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-run-block-job.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-run-block-job.mdx")],
  "506b7c13": [() => import(/* webpackChunkName: "506b7c13" */ "@site/docs/api/lusid/schemas/schema-grouped-result-of-address-key.mdx"), "@site/docs/api/lusid/schemas/schema-grouped-result-of-address-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-grouped-result-of-address-key.mdx")],
  "507ca81b": [() => import(/* webpackChunkName: "507ca81b" */ "@site/docs/api/lusid/schemas/schema-adjust-holding.mdx"), "@site/docs/api/lusid/schemas/schema-adjust-holding.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-adjust-holding.mdx")],
  "50a07e2b": [() => import(/* webpackChunkName: "50a07e2b" */ "@site/docs/api/web-app/schemas/schema-grouping-level.mdx"), "@site/docs/api/web-app/schemas/schema-grouping-level.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-grouping-level.mdx")],
  "50ac289c": [() => import(/* webpackChunkName: "50ac289c" */ "@site/docs/api/lusid/endpoints/conventions/operation-get-cds-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-get-cds-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-get-cds-flow-conventions.mdx")],
  "50c33039": [() => import(/* webpackChunkName: "50c33039" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-recipe-composer.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-recipe-composer.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-recipe-composer.mdx")],
  "50d72a5b": [() => import(/* webpackChunkName: "50d72a5b" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-trade-ticket.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-trade-ticket.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-trade-ticket.mdx")],
  "50f12295": [() => import(/* webpackChunkName: "50f12295" */ "@site/docs/api/lusid/schemas/schema-reconciliation-side-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-side-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-side-configuration.mdx")],
  "51018805": [() => import(/* webpackChunkName: "51018805" */ "@site/docs/api/lusid/endpoints/funds/operation-list-fees.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-list-fees.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-list-fees.mdx")],
  "5101d338": [() => import(/* webpackChunkName: "5101d338" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-expansion.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-expansion.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-expansion.mdx")],
  "51055e33": [() => import(/* webpackChunkName: "51055e33" */ "@site/docs/api/luminesce/endpoints/certificate-management/operation-list-certificates.mdx"), "@site/docs/api/luminesce/endpoints/certificate-management/operation-list-certificates.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/certificate-management/operation-list-certificates.mdx")],
  "510c4e3f": [() => import(/* webpackChunkName: "510c4e3f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-composer-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-composer-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-recipe-composer-response.mdx")],
  "51167b71": [() => import(/* webpackChunkName: "51167b71" */ "@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-request.mdx"), "@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancel-orders-and-move-remaining-request.mdx")],
  "512a3d3c": [() => import(/* webpackChunkName: "512a3d3c" */ "@site/docs/api/lusid/endpoints/counterparties/operation-delete-counterparty-agreement.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-delete-counterparty-agreement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-delete-counterparty-agreement.mdx")],
  "5130e7d0": [() => import(/* webpackChunkName: "5130e7d0" */ "@site/docs/api/lusid/endpoints/queryable-keys/operation-get-all-queryable-keys.mdx"), "@site/docs/api/lusid/endpoints/queryable-keys/operation-get-all-queryable-keys.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/queryable-keys/operation-get-all-queryable-keys.mdx")],
  "51ab9a86": [() => import(/* webpackChunkName: "51ab9a86" */ "@site/docs/api/lusid/schemas/schema-equity-option.mdx"), "@site/docs/api/lusid/schemas/schema-equity-option.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-option.mdx")],
  "51dd834f": [() => import(/* webpackChunkName: "51dd834f" */ "@site/docs/api/lusid/endpoints/address-key-definition/operation-get-address-key-definition.mdx"), "@site/docs/api/lusid/endpoints/address-key-definition/operation-get-address-key-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/address-key-definition/operation-get-address-key-definition.mdx")],
  "51f1bdfb": [() => import(/* webpackChunkName: "51f1bdfb" */ "@site/docs/api/lusid/schemas/schema-call-on-intermediate-securities-event.mdx"), "@site/docs/api/lusid/schemas/schema-call-on-intermediate-securities-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-call-on-intermediate-securities-event.mdx")],
  "51f2afd9": [() => import(/* webpackChunkName: "51f2afd9" */ "@site/docs/api/lusid/schemas/schema-sub-holding-key-value-equals.mdx"), "@site/docs/api/lusid/schemas/schema-sub-holding-key-value-equals.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-sub-holding-key-value-equals.mdx")],
  "51fda06b": [() => import(/* webpackChunkName: "51fda06b" */ "@site/docs/api/lusid/schemas/schema-package.mdx"), "@site/docs/api/lusid/schemas/schema-package.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-package.mdx")],
  "524e21d6": [() => import(/* webpackChunkName: "524e21d6" */ "@site/docs/api/lusid/schemas/schema-staged-modifications-requested-change-interval.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modifications-requested-change-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modifications-requested-change-interval.mdx")],
  "5264b33f": [() => import(/* webpackChunkName: "5264b33f" */ "@site/docs/api/lusid/schemas/schema-cds-credit-event.mdx"), "@site/docs/api/lusid/schemas/schema-cds-credit-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cds-credit-event.mdx")],
  "5289e218": [() => import(/* webpackChunkName: "5289e218" */ "@site/docs/api/identity/endpoints/roles/operation-create-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-create-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-create-role.mdx")],
  "5289f7b0": [() => import(/* webpackChunkName: "5289f7b0" */ "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-get-definition.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-get-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-get-definition.mdx")],
  "52d50127": [() => import(/* webpackChunkName: "52d50127" */ "@site/docs/api/lusid/schemas/schema-empty-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-empty-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-empty-model-options.mdx")],
  "53323080": [() => import(/* webpackChunkName: "53323080" */ "@site/docs/api/horizon/schemas/schema-process-information.mdx"), "@site/docs/api/horizon/schemas/schema-process-information.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-process-information.mdx")],
  "53711232": [() => import(/* webpackChunkName: "53711232" */ "@site/docs/api/access/schemas/schema-evaluation-response.mdx"), "@site/docs/api/access/schemas/schema-evaluation-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-evaluation-response.mdx")],
  "538888e1": [() => import(/* webpackChunkName: "538888e1" */ "@site/docs/excel/functions/Instrument Properties.mdx"), "@site/docs/excel/functions/Instrument Properties.mdx", require.resolveWeak("@site/docs/excel/functions/Instrument Properties.mdx")],
  "53891027": [() => import(/* webpackChunkName: "53891027" */ "@site/docs/api/lusid/schemas/schema-instrument-resolution-detail.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-resolution-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-resolution-detail.mdx")],
  "538f716e": [() => import(/* webpackChunkName: "538f716e" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relationships.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relationships.mdx")],
  "539903a4": [() => import(/* webpackChunkName: "539903a4" */ "@site/docs/api/lusid/endpoints/order-management/operation-get-order-history.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-get-order-history.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-get-order-history.mdx")],
  "53ab09f0": [() => import(/* webpackChunkName: "53ab09f0" */ "@site/docs/api/access/schemas/schema-policy-collection-update-request.mdx"), "@site/docs/api/access/schemas/schema-policy-collection-update-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-collection-update-request.mdx")],
  "53f1c0a9": [() => import(/* webpackChunkName: "53f1c0a9" */ "@site/docs/api/lusid/schemas/schema-abor-request.mdx"), "@site/docs/api/lusid/schemas/schema-abor-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor-request.mdx")],
  "53f8df4f": [() => import(/* webpackChunkName: "53f8df4f" */ "@site/docs/api/lusid/endpoints/instrument-events/operation-query-applicable-instrument-events.mdx"), "@site/docs/api/lusid/endpoints/instrument-events/operation-query-applicable-instrument-events.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-events/operation-query-applicable-instrument-events.mdx")],
  "5425cecc": [() => import(/* webpackChunkName: "5425cecc" */ "@site/docs/api/insights/endpoints/requests/operation-get-response.mdx"), "@site/docs/api/insights/endpoints/requests/operation-get-response.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/requests/operation-get-response.mdx")],
  "542e59e9": [() => import(/* webpackChunkName: "542e59e9" */ "@site/docs/api/scheduler/endpoints/images/operation-delete-image.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-delete-image.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-delete-image.mdx")],
  "543ea5d6": [() => import(/* webpackChunkName: "543ea5d6" */ "@site/docs/api/lusid/endpoints/sequences/operation-get-sequence.mdx"), "@site/docs/api/lusid/endpoints/sequences/operation-get-sequence.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/sequences/operation-get-sequence.mdx")],
  "5442c8c0": [() => import(/* webpackChunkName: "5442c8c0" */ "@site/docs/api/lusid/endpoints/order-instructions/operation-list-order-instructions.mdx"), "@site/docs/api/lusid/endpoints/order-instructions/operation-list-order-instructions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-instructions/operation-list-order-instructions.mdx")],
  "544baa29": [() => import(/* webpackChunkName: "544baa29" */ "@site/docs/api/insights/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/insights/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-access-controlled-resource.mdx")],
  "5459dc0e": [() => import(/* webpackChunkName: "5459dc0e" */ "@site/docs/api/luminesce/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/luminesce/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-lusid-problem-details.mdx")],
  "54695dd2": [() => import(/* webpackChunkName: "54695dd2" */ "@site/docs/api/lusid/schemas/schema-requested-changes.mdx"), "@site/docs/api/lusid/schemas/schema-requested-changes.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-requested-changes.mdx")],
  "546a9a6d": [() => import(/* webpackChunkName: "546a9a6d" */ "@site/docs/api/lusid/schemas/schema-upsert-portfolio-transactions-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-portfolio-transactions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-portfolio-transactions-response.mdx")],
  "547e6fb8": [() => import(/* webpackChunkName: "547e6fb8" */ "@site/docs/api/lusid/schemas/schema-portfolio.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio.mdx")],
  "549a989b": [() => import(/* webpackChunkName: "549a989b" */ "@site/docs/api/lusid/schemas/schema-property-value-equals.mdx"), "@site/docs/api/lusid/schemas/schema-property-value-equals.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-value-equals.mdx")],
  "54ae93fc": [() => import(/* webpackChunkName: "54ae93fc" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-templates.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-templates.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-templates.mdx")],
  "54af9e73": [() => import(/* webpackChunkName: "54af9e73" */ "@site/docs/api/lusid/schemas/schema-transaction-date-windows.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-date-windows.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-date-windows.mdx")],
  "54e2faa4": [() => import(/* webpackChunkName: "54e2faa4" */ "@site/docs/api/lusid/endpoints/instruments/operation-calculate-settlement-date.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-calculate-settlement-date.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-calculate-settlement-date.mdx")],
  "54e50055": [() => import(/* webpackChunkName: "54e50055" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-patch-portfolio-group-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-patch-portfolio-group-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-patch-portfolio-group-access-metadata.mdx")],
  "55592c67": [() => import(/* webpackChunkName: "55592c67" */ "@site/docs/api/lusid/schemas/schema-reconcile-numeric-rule.mdx"), "@site/docs/api/lusid/schemas/schema-reconcile-numeric-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconcile-numeric-rule.mdx")],
  "5570b65f": [() => import(/* webpackChunkName: "5570b65f" */ "@site/docs/api/lusid/schemas/schema-effective-range.mdx"), "@site/docs/api/lusid/schemas/schema-effective-range.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-effective-range.mdx")],
  "55c374d1": [() => import(/* webpackChunkName: "55c374d1" */ "@site/docs/api/lusid/schemas/schema-get-recipe-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-recipe-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-recipe-response.mdx")],
  "55c56ae9": [() => import(/* webpackChunkName: "55c56ae9" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-placement-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-placement-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-placement-detail.mdx")],
  "55e96721": [() => import(/* webpackChunkName: "55e96721" */ "@site/docs/api/lusid/schemas/schema-informational-event.mdx"), "@site/docs/api/lusid/schemas/schema-informational-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-informational-event.mdx")],
  "55ff5c94": [() => import(/* webpackChunkName: "55ff5c94" */ "@site/docs/api/identity/endpoints/roles/operation-remove-user-from-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-remove-user-from-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-remove-user-from-role.mdx")],
  "5610d461": [() => import(/* webpackChunkName: "5610d461" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-relation.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-relation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-relation.mdx")],
  "561568c6": [() => import(/* webpackChunkName: "561568c6" */ "@site/docs/api/lusid/schemas/schema-entity-identifier.mdx"), "@site/docs/api/lusid/schemas/schema-entity-identifier.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-entity-identifier.mdx")],
  "5626def3": [() => import(/* webpackChunkName: "5626def3" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-transactions.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-cancel-transactions.mdx")],
  "5629dfa7": [() => import(/* webpackChunkName: "5629dfa7" */ "@site/docs/api/drive/endpoints/folders/operation-move-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-move-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-move-folder.mdx")],
  "5631a787": [() => import(/* webpackChunkName: "5631a787" */ "@site/docs/api/web-app/schemas/schema-hubspot-support-article-list.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-support-article-list.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-support-article-list.mdx")],
  "567f746a": [() => import(/* webpackChunkName: "567f746a" */ "@site/docs/api/web-app/schemas/schema-contact.mdx"), "@site/docs/api/web-app/schemas/schema-contact.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-contact.mdx")],
  "5685298a": [() => import(/* webpackChunkName: "5685298a" */ "@site/docs/api/lusid/schemas/schema-funding-leg.mdx"), "@site/docs/api/lusid/schemas/schema-funding-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-funding-leg.mdx")],
  "5698355d": [() => import(/* webpackChunkName: "5698355d" */ "@site/docs/api/lusid/endpoints/compliance/operation-run-compliance.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-run-compliance.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-run-compliance.mdx")],
  "569936f2": [() => import(/* webpackChunkName: "569936f2" */ "@site/docs/api/horizon/schemas/schema-open-figi-data.mdx"), "@site/docs/api/horizon/schemas/schema-open-figi-data.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-open-figi-data.mdx")],
  "56bdf335": [() => import(/* webpackChunkName: "56bdf335" */ "@site/docs/api/lusid/schemas/schema-batch-adjust-holdings-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-adjust-holdings-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-adjust-holdings-response.mdx")],
  "56d55f69": [() => import(/* webpackChunkName: "56d55f69" */ "@site/docs/api/lusid/schemas/schema-security-offer-election.mdx"), "@site/docs/api/lusid/schemas/schema-security-offer-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-security-offer-election.mdx")],
  "56d7e073": [() => import(/* webpackChunkName: "56d7e073" */ "@site/docs/api/access/schemas/schema-role-resource-request.mdx"), "@site/docs/api/access/schemas/schema-role-resource-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-role-resource-request.mdx")],
  "570adafc": [() => import(/* webpackChunkName: "570adafc" */ "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-histogram.mdx"), "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-histogram.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-histogram.mdx")],
  "570ae81d": [() => import(/* webpackChunkName: "570ae81d" */ "@site/docs/api/identity/endpoints/users/operation-unsuspend-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-unsuspend-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-unsuspend-user.mdx")],
  "57189bfd": [() => import(/* webpackChunkName: "57189bfd" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-csv.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-csv.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-csv.mdx")],
  "57483e8c": [() => import(/* webpackChunkName: "57483e8c" */ "@site/docs/api/workflow/schemas/schema-create-child-task-configuration.mdx"), "@site/docs/api/workflow/schemas/schema-create-child-task-configuration.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-child-task-configuration.mdx")],
  "57581cb2": [() => import(/* webpackChunkName: "57581cb2" */ "@site/docs/api/identity/endpoints/users/operation-reset-password.mdx"), "@site/docs/api/identity/endpoints/users/operation-reset-password.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-reset-password.mdx")],
  "57b36164": [() => import(/* webpackChunkName: "57b36164" */ "@site/docs/api/lusid/schemas/schema-filter-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-filter-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-filter-step-request.mdx")],
  "57ba0802": [() => import(/* webpackChunkName: "57ba0802" */ "@site/docs/api/lusid/schemas/schema-stock-split-event.mdx"), "@site/docs/api/lusid/schemas/schema-stock-split-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-stock-split-event.mdx")],
  "57bafc1e": [() => import(/* webpackChunkName: "57bafc1e" */ "@site/docs/api/identity/schemas/schema-create-role-request.mdx"), "@site/docs/api/identity/schemas/schema-create-role-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-create-role-request.mdx")],
  "57c05d72": [() => import(/* webpackChunkName: "57c05d72" */ "@site/docs/api/luminesce/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/luminesce/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-id-selector-definition.mdx")],
  "57e731a5": [() => import(/* webpackChunkName: "57e731a5" */ "@site/docs/api/luminesce/schemas/schema-scalar-parameter.mdx"), "@site/docs/api/luminesce/schemas/schema-scalar-parameter.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-scalar-parameter.mdx")],
  "57ea9a23": [() => import(/* webpackChunkName: "57ea9a23" */ "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-providers.mdx"), "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-providers.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-providers.mdx")],
  "57f1c00d": [() => import(/* webpackChunkName: "57f1c00d" */ "@site/docs/api/lusid/endpoints/persons/operation-upsert-person.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-upsert-person.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-upsert-person.mdx")],
  "58172d35": [() => import(/* webpackChunkName: "58172d35" */ "@site/docs/api/lusid/schemas/schema-scope-definition.mdx"), "@site/docs/api/lusid/schemas/schema-scope-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-scope-definition.mdx")],
  "5821d434": [() => import(/* webpackChunkName: "5821d434" */ "@site/docs/api/workflow/schemas/schema-value-constraints.mdx"), "@site/docs/api/workflow/schemas/schema-value-constraints.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-value-constraints.mdx")],
  "58343748": [() => import(/* webpackChunkName: "58343748" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relations.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-relations.mdx")],
  "583ed4aa": [() => import(/* webpackChunkName: "583ed4aa" */ "@site/docs/api/lusid/endpoints/entities/operation-get-entity-history.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-entity-history.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-entity-history.mdx")],
  "583f520a": [() => import(/* webpackChunkName: "583f520a" */ "@site/docs/api/lusid/endpoints/relationships/operation-create-relationship.mdx"), "@site/docs/api/lusid/endpoints/relationships/operation-create-relationship.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationships/operation-create-relationship.mdx")],
  "5842e0cc": [() => import(/* webpackChunkName: "5842e0cc" */ "@site/docs/api/lusid/schemas/schema-instrument-event-instructions-response.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event-instructions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event-instructions-response.mdx")],
  "5849d94c": [() => import(/* webpackChunkName: "5849d94c" */ "@site/docs/api/identity/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/identity/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-lusid-problem-details.mdx")],
  "58661a01": [() => import(/* webpackChunkName: "58661a01" */ "@site/docs/api/lusid/schemas/schema-counterparty-signatory.mdx"), "@site/docs/api/lusid/schemas/schema-counterparty-signatory.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-counterparty-signatory.mdx")],
  "586ea6b3": [() => import(/* webpackChunkName: "586ea6b3" */ "@site/docs/api/luminesce/schemas/schema-error-highlight-request.mdx"), "@site/docs/api/luminesce/schemas/schema-error-highlight-request.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-error-highlight-request.mdx")],
  "58724573": [() => import(/* webpackChunkName: "58724573" */ "@site/docs/api/lusid/schemas/schema-interest-rate-swap.mdx"), "@site/docs/api/lusid/schemas/schema-interest-rate-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-interest-rate-swap.mdx")],
  "587c265e": [() => import(/* webpackChunkName: "587c265e" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transactions.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transactions.mdx")],
  "588232d8": [() => import(/* webpackChunkName: "588232d8" */ "@site/docs/api/lusid/endpoints/funds/operation-delete-fee.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-delete-fee.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-delete-fee.mdx")],
  "58986208": [() => import(/* webpackChunkName: "58986208" */ "@site/docs/api/lusid/schemas/schema-upsert-portfolio-group-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-portfolio-group-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-portfolio-group-access-metadata-request.mdx")],
  "58b962cc": [() => import(/* webpackChunkName: "58b962cc" */ "@site/docs/api/horizon/schemas/schema-vendor-field.mdx"), "@site/docs/api/horizon/schemas/schema-vendor-field.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-vendor-field.mdx")],
  "58f867c5": [() => import(/* webpackChunkName: "58f867c5" */ "@site/docs/api/identity/endpoints/applications/operation-create-application.mdx"), "@site/docs/api/identity/endpoints/applications/operation-create-application.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/applications/operation-create-application.mdx")],
  "593a7e11": [() => import(/* webpackChunkName: "593a7e11" */ "@site/docs/api/web-app/schemas/schema-hedging-policy-type.mdx"), "@site/docs/api/web-app/schemas/schema-hedging-policy-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hedging-policy-type.mdx")],
  "5942f478": [() => import(/* webpackChunkName: "5942f478" */ "@site/docs/api/lusid/endpoints/relations/operation-create-relation.mdx"), "@site/docs/api/lusid/endpoints/relations/operation-create-relation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relations/operation-create-relation.mdx")],
  "59596eea": [() => import(/* webpackChunkName: "59596eea" */ "@site/docs/api/lusid/schemas/schema-funding-leg-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-funding-leg-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-funding-leg-model-options.mdx")],
  "5960cf72": [() => import(/* webpackChunkName: "5960cf72" */ "@site/docs/api/drive/endpoints/folders/operation-get-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-get-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-get-folder.mdx")],
  "596ca4ca": [() => import(/* webpackChunkName: "596ca4ca" */ "@site/docs/api/access/schemas/schema-request-details.mdx"), "@site/docs/api/access/schemas/schema-request-details.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-request-details.mdx")],
  "59774d8c": [() => import(/* webpackChunkName: "59774d8c" */ "@site/docs/api/identity/schemas/schema-support-role.mdx"), "@site/docs/api/identity/schemas/schema-support-role.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-role.mdx")],
  "59997238": [() => import(/* webpackChunkName: "59997238" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-side-definition.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-side-definition.mdx")],
  "59a0d523": [() => import(/* webpackChunkName: "59a0d523" */ "@site/src/pages/api/index.tsx"), "@site/src/pages/api/index.tsx", require.resolveWeak("@site/src/pages/api/index.tsx")],
  "59e3f9f2": [() => import(/* webpackChunkName: "59e3f9f2" */ "@site/docs/api/scheduler/schemas/schema-notification.mdx"), "@site/docs/api/scheduler/schemas/schema-notification.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-notification.mdx")],
  "59f931ba": [() => import(/* webpackChunkName: "59f931ba" */ "@site/docs/api/horizon/schemas/schema-open-figi-search-result.mdx"), "@site/docs/api/horizon/schemas/schema-open-figi-search-result.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-open-figi-search-result.mdx")],
  "5a0d18fb": [() => import(/* webpackChunkName: "5a0d18fb" */ "@site/docs/api/access/schemas/schema-if-request-header-expression.mdx"), "@site/docs/api/access/schemas/schema-if-request-header-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-if-request-header-expression.mdx")],
  "5a0d5cac": [() => import(/* webpackChunkName: "5a0d5cac" */ "@site/docs/api/lusid/schemas/schema-custom-entity-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-definition-request.mdx")],
  "5a10e410": [() => import(/* webpackChunkName: "5a10e410" */ "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-create-tax-rule-set.mdx"), "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-create-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/tax-rule-sets/operation-create-tax-rule-set.mdx")],
  "5a2ae3e7": [() => import(/* webpackChunkName: "5a2ae3e7" */ "@site/docs/api/horizon/schemas/schema-audit-complete-status.mdx"), "@site/docs/api/horizon/schemas/schema-audit-complete-status.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-complete-status.mdx")],
  "5a2bbf76": [() => import(/* webpackChunkName: "5a2bbf76" */ "@site/docs/api/identity/schemas/schema-create-user-request.mdx"), "@site/docs/api/identity/schemas/schema-create-user-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-create-user-request.mdx")],
  "5a311623": [() => import(/* webpackChunkName: "5a311623" */ "@site/docs/api/lusid/schemas/schema-compounding.mdx"), "@site/docs/api/lusid/schemas/schema-compounding.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compounding.mdx")],
  "5a316772": [() => import(/* webpackChunkName: "5a316772" */ "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-vendor-product.mdx"), "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-vendor-product.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-paged-resource-list-of-vendor-product.mdx")],
  "5a3fc6e8": [() => import(/* webpackChunkName: "5a3fc6e8" */ "@site/docs/api/lusid/schemas/schema-index-convention.mdx"), "@site/docs/api/lusid/schemas/schema-index-convention.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-index-convention.mdx")],
  "5a67f652": [() => import(/* webpackChunkName: "5a67f652" */ "@site/docs/api/lusid/schemas/schema-corporate-action.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action.mdx")],
  "5ab2279c": [() => import(/* webpackChunkName: "5ab2279c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-calendar.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-calendar.mdx")],
  "5acc530c": [() => import(/* webpackChunkName: "5acc530c" */ "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-catalog.mdx"), "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-catalog.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-catalog.mdx")],
  "5b042c9a": [() => import(/* webpackChunkName: "5b042c9a" */ "@site/docs/api/horizon/endpoints/instrument/operation-enrich-instrument.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-enrich-instrument.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-enrich-instrument.mdx")],
  "5b19b0c6": [() => import(/* webpackChunkName: "5b19b0c6" */ "@site/docs/api/lusid/endpoints/counterparties/operation-upsert-counterparty-agreement.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-upsert-counterparty-agreement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-upsert-counterparty-agreement.mdx")],
  "5b22971f": [() => import(/* webpackChunkName: "5b22971f" */ "@site/docs/api/lusid/schemas/schema-transaction-field-map.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-field-map.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-field-map.mdx")],
  "5bbd0695": [() => import(/* webpackChunkName: "5bbd0695" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-comparison-ruleset.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-create-comparison-ruleset.mdx")],
  "5bd31f56": [() => import(/* webpackChunkName: "5bd31f56" */ "@site/docs/api/workflow/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/workflow/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-identifier-part-schema.mdx")],
  "5be1753f": [() => import(/* webpackChunkName: "5be1753f" */ "@site/docs/api/horizon/endpoints/instrument/operation-retrieve-perm-id-result.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-retrieve-perm-id-result.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-retrieve-perm-id-result.mdx")],
  "5bef3413": [() => import(/* webpackChunkName: "5bef3413" */ "@site/docs/api/lusid/schemas/schema-property-schema.mdx"), "@site/docs/api/lusid/schemas/schema-property-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-schema.mdx")],
  "5c019fa1": [() => import(/* webpackChunkName: "5c019fa1" */ "@site/docs/api/lusid/schemas/schema-reference-list.mdx"), "@site/docs/api/lusid/schemas/schema-reference-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-list.mdx")],
  "5c1c44a1": [() => import(/* webpackChunkName: "5c1c44a1" */ "@site/docs/api/lusid/schemas/schema-bool-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-bool-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bool-list-compliance-parameter.mdx")],
  "5c1c635e": [() => import(/* webpackChunkName: "5c1c635e" */ "@site/docs/api/access/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/access/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-lusid-validation-problem-details.mdx")],
  "5c32c5c4": [() => import(/* webpackChunkName: "5c32c5c4" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-with-orders.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-with-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-with-orders.mdx")],
  "5c6f723b": [() => import(/* webpackChunkName: "5c6f723b" */ "@site/docs/api/workflow/schemas/schema-worker.mdx"), "@site/docs/api/workflow/schemas/schema-worker.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-worker.mdx")],
  "5c719fea": [() => import(/* webpackChunkName: "5c719fea" */ "@site/docs/api/lusid/endpoints/reference-lists/operation-delete-reference-list.mdx"), "@site/docs/api/lusid/endpoints/reference-lists/operation-delete-reference-list.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-lists/operation-delete-reference-list.mdx")],
  "5c8bbf8c": [() => import(/* webpackChunkName: "5c8bbf8c" */ "@site/docs/api/lusid/schemas/schema-term-deposit.mdx"), "@site/docs/api/lusid/schemas/schema-term-deposit.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-term-deposit.mdx")],
  "5c8dc869": [() => import(/* webpackChunkName: "5c8dc869" */ "@site/docs/api/access/schemas/schema-role-creation-request.mdx"), "@site/docs/api/access/schemas/schema-role-creation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-role-creation-request.mdx")],
  "5cb6f2d6": [() => import(/* webpackChunkName: "5cb6f2d6" */ "@site/docs/api/lusid/schemas/schema-update-calendar-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-calendar-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-calendar-request.mdx")],
  "5ce421c7": [() => import(/* webpackChunkName: "5ce421c7" */ "@site/docs/api/notification/endpoints/manual-event/operation-trigger-manual-event.mdx"), "@site/docs/api/notification/endpoints/manual-event/operation-trigger-manual-event.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/manual-event/operation-trigger-manual-event.mdx")],
  "5ceee147": [() => import(/* webpackChunkName: "5ceee147" */ "@site/docs/api/lusid/schemas/schema-staged-modification-staging-rule.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modification-staging-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modification-staging-rule.mdx")],
  "5cf261cc": [() => import(/* webpackChunkName: "5cf261cc" */ "@site/docs/api/insights/schemas/schema-problem-details.mdx"), "@site/docs/api/insights/schemas/schema-problem-details.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-problem-details.mdx")],
  "5cfdc19b": [() => import(/* webpackChunkName: "5cfdc19b" */ "@site/docs/excel/functions/Scopes.mdx"), "@site/docs/excel/functions/Scopes.mdx", require.resolveWeak("@site/docs/excel/functions/Scopes.mdx")],
  "5cfe5949": [() => import(/* webpackChunkName: "5cfe5949" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-i-unit-definition-dto.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-i-unit-definition-dto.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-i-unit-definition-dto.mdx")],
  "5d170131": [() => import(/* webpackChunkName: "5d170131" */ "@site/docs/api/lusid/endpoints/portfolios/operation-delete-instrument-event-instruction.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-delete-instrument-event-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-delete-instrument-event-instruction.mdx")],
  "5d1733a6": [() => import(/* webpackChunkName: "5d1733a6" */ "@site/docs/api/lusid/schemas/schema-change-item.mdx"), "@site/docs/api/lusid/schemas/schema-change-item.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-change-item.mdx")],
  "5d3dab70": [() => import(/* webpackChunkName: "5d3dab70" */ "@site/docs/api/lusid/schemas/schema-translate-trade-ticket-request.mdx"), "@site/docs/api/lusid/schemas/schema-translate-trade-ticket-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-trade-ticket-request.mdx")],
  "5d58dc45": [() => import(/* webpackChunkName: "5d58dc45" */ "@site/docs/api/lusid/schemas/schema-cancel-placements-response.mdx"), "@site/docs/api/lusid/schemas/schema-cancel-placements-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancel-placements-response.mdx")],
  "5d65fbfa": [() => import(/* webpackChunkName: "5d65fbfa" */ "@site/docs/api/access/schemas/schema-add-policy-collection-to-role-request.mdx"), "@site/docs/api/access/schemas/schema-add-policy-collection-to-role-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-add-policy-collection-to-role-request.mdx")],
  "5d66f1d3": [() => import(/* webpackChunkName: "5d66f1d3" */ "@site/docs/api/web-app/schemas/schema-order-time-in-force.mdx"), "@site/docs/api/web-app/schemas/schema-order-time-in-force.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-order-time-in-force.mdx")],
  "5d73cfd6": [() => import(/* webpackChunkName: "5d73cfd6" */ "@site/docs/api/workflow/schemas/schema-trigger-parent-task-action.mdx"), "@site/docs/api/workflow/schemas/schema-trigger-parent-task-action.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-trigger-parent-task-action.mdx")],
  "5d9ff486": [() => import(/* webpackChunkName: "5d9ff486" */ "@site/docs/api/lusid/endpoints/transaction-fees/operation-list-transaction-fee-rules.mdx"), "@site/docs/api/lusid/endpoints/transaction-fees/operation-list-transaction-fee-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-fees/operation-list-transaction-fee-rules.mdx")],
  "5dac2a77": [() => import(/* webpackChunkName: "5dac2a77" */ "@site/docs/api/lusid/schemas/schema-create-relationship-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-relationship-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-relationship-definition-request.mdx")],
  "5e101544": [() => import(/* webpackChunkName: "5e101544" */ "@site/docs/api/lusid/schemas/schema-change-history.mdx"), "@site/docs/api/lusid/schemas/schema-change-history.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-change-history.mdx")],
  "5e121801": [() => import(/* webpackChunkName: "5e121801" */ "@site/docs/api/horizon/endpoints/process-history/operation-create-complete-event.mdx"), "@site/docs/api/horizon/endpoints/process-history/operation-create-complete-event.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/process-history/operation-create-complete-event.mdx")],
  "5e36573b": [() => import(/* webpackChunkName: "5e36573b" */ "@site/docs/api/lusid/endpoints/abor/operation-patch-abor.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-patch-abor.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-patch-abor.mdx")],
  "5e63eb2b": [() => import(/* webpackChunkName: "5e63eb2b" */ "@site/docs/api/lusid/schemas/schema-group-by-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-group-by-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-by-step-request.mdx")],
  "5e7dbd3a": [() => import(/* webpackChunkName: "5e7dbd3a" */ "@site/docs/api/lusid/schemas/schema-fund-id-list.mdx"), "@site/docs/api/lusid/schemas/schema-fund-id-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-id-list.mdx")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "5eb2e2b4": [() => import(/* webpackChunkName: "5eb2e2b4" */ "@site/docs/api/lusid/schemas/schema-field-definition.mdx"), "@site/docs/api/lusid/schemas/schema-field-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-field-definition.mdx")],
  "5ebd73ba": [() => import(/* webpackChunkName: "5ebd73ba" */ "@site/docs/api/lusid/schemas/schema-ir-vol-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-ir-vol-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-ir-vol-dependency.mdx")],
  "5ec51d26": [() => import(/* webpackChunkName: "5ec51d26" */ "@site/docs/api/web-app/schemas/schema-personalisation.mdx"), "@site/docs/api/web-app/schemas/schema-personalisation.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-personalisation.mdx")],
  "5ef163c0": [() => import(/* webpackChunkName: "5ef163c0" */ "@site/docs/api/lusid/schemas/schema-custom-entity-type.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-type.mdx")],
  "5ef7585b": [() => import(/* webpackChunkName: "5ef7585b" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-delete-fund-configuration.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-delete-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-delete-fund-configuration.mdx")],
  "5f124c7c": [() => import(/* webpackChunkName: "5f124c7c" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-child-placement-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-child-placement-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-child-placement-detail.mdx")],
  "5f1fdd88": [() => import(/* webpackChunkName: "5f1fdd88" */ "@site/docs/api/lusid/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/lusid/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lusid-validation-problem-details.mdx")],
  "5f40dd13": [() => import(/* webpackChunkName: "5f40dd13" */ "@site/docs/api/lusid/schemas/schema-decimal-list.mdx"), "@site/docs/api/lusid/schemas/schema-decimal-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-decimal-list.mdx")],
  "5f72b0e0": [() => import(/* webpackChunkName: "5f72b0e0" */ "@site/docs/api/workflow/schemas/schema-create-new-task-activity-response.mdx"), "@site/docs/api/workflow/schemas/schema-create-new-task-activity-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-new-task-activity-response.mdx")],
  "5f81091f": [() => import(/* webpackChunkName: "5f81091f" */ "@site/docs/api/lusid/endpoints/relation-definitions/operation-delete-relation-definition.mdx"), "@site/docs/api/lusid/endpoints/relation-definitions/operation-delete-relation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relation-definitions/operation-delete-relation-definition.mdx")],
  "5f8eeffe": [() => import(/* webpackChunkName: "5f8eeffe" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-run-legacy-compliance.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-run-legacy-compliance.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-run-legacy-compliance.mdx")],
  "5fc9854b": [() => import(/* webpackChunkName: "5fc9854b" */ "@site/docs/api/drive/schemas/schema-action-id.mdx"), "@site/docs/api/drive/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-action-id.mdx")],
  "5ff4b799": [() => import(/* webpackChunkName: "5ff4b799" */ "@site/docs/api/lusid/schemas/schema-aggregated-return.mdx"), "@site/docs/api/lusid/schemas/schema-aggregated-return.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregated-return.mdx")],
  "5ffeb0ff": [() => import(/* webpackChunkName: "5ffeb0ff" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-comparison-ruleset.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-comparison-ruleset.mdx")],
  "6020e37e": [() => import(/* webpackChunkName: "6020e37e" */ "@site/docs/api/lusid/schemas/schema-flow-convention-name.mdx"), "@site/docs/api/lusid/schemas/schema-flow-convention-name.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-flow-convention-name.mdx")],
  "6024cdda": [() => import(/* webpackChunkName: "6024cdda" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-list-property-definitions.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-list-property-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-list-property-definitions.mdx")],
  "60255cf3": [() => import(/* webpackChunkName: "60255cf3" */ "@site/docs/api/lusid/schemas/schema-query-cash-flows-request.mdx"), "@site/docs/api/lusid/schemas/schema-query-cash-flows-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-query-cash-flows-request.mdx")],
  "60335840": [() => import(/* webpackChunkName: "60335840" */ "@site/docs/api/lusid/endpoints/funds/operation-upsert-diary-entry-type-valuation-point.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-upsert-diary-entry-type-valuation-point.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-upsert-diary-entry-type-valuation-point.mdx")],
  "60795926": [() => import(/* webpackChunkName: "60795926" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-job-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-job-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-job-definition.mdx")],
  "60bd151f": [() => import(/* webpackChunkName: "60bd151f" */ "@site/docs/api/lusid/schemas/schema-property-filter.mdx"), "@site/docs/api/lusid/schemas/schema-property-filter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-filter.mdx")],
  "60c6608f": [() => import(/* webpackChunkName: "60c6608f" */ "@site/docs/api/lusid/schemas/schema-reconciliation-line.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-line.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-line.mdx")],
  "60e5d379": [() => import(/* webpackChunkName: "60e5d379" */ "@site/docs/api/notification/schemas/schema-attempt.mdx"), "@site/docs/api/notification/schemas/schema-attempt.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-attempt.mdx")],
  "60f5c2b0": [() => import(/* webpackChunkName: "60f5c2b0" */ "@site/docs/api/access/schemas/schema-requested-action-key.mdx"), "@site/docs/api/access/schemas/schema-requested-action-key.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-requested-action-key.mdx")],
  "6100ea0c": [() => import(/* webpackChunkName: "6100ea0c" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-synopsis.mdx")],
  "61078e9c": [() => import(/* webpackChunkName: "61078e9c" */ "@site/docs/api/lusid/schemas/schema-composite-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-composite-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-composite-breakdown.mdx")],
  "610c9dc6": [() => import(/* webpackChunkName: "610c9dc6" */ "@site/docs/api/lusid/schemas/schema-economic-dependency-with-complex-market-data.mdx"), "@site/docs/api/lusid/schemas/schema-economic-dependency-with-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-economic-dependency-with-complex-market-data.mdx")],
  "611a025a": [() => import(/* webpackChunkName: "611a025a" */ "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-template.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-get-compliance-template.mdx")],
  "6129d0cd": [() => import(/* webpackChunkName: "6129d0cd" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-transaction-type.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-transaction-type.mdx")],
  "617f4664": [() => import(/* webpackChunkName: "617f4664" */ "@site/docs/api/horizon/schemas/schema-allowed-parameter-value.mdx"), "@site/docs/api/horizon/schemas/schema-allowed-parameter-value.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-allowed-parameter-value.mdx")],
  "61c83f97": [() => import(/* webpackChunkName: "61c83f97" */ "@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-summary-rule-result.mdx")],
  "61cd10b0": [() => import(/* webpackChunkName: "61cd10b0" */ "@site/docs/api/notification/schemas/schema-resource-list-of-notification.mdx"), "@site/docs/api/notification/schemas/schema-resource-list-of-notification.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-list-of-notification.mdx")],
  "61fe5927": [() => import(/* webpackChunkName: "61fe5927" */ "@site/docs/api/lusid/schemas/schema-update-group-reconciliation-comparison-ruleset-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-group-reconciliation-comparison-ruleset-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-group-reconciliation-comparison-ruleset-request.mdx")],
  "6229c2c7": [() => import(/* webpackChunkName: "6229c2c7" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-custodian-accounts.mdx")],
  "623b41e3": [() => import(/* webpackChunkName: "623b41e3" */ "@site/docs/api/lusid/endpoints/data-types/operation-create-data-type.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-create-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-create-data-type.mdx")],
  "6257e025": [() => import(/* webpackChunkName: "6257e025" */ "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-update-cut-label-definition.mdx"), "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-update-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/cut-label-definitions/operation-update-cut-label-definition.mdx")],
  "627f3d3a": [() => import(/* webpackChunkName: "627f3d3a" */ "@site/docs/api/lusid/schemas/schema-mastered-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-mastered-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mastered-instrument.mdx")],
  "62861a17": [() => import(/* webpackChunkName: "62861a17" */ "@site/docs/api/lusid/schemas/schema-equity-vol-surface-data.mdx"), "@site/docs/api/lusid/schemas/schema-equity-vol-surface-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-vol-surface-data.mdx")],
  "6299fa38": [() => import(/* webpackChunkName: "6299fa38" */ "@site/docs/api/lusid/schemas/schema-instrument-payment-diary-row.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-payment-diary-row.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-payment-diary-row.mdx")],
  "62ab6ff0": [() => import(/* webpackChunkName: "62ab6ff0" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-upsert-legacy-compliance-rules.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-upsert-legacy-compliance-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-upsert-legacy-compliance-rules.mdx")],
  "62c06fe1": [() => import(/* webpackChunkName: "62c06fe1" */ "@site/docs/api/lusid/endpoints/instruments/operation-batch-upsert-instrument-properties.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-batch-upsert-instrument-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-batch-upsert-instrument-properties.mdx")],
  "62c4e035": [() => import(/* webpackChunkName: "62c4e035" */ "@site/docs/api/identity/schemas/schema-create-application-request.mdx"), "@site/docs/api/identity/schemas/schema-create-application-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-create-application-request.mdx")],
  "63048a2f": [() => import(/* webpackChunkName: "63048a2f" */ "@site/docs/api/lusid/schemas/schema-day-month.mdx"), "@site/docs/api/lusid/schemas/schema-day-month.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-day-month.mdx")],
  "630ed446": [() => import(/* webpackChunkName: "630ed446" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-data-type-summary.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-data-type-summary.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-data-type-summary.mdx")],
  "631b4466": [() => import(/* webpackChunkName: "631b4466" */ "@site/docs/api/lusid/schemas/schema-journal-entry-line.mdx"), "@site/docs/api/lusid/schemas/schema-journal-entry-line.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-journal-entry-line.mdx")],
  "6327f461": [() => import(/* webpackChunkName: "6327f461" */ "@site/docs/api/horizon/schemas/schema-execute-instance-response.mdx"), "@site/docs/api/horizon/schemas/schema-execute-instance-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-execute-instance-response.mdx")],
  "632d5d36": [() => import(/* webpackChunkName: "632d5d36" */ "@site/docs/api/lusid/schemas/schema-holdings-adjustment-header.mdx"), "@site/docs/api/lusid/schemas/schema-holdings-adjustment-header.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holdings-adjustment-header.mdx")],
  "633fbc05": [() => import(/* webpackChunkName: "633fbc05" */ "@site/docs/api/lusid/endpoints/quotes/operation-delete-quotes.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-delete-quotes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-delete-quotes.mdx")],
  "63459c36": [() => import(/* webpackChunkName: "63459c36" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type-source.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-set-transaction-type-source.mdx")],
  "63493196": [() => import(/* webpackChunkName: "63493196" */ "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-fields.mdx"), "@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-fields.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/current-table-field-catalog/operation-get-fields.mdx")],
  "63731e9d": [() => import(/* webpackChunkName: "63731e9d" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-sqlite.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-sqlite.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-sqlite.mdx")],
  "637971ea": [() => import(/* webpackChunkName: "637971ea" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-request.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-request.mdx")],
  "638b8710": [() => import(/* webpackChunkName: "638b8710" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staging-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-staging-rule-set.mdx")],
  "63df97a8": [() => import(/* webpackChunkName: "63df97a8" */ "@site/docs/api/lusid/schemas/schema-complete-relationship.mdx"), "@site/docs/api/lusid/schemas/schema-complete-relationship.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complete-relationship.mdx")],
  "63f84dcb": [() => import(/* webpackChunkName: "63f84dcb" */ "@site/docs/api/access/schemas/schema-generate-policy-from-template-request.mdx"), "@site/docs/api/access/schemas/schema-generate-policy-from-template-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-generate-policy-from-template-request.mdx")],
  "63fd677f": [() => import(/* webpackChunkName: "63fd677f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-credit-support-annex-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-credit-support-annex-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-credit-support-annex-response.mdx")],
  "6407fc88": [() => import(/* webpackChunkName: "6407fc88" */ "@site/docs/api/lusid/schemas/schema-compliance-template-variation-dto.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-template-variation-dto.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-template-variation-dto.mdx")],
  "6488e22b": [() => import(/* webpackChunkName: "6488e22b" */ "@site/docs/api/access/endpoints/policies/operation-remove-from-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-remove-from-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-remove-from-policy-collection.mdx")],
  "648fb4c7": [() => import(/* webpackChunkName: "648fb4c7" */ "@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument-properties.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-delete-instrument-properties.mdx")],
  "64a8dcb7": [() => import(/* webpackChunkName: "64a8dcb7" */ "@site/docs/api/lusid/schemas/schema-action-id.mdx"), "@site/docs/api/lusid/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-action-id.mdx")],
  "64cd17fe": [() => import(/* webpackChunkName: "64cd17fe" */ "@site/docs/api/scheduler/schemas/schema-job-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-job-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-job-definition.mdx")],
  "64d8fee5": [() => import(/* webpackChunkName: "64d8fee5" */ "@site/docs/api/lusid/schemas/schema-economic-dependency-with-quote.mdx"), "@site/docs/api/lusid/schemas/schema-economic-dependency-with-quote.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-economic-dependency-with-quote.mdx")],
  "64e03c66": [() => import(/* webpackChunkName: "64e03c66" */ "@site/docs/api/lusid/schemas/schema-transaction-property-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-property-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-property-mapping.mdx")],
  "64e6f768": [() => import(/* webpackChunkName: "64e6f768" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-placement.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-placement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-placement.mdx")],
  "6502f824": [() => import(/* webpackChunkName: "6502f824" */ "@site/docs/api/lusid/schemas/schema-get-virtual-document-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-virtual-document-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-virtual-document-response.mdx")],
  "65120f72": [() => import(/* webpackChunkName: "65120f72" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-holder.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-holder.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-instrument-event-holder.mdx")],
  "652e8d7b": [() => import(/* webpackChunkName: "652e8d7b" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-delete-structured-result-data.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-delete-structured-result-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-delete-structured-result-data.mdx")],
  "6544ecfd": [() => import(/* webpackChunkName: "6544ecfd" */ "@site/docs/api/lusid/schemas/schema-step-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-step-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-step-schedule.mdx")],
  "657285d8": [() => import(/* webpackChunkName: "657285d8" */ "@site/docs/api/lusid/schemas/schema-cash-dividend-event.mdx"), "@site/docs/api/lusid/schemas/schema-cash-dividend-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-dividend-event.mdx")],
  "65729b3f": [() => import(/* webpackChunkName: "65729b3f" */ "@site/docs/api/lusid/schemas/schema-maturity-event.mdx"), "@site/docs/api/lusid/schemas/schema-maturity-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-maturity-event.mdx")],
  "6592497c": [() => import(/* webpackChunkName: "6592497c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-diary-entry.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-diary-entry.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-diary-entry.mdx")],
  "65af859a": [() => import(/* webpackChunkName: "65af859a" */ "@site/docs/api/identity/endpoints/users/operation-list-users.mdx"), "@site/docs/api/identity/endpoints/users/operation-list-users.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-list-users.mdx")],
  "65d28c60": [() => import(/* webpackChunkName: "65d28c60" */ "@site/docs/api/drive/schemas/schema-search-body.mdx"), "@site/docs/api/drive/schemas/schema-search-body.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-search-body.mdx")],
  "65d9140b": [() => import(/* webpackChunkName: "65d9140b" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-cash-flow.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-cash-flow.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-cash-flow.mdx")],
  "6604f128": [() => import(/* webpackChunkName: "6604f128" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-custodian-account.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-custodian-account.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-custodian-account.mdx")],
  "66087a17": [() => import(/* webpackChunkName: "66087a17" */ "@site/docs/api/workflow/endpoints/tasks/operation-update-task.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-update-task.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-update-task.mdx")],
  "66144097": [() => import(/* webpackChunkName: "66144097" */ "@site/docs/api/lusid/endpoints/staged-modifications/operation-get-staged-modification.mdx"), "@site/docs/api/lusid/endpoints/staged-modifications/operation-get-staged-modification.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staged-modifications/operation-get-staged-modification.mdx")],
  "6617f2da": [() => import(/* webpackChunkName: "6617f2da" */ "@site/docs/api/notification/schemas/schema-notification.mdx"), "@site/docs/api/notification/schemas/schema-notification.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-notification.mdx")],
  "6662ea40": [() => import(/* webpackChunkName: "6662ea40" */ "@site/docs/api/lusid/schemas/schema-fund.mdx"), "@site/docs/api/lusid/schemas/schema-fund.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund.mdx")],
  "66745b29": [() => import(/* webpackChunkName: "66745b29" */ "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-delete-cut-label-definition.mdx"), "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-delete-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/cut-label-definitions/operation-delete-cut-label-definition.mdx")],
  "667f5578": [() => import(/* webpackChunkName: "667f5578" */ "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios.mdx")],
  "66a8ae91": [() => import(/* webpackChunkName: "66a8ae91" */ "@site/docs/api/lusid/endpoints/workspace/operation-create-personal-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-create-personal-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-create-personal-workspace.mdx")],
  "66c53d98": [() => import(/* webpackChunkName: "66c53d98" */ "@site/docs/api/insights/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/insights/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-lusid-validation-problem-details.mdx")],
  "66f6a810": [() => import(/* webpackChunkName: "66f6a810" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-property-interval.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-property-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-property-interval.mdx")],
  "66fe38bc": [() => import(/* webpackChunkName: "66fe38bc" */ "@site/docs/api/workflow/schemas/schema-create-worker-request.mdx"), "@site/docs/api/workflow/schemas/schema-create-worker-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-worker-request.mdx")],
  "67071674": [() => import(/* webpackChunkName: "67071674" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-allocation-detail.mdx")],
  "67090274": [() => import(/* webpackChunkName: "67090274" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-delete-task-definition.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-delete-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-delete-task-definition.mdx")],
  "672f21de": [() => import(/* webpackChunkName: "672f21de" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-execution-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-execution-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-execution-synopsis.mdx")],
  "673af95b": [() => import(/* webpackChunkName: "673af95b" */ "@site/docs/api/scheduler/schemas/schema-start-schedule-response.mdx"), "@site/docs/api/scheduler/schemas/schema-start-schedule-response.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-start-schedule-response.mdx")],
  "674ea11e": [() => import(/* webpackChunkName: "674ea11e" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-recipe-composers.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-recipe-composers.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-recipe-composers.mdx")],
  "6759dad2": [() => import(/* webpackChunkName: "6759dad2" */ "@site/docs/api/workflow/schemas/schema-scheduler-job.mdx"), "@site/docs/api/workflow/schemas/schema-scheduler-job.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-scheduler-job.mdx")],
  "6797c565": [() => import(/* webpackChunkName: "6797c565" */ "@site/docs/api/identity/schemas/schema-update-password-policy-request.mdx"), "@site/docs/api/identity/schemas/schema-update-password-policy-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-password-policy-request.mdx")],
  "680af4db": [() => import(/* webpackChunkName: "680af4db" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-excel.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-excel.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-get-by-query-excel.mdx")],
  "68284b41": [() => import(/* webpackChunkName: "68284b41" */ "@site/docs/api/horizon/endpoints/integrations/operation-get-integration-configuration.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-get-integration-configuration.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-get-integration-configuration.mdx")],
  "6834602e": [() => import(/* webpackChunkName: "6834602e" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-properties.mdx")],
  "685ab5d6": [() => import(/* webpackChunkName: "685ab5d6" */ "@site/docs/api/lusid/endpoints/placements/operation-upsert-placements.mdx"), "@site/docs/api/lusid/endpoints/placements/operation-upsert-placements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/placements/operation-upsert-placements.mdx")],
  "68695d89": [() => import(/* webpackChunkName: "68695d89" */ "@site/docs/api/lusid/schemas/schema-address-key-filter.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-filter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-filter.mdx")],
  "68b43da9": [() => import(/* webpackChunkName: "68b43da9" */ "@site/docs/api/notification/schemas/schema-email-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-email-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-email-notification-type.mdx")],
  "68cfcd29": [() => import(/* webpackChunkName: "68cfcd29" */ "@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type-response.mdx")],
  "68d400e7": [() => import(/* webpackChunkName: "68d400e7" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-allocation-detail.mdx")],
  "68e9cd27": [() => import(/* webpackChunkName: "68e9cd27" */ "@site/docs/api/lusid/schemas/schema-virtual-row.mdx"), "@site/docs/api/lusid/schemas/schema-virtual-row.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-virtual-row.mdx")],
  "68f2441c": [() => import(/* webpackChunkName: "68f2441c" */ "@site/docs/api/access/schemas/schema-as-at-relative.mdx"), "@site/docs/api/access/schemas/schema-as-at-relative.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-as-at-relative.mdx")],
  "68f83cc8": [() => import(/* webpackChunkName: "68f83cc8" */ "@site/docs/api/horizon/schemas/schema-optionality.mdx"), "@site/docs/api/horizon/schemas/schema-optionality.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-optionality.mdx")],
  "6911574b": [() => import(/* webpackChunkName: "6911574b" */ "@site/docs/api/lusid/endpoints/funds/operation-accept-estimate-valuation-point.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-accept-estimate-valuation-point.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-accept-estimate-valuation-point.mdx")],
  "6926110a": [() => import(/* webpackChunkName: "6926110a" */ "@site/docs/api/lusid/endpoints/staging-rule-set/operation-get-staging-rule-set.mdx"), "@site/docs/api/lusid/endpoints/staging-rule-set/operation-get-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staging-rule-set/operation-get-staging-rule-set.mdx")],
  "6934ddad": [() => import(/* webpackChunkName: "6934ddad" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-view-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-view-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-view-design.mdx")],
  "694944d4": [() => import(/* webpackChunkName: "694944d4" */ "@site/docs/api/lusid/schemas/schema-moved-order-to-different-block-response.mdx"), "@site/docs/api/lusid/schemas/schema-moved-order-to-different-block-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-moved-order-to-different-block-response.mdx")],
  "694bf5e2": [() => import(/* webpackChunkName: "694bf5e2" */ "@site/docs/api/drive/endpoints/files/operation-delete-file.mdx"), "@site/docs/api/drive/endpoints/files/operation-delete-file.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-delete-file.mdx")],
  "696e4768": [() => import(/* webpackChunkName: "696e4768" */ "@site/docs/api/lusid/schemas/schema-instrument-payment-diary-leg.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-payment-diary-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-payment-diary-leg.mdx")],
  "69cc9808": [() => import(/* webpackChunkName: "69cc9808" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-create-derived-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-create-derived-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-create-derived-property-definition.mdx")],
  "69f8889c": [() => import(/* webpackChunkName: "69f8889c" */ "@site/docs/api/lusid/schemas/schema-asset-leg.mdx"), "@site/docs/api/lusid/schemas/schema-asset-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-asset-leg.mdx")],
  "6a24299e": [() => import(/* webpackChunkName: "6a24299e" */ "@site/docs/api/lusid/endpoints/blocks/operation-delete-block.mdx"), "@site/docs/api/lusid/endpoints/blocks/operation-delete-block.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/blocks/operation-delete-block.mdx")],
  "6a40d250": [() => import(/* webpackChunkName: "6a40d250" */ "@site/docs/api/lusid/schemas/schema-staging-rule.mdx"), "@site/docs/api/lusid/schemas/schema-staging-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staging-rule.mdx")],
  "6a72e0bd": [() => import(/* webpackChunkName: "6a72e0bd" */ "@site/docs/api/lusid/schemas/schema-account-properties.mdx"), "@site/docs/api/lusid/schemas/schema-account-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-account-properties.mdx")],
  "6aadc472": [() => import(/* webpackChunkName: "6aadc472" */ "@site/docs/api/web-app/schemas/schema-contact-properties-request.mdx"), "@site/docs/api/web-app/schemas/schema-contact-properties-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-contact-properties-request.mdx")],
  "6adfce6b": [() => import(/* webpackChunkName: "6adfce6b" */ "@site/docs/api/lusid/endpoints/funds/operation-upsert-fee-properties.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-upsert-fee-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-upsert-fee-properties.mdx")],
  "6afa4179": [() => import(/* webpackChunkName: "6afa4179" */ "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-response.mdx")],
  "6b5e1a98": [() => import(/* webpackChunkName: "6b5e1a98" */ "@site/docs/api/lusid/schemas/schema-update-data-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-data-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-data-type-request.mdx")],
  "6b6f1b7c": [() => import(/* webpackChunkName: "6b6f1b7c" */ "@site/docs/api/drive/schemas/schema-link.mdx"), "@site/docs/api/drive/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-link.mdx")],
  "6b8602d9": [() => import(/* webpackChunkName: "6b8602d9" */ "@site/docs/api/workflow/endpoints/event-handlers/operation-list-event-handlers.mdx"), "@site/docs/api/workflow/endpoints/event-handlers/operation-list-event-handlers.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/event-handlers/operation-list-event-handlers.mdx")],
  "6b9478a9": [() => import(/* webpackChunkName: "6b9478a9" */ "@site/docs/api/identity/schemas/schema-set-password.mdx"), "@site/docs/api/identity/schemas/schema-set-password.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-set-password.mdx")],
  "6bbfcfce": [() => import(/* webpackChunkName: "6bbfcfce" */ "@site/docs/api/luminesce/endpoints/certificate-management/operation-download-certificate.mdx"), "@site/docs/api/luminesce/endpoints/certificate-management/operation-download-certificate.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/certificate-management/operation-download-certificate.mdx")],
  "6bcb3609": [() => import(/* webpackChunkName: "6bcb3609" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-rules.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-rules.mdx")],
  "6bf7e5bc": [() => import(/* webpackChunkName: "6bf7e5bc" */ "@site/docs/api/lusid/schemas/schema-cash-election.mdx"), "@site/docs/api/lusid/schemas/schema-cash-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-election.mdx")],
  "6c00a08d": [() => import(/* webpackChunkName: "6c00a08d" */ "@site/docs/api/identity/endpoints/users/operation-get-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-get-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-get-user.mdx")],
  "6c020cac": [() => import(/* webpackChunkName: "6c020cac" */ "@site/docs/api/horizon/schemas/schema-link.mdx"), "@site/docs/api/horizon/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-link.mdx")],
  "6c0cf48b": [() => import(/* webpackChunkName: "6c0cf48b" */ "@site/docs/api/access/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/access/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "6c1cc04b": [() => import(/* webpackChunkName: "6c1cc04b" */ "@site/docs/api/horizon/endpoints/integrations/operation-update-instance.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-update-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-update-instance.mdx")],
  "6c81ef99": [() => import(/* webpackChunkName: "6c81ef99" */ "@site/docs/api/workflow/schemas/schema-create-child-tasks-action.mdx"), "@site/docs/api/workflow/schemas/schema-create-child-tasks-action.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-child-tasks-action.mdx")],
  "6c9e74dc": [() => import(/* webpackChunkName: "6c9e74dc" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-custom-entity-response.mdx")],
  "6cc92d35": [() => import(/* webpackChunkName: "6cc92d35" */ "@site/docs/api/lusid/schemas/schema-credit-rating.mdx"), "@site/docs/api/lusid/schemas/schema-credit-rating.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-credit-rating.mdx")],
  "6cd52469": [() => import(/* webpackChunkName: "6cd52469" */ "@site/docs/api/identity/schemas/schema-password-policy-response-complexity.mdx"), "@site/docs/api/identity/schemas/schema-password-policy-response-complexity.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-password-policy-response-complexity.mdx")],
  "6ced889e": [() => import(/* webpackChunkName: "6ced889e" */ "@site/docs/api/lusid/schemas/schema-curve-options.mdx"), "@site/docs/api/lusid/schemas/schema-curve-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-curve-options.mdx")],
  "6d227687": [() => import(/* webpackChunkName: "6d227687" */ "@site/docs/api/lusid/endpoints/participations/operation-get-participation.mdx"), "@site/docs/api/lusid/endpoints/participations/operation-get-participation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/participations/operation-get-participation.mdx")],
  "6d2a0821": [() => import(/* webpackChunkName: "6d2a0821" */ "@site/docs/api/lusid/schemas/schema-aggregation-context.mdx"), "@site/docs/api/lusid/schemas/schema-aggregation-context.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregation-context.mdx")],
  "6d2f3cba": [() => import(/* webpackChunkName: "6d2f3cba" */ "@site/docs/api/lusid/schemas/schema-dialect-id.mdx"), "@site/docs/api/lusid/schemas/schema-dialect-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dialect-id.mdx")],
  "6d2fa8fd": [() => import(/* webpackChunkName: "6d2fa8fd" */ "@site/docs/api/lusid/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/lusid/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-access-controlled-resource.mdx")],
  "6d319c63": [() => import(/* webpackChunkName: "6d319c63" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-flows.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-flows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-flows.mdx")],
  "6d4c65be": [() => import(/* webpackChunkName: "6d4c65be" */ "@site/docs/api/lusid/schemas/schema-address-key-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-compliance-parameter.mdx")],
  "6d5f4368": [() => import(/* webpackChunkName: "6d5f4368" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document-rows.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document-rows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-get-virtual-document-rows.mdx")],
  "6d6dfc2a": [() => import(/* webpackChunkName: "6d6dfc2a" */ "@site/docs/api/scheduler/schemas/schema-link.mdx"), "@site/docs/api/scheduler/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-link.mdx")],
  "6d7fce4b": [() => import(/* webpackChunkName: "6d7fce4b" */ "@site/docs/api/lusid/schemas/schema-set-transaction-configuration-source-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-transaction-configuration-source-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-transaction-configuration-source-request.mdx")],
  "6d8771f7": [() => import(/* webpackChunkName: "6d8771f7" */ "@site/docs/api/lusid/schemas/schema-set-share-class-instruments-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-share-class-instruments-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-share-class-instruments-request.mdx")],
  "6dc879e0": [() => import(/* webpackChunkName: "6dc879e0" */ "@site/docs/api/lusid/schemas/schema-result-data-key-rule.mdx"), "@site/docs/api/lusid/schemas/schema-result-data-key-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-data-key-rule.mdx")],
  "6dd75c21": [() => import(/* webpackChunkName: "6dd75c21" */ "@site/docs/api/horizon/schemas/schema-process-summary.mdx"), "@site/docs/api/horizon/schemas/schema-process-summary.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-process-summary.mdx")],
  "6de64cd8": [() => import(/* webpackChunkName: "6de64cd8" */ "@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-rule.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-rule.mdx")],
  "6dfeef78": [() => import(/* webpackChunkName: "6dfeef78" */ "@site/docs/api/luminesce/schemas/schema-column.mdx"), "@site/docs/api/luminesce/schemas/schema-column.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-column.mdx")],
  "6e24a6a1": [() => import(/* webpackChunkName: "6e24a6a1" */ "@site/docs/api/identity/endpoints/roles/operation-update-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-update-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-update-role.mdx")],
  "6e3477f5": [() => import(/* webpackChunkName: "6e3477f5" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-list-dialect-ids.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-list-dialect-ids.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-list-dialect-ids.mdx")],
  "6e35adcb": [() => import(/* webpackChunkName: "6e35adcb" */ "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-log.mdx"), "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-log.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-log.mdx")],
  "6e37622d": [() => import(/* webpackChunkName: "6e37622d" */ "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-upsert-personalisations.mdx"), "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-upsert-personalisations.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-upsert-personalisations.mdx")],
  "6e625b7e": [() => import(/* webpackChunkName: "6e625b7e" */ "@site/docs/api/lusid/schemas/schema-cleardown-module-request.mdx"), "@site/docs/api/lusid/schemas/schema-cleardown-module-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cleardown-module-request.mdx")],
  "6e7eb864": [() => import(/* webpackChunkName: "6e7eb864" */ "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-json.mdx"), "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-json.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-fetch-history-result-json.mdx")],
  "6e8f0e79": [() => import(/* webpackChunkName: "6e8f0e79" */ "@site/docs/api/lusid/schemas/schema-list-aggregation-response.mdx"), "@site/docs/api/lusid/schemas/schema-list-aggregation-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-list-aggregation-response.mdx")],
  "6e92f049": [() => import(/* webpackChunkName: "6e92f049" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-configuration.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-configuration.mdx")],
  "6e983b4b": [() => import(/* webpackChunkName: "6e983b4b" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-dates.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-dates.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-dates.mdx")],
  "6eb060ec": [() => import(/* webpackChunkName: "6eb060ec" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-rule.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-compliance-rule.mdx")],
  "6edcbaf8": [() => import(/* webpackChunkName: "6edcbaf8" */ "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolio-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolio-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolio-properties.mdx")],
  "6efbe7d3": [() => import(/* webpackChunkName: "6efbe7d3" */ "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-progress-of-history.mdx"), "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-progress-of-history.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-progress-of-history.mdx")],
  "6effd7d1": [() => import(/* webpackChunkName: "6effd7d1" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-ruleset.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-ruleset.mdx")],
  "6f1d6c5e": [() => import(/* webpackChunkName: "6f1d6c5e" */ "@site/docs/api/lusid/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/lusid/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-access-controlled-action.mdx")],
  "6f1e002d": [() => import(/* webpackChunkName: "6f1e002d" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-json.mdx")],
  "6f1eec20": [() => import(/* webpackChunkName: "6f1eec20" */ "@site/docs/api/lusid/schemas/schema-virtual-document.mdx"), "@site/docs/api/lusid/schemas/schema-virtual-document.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-virtual-document.mdx")],
  "6f23f254": [() => import(/* webpackChunkName: "6f23f254" */ "@site/docs/api/access/schemas/schema-if-feature-chain-expression.mdx"), "@site/docs/api/access/schemas/schema-if-feature-chain-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-if-feature-chain-expression.mdx")],
  "6f3b57df": [() => import(/* webpackChunkName: "6f3b57df" */ "@site/docs/api/scheduler/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/scheduler/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "6f5c3cc1": [() => import(/* webpackChunkName: "6f5c3cc1" */ "@site/docs/api/access/schemas/schema-policy-id.mdx"), "@site/docs/api/access/schemas/schema-policy-id.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-id.mdx")],
  "6f9b87d7": [() => import(/* webpackChunkName: "6f9b87d7" */ "@site/docs/api/lusid/schemas/schema-person.mdx"), "@site/docs/api/lusid/schemas/schema-person.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-person.mdx")],
  "6faba82d": [() => import(/* webpackChunkName: "6faba82d" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cut-label-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cut-label-definition.mdx")],
  "6fb3a323": [() => import(/* webpackChunkName: "6fb3a323" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-transaction-type.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-get-transaction-type.mdx")],
  "6fba76be": [() => import(/* webpackChunkName: "6fba76be" */ "@site/docs/api/notification/intro.md"), "@site/docs/api/notification/intro.md", require.resolveWeak("@site/docs/api/notification/intro.md")],
  "6fc9c845": [() => import(/* webpackChunkName: "6fc9c845" */ "@site/docs/api/access/schemas/schema-if-expression.mdx"), "@site/docs/api/access/schemas/schema-if-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-if-expression.mdx")],
  "6fd819b0": [() => import(/* webpackChunkName: "6fd819b0" */ "@site/docs/api/lusid/schemas/schema-target-tax-lot-request.mdx"), "@site/docs/api/lusid/schemas/schema-target-tax-lot-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-target-tax-lot-request.mdx")],
  "6fea08b4": [() => import(/* webpackChunkName: "6fea08b4" */ "@site/docs/api/lusid/schemas/schema-participation-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-participation-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-participation-set-request.mdx")],
  "701898e5": [() => import(/* webpackChunkName: "701898e5" */ "@site/docs/api/lusid/schemas/schema-fund-valuation-point-data.mdx"), "@site/docs/api/lusid/schemas/schema-fund-valuation-point-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-valuation-point-data.mdx")],
  "7018f3bf": [() => import(/* webpackChunkName: "7018f3bf" */ "@site/docs/api/lusid/schemas/schema-informational-error-event.mdx"), "@site/docs/api/lusid/schemas/schema-informational-error-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-informational-error-event.mdx")],
  "701f4500": [() => import(/* webpackChunkName: "701f4500" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-multiple-holding-contributors.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-multiple-holding-contributors.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-multiple-holding-contributors.mdx")],
  "703e6bd6": [() => import(/* webpackChunkName: "703e6bd6" */ "@site/docs/api/lusid/schemas/schema-translation-context.mdx"), "@site/docs/api/lusid/schemas/schema-translation-context.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translation-context.mdx")],
  "70482fde": [() => import(/* webpackChunkName: "70482fde" */ "@site/docs/api/web-app/schemas/schema-fx-map.mdx"), "@site/docs/api/web-app/schemas/schema-fx-map.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-fx-map.mdx")],
  "70505db2": [() => import(/* webpackChunkName: "70505db2" */ "@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-type.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-type.mdx")],
  "706e08d6": [() => import(/* webpackChunkName: "706e08d6" */ "@site/docs/api/identity/endpoints/authentication/operation-invalidate-support-access.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-invalidate-support-access.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-invalidate-support-access.mdx")],
  "7073d4ec": [() => import(/* webpackChunkName: "7073d4ec" */ "@site/docs/api/lusid/schemas/schema-upsert-recipe-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-recipe-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-recipe-request.mdx")],
  "70786817": [() => import(/* webpackChunkName: "70786817" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-delete-transaction-configuration-source.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-delete-transaction-configuration-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-delete-transaction-configuration-source.mdx")],
  "709903a4": [() => import(/* webpackChunkName: "709903a4" */ "@site/docs/api/insights/schemas/schema-vendor-request.mdx"), "@site/docs/api/insights/schemas/schema-vendor-request.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-vendor-request.mdx")],
  "70ace6c6": [() => import(/* webpackChunkName: "70ace6c6" */ "@site/docs/api/identity/endpoints/authentication/operation-grant-support-access.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-grant-support-access.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-grant-support-access.mdx")],
  "70cfe0ea": [() => import(/* webpackChunkName: "70cfe0ea" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity.mdx")],
  "70e316c0": [() => import(/* webpackChunkName: "70e316c0" */ "@site/docs/api/lusid/schemas/schema-abor-properties.mdx"), "@site/docs/api/lusid/schemas/schema-abor-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor-properties.mdx")],
  "70f939cc": [() => import(/* webpackChunkName: "70f939cc" */ "@site/docs/api/lusid/schemas/schema-address-definition.mdx"), "@site/docs/api/lusid/schemas/schema-address-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-definition.mdx")],
  "70fd8587": [() => import(/* webpackChunkName: "70fd8587" */ "@site/docs/api/lusid/schemas/schema-date-attributes.mdx"), "@site/docs/api/lusid/schemas/schema-date-attributes.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-date-attributes.mdx")],
  "71251674": [() => import(/* webpackChunkName: "71251674" */ "@site/docs/api/lusid/schemas/schema-translate-entities-request.mdx"), "@site/docs/api/lusid/schemas/schema-translate-entities-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-entities-request.mdx")],
  "712ad5d1": [() => import(/* webpackChunkName: "712ad5d1" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-create-transaction-template.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-create-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-create-transaction-template.mdx")],
  "7136ec44": [() => import(/* webpackChunkName: "7136ec44" */ "@site/docs/api/identity/schemas/schema-update-role-request.mdx"), "@site/docs/api/identity/schemas/schema-update-role-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-role-request.mdx")],
  "713e2fd6": [() => import(/* webpackChunkName: "713e2fd6" */ "@site/docs/api/lusid/schemas/schema-fund-pnl-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-fund-pnl-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-pnl-breakdown.mdx")],
  "716e96d9": [() => import(/* webpackChunkName: "716e96d9" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-breakdown.mdx")],
  "7191a351": [() => import(/* webpackChunkName: "7191a351" */ "@site/docs/api/horizon/endpoints/process-history/operation-get-latest-runs.mdx"), "@site/docs/api/horizon/endpoints/process-history/operation-get-latest-runs.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/process-history/operation-get-latest-runs.mdx")],
  "71a23deb": [() => import(/* webpackChunkName: "71a23deb" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-definition.mdx")],
  "71ba26e9": [() => import(/* webpackChunkName: "71ba26e9" */ "@site/docs/api/identity/schemas/schema-password-policy-response-age.mdx"), "@site/docs/api/identity/schemas/schema-password-policy-response-age.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-password-policy-response-age.mdx")],
  "71c8aed2": [() => import(/* webpackChunkName: "71c8aed2" */ "@site/docs/api/notification/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/notification/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-lusid-validation-problem-details.mdx")],
  "71f27c7c": [() => import(/* webpackChunkName: "71f27c7c" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type-source.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type-source.mdx")],
  "7214f4fe": [() => import(/* webpackChunkName: "7214f4fe" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-comparison-ruleset.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-comparison-ruleset.mdx")],
  "7259ff91": [() => import(/* webpackChunkName: "7259ff91" */ "@site/docs/api/lusid/schemas/schema-cash-flow-event.mdx"), "@site/docs/api/lusid/schemas/schema-cash-flow-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-flow-event.mdx")],
  "7260d677": [() => import(/* webpackChunkName: "7260d677" */ "@site/docs/api/workflow/schemas/schema-run-worker-request.mdx"), "@site/docs/api/workflow/schemas/schema-run-worker-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-run-worker-request.mdx")],
  "727ddc89": [() => import(/* webpackChunkName: "727ddc89" */ "@site/docs/api/lusid/schemas/schema-update-instrument-identifier-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-instrument-identifier-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-instrument-identifier-request.mdx")],
  "72cc813e": [() => import(/* webpackChunkName: "72cc813e" */ "@site/docs/api/lusid/schemas/schema-upsert-person-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-person-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-person-request.mdx")],
  "72db1669": [() => import(/* webpackChunkName: "72db1669" */ "@site/docs/api/lusid/schemas/schema-dividend-reinvestment-event.mdx"), "@site/docs/api/lusid/schemas/schema-dividend-reinvestment-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dividend-reinvestment-event.mdx")],
  "72fb18d4": [() => import(/* webpackChunkName: "72fb18d4" */ "@site/docs/api/access/schemas/schema-resource-list-of-policy-response.mdx"), "@site/docs/api/access/schemas/schema-resource-list-of-policy-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-list-of-policy-response.mdx")],
  "72fd6865": [() => import(/* webpackChunkName: "72fd6865" */ "@site/docs/api/scheduler/endpoints/schedules/operation-list-schedules.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-list-schedules.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-list-schedules.mdx")],
  "72fdec17": [() => import(/* webpackChunkName: "72fdec17" */ "@site/docs/api/notification/endpoints/notifications/operation-list-notifications.mdx"), "@site/docs/api/notification/endpoints/notifications/operation-list-notifications.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/notifications/operation-list-notifications.mdx")],
  "73293919": [() => import(/* webpackChunkName: "73293919" */ "@site/docs/api/lusid/schemas/schema-create-portfolio-group-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-portfolio-group-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-portfolio-group-request.mdx")],
  "734aceee": [() => import(/* webpackChunkName: "734aceee" */ "@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-workspace.mdx")],
  "735c63d2": [() => import(/* webpackChunkName: "735c63d2" */ "@site/docs/api/identity/schemas/schema-create-api-key.mdx"), "@site/docs/api/identity/schemas/schema-create-api-key.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-create-api-key.mdx")],
  "735dea47": [() => import(/* webpackChunkName: "735dea47" */ "@site/docs/api/web-app/schemas/schema-target-weight-details.mdx"), "@site/docs/api/web-app/schemas/schema-target-weight-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-target-weight-details.mdx")],
  "736a2b9d": [() => import(/* webpackChunkName: "736a2b9d" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities-inlined.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities-inlined.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities-inlined.mdx")],
  "739a5395": [() => import(/* webpackChunkName: "739a5395" */ "@site/docs/api/lusid/schemas/schema-add-business-days-to-date-response.mdx"), "@site/docs/api/lusid/schemas/schema-add-business-days-to-date-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-add-business-days-to-date-response.mdx")],
  "73a03c76": [() => import(/* webpackChunkName: "73a03c76" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reference-list-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reference-list-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reference-list-response.mdx")],
  "73e3b52b": [() => import(/* webpackChunkName: "73e3b52b" */ "@site/docs/api/identity/endpoints/roles/operation-list-roles.mdx"), "@site/docs/api/identity/endpoints/roles/operation-list-roles.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-list-roles.mdx")],
  "73f8fbfd": [() => import(/* webpackChunkName: "73f8fbfd" */ "@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-amazon-sqs-principal-auth-notification-type.mdx")],
  "7431067a": [() => import(/* webpackChunkName: "7431067a" */ "@site/docs/api/identity/schemas/schema-update-password-policy-request-conditions.mdx"), "@site/docs/api/identity/schemas/schema-update-password-policy-request-conditions.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-password-policy-request-conditions.mdx")],
  "7458dc0e": [() => import(/* webpackChunkName: "7458dc0e" */ "@site/docs/api/lusid/endpoints/conventions/operation-get-index-convention.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-get-index-convention.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-get-index-convention.mdx")],
  "7465eca2": [() => import(/* webpackChunkName: "7465eca2" */ "@site/docs/api/lusid/schemas/schema-schema.mdx"), "@site/docs/api/lusid/schemas/schema-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-schema.mdx")],
  "74ba4f9b": [() => import(/* webpackChunkName: "74ba4f9b" */ "@site/docs/api/lusid/schemas/schema-add-business-days-to-date-request.mdx"), "@site/docs/api/lusid/schemas/schema-add-business-days-to-date-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-add-business-days-to-date-request.mdx")],
  "74d3ac38": [() => import(/* webpackChunkName: "74d3ac38" */ "@site/docs/api/identity/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/identity/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "74d9019b": [() => import(/* webpackChunkName: "74d9019b" */ "@site/docs/api/notification/schemas/schema-manual-event-header.mdx"), "@site/docs/api/notification/schemas/schema-manual-event-header.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-manual-event-header.mdx")],
  "74e70f69": [() => import(/* webpackChunkName: "74e70f69" */ "@site/docs/api/lusid/schemas/schema-fx-vol-surface-data.mdx"), "@site/docs/api/lusid/schemas/schema-fx-vol-surface-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-vol-surface-data.mdx")],
  "751c28b7": [() => import(/* webpackChunkName: "751c28b7" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-definition.mdx")],
  "75252047": [() => import(/* webpackChunkName: "75252047" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-order-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-order-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-order-detail.mdx")],
  "755223ab": [() => import(/* webpackChunkName: "755223ab" */ "@site/docs/api/access/schemas/schema-evaluation-request.mdx"), "@site/docs/api/access/schemas/schema-evaluation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-evaluation-request.mdx")],
  "756aba6c": [() => import(/* webpackChunkName: "756aba6c" */ "@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-delete-personal-item.mdx")],
  "757a3905": [() => import(/* webpackChunkName: "757a3905" */ "@site/docs/api/lusid/schemas/schema-compliance-run-info-v-2.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-run-info-v-2.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-run-info-v-2.mdx")],
  "75d00178": [() => import(/* webpackChunkName: "75d00178" */ "@site/docs/api/workflow/schemas/schema-luminesce-view.mdx"), "@site/docs/api/workflow/schemas/schema-luminesce-view.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-luminesce-view.mdx")],
  "75efa269": [() => import(/* webpackChunkName: "75efa269" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-properties.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-properties.mdx")],
  "75f9bdf2": [() => import(/* webpackChunkName: "75f9bdf2" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee-type.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee-type.mdx")],
  "760cc13a": [() => import(/* webpackChunkName: "760cc13a" */ "@site/docs/api/configuration/intro.md"), "@site/docs/api/configuration/intro.md", require.resolveWeak("@site/docs/api/configuration/intro.md")],
  "76108243": [() => import(/* webpackChunkName: "76108243" */ "@site/docs/api/lusid/schemas/schema-complex-bond.mdx"), "@site/docs/api/lusid/schemas/schema-complex-bond.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complex-bond.mdx")],
  "76239e75": [() => import(/* webpackChunkName: "76239e75" */ "@site/docs/api/lusid/schemas/schema-composite-breakdown-response.mdx"), "@site/docs/api/lusid/schemas/schema-composite-breakdown-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-composite-breakdown-response.mdx")],
  "76319904": [() => import(/* webpackChunkName: "76319904" */ "@site/docs/api/access/schemas/schema-user-role-response.mdx"), "@site/docs/api/access/schemas/schema-user-role-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-user-role-response.mdx")],
  "7633c8de": [() => import(/* webpackChunkName: "7633c8de" */ "@site/docs/api/workflow/schemas/schema-version-info.mdx"), "@site/docs/api/workflow/schemas/schema-version-info.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-version-info.mdx")],
  "7643740c": [() => import(/* webpackChunkName: "7643740c" */ "@site/docs/api/workflow/schemas/schema-action-details-response.mdx"), "@site/docs/api/workflow/schemas/schema-action-details-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-action-details-response.mdx")],
  "765ac167": [() => import(/* webpackChunkName: "765ac167" */ "@site/docs/api/lusid/schemas/schema-weekend-mask.mdx"), "@site/docs/api/lusid/schemas/schema-weekend-mask.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-weekend-mask.mdx")],
  "768c107a": [() => import(/* webpackChunkName: "768c107a" */ "@site/docs/api/luminesce/schemas/schema-background-query-response.mdx"), "@site/docs/api/luminesce/schemas/schema-background-query-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-query-response.mdx")],
  "7699f1c6": [() => import(/* webpackChunkName: "7699f1c6" */ "@site/docs/excel/functions/Blocks.mdx"), "@site/docs/excel/functions/Blocks.mdx", require.resolveWeak("@site/docs/excel/functions/Blocks.mdx")],
  "76d00112": [() => import(/* webpackChunkName: "76d00112" */ "@site/docs/api/notification/schemas/schema-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-notification-type.mdx")],
  "7702c8b9": [() => import(/* webpackChunkName: "7702c8b9" */ "@site/docs/api/horizon/schemas/schema-file-details.mdx"), "@site/docs/api/horizon/schemas/schema-file-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-file-details.mdx")],
  "771a2c1f": [() => import(/* webpackChunkName: "771a2c1f" */ "@site/docs/api/identity/schemas/schema-user-summary.mdx"), "@site/docs/api/identity/schemas/schema-user-summary.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-user-summary.mdx")],
  "77235295": [() => import(/* webpackChunkName: "77235295" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-delete-transaction-template.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-delete-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-delete-transaction-template.mdx")],
  "77798e0d": [() => import(/* webpackChunkName: "77798e0d" */ "@site/docs/api/lusid/schemas/schema-upsert-quote-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-quote-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-quote-request.mdx")],
  "777e6e61": [() => import(/* webpackChunkName: "777e6e61" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-response.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-response.mdx")],
  "77914263": [() => import(/* webpackChunkName: "77914263" */ "@site/docs/api/lusid/schemas/schema-sides-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-sides-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-sides-definition-request.mdx")],
  "7791fed3": [() => import(/* webpackChunkName: "7791fed3" */ "@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type-response.mdx")],
  "77a0dc57": [() => import(/* webpackChunkName: "77a0dc57" */ "@site/docs/api/scheduler/schemas/schema-scan-report.mdx"), "@site/docs/api/scheduler/schemas/schema-scan-report.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-scan-report.mdx")],
  "77cc8dec": [() => import(/* webpackChunkName: "77cc8dec" */ "@site/docs/api/lusid/schemas/schema-package-request.mdx"), "@site/docs/api/lusid/schemas/schema-package-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-package-request.mdx")],
  "77e9eb86": [() => import(/* webpackChunkName: "77e9eb86" */ "@site/docs/api/lusid/schemas/schema-portfolio-without-href.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-without-href.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-without-href.mdx")],
  "77eff7ae": [() => import(/* webpackChunkName: "77eff7ae" */ "@site/docs/api/lusid/schemas/schema-result-value-dictionary.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-dictionary.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-dictionary.mdx")],
  "77f089b1": [() => import(/* webpackChunkName: "77f089b1" */ "@site/docs/api/horizon/schemas/schema-integration-run-log-link.mdx"), "@site/docs/api/horizon/schemas/schema-integration-run-log-link.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-run-log-link.mdx")],
  "77f6d4c6": [() => import(/* webpackChunkName: "77f6d4c6" */ "@site/docs/api/lusid/schemas/schema-simple-rounding-convention.mdx"), "@site/docs/api/lusid/schemas/schema-simple-rounding-convention.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-simple-rounding-convention.mdx")],
  "77fb71cc": [() => import(/* webpackChunkName: "77fb71cc" */ "@site/docs/api/lusid/schemas/schema-delete-instrument-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-delete-instrument-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-instrument-properties-response.mdx")],
  "782cc8c6": [() => import(/* webpackChunkName: "782cc8c6" */ "@site/docs/api/lusid/schemas/schema-reference-data.mdx"), "@site/docs/api/lusid/schemas/schema-reference-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-data.mdx")],
  "782d3eeb": [() => import(/* webpackChunkName: "782d3eeb" */ "@site/docs/api/identity/endpoints/applications/operation-rotate-application-secrets.mdx"), "@site/docs/api/identity/endpoints/applications/operation-rotate-application-secrets.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/applications/operation-rotate-application-secrets.mdx")],
  "783e2b0c": [() => import(/* webpackChunkName: "783e2b0c" */ "@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping-details.mdx"), "@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping-details.mdx")],
  "784330a4": [() => import(/* webpackChunkName: "784330a4" */ "@site/docs/api/insights/endpoints/access-evaluations/operation-get-access-evaluation-log.mdx"), "@site/docs/api/insights/endpoints/access-evaluations/operation-get-access-evaluation-log.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/access-evaluations/operation-get-access-evaluation-log.mdx")],
  "7857e781": [() => import(/* webpackChunkName: "7857e781" */ "@site/docs/api/lusid/schemas/schema-share-class-data.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-data.mdx")],
  "78590f43": [() => import(/* webpackChunkName: "78590f43" */ "@site/docs/api/lusid/schemas/schema-capital-distribution-event.mdx"), "@site/docs/api/lusid/schemas/schema-capital-distribution-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-capital-distribution-event.mdx")],
  "787ab62a": [() => import(/* webpackChunkName: "787ab62a" */ "@site/docs/api/scheduler/endpoints/images/operation-upload-image.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-upload-image.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-upload-image.mdx")],
  "78ba6beb": [() => import(/* webpackChunkName: "78ba6beb" */ "@site/docs/api/access/schemas/schema-effective-date-has-quality.mdx"), "@site/docs/api/access/schemas/schema-effective-date-has-quality.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-effective-date-has-quality.mdx")],
  "78c3675d": [() => import(/* webpackChunkName: "78c3675d" */ "@site/docs/api/luminesce/schemas/schema-error-highlight-item.mdx"), "@site/docs/api/luminesce/schemas/schema-error-highlight-item.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-error-highlight-item.mdx")],
  "78c62375": [() => import(/* webpackChunkName: "78c62375" */ "@site/docs/api/lusid/schemas/schema-transaction-price.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-price.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-price.mdx")],
  "78f240dd": [() => import(/* webpackChunkName: "78f240dd" */ "@site/docs/api/lusid/schemas/schema-aggregated-returns-response.mdx"), "@site/docs/api/lusid/schemas/schema-aggregated-returns-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregated-returns-response.mdx")],
  "793ed57e": [() => import(/* webpackChunkName: "793ed57e" */ "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-list-quotes.mdx")],
  "796a5a14": [() => import(/* webpackChunkName: "796a5a14" */ "@site/docs/api/lusid/schemas/schema-float-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-float-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-float-schedule.mdx")],
  "796ad248": [() => import(/* webpackChunkName: "796ad248" */ "@site/docs/api/lusid/schemas/schema-date-time-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-date-time-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-date-time-compliance-parameter.mdx")],
  "7974e758": [() => import(/* webpackChunkName: "7974e758" */ "@site/docs/api/web-app/schemas/schema-fx-map-details.mdx"), "@site/docs/api/web-app/schemas/schema-fx-map-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-fx-map-details.mdx")],
  "798b08a5": [() => import(/* webpackChunkName: "798b08a5" */ "@site/docs/api/scheduler/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/scheduler/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-lusid-problem-details.mdx")],
  "79941300": [() => import(/* webpackChunkName: "79941300" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-flow-conventions-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-flow-conventions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-flow-conventions-response.mdx")],
  "79ada172": [() => import(/* webpackChunkName: "79ada172" */ "@site/docs/api/identity/endpoints/users/operation-create-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-create-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-create-user.mdx")],
  "79baf49a": [() => import(/* webpackChunkName: "79baf49a" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-transaction-type.mdx")],
  "79f5686a": [() => import(/* webpackChunkName: "79f5686a" */ "@site/docs/api/access/endpoints/user-roles/operation-get-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-get-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-get-user-role.mdx")],
  "7a25e9ad": [() => import(/* webpackChunkName: "7a25e9ad" */ "@site/docs/api/access/schemas/schema-policy-creation-request.mdx"), "@site/docs/api/access/schemas/schema-policy-creation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-creation-request.mdx")],
  "7a57ae38": [() => import(/* webpackChunkName: "7a57ae38" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-processed-command.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-processed-command.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-processed-command.mdx")],
  "7aefd77b": [() => import(/* webpackChunkName: "7aefd77b" */ "@site/docs/api/lusid/schemas/schema-lusid-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-lusid-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lusid-instrument.mdx")],
  "7afdca4d": [() => import(/* webpackChunkName: "7afdca4d" */ "@site/docs/api/lusid/schemas/schema-adjust-holding-for-date-request.mdx"), "@site/docs/api/lusid/schemas/schema-adjust-holding-for-date-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-adjust-holding-for-date-request.mdx")],
  "7aff259a": [() => import(/* webpackChunkName: "7aff259a" */ "@site/docs/api/identity/endpoints/users/operation-list-runnable-users.mdx"), "@site/docs/api/identity/endpoints/users/operation-list-runnable-users.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-list-runnable-users.mdx")],
  "7b3194b0": [() => import(/* webpackChunkName: "7b3194b0" */ "@site/docs/api/lusid/schemas/schema-result-value-string.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-string.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-string.mdx")],
  "7b750d92": [() => import(/* webpackChunkName: "7b750d92" */ "@site/docs/api/lusid/endpoints/funds/operation-set-share-class-instruments.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-set-share-class-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-set-share-class-instruments.mdx")],
  "7b80eb68": [() => import(/* webpackChunkName: "7b80eb68" */ "@site/docs/api/identity/schemas/schema-update-password-policy-request-complexity.mdx"), "@site/docs/api/identity/schemas/schema-update-password-policy-request-complexity.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-password-policy-request-complexity.mdx")],
  "7b887a0a": [() => import(/* webpackChunkName: "7b887a0a" */ "@site/docs/api/lusid/schemas/schema-recipe-block.mdx"), "@site/docs/api/lusid/schemas/schema-recipe-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-recipe-block.mdx")],
  "7bb0d410": [() => import(/* webpackChunkName: "7bb0d410" */ "@site/docs/api/lusid/schemas/schema-industry-classifier.mdx"), "@site/docs/api/lusid/schemas/schema-industry-classifier.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-industry-classifier.mdx")],
  "7be848f7": [() => import(/* webpackChunkName: "7be848f7" */ "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-event-handler.mdx"), "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-paged-resource-list-of-event-handler.mdx")],
  "7bea7d1c": [() => import(/* webpackChunkName: "7bea7d1c" */ "@site/docs/api/lusid/endpoints/transaction-fees/operation-get-applicable-transaction-fees.mdx"), "@site/docs/api/lusid/endpoints/transaction-fees/operation-get-applicable-transaction-fees.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-fees/operation-get-applicable-transaction-fees.mdx")],
  "7c43b1ab": [() => import(/* webpackChunkName: "7c43b1ab" */ "@site/docs/api/scheduler/schemas/schema-job-run-result.mdx"), "@site/docs/api/scheduler/schemas/schema-job-run-result.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-job-run-result.mdx")],
  "7c65013d": [() => import(/* webpackChunkName: "7c65013d" */ "@site/docs/api/lusid/schemas/schema-recombine-step.mdx"), "@site/docs/api/lusid/schemas/schema-recombine-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-recombine-step.mdx")],
  "7c67421d": [() => import(/* webpackChunkName: "7c67421d" */ "@site/docs/api/lusid/endpoints/funds/operation-upsert-fund-properties.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-upsert-fund-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-upsert-fund-properties.mdx")],
  "7c6fadb5": [() => import(/* webpackChunkName: "7c6fadb5" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-derived-recipes.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-derived-recipes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-list-derived-recipes.mdx")],
  "7c75a184": [() => import(/* webpackChunkName: "7c75a184" */ "@site/docs/api/lusid/endpoints/reference-lists/operation-get-reference-list.mdx"), "@site/docs/api/lusid/endpoints/reference-lists/operation-get-reference-list.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-lists/operation-get-reference-list.mdx")],
  "7c7c941a": [() => import(/* webpackChunkName: "7c7c941a" */ "@site/docs/api/lusid/schemas/schema-fixed-leg.mdx"), "@site/docs/api/lusid/schemas/schema-fixed-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fixed-leg.mdx")],
  "7cd2674f": [() => import(/* webpackChunkName: "7cd2674f" */ "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituents-request.mdx")],
  "7cd988f1": [() => import(/* webpackChunkName: "7cd988f1" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-parquet.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-parquet.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-parquet.mdx")],
  "7ce7eb29": [() => import(/* webpackChunkName: "7ce7eb29" */ "@site/docs/api/lusid/endpoints/order-management/operation-move-orders.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-move-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-move-orders.mdx")],
  "7cff97de": [() => import(/* webpackChunkName: "7cff97de" */ "@site/docs/api/workflow/schemas/schema-fail-response.mdx"), "@site/docs/api/workflow/schemas/schema-fail-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-fail-response.mdx")],
  "7d35b411": [() => import(/* webpackChunkName: "7d35b411" */ "@site/docs/api/lusid/endpoints/workspace/operation-create-personal-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-create-personal-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-create-personal-item.mdx")],
  "7d526363": [() => import(/* webpackChunkName: "7d526363" */ "@site/docs/api/horizon/schemas/schema-lusid-property-definition.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-property-definition.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-property-definition.mdx")],
  "7d52967c": [() => import(/* webpackChunkName: "7d52967c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-response.mdx")],
  "7d70b7c8": [() => import(/* webpackChunkName: "7d70b7c8" */ "@site/docs/api/lusid/schemas/schema-valuation-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-schedule.mdx")],
  "7d9a042d": [() => import(/* webpackChunkName: "7d9a042d" */ "@site/docs/api/configuration/schemas/schema-configuration-item.mdx"), "@site/docs/api/configuration/schemas/schema-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-configuration-item.mdx")],
  "7daa950d": [() => import(/* webpackChunkName: "7daa950d" */ "@site/docs/api/lusid/schemas/schema-create-reference-portfolio-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-reference-portfolio-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-reference-portfolio-request.mdx")],
  "7db22d9a": [() => import(/* webpackChunkName: "7db22d9a" */ "@site/docs/api/lusid/schemas/schema-property-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-property-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-list-compliance-parameter.mdx")],
  "7dcbe495": [() => import(/* webpackChunkName: "7dcbe495" */ "@site/docs/api/workflow/schemas/schema-fail.mdx"), "@site/docs/api/workflow/schemas/schema-fail.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-fail.mdx")],
  "7de233d8": [() => import(/* webpackChunkName: "7de233d8" */ "@site/docs/api/lusid/schemas/schema-portfolio-group.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group.mdx")],
  "7de65723": [() => import(/* webpackChunkName: "7de65723" */ "@site/docs/api/drive/endpoints/folders/operation-update-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-update-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-update-folder.mdx")],
  "7e0bbed2": [() => import(/* webpackChunkName: "7e0bbed2" */ "@site/docs/api/lusid/endpoints/application-metadata/operation-get-lusid-versions.mdx"), "@site/docs/api/lusid/endpoints/application-metadata/operation-get-lusid-versions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/application-metadata/operation-get-lusid-versions.mdx")],
  "7e0cad54": [() => import(/* webpackChunkName: "7e0cad54" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-transactions-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-transactions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-transactions-response.mdx")],
  "7e0ce35c": [() => import(/* webpackChunkName: "7e0ce35c" */ "@site/docs/api/lusid/endpoints/abor-configuration/operation-list-abor-configurations.mdx"), "@site/docs/api/lusid/endpoints/abor-configuration/operation-list-abor-configurations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor-configuration/operation-list-abor-configurations.mdx")],
  "7e55093a": [() => import(/* webpackChunkName: "7e55093a" */ "@site/docs/api/access/endpoints/policies/operation-delete-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-delete-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-delete-policy-collection.mdx")],
  "7ea61777": [() => import(/* webpackChunkName: "7ea61777" */ "@site/docs/api/luminesce/schemas/schema-intellisense-response.mdx"), "@site/docs/api/luminesce/schemas/schema-intellisense-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-intellisense-response.mdx")],
  "7eac9be6": [() => import(/* webpackChunkName: "7eac9be6" */ "@site/docs/api/access/endpoints/policies/operation-get-own-policies.mdx"), "@site/docs/api/access/endpoints/policies/operation-get-own-policies.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-get-own-policies.mdx")],
  "7eb2fce0": [() => import(/* webpackChunkName: "7eb2fce0" */ "@site/docs/api/lusid/schemas/schema-warning.mdx"), "@site/docs/api/lusid/schemas/schema-warning.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-warning.mdx")],
  "7eb729ef": [() => import(/* webpackChunkName: "7eb729ef" */ "@site/docs/api/lusid/schemas/schema-update-group-reconciliation-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-group-reconciliation-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-group-reconciliation-definition-request.mdx")],
  "7ee6977a": [() => import(/* webpackChunkName: "7ee6977a" */ "@site/docs/api/insights/schemas/schema-request.mdx"), "@site/docs/api/insights/schemas/schema-request.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-request.mdx")],
  "7ef81780": [() => import(/* webpackChunkName: "7ef81780" */ "@site/docs/api/lusid/schemas/schema-update-custom-entity-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-custom-entity-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-custom-entity-definition-request.mdx")],
  "7f007c71": [() => import(/* webpackChunkName: "7f007c71" */ "@site/docs/api/lusid/endpoints/calendars/operation-delete-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-delete-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-delete-calendar.mdx")],
  "7f30fe97": [() => import(/* webpackChunkName: "7f30fe97" */ "@site/docs/api/luminesce/schemas/schema-background-multi-query-progress-response.mdx"), "@site/docs/api/luminesce/schemas/schema-background-multi-query-progress-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-multi-query-progress-response.mdx")],
  "7f316bd8": [() => import(/* webpackChunkName: "7f316bd8" */ "@site/docs/api/lusid/endpoints/calendars/operation-update-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-update-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-update-calendar.mdx")],
  "7f366aaf": [() => import(/* webpackChunkName: "7f366aaf" */ "@site/docs/api/lusid/endpoints/conventions/operation-upsert-cds-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-upsert-cds-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-upsert-cds-flow-conventions.mdx")],
  "7f388456": [() => import(/* webpackChunkName: "7f388456" */ "@site/docs/api/access/schemas/schema-if-identity-scope-expression.mdx"), "@site/docs/api/access/schemas/schema-if-identity-scope-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-if-identity-scope-expression.mdx")],
  "7f48782e": [() => import(/* webpackChunkName: "7f48782e" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-cleardown-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-cleardown-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-cleardown-module.mdx")],
  "7f528df9": [() => import(/* webpackChunkName: "7f528df9" */ "@site/docs/api/identity/schemas/schema-update-user-request.mdx"), "@site/docs/api/identity/schemas/schema-update-user-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-user-request.mdx")],
  "7fa0adf5": [() => import(/* webpackChunkName: "7fa0adf5" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-sets.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-sets.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-sets.mdx")],
  "7fd25dc8": [() => import(/* webpackChunkName: "7fd25dc8" */ "@site/docs/api/lusid/schemas/schema-cancelled-placement-result.mdx"), "@site/docs/api/lusid/schemas/schema-cancelled-placement-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancelled-placement-result.mdx")],
  "7fdeb206": [() => import(/* webpackChunkName: "7fdeb206" */ "@site/docs/api/lusid/schemas/schema-annul-quotes-response.mdx"), "@site/docs/api/lusid/schemas/schema-annul-quotes-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-annul-quotes-response.mdx")],
  "7ffc238a": [() => import(/* webpackChunkName: "7ffc238a" */ "@site/docs/api/lusid/endpoints/abor-configuration/operation-get-abor-configuration.mdx"), "@site/docs/api/lusid/endpoints/abor-configuration/operation-get-abor-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor-configuration/operation-get-abor-configuration.mdx")],
  "8009b42b": [() => import(/* webpackChunkName: "8009b42b" */ "@site/docs/api/lusid/schemas/schema-data-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-data-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-mapping.mdx")],
  "804156fc": [() => import(/* webpackChunkName: "804156fc" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-dialect-id.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-dialect-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-dialect-id.mdx")],
  "80593bdd": [() => import(/* webpackChunkName: "80593bdd" */ "@site/docs/api/notification/endpoints/notifications/operation-update-notification.mdx"), "@site/docs/api/notification/endpoints/notifications/operation-update-notification.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/notifications/operation-update-notification.mdx")],
  "805995e3": [() => import(/* webpackChunkName: "805995e3" */ "@site/docs/api/lusid/schemas/schema-fx-swap.mdx"), "@site/docs/api/lusid/schemas/schema-fx-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-swap.mdx")],
  "807caa11": [() => import(/* webpackChunkName: "807caa11" */ "@site/docs/api/web-app/schemas/schema-order-type.mdx"), "@site/docs/api/web-app/schemas/schema-order-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-order-type.mdx")],
  "808d1870": [() => import(/* webpackChunkName: "808d1870" */ "@site/docs/api/lusid/schemas/schema-portfolio-entity-id.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-entity-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-entity-id.mdx")],
  "80915520": [() => import(/* webpackChunkName: "80915520" */ "@site/docs/api/identity/schemas/schema-support-access-request.mdx"), "@site/docs/api/identity/schemas/schema-support-access-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-access-request.mdx")],
  "80c5c2f9": [() => import(/* webpackChunkName: "80c5c2f9" */ "@site/docs/api/lusid/endpoints/participations/operation-upsert-participations.mdx"), "@site/docs/api/lusid/endpoints/participations/operation-upsert-participations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/participations/operation-upsert-participations.mdx")],
  "80dc4669": [() => import(/* webpackChunkName: "80dc4669" */ "@site/docs/api/lusid/schemas/schema-rounding-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-rounding-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-rounding-configuration.mdx")],
  "81125288": [() => import(/* webpackChunkName: "81125288" */ "@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments.mdx")],
  "81288d4d": [() => import(/* webpackChunkName: "81288d4d" */ "@site/docs/api/lusid/schemas/schema-get-instruments-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-instruments-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-instruments-response.mdx")],
  "815225f6": [() => import(/* webpackChunkName: "815225f6" */ "@site/docs/api/workflow/schemas/schema-initial-state.mdx"), "@site/docs/api/workflow/schemas/schema-initial-state.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-initial-state.mdx")],
  "8152465b": [() => import(/* webpackChunkName: "8152465b" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-side-definition.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-delete-side-definition.mdx")],
  "818c544b": [() => import(/* webpackChunkName: "818c544b" */ "@site/docs/api/horizon/schemas/schema-property-mapping.mdx"), "@site/docs/api/horizon/schemas/schema-property-mapping.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-property-mapping.mdx")],
  "81c1dd16": [() => import(/* webpackChunkName: "81c1dd16" */ "@site/docs/api/lusid/schemas/schema-accumulation-event.mdx"), "@site/docs/api/lusid/schemas/schema-accumulation-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-accumulation-event.mdx")],
  "81c599b9": [() => import(/* webpackChunkName: "81c599b9" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-get-amortisation-rule-set.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-get-amortisation-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-get-amortisation-rule-set.mdx")],
  "81ca015c": [() => import(/* webpackChunkName: "81ca015c" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-configuration-recipe.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-configuration-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-configuration-recipe.mdx")],
  "81e25dd8": [() => import(/* webpackChunkName: "81e25dd8" */ "@site/docs/api/identity/endpoints/authentication/operation-get-support-access-history.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-get-support-access-history.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-get-support-access-history.mdx")],
  "81e6d08a": [() => import(/* webpackChunkName: "81e6d08a" */ "@site/docs/api/lusid/schemas/schema-portfolio-return-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-return-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-return-breakdown.mdx")],
  "82301782": [() => import(/* webpackChunkName: "82301782" */ "@site/docs/api/drive/endpoints/folders/operation-get-folder-contents.mdx"), "@site/docs/api/drive/endpoints/folders/operation-get-folder-contents.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-get-folder-contents.mdx")],
  "823c9612": [() => import(/* webpackChunkName: "823c9612" */ "@site/docs/api/lusid/schemas/schema-address-key-list.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-list.mdx")],
  "8268be85": [() => import(/* webpackChunkName: "8268be85" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-holdings-adjustment-header.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-holdings-adjustment-header.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-holdings-adjustment-header.mdx")],
  "828dbeb2": [() => import(/* webpackChunkName: "828dbeb2" */ "@site/docs/api/identity/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/identity/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "828df27a": [() => import(/* webpackChunkName: "828df27a" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-upsert-instrument-events.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-upsert-instrument-events.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-upsert-instrument-events.mdx")],
  "829ca564": [() => import(/* webpackChunkName: "829ca564" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-chart-of-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-chart-of-accounts.mdx")],
  "82a9b145": [() => import(/* webpackChunkName: "82a9b145" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-account-properties.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-account-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-account-properties.mdx")],
  "82ee9708": [() => import(/* webpackChunkName: "82ee9708" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-event-holder.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-event-holder.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-instrument-event-holder.mdx")],
  "830b8058": [() => import(/* webpackChunkName: "830b8058" */ "@site/docs/api/lusid/schemas/schema-instrument-search-property.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-search-property.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-search-property.mdx")],
  "831937bf": [() => import(/* webpackChunkName: "831937bf" */ "@site/docs/api/lusid/schemas/schema-posting-module-request.mdx"), "@site/docs/api/lusid/schemas/schema-posting-module-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-posting-module-request.mdx")],
  "83200540": [() => import(/* webpackChunkName: "83200540" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-detail.mdx")],
  "8339b8e6": [() => import(/* webpackChunkName: "8339b8e6" */ "@site/docs/api/horizon/endpoints/process-history/operation-process-entry-updates.mdx"), "@site/docs/api/horizon/endpoints/process-history/operation-process-entry-updates.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/process-history/operation-process-entry-updates.mdx")],
  "83410d0c": [() => import(/* webpackChunkName: "83410d0c" */ "@site/docs/api/workflow/schemas/schema-run-worker-response.mdx"), "@site/docs/api/workflow/schemas/schema-run-worker-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-run-worker-response.mdx")],
  "837fb6f4": [() => import(/* webpackChunkName: "837fb6f4" */ "@site/docs/api/luminesce/schemas/schema-convert-to-view-data.mdx"), "@site/docs/api/luminesce/schemas/schema-convert-to-view-data.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-convert-to-view-data.mdx")],
  "839aac6c": [() => import(/* webpackChunkName: "839aac6c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund-configuration.mdx")],
  "83b1be33": [() => import(/* webpackChunkName: "83b1be33" */ "@site/docs/api/luminesce/schemas/schema-certificate-file-type.mdx"), "@site/docs/api/luminesce/schemas/schema-certificate-file-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-certificate-file-type.mdx")],
  "83b75796": [() => import(/* webpackChunkName: "83b75796" */ "@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders.mdx")],
  "83e1c77f": [() => import(/* webpackChunkName: "83e1c77f" */ "@site/docs/api/identity/schemas/schema-authentication-information.mdx"), "@site/docs/api/identity/schemas/schema-authentication-information.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-authentication-information.mdx")],
  "83e8e878": [() => import(/* webpackChunkName: "83e8e878" */ "@site/docs/api/horizon/schemas/schema-identifiers.mdx"), "@site/docs/api/horizon/schemas/schema-identifiers.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-identifiers.mdx")],
  "83fc8315": [() => import(/* webpackChunkName: "83fc8315" */ "@site/docs/api/lusid/endpoints/workspace/operation-update-personal-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-update-personal-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-update-personal-item.mdx")],
  "84141438": [() => import(/* webpackChunkName: "84141438" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-list-translation-script-ids.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-list-translation-script-ids.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-list-translation-script-ids.mdx")],
  "841f12f3": [() => import(/* webpackChunkName: "841f12f3" */ "@site/docs/api/lusid/schemas/schema-bond-principal-event.mdx"), "@site/docs/api/lusid/schemas/schema-bond-principal-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond-principal-event.mdx")],
  "84489ef4": [() => import(/* webpackChunkName: "84489ef4" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-all-possible-features.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-all-possible-features.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-all-possible-features.mdx")],
  "84491502": [() => import(/* webpackChunkName: "84491502" */ "@site/docs/api/identity/endpoints/authentication/operation-get-password-policy.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-get-password-policy.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-get-password-policy.mdx")],
  "8486ee31": [() => import(/* webpackChunkName: "8486ee31" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-set-amortisation-rules.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-set-amortisation-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-set-amortisation-rules.mdx")],
  "84977b34": [() => import(/* webpackChunkName: "84977b34" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-journal-entry-line.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-journal-entry-line.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-journal-entry-line.mdx")],
  "84a08045": [() => import(/* webpackChunkName: "84a08045" */ "@site/docs/api/lusid/schemas/schema-get-cds-flow-conventions-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-cds-flow-conventions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-cds-flow-conventions-response.mdx")],
  "84a95b5d": [() => import(/* webpackChunkName: "84a95b5d" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-case-statement-design-to-sql.mdx")],
  "84ad4d49": [() => import(/* webpackChunkName: "84ad4d49" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-rules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-rules.mdx")],
  "84b0baa7": [() => import(/* webpackChunkName: "84b0baa7" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-patch-custom-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-patch-custom-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-patch-custom-entity-access-metadata.mdx")],
  "84c23fbc": [() => import(/* webpackChunkName: "84c23fbc" */ "@site/docs/api/lusid/schemas/schema-placement-request.mdx"), "@site/docs/api/lusid/schemas/schema-placement-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-placement-request.mdx")],
  "84f5ce29": [() => import(/* webpackChunkName: "84f5ce29" */ "@site/docs/api/lusid/schemas/schema-cash.mdx"), "@site/docs/api/lusid/schemas/schema-cash.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash.mdx")],
  "84fbf9a5": [() => import(/* webpackChunkName: "84fbf9a5" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-pipe.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-pipe.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-pipe.mdx")],
  "84fc4949": [() => import(/* webpackChunkName: "84fc4949" */ "@site/docs/api/lusid/schemas/schema-group-of-market-data-key-rules.mdx"), "@site/docs/api/lusid/schemas/schema-group-of-market-data-key-rules.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-of-market-data-key-rules.mdx")],
  "85208313": [() => import(/* webpackChunkName: "85208313" */ "@site/docs/excel/functions/Executions.mdx"), "@site/docs/excel/functions/Executions.mdx", require.resolveWeak("@site/docs/excel/functions/Executions.mdx")],
  "855642f3": [() => import(/* webpackChunkName: "855642f3" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-upsert-legal-entity.mdx")],
  "857246bb": [() => import(/* webpackChunkName: "857246bb" */ "@site/docs/api/scheduler/endpoints/images/operation-download-image.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-download-image.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-download-image.mdx")],
  "85887957": [() => import(/* webpackChunkName: "85887957" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-template-specifications.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-template-specifications.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-list-transaction-template-specifications.mdx")],
  "85896dcb": [() => import(/* webpackChunkName: "85896dcb" */ "@site/docs/api/luminesce/schemas/schema-options-xml.mdx"), "@site/docs/api/luminesce/schemas/schema-options-xml.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-options-xml.mdx")],
  "858c6581": [() => import(/* webpackChunkName: "858c6581" */ "@site/docs/api/lusid/schemas/schema-get-index-convention-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-index-convention-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-index-convention-response.mdx")],
  "85a1d21e": [() => import(/* webpackChunkName: "85a1d21e" */ "@site/docs/api/lusid/schemas/schema-group-filter-step.mdx"), "@site/docs/api/lusid/schemas/schema-group-filter-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-filter-step.mdx")],
  "85a7de84": [() => import(/* webpackChunkName: "85a7de84" */ "@site/docs/api/lusid/schemas/schema-fund-configuration-properties.mdx"), "@site/docs/api/lusid/schemas/schema-fund-configuration-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-configuration-properties.mdx")],
  "85bc49b6": [() => import(/* webpackChunkName: "85bc49b6" */ "@site/docs/api/workflow/schemas/schema-resultant-child-task-configuration.mdx"), "@site/docs/api/workflow/schemas/schema-resultant-child-task-configuration.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-resultant-child-task-configuration.mdx")],
  "85c4ae6e": [() => import(/* webpackChunkName: "85c4ae6e" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-participation.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-participation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-participation.mdx")],
  "85da2b73": [() => import(/* webpackChunkName: "85da2b73" */ "@site/docs/api/lusid/schemas/schema-constituents-adjustment-header.mdx"), "@site/docs/api/lusid/schemas/schema-constituents-adjustment-header.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-constituents-adjustment-header.mdx")],
  "860b8104": [() => import(/* webpackChunkName: "860b8104" */ "@site/docs/api/lusid/schemas/schema-instrument-id-type-descriptor.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-id-type-descriptor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-id-type-descriptor.mdx")],
  "86460860": [() => import(/* webpackChunkName: "86460860" */ "@site/docs/api/lusid/schemas/schema-posting-module-rules-updated-response.mdx"), "@site/docs/api/lusid/schemas/schema-posting-module-rules-updated-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-posting-module-rules-updated-response.mdx")],
  "867ada35": [() => import(/* webpackChunkName: "867ada35" */ "@site/docs/api/lusid/schemas/schema-upsert-quote-access-metadata-rule-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-quote-access-metadata-rule-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-quote-access-metadata-rule-request.mdx")],
  "86805913": [() => import(/* webpackChunkName: "86805913" */ "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-response.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-response.mdx")],
  "86c810e1": [() => import(/* webpackChunkName: "86c810e1" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-template.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-template.mdx")],
  "870e56a2": [() => import(/* webpackChunkName: "870e56a2" */ "@site/docs/api/lusid/schemas/schema-access-metadata-value.mdx"), "@site/docs/api/lusid/schemas/schema-access-metadata-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-access-metadata-value.mdx")],
  "87160cb1": [() => import(/* webpackChunkName: "87160cb1" */ "@site/docs/api/luminesce/schemas/schema-certificate-action.mdx"), "@site/docs/api/luminesce/schemas/schema-certificate-action.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-certificate-action.mdx")],
  "873338dc": [() => import(/* webpackChunkName: "873338dc" */ "@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap.mdx"), "@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/roadmap/operation-roadmap-get-roadmap.mdx")],
  "87366dc3": [() => import(/* webpackChunkName: "87366dc3" */ "@site/docs/api/lusid/endpoints/order-management/operation-cancel-placements.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-cancel-placements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-cancel-placements.mdx")],
  "87420e6b": [() => import(/* webpackChunkName: "87420e6b" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-get-provider-template-for-export.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-get-provider-template-for-export.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-get-provider-template-for-export.mdx")],
  "8758b5af": [() => import(/* webpackChunkName: "8758b5af" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-tolerance.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-tolerance.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-tolerance.mdx")],
  "876287c4": [() => import(/* webpackChunkName: "876287c4" */ "@site/docs/api/identity/schemas/schema-add-scim-response.mdx"), "@site/docs/api/identity/schemas/schema-add-scim-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-add-scim-response.mdx")],
  "87830a5d": [() => import(/* webpackChunkName: "87830a5d" */ "@site/docs/api/access/schemas/schema-if-identity-claim-expression.mdx"), "@site/docs/api/access/schemas/schema-if-identity-claim-expression.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-if-identity-claim-expression.mdx")],
  "8796167c": [() => import(/* webpackChunkName: "8796167c" */ "@site/docs/api/workflow/schemas/schema-health-check-response.mdx"), "@site/docs/api/workflow/schemas/schema-health-check-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-health-check-response.mdx")],
  "879c2d7b": [() => import(/* webpackChunkName: "879c2d7b" */ "@site/docs/api/lusid/schemas/schema-compliance-template.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-template.mdx")],
  "87e94562": [() => import(/* webpackChunkName: "87e94562" */ "@site/docs/api/lusid/schemas/schema-order-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-order-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-set-request.mdx")],
  "87fc771b": [() => import(/* webpackChunkName: "87fc771b" */ "@site/docs/api/lusid/schemas/schema-cash-perpetual.mdx"), "@site/docs/api/lusid/schemas/schema-cash-perpetual.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-perpetual.mdx")],
  "8807d375": [() => import(/* webpackChunkName: "8807d375" */ "@site/docs/api/workflow/schemas/schema-create-child-tasks-action-response.mdx"), "@site/docs/api/workflow/schemas/schema-create-child-tasks-action-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-child-tasks-action-response.mdx")],
  "881aec8e": [() => import(/* webpackChunkName: "881aec8e" */ "@site/docs/api/identity/endpoints/users/operation-update-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-update-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-update-user.mdx")],
  "8820c724": [() => import(/* webpackChunkName: "8820c724" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-preview-transaction.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-preview-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-preview-transaction.mdx")],
  "8822f9fe": [() => import(/* webpackChunkName: "8822f9fe" */ "@site/docs/api/lusid/schemas/schema-response-meta-data.mdx"), "@site/docs/api/lusid/schemas/schema-response-meta-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-response-meta-data.mdx")],
  "884671ff": [() => import(/* webpackChunkName: "884671ff" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-recipe-ids.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-recipe-ids.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-recipe-ids.mdx")],
  "88498a5f": [() => import(/* webpackChunkName: "88498a5f" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-access-metadata.mdx")],
  "884fa05c": [() => import(/* webpackChunkName: "884fa05c" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-create-configuration-set.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-create-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-create-configuration-set.mdx")],
  "8895240a": [() => import(/* webpackChunkName: "8895240a" */ "@site/docs/excel/index.md"), "@site/docs/excel/index.md", require.resolveWeak("@site/docs/excel/index.md")],
  "889560cf": [() => import(/* webpackChunkName: "889560cf" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-general-ledger-profile.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-general-ledger-profile.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-general-ledger-profile.mdx")],
  "889cb765": [() => import(/* webpackChunkName: "889cb765" */ "@site/docs/api/lusid/schemas/schema-counterparty-agreement.mdx"), "@site/docs/api/lusid/schemas/schema-counterparty-agreement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-counterparty-agreement.mdx")],
  "88cb8273": [() => import(/* webpackChunkName: "88cb8273" */ "@site/docs/api/lusid/schemas/schema-next-value-in-sequence-response.mdx"), "@site/docs/api/lusid/schemas/schema-next-value-in-sequence-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-next-value-in-sequence-response.mdx")],
  "88cb9166": [() => import(/* webpackChunkName: "88cb9166" */ "@site/docs/api/web-app/schemas/schema-sdk-quote-type.mdx"), "@site/docs/api/web-app/schemas/schema-sdk-quote-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-sdk-quote-type.mdx")],
  "88e6135f": [() => import(/* webpackChunkName: "88e6135f" */ "@site/docs/api/scheduler/schemas/schema-update-schedule-request.mdx"), "@site/docs/api/scheduler/schemas/schema-update-schedule-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-update-schedule-request.mdx")],
  "88ed02a0": [() => import(/* webpackChunkName: "88ed02a0" */ "@site/docs/api/lusid/schemas/schema-fx-linked-notional-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-fx-linked-notional-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-linked-notional-schedule.mdx")],
  "8900344e": [() => import(/* webpackChunkName: "8900344e" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-quote-access-metadata-rule.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-quote-access-metadata-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-quote-access-metadata-rule.mdx")],
  "8909a328": [() => import(/* webpackChunkName: "8909a328" */ "@site/docs/api/lusid/schemas/schema-model-selection.mdx"), "@site/docs/api/lusid/schemas/schema-model-selection.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-model-selection.mdx")],
  "891347e9": [() => import(/* webpackChunkName: "891347e9" */ "@site/docs/api/web-app/schemas/schema-resource-list-shared-personalisation.mdx"), "@site/docs/api/web-app/schemas/schema-resource-list-shared-personalisation.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-resource-list-shared-personalisation.mdx")],
  "893549ad": [() => import(/* webpackChunkName: "893549ad" */ "@site/docs/api/workflow/schemas/schema-run-worker-action.mdx"), "@site/docs/api/workflow/schemas/schema-run-worker-action.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-run-worker-action.mdx")],
  "894d2e37": [() => import(/* webpackChunkName: "894d2e37" */ "@site/docs/api/lusid/schemas/schema-reconciliation-break.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-break.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-break.mdx")],
  "89608a97": [() => import(/* webpackChunkName: "89608a97" */ "@site/docs/api/web-app/schemas/schema-get-security-token-response.mdx"), "@site/docs/api/web-app/schemas/schema-get-security-token-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-get-security-token-response.mdx")],
  "8980ded4": [() => import(/* webpackChunkName: "8980ded4" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-relationships.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-relationships.mdx")],
  "89a72eaa": [() => import(/* webpackChunkName: "89a72eaa" */ "@site/docs/api/notification/schemas/schema-sms-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-sms-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-sms-notification-type.mdx")],
  "89b0ace3": [() => import(/* webpackChunkName: "89b0ace3" */ "@site/docs/api/configuration/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/configuration/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "89e4ca7b": [() => import(/* webpackChunkName: "89e4ca7b" */ "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-delete-portfolio-properties.mdx")],
  "89ec957e": [() => import(/* webpackChunkName: "89ec957e" */ "@site/docs/api/lusid/schemas/schema-amortisation-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-amortisation-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-amortisation-rule-set.mdx")],
  "89fc0587": [() => import(/* webpackChunkName: "89fc0587" */ "@site/docs/api/lusid/schemas/schema-create-amortisation-rule-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-amortisation-rule-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-amortisation-rule-set-request.mdx")],
  "8a1ca626": [() => import(/* webpackChunkName: "8a1ca626" */ "@site/docs/api/lusid/schemas/schema-structured-result-data.mdx"), "@site/docs/api/lusid/schemas/schema-structured-result-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-structured-result-data.mdx")],
  "8a1f39bf": [() => import(/* webpackChunkName: "8a1f39bf" */ "@site/docs/api/lusid/schemas/schema-settlement-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-settlement-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-settlement-schedule.mdx")],
  "8a23a8d3": [() => import(/* webpackChunkName: "8a23a8d3" */ "@site/docs/api/lusid/schemas/schema-order-update-request.mdx"), "@site/docs/api/lusid/schemas/schema-order-update-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-update-request.mdx")],
  "8a2d3657": [() => import(/* webpackChunkName: "8a2d3657" */ "@site/docs/api/lusid/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/lusid/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-identifier-part-schema.mdx")],
  "8a477ece": [() => import(/* webpackChunkName: "8a477ece" */ "@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-response.mdx"), "@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-response.mdx")],
  "8a96586c": [() => import(/* webpackChunkName: "8a96586c" */ "@site/docs/api/lusid/schemas/schema-abor-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-abor-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor-configuration.mdx")],
  "8a9b4409": [() => import(/* webpackChunkName: "8a9b4409" */ "@site/docs/api/identity/schemas/schema-o-auth-application.mdx"), "@site/docs/api/identity/schemas/schema-o-auth-application.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-o-auth-application.mdx")],
  "8a9ccdd0": [() => import(/* webpackChunkName: "8a9ccdd0" */ "@site/docs/api/lusid/schemas/schema-yield-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-yield-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-yield-curve-data.mdx")],
  "8abe9a31": [() => import(/* webpackChunkName: "8abe9a31" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-instruction.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-instruction.mdx")],
  "8acc9c28": [() => import(/* webpackChunkName: "8acc9c28" */ "@site/docs/api/lusid/endpoints/funds/operation-list-valuation-point-overview.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-list-valuation-point-overview.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-list-valuation-point-overview.mdx")],
  "8ad4d6f9": [() => import(/* webpackChunkName: "8ad4d6f9" */ "@site/docs/api/lusid/schemas/schema-invalid.mdx"), "@site/docs/api/lusid/schemas/schema-invalid.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-invalid.mdx")],
  "8b13eb0b": [() => import(/* webpackChunkName: "8b13eb0b" */ "@site/docs/api/insights/endpoints/access-evaluations/operation-list-access-evaluation-logs.mdx"), "@site/docs/api/insights/endpoints/access-evaluations/operation-list-access-evaluation-logs.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/access-evaluations/operation-list-access-evaluation-logs.mdx")],
  "8b33925f": [() => import(/* webpackChunkName: "8b33925f" */ "@site/docs/api/lusid/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/lusid/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-id-selector-definition.mdx")],
  "8b3befee": [() => import(/* webpackChunkName: "8b3befee" */ "@site/docs/api/lusid/schemas/schema-create-derived-transaction-portfolio-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-derived-transaction-portfolio-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-derived-transaction-portfolio-request.mdx")],
  "8b4174b9": [() => import(/* webpackChunkName: "8b4174b9" */ "@site/docs/api/web-app/schemas/schema-property-value.mdx"), "@site/docs/api/web-app/schemas/schema-property-value.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-property-value.mdx")],
  "8b75f291": [() => import(/* webpackChunkName: "8b75f291" */ "@site/docs/api/workflow/schemas/schema-task-activity-response.mdx"), "@site/docs/api/workflow/schemas/schema-task-activity-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-activity-response.mdx")],
  "8b7fa1bf": [() => import(/* webpackChunkName: "8b7fa1bf" */ "@site/docs/api/access/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/access/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-access-controlled-action.mdx")],
  "8b8fdc37": [() => import(/* webpackChunkName: "8b8fdc37" */ "@site/docs/api/luminesce/schemas/schema-writer-design.mdx"), "@site/docs/api/luminesce/schemas/schema-writer-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-writer-design.mdx")],
  "8bc446a0": [() => import(/* webpackChunkName: "8bc446a0" */ "@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation.mdx"), "@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/aggregation/operation-get-valuation.mdx")],
  "8bcac8b7": [() => import(/* webpackChunkName: "8bcac8b7" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-chart-of-accounts.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-chart-of-accounts.mdx")],
  "8bd0165c": [() => import(/* webpackChunkName: "8bd0165c" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-build-transactions-for-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-build-transactions-for-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-build-transactions-for-portfolio-group.mdx")],
  "8bd1154c": [() => import(/* webpackChunkName: "8bd1154c" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-unshare-rebalance-configuration.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-unshare-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-unshare-rebalance-configuration.mdx")],
  "8c33488b": [() => import(/* webpackChunkName: "8c33488b" */ "@site/docs/api/identity/endpoints/applications/operation-delete-application.mdx"), "@site/docs/api/identity/endpoints/applications/operation-delete-application.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/applications/operation-delete-application.mdx")],
  "8c33ec82": [() => import(/* webpackChunkName: "8c33ec82" */ "@site/docs/api/lusid/schemas/schema-amortisation-event.mdx"), "@site/docs/api/lusid/schemas/schema-amortisation-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-amortisation-event.mdx")],
  "8c62858a": [() => import(/* webpackChunkName: "8c62858a" */ "@site/docs/api/lusid/schemas/schema-aggregate-spec.mdx"), "@site/docs/api/lusid/schemas/schema-aggregate-spec.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregate-spec.mdx")],
  "8c66287a": [() => import(/* webpackChunkName: "8c66287a" */ "@site/docs/api/lusid/endpoints/persons/operation-set-person-identifiers.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-set-person-identifiers.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-set-person-identifiers.mdx")],
  "8c734fd0": [() => import(/* webpackChunkName: "8c734fd0" */ "@site/docs/api/lusid/endpoints/custom-entity-types/operation-create-custom-entity-type.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-types/operation-create-custom-entity-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-types/operation-create-custom-entity-type.mdx")],
  "8c86073c": [() => import(/* webpackChunkName: "8c86073c" */ "@site/docs/api/luminesce/schemas/schema-intellisense-request.mdx"), "@site/docs/api/luminesce/schemas/schema-intellisense-request.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-intellisense-request.mdx")],
  "8ca3959e": [() => import(/* webpackChunkName: "8ca3959e" */ "@site/docs/api/luminesce/schemas/schema-mapping-flags.mdx"), "@site/docs/api/luminesce/schemas/schema-mapping-flags.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-mapping-flags.mdx")],
  "8ca43a61": [() => import(/* webpackChunkName: "8ca43a61" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-placement-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-placement-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-placement-synopsis.mdx")],
  "8ca4485b": [() => import(/* webpackChunkName: "8ca4485b" */ "@site/docs/api/web-app/schemas/schema-share-personalisations-request.mdx"), "@site/docs/api/web-app/schemas/schema-share-personalisations-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-share-personalisations-request.mdx")],
  "8cad19e9": [() => import(/* webpackChunkName: "8cad19e9" */ "@site/docs/api/lusid/schemas/schema-perpetual-property.mdx"), "@site/docs/api/lusid/schemas/schema-perpetual-property.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-perpetual-property.mdx")],
  "8cb9a15c": [() => import(/* webpackChunkName: "8cb9a15c" */ "@site/docs/api/configuration/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/configuration/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-lusid-problem-details.mdx")],
  "8ce039f2": [() => import(/* webpackChunkName: "8ce039f2" */ "@site/docs/api/lusid/schemas/schema-transaction-currency-and-amount.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-currency-and-amount.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-currency-and-amount.mdx")],
  "8d0d0757": [() => import(/* webpackChunkName: "8d0d0757" */ "@site/docs/api/lusid/schemas/schema-posting-module-details.mdx"), "@site/docs/api/lusid/schemas/schema-posting-module-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-posting-module-details.mdx")],
  "8d166c25": [() => import(/* webpackChunkName: "8d166c25" */ "@site/docs/api/lusid/schemas/schema-reconciled-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-reconciled-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciled-transaction.mdx")],
  "8d41d87f": [() => import(/* webpackChunkName: "8d41d87f" */ "@site/docs/api/lusid/schemas/schema-date-time-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-date-time-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-date-time-list-compliance-parameter.mdx")],
  "8d8f33f7": [() => import(/* webpackChunkName: "8d8f33f7" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-details.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-posting-module-details.mdx")],
  "8dad2d5e": [() => import(/* webpackChunkName: "8dad2d5e" */ "@site/docs/api/workflow/endpoints/event-handlers/operation-create-event-handler.mdx"), "@site/docs/api/workflow/endpoints/event-handlers/operation-create-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/event-handlers/operation-create-event-handler.mdx")],
  "8db25047": [() => import(/* webpackChunkName: "8db25047" */ "@site/docs/api/lusid/schemas/schema-instrument-properties.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-properties.mdx")],
  "8dff6856": [() => import(/* webpackChunkName: "8dff6856" */ "@site/docs/api/lusid/schemas/schema-create-group-reconciliation-comparison-ruleset-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-group-reconciliation-comparison-ruleset-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-group-reconciliation-comparison-ruleset-request.mdx")],
  "8e3a7c17": [() => import(/* webpackChunkName: "8e3a7c17" */ "@site/docs/api/web-app/endpoints/views/operation-views-delete-shared-view.mdx"), "@site/docs/api/web-app/endpoints/views/operation-views-delete-shared-view.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/views/operation-views-delete-shared-view.mdx")],
  "8e4d9e8d": [() => import(/* webpackChunkName: "8e4d9e8d" */ "@site/docs/api/web-app/schemas/schema-ticket-base.mdx"), "@site/docs/api/web-app/schemas/schema-ticket-base.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-ticket-base.mdx")],
  "8e619e19": [() => import(/* webpackChunkName: "8e619e19" */ "@site/docs/api/insights/endpoints/vendor-logs/operation-list-vendor-logs.mdx"), "@site/docs/api/insights/endpoints/vendor-logs/operation-list-vendor-logs.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/vendor-logs/operation-list-vendor-logs.mdx")],
  "8e831cf5": [() => import(/* webpackChunkName: "8e831cf5" */ "@site/docs/api/lusid/schemas/schema-order-graph-block.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block.mdx")],
  "8e88ead9": [() => import(/* webpackChunkName: "8e88ead9" */ "@site/docs/api/web-app/schemas/schema-get-order-instructions-by-id-request.mdx"), "@site/docs/api/web-app/schemas/schema-get-order-instructions-by-id-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-get-order-instructions-by-id-request.mdx")],
  "8e8bcd5d": [() => import(/* webpackChunkName: "8e8bcd5d" */ "@site/docs/api/configuration/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/configuration/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "8e93976e": [() => import(/* webpackChunkName: "8e93976e" */ "@site/docs/api/insights/schemas/schema-resource.mdx"), "@site/docs/api/insights/schemas/schema-resource.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource.mdx")],
  "8e9ab8e8": [() => import(/* webpackChunkName: "8e9ab8e8" */ "@site/docs/api/lusid/schemas/schema-property-definition-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-property-definition-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-definition-search-result.mdx")],
  "8ea54a50": [() => import(/* webpackChunkName: "8ea54a50" */ "@site/docs/api/identity/endpoints/authentication/operation-get-support-roles.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-get-support-roles.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-get-support-roles.mdx")],
  "8ebf3424": [() => import(/* webpackChunkName: "8ebf3424" */ "@site/docs/api/lusid/schemas/schema-share-class-pnl-breakdown.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-pnl-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-pnl-breakdown.mdx")],
  "8ec9a1ce": [() => import(/* webpackChunkName: "8ec9a1ce" */ "@site/docs/api/workflow/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/workflow/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-access-controlled-action.mdx")],
  "8ee6fbef": [() => import(/* webpackChunkName: "8ee6fbef" */ "@site/docs/api/lusid/schemas/schema-holding-adjustment-with-date.mdx"), "@site/docs/api/lusid/schemas/schema-holding-adjustment-with-date.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-adjustment-with-date.mdx")],
  "8eed759d": [() => import(/* webpackChunkName: "8eed759d" */ "@site/docs/api/workflow/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/workflow/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-id-selector-definition.mdx")],
  "8f13f719": [() => import(/* webpackChunkName: "8f13f719" */ "@site/docs/api/lusid/schemas/schema-inflation-index-conventions.mdx"), "@site/docs/api/lusid/schemas/schema-inflation-index-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-inflation-index-conventions.mdx")],
  "8f4c1bff": [() => import(/* webpackChunkName: "8f4c1bff" */ "@site/docs/api/lusid/schemas/schema-reconcile-exact.mdx"), "@site/docs/api/lusid/schemas/schema-reconcile-exact.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconcile-exact.mdx")],
  "8f5b9df1": [() => import(/* webpackChunkName: "8f5b9df1" */ "@site/docs/api/lusid/schemas/schema-result-value-currency.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-currency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-currency.mdx")],
  "8f6ee374": [() => import(/* webpackChunkName: "8f6ee374" */ "@site/docs/api/lusid/schemas/schema-order-request.mdx"), "@site/docs/api/lusid/schemas/schema-order-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-request.mdx")],
  "8f88fccb": [() => import(/* webpackChunkName: "8f88fccb" */ "@site/docs/api/lusid/schemas/schema-place-blocks-request.mdx"), "@site/docs/api/lusid/schemas/schema-place-blocks-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-place-blocks-request.mdx")],
  "8fc961eb": [() => import(/* webpackChunkName: "8fc961eb" */ "@site/docs/api/lusid/schemas/schema-metric-value.mdx"), "@site/docs/api/lusid/schemas/schema-metric-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-metric-value.mdx")],
  "8fea2ba2": [() => import(/* webpackChunkName: "8fea2ba2" */ "@site/docs/api/notification/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/notification/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-id-selector-definition.mdx")],
  "9005c80f": [() => import(/* webpackChunkName: "9005c80f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-block.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-block.mdx")],
  "901f4091": [() => import(/* webpackChunkName: "901f4091" */ "@site/docs/api/lusid/endpoints/funds/operation-patch-fund.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-patch-fund.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-patch-fund.mdx")],
  "902d46f3": [() => import(/* webpackChunkName: "902d46f3" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-transaction-template.mdx")],
  "9049608a": [() => import(/* webpackChunkName: "9049608a" */ "@site/docs/api/lusid/schemas/schema-upsert-legal-entities-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-legal-entities-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-legal-entities-response.mdx")],
  "905f8d1d": [() => import(/* webpackChunkName: "905f8d1d" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relations.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-legal-entity-relations.mdx")],
  "90804a9a": [() => import(/* webpackChunkName: "90804a9a" */ "@site/docs/api/notification/schemas/schema-action-id.mdx"), "@site/docs/api/notification/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-action-id.mdx")],
  "9095f568": [() => import(/* webpackChunkName: "9095f568" */ "@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-compliance-run-summary-request.mdx")],
  "909bfab7": [() => import(/* webpackChunkName: "909bfab7" */ "@site/docs/api/identity/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/identity/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-access-controlled-resource.mdx")],
  "90c86ecd": [() => import(/* webpackChunkName: "90c86ecd" */ "@site/docs/api/horizon/endpoints/integrations/operation-set-instance-optional-property-mapping.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-set-instance-optional-property-mapping.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-set-instance-optional-property-mapping.mdx")],
  "90fc1d4e": [() => import(/* webpackChunkName: "90fc1d4e" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-date-pair.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-date-pair.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-date-pair.mdx")],
  "91057579": [() => import(/* webpackChunkName: "91057579" */ "@site/docs/api/lusid/schemas/schema-operation.mdx"), "@site/docs/api/lusid/schemas/schema-operation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-operation.mdx")],
  "9137d7bf": [() => import(/* webpackChunkName: "9137d7bf" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-result-portfolio-detail.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-result-portfolio-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-result-portfolio-detail.mdx")],
  "916a49ef": [() => import(/* webpackChunkName: "916a49ef" */ "@site/docs/api/identity/endpoints/users/operation-unlock-user.mdx"), "@site/docs/api/identity/endpoints/users/operation-unlock-user.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-unlock-user.mdx")],
  "916e983b": [() => import(/* webpackChunkName: "916e983b" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-list-configuration-sets.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-list-configuration-sets.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-list-configuration-sets.mdx")],
  "9171922c": [() => import(/* webpackChunkName: "9171922c" */ "@site/docs/api/workflow/schemas/schema-luminesce-view-response.mdx"), "@site/docs/api/workflow/schemas/schema-luminesce-view-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-luminesce-view-response.mdx")],
  "91847f6d": [() => import(/* webpackChunkName: "91847f6d" */ "@site/docs/api/identity/schemas/schema-created-api-key.mdx"), "@site/docs/api/identity/schemas/schema-created-api-key.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-created-api-key.mdx")],
  "918ab387": [() => import(/* webpackChunkName: "918ab387" */ "@site/docs/api/lusid/schemas/schema-calendar.mdx"), "@site/docs/api/lusid/schemas/schema-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-calendar.mdx")],
  "918ec6af": [() => import(/* webpackChunkName: "918ec6af" */ "@site/docs/api/web-app/schemas/schema-cash-rule-type.mdx"), "@site/docs/api/web-app/schemas/schema-cash-rule-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-cash-rule-type.mdx")],
  "919dc59f": [() => import(/* webpackChunkName: "919dc59f" */ "@site/docs/api/lusid/intro.md"), "@site/docs/api/lusid/intro.md", require.resolveWeak("@site/docs/api/lusid/intro.md")],
  "91a8243c": [() => import(/* webpackChunkName: "91a8243c" */ "@site/docs/api/web-app/schemas/schema-order-instruction-summary.mdx"), "@site/docs/api/web-app/schemas/schema-order-instruction-summary.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-order-instruction-summary.mdx")],
  "91b1558f": [() => import(/* webpackChunkName: "91b1558f" */ "@site/docs/api/lusid/schemas/schema-block-and-order-id-request.mdx"), "@site/docs/api/lusid/schemas/schema-block-and-order-id-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-and-order-id-request.mdx")],
  "91de6296": [() => import(/* webpackChunkName: "91de6296" */ "@site/docs/api/lusid/endpoints/counterparties/operation-list-counterparty-agreements.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-list-counterparty-agreements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-list-counterparty-agreements.mdx")],
  "92208e3b": [() => import(/* webpackChunkName: "92208e3b" */ "@site/docs/api/lusid/schemas/schema-portfolio-group-id-list.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group-id-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group-id-list.mdx")],
  "92510070": [() => import(/* webpackChunkName: "92510070" */ "@site/docs/api/lusid/schemas/schema-event-date-range.mdx"), "@site/docs/api/lusid/schemas/schema-event-date-range.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-event-date-range.mdx")],
  "9254984d": [() => import(/* webpackChunkName: "9254984d" */ "@site/docs/api/lusid/endpoints/data-types/operation-delete-data-type.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-delete-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-delete-data-type.mdx")],
  "925e9c3d": [() => import(/* webpackChunkName: "925e9c3d" */ "@site/docs/api/lusid/schemas/schema-delete-instrument-response.mdx"), "@site/docs/api/lusid/schemas/schema-delete-instrument-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-instrument-response.mdx")],
  "928772ff": [() => import(/* webpackChunkName: "928772ff" */ "@site/docs/api/luminesce/schemas/schema-file-reader-builder-def.mdx"), "@site/docs/api/luminesce/schemas/schema-file-reader-builder-def.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-file-reader-builder-def.mdx")],
  "928ba93b": [() => import(/* webpackChunkName: "928ba93b" */ "@site/docs/api/web-app/schemas/schema-tolerance.mdx"), "@site/docs/api/web-app/schemas/schema-tolerance.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tolerance.mdx")],
  "928f2686": [() => import(/* webpackChunkName: "928f2686" */ "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-by-type.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-by-type.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-property-definition-overrides-by-type.mdx")],
  "92a995da": [() => import(/* webpackChunkName: "92a995da" */ "@site/docs/api/horizon/schemas/schema-vendor-product.mdx"), "@site/docs/api/horizon/schemas/schema-vendor-product.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-vendor-product.mdx")],
  "92b42f53": [() => import(/* webpackChunkName: "92b42f53" */ "@site/docs/api/lusid/schemas/schema-staged-modification-effective-range.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modification-effective-range.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modification-effective-range.mdx")],
  "93046463": [() => import(/* webpackChunkName: "93046463" */ "@site/docs/api/lusid/schemas/schema-cut-local-time.mdx"), "@site/docs/api/lusid/schemas/schema-cut-local-time.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cut-local-time.mdx")],
  "93393c33": [() => import(/* webpackChunkName: "93393c33" */ "@site/docs/api/lusid/schemas/schema-corporate-action-source.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action-source.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action-source.mdx")],
  "934583be": [() => import(/* webpackChunkName: "934583be" */ "@site/docs/api/lusid/schemas/schema-accounts-upsert-response.mdx"), "@site/docs/api/lusid/schemas/schema-accounts-upsert-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-accounts-upsert-response.mdx")],
  "9359cf46": [() => import(/* webpackChunkName: "9359cf46" */ "@site/docs/api/lusid/schemas/schema-instrument-leg.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-leg.mdx")],
  "936c4dcb": [() => import(/* webpackChunkName: "936c4dcb" */ "@site/docs/api/lusid/endpoints/persons/operation-delete-person-identifiers.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-delete-person-identifiers.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-delete-person-identifiers.mdx")],
  "9390f782": [() => import(/* webpackChunkName: "9390f782" */ "@site/docs/api/lusid/schemas/schema-recipe-composer.mdx"), "@site/docs/api/lusid/schemas/schema-recipe-composer.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-recipe-composer.mdx")],
  "9391ebc0": [() => import(/* webpackChunkName: "9391ebc0" */ "@site/docs/api/identity/schemas/schema-user-response.mdx"), "@site/docs/api/identity/schemas/schema-user-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-user-response.mdx")],
  "93a1866c": [() => import(/* webpackChunkName: "93a1866c" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-capabilities.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-capabilities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-existing-instrument-capabilities.mdx")],
  "9410faa2": [() => import(/* webpackChunkName: "9410faa2" */ "@site/docs/api/lusid/schemas/schema-day-of-week.mdx"), "@site/docs/api/lusid/schemas/schema-day-of-week.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-day-of-week.mdx")],
  "94218a9b": [() => import(/* webpackChunkName: "94218a9b" */ "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-update-result.mdx"), "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-update-result.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-update-result.mdx")],
  "942436cc": [() => import(/* webpackChunkName: "942436cc" */ "@site/docs/excel/functions/Placements.mdx"), "@site/docs/excel/functions/Placements.mdx", require.resolveWeak("@site/docs/excel/functions/Placements.mdx")],
  "94305361": [() => import(/* webpackChunkName: "94305361" */ "@site/docs/api/insights/schemas/schema-response.mdx"), "@site/docs/api/insights/schemas/schema-response.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-response.mdx")],
  "946e06c1": [() => import(/* webpackChunkName: "946e06c1" */ "@site/docs/api/lusid/schemas/schema-future.mdx"), "@site/docs/api/lusid/schemas/schema-future.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-future.mdx")],
  "94894328": [() => import(/* webpackChunkName: "94894328" */ "@site/docs/api/lusid/schemas/schema-upsert-returns-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-returns-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-returns-response.mdx")],
  "949222dd": [() => import(/* webpackChunkName: "949222dd" */ "@site/docs/api/lusid/schemas/schema-data-scope.mdx"), "@site/docs/api/lusid/schemas/schema-data-scope.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-scope.mdx")],
  "94a30ae0": [() => import(/* webpackChunkName: "94a30ae0" */ "@site/docs/api/lusid/schemas/schema-cdx-credit-event.mdx"), "@site/docs/api/lusid/schemas/schema-cdx-credit-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cdx-credit-event.mdx")],
  "94afdbe4": [() => import(/* webpackChunkName: "94afdbe4" */ "@site/docs/api/horizon/endpoints/vendor/operation-get-property-mappings-for-product-entity.mdx"), "@site/docs/api/horizon/endpoints/vendor/operation-get-property-mappings-for-product-entity.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/vendor/operation-get-property-mappings-for-product-entity.mdx")],
  "94b6cafa": [() => import(/* webpackChunkName: "94b6cafa" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-get-index-convention-response.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-get-index-convention-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-get-index-convention-response.mdx")],
  "94f52e6a": [() => import(/* webpackChunkName: "94f52e6a" */ "@site/docs/api/lusid/schemas/schema-cash-ladder-record.mdx"), "@site/docs/api/lusid/schemas/schema-cash-ladder-record.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-ladder-record.mdx")],
  "94f62cb1": [() => import(/* webpackChunkName: "94f62cb1" */ "@site/docs/api/lusid/endpoints/portfolios/operation-batch-upsert-portfolio-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-batch-upsert-portfolio-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-batch-upsert-portfolio-access-metadata.mdx")],
  "94fb7d57": [() => import(/* webpackChunkName: "94fb7d57" */ "@site/docs/api/lusid/schemas/schema-label-value-set.mdx"), "@site/docs/api/lusid/schemas/schema-label-value-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-label-value-set.mdx")],
  "94fd7859": [() => import(/* webpackChunkName: "94fd7859" */ "@site/docs/api/lusid/schemas/schema-swap-cash-flow-event.mdx"), "@site/docs/api/lusid/schemas/schema-swap-cash-flow-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-swap-cash-flow-event.mdx")],
  "950ce48a": [() => import(/* webpackChunkName: "950ce48a" */ "@site/docs/api/lusid/schemas/schema-field-value.mdx"), "@site/docs/api/lusid/schemas/schema-field-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-field-value.mdx")],
  "95257971": [() => import(/* webpackChunkName: "95257971" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity.mdx")],
  "953b4ab1": [() => import(/* webpackChunkName: "953b4ab1" */ "@site/docs/api/lusid/schemas/schema-pre-trade-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-pre-trade-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-pre-trade-configuration.mdx")],
  "95680313": [() => import(/* webpackChunkName: "95680313" */ "@site/docs/api/lusid/schemas/schema-holding-contributor.mdx"), "@site/docs/api/lusid/schemas/schema-holding-contributor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-contributor.mdx")],
  "95766cf9": [() => import(/* webpackChunkName: "95766cf9" */ "@site/docs/api/lusid/schemas/schema-general-ledger-profile-request.mdx"), "@site/docs/api/lusid/schemas/schema-general-ledger-profile-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-general-ledger-profile-request.mdx")],
  "9579446d": [() => import(/* webpackChunkName: "9579446d" */ "@site/docs/api/lusid/schemas/schema-economic-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-economic-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-economic-dependency.mdx")],
  "957a29c4": [() => import(/* webpackChunkName: "957a29c4" */ "@site/docs/api/notification/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/notification/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-access-controlled-resource.mdx")],
  "9587f05f": [() => import(/* webpackChunkName: "9587f05f" */ "@site/docs/api/lusid/schemas/schema-result-key-rule.mdx"), "@site/docs/api/lusid/schemas/schema-result-key-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-key-rule.mdx")],
  "9591cd4b": [() => import(/* webpackChunkName: "9591cd4b" */ "@site/docs/api/lusid/endpoints/transaction-fees/operation-get-transaction-fee-rule.mdx"), "@site/docs/api/lusid/endpoints/transaction-fees/operation-get-transaction-fee-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-fees/operation-get-transaction-fee-rule.mdx")],
  "95dd1b19": [() => import(/* webpackChunkName: "95dd1b19" */ "@site/docs/api/lusid/schemas/schema-relationship.mdx"), "@site/docs/api/lusid/schemas/schema-relationship.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-relationship.mdx")],
  "95e33b32": [() => import(/* webpackChunkName: "95e33b32" */ "@site/docs/api/lusid/endpoints/relationship-definitions/operation-create-relationship-definition.mdx"), "@site/docs/api/lusid/endpoints/relationship-definitions/operation-create-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationship-definitions/operation-create-relationship-definition.mdx")],
  "95e93586": [() => import(/* webpackChunkName: "95e93586" */ "@site/docs/api/horizon/endpoints/integrations/operation-get-schema.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-get-schema.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-get-schema.mdx")],
  "95ebf3a4": [() => import(/* webpackChunkName: "95ebf3a4" */ "@site/docs/api/insights/schemas/schema-histogram.mdx"), "@site/docs/api/insights/schemas/schema-histogram.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-histogram.mdx")],
  "95fb9fb3": [() => import(/* webpackChunkName: "95fb9fb3" */ "@site/docs/api/lusid/schemas/schema-custom-entity-request.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-request.mdx")],
  "962b13a0": [() => import(/* webpackChunkName: "962b13a0" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-person.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-person.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-person.mdx")],
  "9635d899": [() => import(/* webpackChunkName: "9635d899" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-adjustment.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-adjustment.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings-adjustment.mdx")],
  "9666fe9f": [() => import(/* webpackChunkName: "9666fe9f" */ "@site/docs/api/luminesce/schemas/schema-view-parameter.mdx"), "@site/docs/api/luminesce/schemas/schema-view-parameter.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-view-parameter.mdx")],
  "968511a2": [() => import(/* webpackChunkName: "968511a2" */ "@site/docs/api/lusid/schemas/schema-cap-floor.mdx"), "@site/docs/api/lusid/schemas/schema-cap-floor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cap-floor.mdx")],
  "968e035f": [() => import(/* webpackChunkName: "968e035f" */ "@site/docs/api/lusid/endpoints/compliance/operation-update-compliance-template.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-update-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-update-compliance-template.mdx")],
  "968f215b": [() => import(/* webpackChunkName: "968f215b" */ "@site/docs/api/lusid/schemas/schema-reconciliation-response.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-response.mdx")],
  "969a78a7": [() => import(/* webpackChunkName: "969a78a7" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-address-key-definitions-for-document.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-get-address-key-definitions-for-document.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-get-address-key-definitions-for-document.mdx")],
  "969d3919": [() => import(/* webpackChunkName: "969d3919" */ "@site/docs/api/lusid/schemas/schema-transaction-property-map.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-property-map.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-property-map.mdx")],
  "96a6f211": [() => import(/* webpackChunkName: "96a6f211" */ "@site/docs/api/web-app/schemas/schema-client-config.mdx"), "@site/docs/api/web-app/schemas/schema-client-config.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-client-config.mdx")],
  "96ab6113": [() => import(/* webpackChunkName: "96ab6113" */ "@site/docs/api/horizon/endpoints/integrations/operation-list-instances.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-list-instances.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-list-instances.mdx")],
  "96cc94d5": [() => import(/* webpackChunkName: "96cc94d5" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-list-legal-entities.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-list-legal-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-list-legal-entities.mdx")],
  "96d1b9cf": [() => import(/* webpackChunkName: "96d1b9cf" */ "@site/docs/api/lusid/schemas/schema-expanded-group.mdx"), "@site/docs/api/lusid/schemas/schema-expanded-group.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-expanded-group.mdx")],
  "96d71f98": [() => import(/* webpackChunkName: "96d71f98" */ "@site/docs/api/insights/schemas/schema-scrollable-collection-of-audit-entry.mdx"), "@site/docs/api/insights/schemas/schema-scrollable-collection-of-audit-entry.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-scrollable-collection-of-audit-entry.mdx")],
  "96dd2825": [() => import(/* webpackChunkName: "96dd2825" */ "@site/docs/api/access/schemas/schema-role-id.mdx"), "@site/docs/api/access/schemas/schema-role-id.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-role-id.mdx")],
  "96de017f": [() => import(/* webpackChunkName: "96de017f" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio.mdx")],
  "970e6f1e": [() => import(/* webpackChunkName: "970e6f1e" */ "@site/docs/api/access/schemas/schema-add-policy-to-role-request.mdx"), "@site/docs/api/access/schemas/schema-add-policy-to-role-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-add-policy-to-role-request.mdx")],
  "97156396": [() => import(/* webpackChunkName: "97156396" */ "@site/docs/api/configuration/schemas/schema-configuration-item-summary.mdx"), "@site/docs/api/configuration/schemas/schema-configuration-item-summary.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-configuration-item-summary.mdx")],
  "971e12db": [() => import(/* webpackChunkName: "971e12db" */ "@site/docs/api/lusid/schemas/schema-valuation-point-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-point-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-point-data-request.mdx")],
  "971e321f": [() => import(/* webpackChunkName: "971e321f" */ "@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio-access-metadata.mdx")],
  "972bf265": [() => import(/* webpackChunkName: "972bf265" */ "@site/docs/api/lusid/schemas/schema-staging-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staging-rule-set.mdx")],
  "973c6d6c": [() => import(/* webpackChunkName: "973c6d6c" */ "@site/docs/api/drive/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/drive/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-access-controlled-action.mdx")],
  "9747577f": [() => import(/* webpackChunkName: "9747577f" */ "@site/docs/api/lusid/schemas/schema-opaque-market-data.mdx"), "@site/docs/api/lusid/schemas/schema-opaque-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-opaque-market-data.mdx")],
  "9748aea3": [() => import(/* webpackChunkName: "9748aea3" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-create-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-create-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-create-portfolio-group.mdx")],
  "977ec9c5": [() => import(/* webpackChunkName: "977ec9c5" */ "@site/docs/api/lusid/schemas/schema-input-transition.mdx"), "@site/docs/api/lusid/schemas/schema-input-transition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-input-transition.mdx")],
  "9789216a": [() => import(/* webpackChunkName: "9789216a" */ "@site/docs/api/insights/endpoints/requests/operation-get-request-log.mdx"), "@site/docs/api/insights/endpoints/requests/operation-get-request-log.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/requests/operation-get-request-log.mdx")],
  "9795bb93": [() => import(/* webpackChunkName: "9795bb93" */ "@site/docs/api/lusid/schemas/schema-break-code-source.mdx"), "@site/docs/api/lusid/schemas/schema-break-code-source.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-break-code-source.mdx")],
  "97e4ea3a": [() => import(/* webpackChunkName: "97e4ea3a" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-property-time-series.mdx")],
  "97e9ac1c": [() => import(/* webpackChunkName: "97e9ac1c" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-template.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-template.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-template.mdx")],
  "982aec9b": [() => import(/* webpackChunkName: "982aec9b" */ "@site/docs/api/luminesce/schemas/schema-case-statement-item.mdx"), "@site/docs/api/luminesce/schemas/schema-case-statement-item.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-case-statement-item.mdx")],
  "982d10a6": [() => import(/* webpackChunkName: "982d10a6" */ "@site/docs/api/lusid/endpoints/data-types/operation-update-reference-values.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-update-reference-values.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-update-reference-values.mdx")],
  "9837cdfa": [() => import(/* webpackChunkName: "9837cdfa" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-chart-of-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-chart-of-accounts.mdx")],
  "984a6cf4": [() => import(/* webpackChunkName: "984a6cf4" */ "@site/docs/api/web-app/schemas/schema-taskize-config.mdx"), "@site/docs/api/web-app/schemas/schema-taskize-config.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-taskize-config.mdx")],
  "98ac118f": [() => import(/* webpackChunkName: "98ac118f" */ "@site/docs/api/web-app/endpoints/config/operation-get-app-config.mdx"), "@site/docs/api/web-app/endpoints/config/operation-get-app-config.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/config/operation-get-app-config.mdx")],
  "98aea4e6": [() => import(/* webpackChunkName: "98aea4e6" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-legal-entity.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-legal-entity.mdx")],
  "98b7e153": [() => import(/* webpackChunkName: "98b7e153" */ "@site/docs/api/lusid/schemas/schema-execution.mdx"), "@site/docs/api/lusid/schemas/schema-execution.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-execution.mdx")],
  "98d187dd": [() => import(/* webpackChunkName: "98d187dd" */ "@site/docs/api/access/endpoints/policies/operation-add-to-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-add-to-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-add-to-policy-collection.mdx")],
  "98d85016": [() => import(/* webpackChunkName: "98d85016" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-result-value.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-result-value.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-upsert-result-value.mdx")],
  "98e8c549": [() => import(/* webpackChunkName: "98e8c549" */ "@site/docs/api/horizon/endpoints/vendor/operation-query-vendors.mdx"), "@site/docs/api/horizon/endpoints/vendor/operation-query-vendors.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/vendor/operation-query-vendors.mdx")],
  "991b0097": [() => import(/* webpackChunkName: "991b0097" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-sub-group-to-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-sub-group-to-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-sub-group-to-group.mdx")],
  "991bbaca": [() => import(/* webpackChunkName: "991bbaca" */ "@site/docs/api/horizon/endpoints/integrations/operation-create-instance.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-create-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-create-instance.mdx")],
  "992a9442": [() => import(/* webpackChunkName: "992a9442" */ "@site/docs/api/lusid/schemas/schema-delete-instruments-response.mdx"), "@site/docs/api/lusid/schemas/schema-delete-instruments-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-instruments-response.mdx")],
  "9931be35": [() => import(/* webpackChunkName: "9931be35" */ "@site/docs/api/notification/endpoints/subscriptions/operation-get-subscription.mdx"), "@site/docs/api/notification/endpoints/subscriptions/operation-get-subscription.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/subscriptions/operation-get-subscription.mdx")],
  "99684452": [() => import(/* webpackChunkName: "99684452" */ "@site/docs/api/horizon/schemas/schema-integration-run-response.mdx"), "@site/docs/api/horizon/schemas/schema-integration-run-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-run-response.mdx")],
  "99739018": [() => import(/* webpackChunkName: "99739018" */ "@site/docs/api/lusid/schemas/schema-execution-request.mdx"), "@site/docs/api/lusid/schemas/schema-execution-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-execution-request.mdx")],
  "9995f85e": [() => import(/* webpackChunkName: "9995f85e" */ "@site/docs/api/lusid/schemas/schema-staged-modifications-info.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modifications-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modifications-info.mdx")],
  "999682b0": [() => import(/* webpackChunkName: "999682b0" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-mapping.mdx")],
  "999fb2d6": [() => import(/* webpackChunkName: "999fb2d6" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-cleardown-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-cleardown-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-cleardown-module.mdx")],
  "99a232ca": [() => import(/* webpackChunkName: "99a232ca" */ "@site/docs/api/lusid/schemas/schema-update-orders-response.mdx"), "@site/docs/api/lusid/schemas/schema-update-orders-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-orders-response.mdx")],
  "99a4d58f": [() => import(/* webpackChunkName: "99a4d58f" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-query-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-query-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-sql-to-query-design.mdx")],
  "99a8d5cf": [() => import(/* webpackChunkName: "99a8d5cf" */ "@site/docs/api/access/endpoints/policies/operation-get-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-get-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-get-policy-collection.mdx")],
  "9a0ea78e": [() => import(/* webpackChunkName: "9a0ea78e" */ "@site/docs/api/lusid/schemas/schema-transaction-template.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-template.mdx")],
  "9a18aca4": [() => import(/* webpackChunkName: "9a18aca4" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-portfolio.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-create-portfolio.mdx")],
  "9a1f4565": [() => import(/* webpackChunkName: "9a1f4565" */ "@site/docs/api/luminesce/schemas/schema-intellisense-item.mdx"), "@site/docs/api/luminesce/schemas/schema-intellisense-item.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-intellisense-item.mdx")],
  "9a200259": [() => import(/* webpackChunkName: "9a200259" */ "@site/docs/api/notification/schemas/schema-sms-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-sms-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-sms-notification-type-response.mdx")],
  "9a398eba": [() => import(/* webpackChunkName: "9a398eba" */ "@site/docs/api/lusid/schemas/schema-portfolio-cash-ladder.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-cash-ladder.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-cash-ladder.mdx")],
  "9a5cf5bf": [() => import(/* webpackChunkName: "9a5cf5bf" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review.mdx")],
  "9a71443d": [() => import(/* webpackChunkName: "9a71443d" */ "@site/docs/api/lusid/endpoints/order-management/operation-update-orders.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-update-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-update-orders.mdx")],
  "9a94d25b": [() => import(/* webpackChunkName: "9a94d25b" */ "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/excel-add-in-docs/plugin-route-context-module-100.json"), "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/excel-add-in-docs/plugin-route-context-module-100.json", require.resolveWeak("/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/excel-add-in-docs/plugin-route-context-module-100.json")],
  "9ac565e8": [() => import(/* webpackChunkName: "9ac565e8" */ "@site/docs/api/lusid/schemas/schema-create-tax-rule-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-tax-rule-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-tax-rule-set-request.mdx")],
  "9b084063": [() => import(/* webpackChunkName: "9b084063" */ "@site/docs/api/lusid/schemas/schema-legal-entity.mdx"), "@site/docs/api/lusid/schemas/schema-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-legal-entity.mdx")],
  "9b0d43f9": [() => import(/* webpackChunkName: "9b0d43f9" */ "@site/docs/api/lusid/schemas/schema-abor.mdx"), "@site/docs/api/lusid/schemas/schema-abor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor.mdx")],
  "9b388481": [() => import(/* webpackChunkName: "9b388481" */ "@site/docs/api/notification/schemas/schema-azure-service-bus-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-azure-service-bus-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-azure-service-bus-notification-type.mdx")],
  "9b553039": [() => import(/* webpackChunkName: "9b553039" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-a-2-b-data-for-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-a-2-b-data-for-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-a-2-b-data-for-portfolio-group.mdx")],
  "9b814ced": [() => import(/* webpackChunkName: "9b814ced" */ "@site/docs/api/lusid/schemas/schema-previous-fund-valuation-point-data.mdx"), "@site/docs/api/lusid/schemas/schema-previous-fund-valuation-point-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-previous-fund-valuation-point-data.mdx")],
  "9b998ebd": [() => import(/* webpackChunkName: "9b998ebd" */ "@site/docs/api/lusid/endpoints/data-types/operation-list-data-type-summaries.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-list-data-type-summaries.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-list-data-type-summaries.mdx")],
  "9bcd4336": [() => import(/* webpackChunkName: "9bcd4336" */ "@site/docs/api/access/schemas/schema-policy-templated-selector.mdx"), "@site/docs/api/access/schemas/schema-policy-templated-selector.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-templated-selector.mdx")],
  "9bce385e": [() => import(/* webpackChunkName: "9bce385e" */ "@site/docs/api/lusid/schemas/schema-discounting-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-discounting-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-discounting-dependency.mdx")],
  "9be1ba6e": [() => import(/* webpackChunkName: "9be1ba6e" */ "@site/docs/api/lusid/endpoints/entities/operation-get-property-definition-by-entity-unique-id.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-property-definition-by-entity-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-property-definition-by-entity-unique-id.mdx")],
  "9be4caf4": [() => import(/* webpackChunkName: "9be4caf4" */ "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-request-log.mdx"), "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-request-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-request-log.mdx")],
  "9c12d085": [() => import(/* webpackChunkName: "9c12d085" */ "@site/docs/api/identity/schemas/schema-current-user-response.mdx"), "@site/docs/api/identity/schemas/schema-current-user-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-current-user-response.mdx")],
  "9c16a429": [() => import(/* webpackChunkName: "9c16a429" */ "@site/docs/api/scheduler/schemas/schema-upload-image-instructions.mdx"), "@site/docs/api/scheduler/schemas/schema-upload-image-instructions.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-upload-image-instructions.mdx")],
  "9c1713fb": [() => import(/* webpackChunkName: "9c1713fb" */ "@site/docs/api/lusid/schemas/schema-custom-entity-entity.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-entity.mdx")],
  "9c37a871": [() => import(/* webpackChunkName: "9c37a871" */ "@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request.mdx")],
  "9c3a9349": [() => import(/* webpackChunkName: "9c3a9349" */ "@site/docs/api/lusid/schemas/schema-general-ledger-profile-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-general-ledger-profile-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-general-ledger-profile-mapping.mdx")],
  "9c7367b4": [() => import(/* webpackChunkName: "9c7367b4" */ "@site/docs/api/lusid/schemas/schema-upsert-custom-entities-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-custom-entities-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-custom-entities-response.mdx")],
  "9c86f1f2": [() => import(/* webpackChunkName: "9c86f1f2" */ "@site/docs/api/luminesce/schemas/schema-feedback-level.mdx"), "@site/docs/api/luminesce/schemas/schema-feedback-level.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-feedback-level.mdx")],
  "9c8ca373": [() => import(/* webpackChunkName: "9c8ca373" */ "@site/docs/api/lusid/endpoints/staged-modifications/operation-list-staged-modifications.mdx"), "@site/docs/api/lusid/endpoints/staged-modifications/operation-list-staged-modifications.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staged-modifications/operation-list-staged-modifications.mdx")],
  "9c958d7b": [() => import(/* webpackChunkName: "9c958d7b" */ "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-delete-api-key.mdx"), "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-delete-api-key.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-delete-api-key.mdx")],
  "9ca73178": [() => import(/* webpackChunkName: "9ca73178" */ "@site/docs/api/lusid/schemas/schema-output-transition.mdx"), "@site/docs/api/lusid/schemas/schema-output-transition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-output-transition.mdx")],
  "9cb2e360": [() => import(/* webpackChunkName: "9cb2e360" */ "@site/docs/api/horizon/schemas/schema-instance-details.mdx"), "@site/docs/api/horizon/schemas/schema-instance-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-instance-details.mdx")],
  "9cdc3d35": [() => import(/* webpackChunkName: "9cdc3d35" */ "@site/docs/api/lusid/schemas/schema-cancelled-order-result.mdx"), "@site/docs/api/lusid/schemas/schema-cancelled-order-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancelled-order-result.mdx")],
  "9cdf2452": [() => import(/* webpackChunkName: "9cdf2452" */ "@site/docs/api/configuration/schemas/schema-create-configuration-item.mdx"), "@site/docs/api/configuration/schemas/schema-create-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-create-configuration-item.mdx")],
  "9d1bf5b5": [() => import(/* webpackChunkName: "9d1bf5b5" */ "@site/docs/api/lusid/endpoints/staging-rule-set/operation-list-staging-rule-sets.mdx"), "@site/docs/api/lusid/endpoints/staging-rule-set/operation-list-staging-rule-sets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staging-rule-set/operation-list-staging-rule-sets.mdx")],
  "9d27147c": [() => import(/* webpackChunkName: "9d27147c" */ "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-get-tax-rule-set.mdx"), "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-get-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/tax-rule-sets/operation-get-tax-rule-set.mdx")],
  "9d3008c5": [() => import(/* webpackChunkName: "9d3008c5" */ "@site/docs/api/lusid/endpoints/staged-modifications/operation-list-requested-changes.mdx"), "@site/docs/api/lusid/endpoints/staged-modifications/operation-list-requested-changes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staged-modifications/operation-list-requested-changes.mdx")],
  "9d404f8d": [() => import(/* webpackChunkName: "9d404f8d" */ "@site/docs/api/web-app/schemas/schema-rebalance-rounding.mdx"), "@site/docs/api/web-app/schemas/schema-rebalance-rounding.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rebalance-rounding.mdx")],
  "9d6eea6f": [() => import(/* webpackChunkName: "9d6eea6f" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-custodian-accounts.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-custodian-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-custodian-accounts.mdx")],
  "9d8c71f9": [() => import(/* webpackChunkName: "9d8c71f9" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-account.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-account.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-account.mdx")],
  "9dcc4fd9": [() => import(/* webpackChunkName: "9dcc4fd9" */ "@site/docs/api/lusid/endpoints/order-management/operation-run-allocation-service.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-run-allocation-service.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-run-allocation-service.mdx")],
  "9de4bf03": [() => import(/* webpackChunkName: "9de4bf03" */ "@site/docs/api/lusid/schemas/schema-staging-rule-approval-criteria.mdx"), "@site/docs/api/lusid/schemas/schema-staging-rule-approval-criteria.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staging-rule-approval-criteria.mdx")],
  "9df76354": [() => import(/* webpackChunkName: "9df76354" */ "@site/docs/api/lusid/endpoints/abor/operation-list-abors.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-list-abors.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-list-abors.mdx")],
  "9dfb3380": [() => import(/* webpackChunkName: "9dfb3380" */ "@site/docs/excel/functions/Templates.mdx"), "@site/docs/excel/functions/Templates.mdx", require.resolveWeak("@site/docs/excel/functions/Templates.mdx")],
  "9e4a96cf": [() => import(/* webpackChunkName: "9e4a96cf" */ "@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-delete-derived-portfolio-details.mdx"), "@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-delete-derived-portfolio-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/derived-transaction-portfolios/operation-delete-derived-portfolio-details.mdx")],
  "9e539acf": [() => import(/* webpackChunkName: "9e539acf" */ "@site/docs/api/access/schemas/schema-for-spec.mdx"), "@site/docs/api/access/schemas/schema-for-spec.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-for-spec.mdx")],
  "9e5ac40c": [() => import(/* webpackChunkName: "9e5ac40c" */ "@site/docs/api/workflow/schemas/schema-task-field-definition.mdx"), "@site/docs/api/workflow/schemas/schema-task-field-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-field-definition.mdx")],
  "9ea22d2b": [() => import(/* webpackChunkName: "9ea22d2b" */ "@site/docs/api/lusid/schemas/schema-set-legal-entity-properties-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-legal-entity-properties-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-legal-entity-properties-request.mdx")],
  "9ea90f6f": [() => import(/* webpackChunkName: "9ea90f6f" */ "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-response.mdx"), "@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-response.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/vendor-logs/operation-get-vendor-response.mdx")],
  "9ed42991": [() => import(/* webpackChunkName: "9ed42991" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-comment.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-comment.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-comment.mdx")],
  "9ee3266c": [() => import(/* webpackChunkName: "9ee3266c" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-trade-ticket.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-trade-ticket.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-trade-ticket.mdx")],
  "9f05eee2": [() => import(/* webpackChunkName: "9f05eee2" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-corporate-action.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-corporate-action.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-corporate-action.mdx")],
  "9f08ed18": [() => import(/* webpackChunkName: "9f08ed18" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group-search-result.mdx")],
  "9f2c34a5": [() => import(/* webpackChunkName: "9f2c34a5" */ "@site/docs/api/lusid/schemas/schema-fx-option.mdx"), "@site/docs/api/lusid/schemas/schema-fx-option.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-option.mdx")],
  "9f370a6c": [() => import(/* webpackChunkName: "9f370a6c" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregated-returns.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregated-returns.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregated-returns.mdx")],
  "9f3cc9bf": [() => import(/* webpackChunkName: "9f3cc9bf" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-composite-breakdown.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-composite-breakdown.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-composite-breakdown.mdx")],
  "9f6ec4da": [() => import(/* webpackChunkName: "9f6ec4da" */ "@site/docs/api/drive/endpoints/search/operation-search.mdx"), "@site/docs/api/drive/endpoints/search/operation-search.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/search/operation-search.mdx")],
  "9f773a83": [() => import(/* webpackChunkName: "9f773a83" */ "@site/docs/api/notification/endpoints/subscriptions/operation-delete-subscription.mdx"), "@site/docs/api/notification/endpoints/subscriptions/operation-delete-subscription.mdx", require.resolveWeak("@site/docs/api/notification/endpoints/subscriptions/operation-delete-subscription.mdx")],
  "9f7bb03d": [() => import(/* webpackChunkName: "9f7bb03d" */ "@site/docs/api/lusid/schemas/schema-result-value-decimal.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-decimal.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-decimal.mdx")],
  "9f7db14f": [() => import(/* webpackChunkName: "9f7db14f" */ "@site/docs/api/web-app/schemas/schema-hubspot-response-property-version.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-response-property-version.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-response-property-version.mdx")],
  "9fa12371": [() => import(/* webpackChunkName: "9fa12371" */ "@site/docs/api/lusid/endpoints/reference-lists/operation-upsert-reference-list.mdx"), "@site/docs/api/lusid/endpoints/reference-lists/operation-upsert-reference-list.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-lists/operation-upsert-reference-list.mdx")],
  "9faa2ad9": [() => import(/* webpackChunkName: "9faa2ad9" */ "@site/docs/api/lusid/schemas/schema-compliance-step.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-step.mdx")],
  "9fd2f3a0": [() => import(/* webpackChunkName: "9fd2f3a0" */ "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-for-scope.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-for-scope.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-list-quotes-for-scope.mdx")],
  "9ffff386": [() => import(/* webpackChunkName: "9ffff386" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-update-amortisation-rule-set-details.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-update-amortisation-rule-set-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-update-amortisation-rule-set-details.mdx")],
  "a00eb5d9": [() => import(/* webpackChunkName: "a00eb5d9" */ "@site/docs/api/lusid/endpoints/abor/operation-close-period.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-close-period.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-close-period.mdx")],
  "a0198fef": [() => import(/* webpackChunkName: "a0198fef" */ "@site/docs/api/lusid/endpoints/instruments/operation-list-instrument-properties.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-list-instrument-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-list-instrument-properties.mdx")],
  "a04d7a66": [() => import(/* webpackChunkName: "a04d7a66" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-metadata.mdx")],
  "a0551829": [() => import(/* webpackChunkName: "a0551829" */ "@site/docs/api/lusid/schemas/schema-workspace-item-update-request.mdx"), "@site/docs/api/lusid/schemas/schema-workspace-item-update-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace-item-update-request.mdx")],
  "a05675a7": [() => import(/* webpackChunkName: "a05675a7" */ "@site/docs/api/scheduler/schemas/schema-image-summary.mdx"), "@site/docs/api/scheduler/schemas/schema-image-summary.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-image-summary.mdx")],
  "a062eda1": [() => import(/* webpackChunkName: "a062eda1" */ "@site/docs/api/access/endpoints/policy-templates/operation-get-policy-template.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-get-policy-template.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-get-policy-template.mdx")],
  "a068e23f": [() => import(/* webpackChunkName: "a068e23f" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-excel.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-excel.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-excel.mdx")],
  "a0812566": [() => import(/* webpackChunkName: "a0812566" */ "@site/docs/api/luminesce/schemas/schema-action-id.mdx"), "@site/docs/api/luminesce/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-action-id.mdx")],
  "a0a1c84c": [() => import(/* webpackChunkName: "a0a1c84c" */ "@site/docs/api/lusid/schemas/schema-create-derived-property-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-derived-property-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-derived-property-definition-request.mdx")],
  "a0a89242": [() => import(/* webpackChunkName: "a0a89242" */ "@site/docs/api/luminesce/schemas/schema-file-reader-builder-response.mdx"), "@site/docs/api/luminesce/schemas/schema-file-reader-builder-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-file-reader-builder-response.mdx")],
  "a0bca227": [() => import(/* webpackChunkName: "a0bca227" */ "@site/docs/api/lusid/endpoints/funds/operation-get-fee.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-get-fee.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-get-fee.mdx")],
  "a0e11222": [() => import(/* webpackChunkName: "a0e11222" */ "@site/docs/api/lusid/endpoints/relationship-definitions/operation-delete-relationship-definition.mdx"), "@site/docs/api/lusid/endpoints/relationship-definitions/operation-delete-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationship-definitions/operation-delete-relationship-definition.mdx")],
  "a10cd855": [() => import(/* webpackChunkName: "a10cd855" */ "@site/docs/api/lusid/schemas/schema-translate-trade-tickets-response.mdx"), "@site/docs/api/lusid/schemas/schema-translate-trade-tickets-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-trade-tickets-response.mdx")],
  "a1492062": [() => import(/* webpackChunkName: "a1492062" */ "@site/docs/excel/functions/Portfolio Properties.mdx"), "@site/docs/excel/functions/Portfolio Properties.mdx", require.resolveWeak("@site/docs/excel/functions/Portfolio Properties.mdx")],
  "a14b9203": [() => import(/* webpackChunkName: "a14b9203" */ "@site/docs/api/lusid/schemas/schema-string-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-string-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-string-list-compliance-parameter.mdx")],
  "a15f041b": [() => import(/* webpackChunkName: "a15f041b" */ "@site/docs/api/lusid/schemas/schema-flexible-loan.mdx"), "@site/docs/api/lusid/schemas/schema-flexible-loan.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-flexible-loan.mdx")],
  "a16a3fc7": [() => import(/* webpackChunkName: "a16a3fc7" */ "@site/docs/api/lusid/schemas/schema-custom-entity-field-definition.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-field-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-field-definition.mdx")],
  "a197c0e6": [() => import(/* webpackChunkName: "a197c0e6" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-property-definition-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-property-definition-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-property-definition-properties-response.mdx")],
  "a1d19df0": [() => import(/* webpackChunkName: "a1d19df0" */ "@site/docs/api/lusid/schemas/schema-list-aggregation-reconciliation.mdx"), "@site/docs/api/lusid/schemas/schema-list-aggregation-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-list-aggregation-reconciliation.mdx")],
  "a23bb19e": [() => import(/* webpackChunkName: "a23bb19e" */ "@site/docs/api/scheduler/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/scheduler/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-access-controlled-action.mdx")],
  "a25393ce": [() => import(/* webpackChunkName: "a25393ce" */ "@site/docs/api/lusid/schemas/schema-applicable-instrument-event.mdx"), "@site/docs/api/lusid/schemas/schema-applicable-instrument-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-applicable-instrument-event.mdx")],
  "a28795f9": [() => import(/* webpackChunkName: "a28795f9" */ "@site/docs/api/horizon/schemas/schema-integration-instance.mdx"), "@site/docs/api/horizon/schemas/schema-integration-instance.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-instance.mdx")],
  "a29eba28": [() => import(/* webpackChunkName: "a29eba28" */ "@site/docs/api/workflow/schemas/schema-action-definition-response.mdx"), "@site/docs/api/workflow/schemas/schema-action-definition-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-action-definition-response.mdx")],
  "a2c5b7e4": [() => import(/* webpackChunkName: "a2c5b7e4" */ "@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-template-specifications.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-template-specifications.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-get-fee-template-specifications.mdx")],
  "a2c7da9b": [() => import(/* webpackChunkName: "a2c7da9b" */ "@site/docs/api/lusid/schemas/schema-upsert-recipe-composer-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-recipe-composer-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-recipe-composer-request.mdx")],
  "a2d158d4": [() => import(/* webpackChunkName: "a2d158d4" */ "@site/docs/api/insights/schemas/schema-resource-list-of-audit-process-summary.mdx"), "@site/docs/api/insights/schemas/schema-resource-list-of-audit-process-summary.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource-list-of-audit-process-summary.mdx")],
  "a2e1aff3": [() => import(/* webpackChunkName: "a2e1aff3" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entities.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-upsert-custom-entities.mdx")],
  "a307a38e": [() => import(/* webpackChunkName: "a307a38e" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-json.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-json.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-json.mdx")],
  "a314fe71": [() => import(/* webpackChunkName: "a314fe71" */ "@site/docs/api/insights/intro.md"), "@site/docs/api/insights/intro.md", require.resolveWeak("@site/docs/api/insights/intro.md")],
  "a325089b": [() => import(/* webpackChunkName: "a325089b" */ "@site/docs/api/lusid/schemas/schema-instrument-capabilities.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-capabilities.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-capabilities.mdx")],
  "a336d980": [() => import(/* webpackChunkName: "a336d980" */ "@site/docs/api/luminesce/schemas/schema-auto-detect-type.mdx"), "@site/docs/api/luminesce/schemas/schema-auto-detect-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-auto-detect-type.mdx")],
  "a361b4a2": [() => import(/* webpackChunkName: "a361b4a2" */ "@site/docs/api/lusid/schemas/schema-equity-curve-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-equity-curve-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-curve-dependency.mdx")],
  "a3885379": [() => import(/* webpackChunkName: "a3885379" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-comparison-rule-operand.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-comparison-rule-operand.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-aggregate-comparison-rule-operand.mdx")],
  "a3cb6905": [() => import(/* webpackChunkName: "a3cb6905" */ "@site/docs/api/lusid/schemas/schema-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-parameter.mdx")],
  "a3f751f1": [() => import(/* webpackChunkName: "a3f751f1" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-order-instructions-by-id.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-order-instructions-by-id.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-get-order-instructions-by-id.mdx")],
  "a3f7e130": [() => import(/* webpackChunkName: "a3f7e130" */ "@site/docs/api/access/schemas/schema-policy-collection-creation-request.mdx"), "@site/docs/api/access/schemas/schema-policy-collection-creation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-collection-creation-request.mdx")],
  "a4056a61": [() => import(/* webpackChunkName: "a4056a61" */ "@site/docs/api/lusid/endpoints/order-instructions/operation-upsert-order-instructions.mdx"), "@site/docs/api/lusid/endpoints/order-instructions/operation-upsert-order-instructions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-instructions/operation-upsert-order-instructions.mdx")],
  "a4285d82": [() => import(/* webpackChunkName: "a4285d82" */ "@site/docs/api/workflow/schemas/schema-resource-list-of-change-item.mdx"), "@site/docs/api/workflow/schemas/schema-resource-list-of-change-item.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-resource-list-of-change-item.mdx")],
  "a4299a85": [() => import(/* webpackChunkName: "a4299a85" */ "@site/docs/api/lusid/endpoints/order-instructions/operation-delete-order-instruction.mdx"), "@site/docs/api/lusid/endpoints/order-instructions/operation-delete-order-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-instructions/operation-delete-order-instruction.mdx")],
  "a4403f96": [() => import(/* webpackChunkName: "a4403f96" */ "@site/docs/api/workflow/schemas/schema-event-handler-mapping.mdx"), "@site/docs/api/workflow/schemas/schema-event-handler-mapping.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-event-handler-mapping.mdx")],
  "a45469fc": [() => import(/* webpackChunkName: "a45469fc" */ "@site/docs/api/lusid/schemas/schema-set-legal-entity-identifiers-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-legal-entity-identifiers-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-legal-entity-identifiers-request.mdx")],
  "a461e389": [() => import(/* webpackChunkName: "a461e389" */ "@site/docs/api/identity/schemas/schema-password-policy-response-lockout.mdx"), "@site/docs/api/identity/schemas/schema-password-policy-response-lockout.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-password-policy-response-lockout.mdx")],
  "a462ac21": [() => import(/* webpackChunkName: "a462ac21" */ "@site/docs/api/scheduler/endpoints/schedules/operation-update-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-update-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-update-schedule.mdx")],
  "a4849cd9": [() => import(/* webpackChunkName: "a4849cd9" */ "@site/docs/api/web-app/schemas/schema-portfolio-type.mdx"), "@site/docs/api/web-app/schemas/schema-portfolio-type.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-portfolio-type.mdx")],
  "a49d3e8a": [() => import(/* webpackChunkName: "a49d3e8a" */ "@site/docs/api/lusid/schemas/schema-group-calculation-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-group-calculation-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-calculation-compliance-parameter.mdx")],
  "a4ac8954": [() => import(/* webpackChunkName: "a4ac8954" */ "@site/docs/api/notification/schemas/schema-event-field-definition.mdx"), "@site/docs/api/notification/schemas/schema-event-field-definition.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-event-field-definition.mdx")],
  "a4b6d4b4": [() => import(/* webpackChunkName: "a4b6d4b4" */ "@site/docs/api/luminesce/schemas/schema-certificate-state.mdx"), "@site/docs/api/luminesce/schemas/schema-certificate-state.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-certificate-state.mdx")],
  "a4d222c4": [() => import(/* webpackChunkName: "a4d222c4" */ "@site/docs/api/horizon/schemas/schema-resource-id.mdx"), "@site/docs/api/horizon/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-resource-id.mdx")],
  "a4ecbf33": [() => import(/* webpackChunkName: "a4ecbf33" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-delete-legacy-compliance-rule.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-delete-legacy-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-delete-legacy-compliance-rule.mdx")],
  "a4f57af9": [() => import(/* webpackChunkName: "a4f57af9" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement.mdx")],
  "a4f938cb": [() => import(/* webpackChunkName: "a4f938cb" */ "@site/docs/api/insights/schemas/schema-link.mdx"), "@site/docs/api/insights/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-link.mdx")],
  "a53e995c": [() => import(/* webpackChunkName: "a53e995c" */ "@site/docs/api/lusid/schemas/schema-diary-entry-request.mdx"), "@site/docs/api/lusid/schemas/schema-diary-entry-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-diary-entry-request.mdx")],
  "a5537673": [() => import(/* webpackChunkName: "a5537673" */ "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placements.mdx"), "@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-graph/operation-list-order-graph-placements.mdx")],
  "a56f1ea5": [() => import(/* webpackChunkName: "a56f1ea5" */ "@site/docs/api/horizon/schemas/schema-integration-run-integration.mdx"), "@site/docs/api/horizon/schemas/schema-integration-run-integration.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-run-integration.mdx")],
  "a57d56f3": [() => import(/* webpackChunkName: "a57d56f3" */ "@site/docs/api/lusid/schemas/schema-reverse-stock-split-event.mdx"), "@site/docs/api/lusid/schemas/schema-reverse-stock-split-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reverse-stock-split-event.mdx")],
  "a58a65fb": [() => import(/* webpackChunkName: "a58a65fb" */ "@site/docs/api/lusid/schemas/schema-eligibility-calculation.mdx"), "@site/docs/api/lusid/schemas/schema-eligibility-calculation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-eligibility-calculation.mdx")],
  "a59a5304": [() => import(/* webpackChunkName: "a59a5304" */ "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-start-queries.mdx"), "@site/docs/api/luminesce/endpoints/multi-query-execution/operation-start-queries.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/multi-query-execution/operation-start-queries.mdx")],
  "a5a0f3e7": [() => import(/* webpackChunkName: "a5a0f3e7" */ "@site/docs/api/lusid/schemas/schema-election-specification.mdx"), "@site/docs/api/lusid/schemas/schema-election-specification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-election-specification.mdx")],
  "a5c4a595": [() => import(/* webpackChunkName: "a5c4a595" */ "@site/docs/api/lusid/schemas/schema-portfolio-id-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-id-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-id-list-compliance-parameter.mdx")],
  "a5c7d28f": [() => import(/* webpackChunkName: "a5c7d28f" */ "@site/docs/api/lusid/endpoints/abor-configuration/operation-create-abor-configuration.mdx"), "@site/docs/api/lusid/endpoints/abor-configuration/operation-create-abor-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor-configuration/operation-create-abor-configuration.mdx")],
  "a5f75dfb": [() => import(/* webpackChunkName: "a5f75dfb" */ "@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-list-block-jobs.mdx"), "@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-list-block-jobs.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/order-blotter/operation-order-blotter-list-block-jobs.mdx")],
  "a60ca886": [() => import(/* webpackChunkName: "a60ca886" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-run-info.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-run-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-compliance-run-info.mdx")],
  "a6148f16": [() => import(/* webpackChunkName: "a6148f16" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fund.mdx")],
  "a6233c8b": [() => import(/* webpackChunkName: "a6233c8b" */ "@site/docs/api/lusid/schemas/schema-contract-for-difference.mdx"), "@site/docs/api/lusid/schemas/schema-contract-for-difference.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-contract-for-difference.mdx")],
  "a62e1454": [() => import(/* webpackChunkName: "a62e1454" */ "@site/docs/api/lusid/schemas/schema-valuation-point-overview.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-point-overview.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-point-overview.mdx")],
  "a6468341": [() => import(/* webpackChunkName: "a6468341" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-xml.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-xml.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-xml.mdx")],
  "a673c5c8": [() => import(/* webpackChunkName: "a673c5c8" */ "@site/docs/api/workflow/endpoints/tasks/operation-get-task.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-get-task.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-get-task.mdx")],
  "a67d8719": [() => import(/* webpackChunkName: "a67d8719" */ "@site/docs/api/lusid/schemas/schema-upsert-instrument-event-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-instrument-event-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-instrument-event-request.mdx")],
  "a6820008": [() => import(/* webpackChunkName: "a6820008" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-transaction-synopsis.mdx")],
  "a6b06392": [() => import(/* webpackChunkName: "a6b06392" */ "@site/docs/api/lusid/schemas/schema-move-orders-to-different-blocks-request.mdx"), "@site/docs/api/lusid/schemas/schema-move-orders-to-different-blocks-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-move-orders-to-different-blocks-request.mdx")],
  "a6e52f73": [() => import(/* webpackChunkName: "a6e52f73" */ "@site/docs/api/lusid/endpoints/persons/operation-delete-person-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-delete-person-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-delete-person-access-metadata.mdx")],
  "a708ef7b": [() => import(/* webpackChunkName: "a708ef7b" */ "@site/docs/api/workflow/endpoints/workers/operation-update-worker.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-update-worker.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-update-worker.mdx")],
  "a709e126": [() => import(/* webpackChunkName: "a709e126" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-abor.mdx")],
  "a70d40f7": [() => import(/* webpackChunkName: "a70d40f7" */ "@site/docs/api/notification/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/notification/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-lusid-problem-details.mdx")],
  "a71071b6": [() => import(/* webpackChunkName: "a71071b6" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-package.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-package.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-package.mdx")],
  "a7187920": [() => import(/* webpackChunkName: "a7187920" */ "@site/docs/api/luminesce/schemas/schema-data-type.mdx"), "@site/docs/api/luminesce/schemas/schema-data-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-data-type.mdx")],
  "a729945f": [() => import(/* webpackChunkName: "a729945f" */ "@site/docs/api/lusid/schemas/schema-future-expiry-event.mdx"), "@site/docs/api/lusid/schemas/schema-future-expiry-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-future-expiry-event.mdx")],
  "a7502cf7": [() => import(/* webpackChunkName: "a7502cf7" */ "@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule.mdx"), "@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule.mdx")],
  "a76f9e00": [() => import(/* webpackChunkName: "a76f9e00" */ "@site/docs/api/web-app/schemas/schema-global-search-instrument-response.mdx"), "@site/docs/api/web-app/schemas/schema-global-search-instrument-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-global-search-instrument-response.mdx")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a7c06eed": [() => import(/* webpackChunkName: "a7c06eed" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-property-time-series.mdx")],
  "a7e6724d": [() => import(/* webpackChunkName: "a7e6724d" */ "@site/docs/api/luminesce/schemas/schema-error-highlight-response.mdx"), "@site/docs/api/luminesce/schemas/schema-error-highlight-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-error-highlight-response.mdx")],
  "a80d8768": [() => import(/* webpackChunkName: "a80d8768" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-rule.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-rule.mdx")],
  "a80ff148": [() => import(/* webpackChunkName: "a80ff148" */ "@site/docs/api/scheduler/endpoints/jobs/operation-delete-job.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-delete-job.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-delete-job.mdx")],
  "a81a7b7f": [() => import(/* webpackChunkName: "a81a7b7f" */ "@site/docs/api/lusid/schemas/schema-option-exercise-election.mdx"), "@site/docs/api/lusid/schemas/schema-option-exercise-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-option-exercise-election.mdx")],
  "a81d9d4f": [() => import(/* webpackChunkName: "a81d9d4f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-applicable-instrument-event.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-applicable-instrument-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-applicable-instrument-event.mdx")],
  "a826b084": [() => import(/* webpackChunkName: "a826b084" */ "@site/docs/api/lusid/schemas/schema-composite-dispersion-response.mdx"), "@site/docs/api/lusid/schemas/schema-composite-dispersion-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-composite-dispersion-response.mdx")],
  "a83e95c0": [() => import(/* webpackChunkName: "a83e95c0" */ "@site/docs/api/lusid/schemas/schema-resource-id.mdx"), "@site/docs/api/lusid/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-id.mdx")],
  "a85417da": [() => import(/* webpackChunkName: "a85417da" */ "@site/docs/api/web-app/schemas/schema-tolerance-rule-with-value.mdx"), "@site/docs/api/web-app/schemas/schema-tolerance-rule-with-value.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tolerance-rule-with-value.mdx")],
  "a86408fb": [() => import(/* webpackChunkName: "a86408fb" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-details.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-cleardown-module-details.mdx")],
  "a87b9974": [() => import(/* webpackChunkName: "a87b9974" */ "@site/docs/api/lusid/schemas/schema-file-response.mdx"), "@site/docs/api/lusid/schemas/schema-file-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-file-response.mdx")],
  "a87fd72b": [() => import(/* webpackChunkName: "a87fd72b" */ "@site/docs/api/lusid/schemas/schema-staged-modification-decision.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modification-decision.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modification-decision.mdx")],
  "a8a4869b": [() => import(/* webpackChunkName: "a8a4869b" */ "@site/docs/api/workflow/schemas/schema-create-task-definition-request.mdx"), "@site/docs/api/workflow/schemas/schema-create-task-definition-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-task-definition-request.mdx")],
  "a8b41216": [() => import(/* webpackChunkName: "a8b41216" */ "@site/docs/api/workflow/endpoints/tasks/operation-create-task.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-create-task.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-create-task.mdx")],
  "a8f3c73d": [() => import(/* webpackChunkName: "a8f3c73d" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-update-rebalance-configuration.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-update-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-update-rebalance-configuration.mdx")],
  "a90f2a5e": [() => import(/* webpackChunkName: "a90f2a5e" */ "@site/docs/api/lusid/schemas/schema-portfolio-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-reconciliation-request.mdx")],
  "a93abe8f": [() => import(/* webpackChunkName: "a93abe8f" */ "@site/docs/api/lusid/schemas/schema-instrument-event-holder.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event-holder.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event-holder.mdx")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "a9524d82": [() => import(/* webpackChunkName: "a9524d82" */ "@site/docs/api/insights/schemas/schema-audit-process-summary.mdx"), "@site/docs/api/insights/schemas/schema-audit-process-summary.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-audit-process-summary.mdx")],
  "a9a9ee97": [() => import(/* webpackChunkName: "a9a9ee97" */ "@site/docs/api/lusid/endpoints/order-management/operation-place-blocks.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-place-blocks.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-place-blocks.mdx")],
  "a9bc9358": [() => import(/* webpackChunkName: "a9bc9358" */ "@site/docs/api/lusid/schemas/schema-fee-rule-upsert-response.mdx"), "@site/docs/api/lusid/schemas/schema-fee-rule-upsert-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-rule-upsert-response.mdx")],
  "a9d36716": [() => import(/* webpackChunkName: "a9d36716" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-order.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-order.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-order.mdx")],
  "a9ff2130": [() => import(/* webpackChunkName: "a9ff2130" */ "@site/docs/api/lusid/schemas/schema-change-interval.mdx"), "@site/docs/api/lusid/schemas/schema-change-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-change-interval.mdx")],
  "aa10049b": [() => import(/* webpackChunkName: "aa10049b" */ "@site/docs/api/scheduler/endpoints/schedules/operation-delete-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-delete-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-delete-schedule.mdx")],
  "aa16ad3f": [() => import(/* webpackChunkName: "aa16ad3f" */ "@site/docs/api/lusid/schemas/schema-fee-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-fee-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-type-request.mdx")],
  "aa27cb29": [() => import(/* webpackChunkName: "aa27cb29" */ "@site/docs/api/lusid/schemas/schema-leg-definition.mdx"), "@site/docs/api/lusid/schemas/schema-leg-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-leg-definition.mdx")],
  "aa2d3c11": [() => import(/* webpackChunkName: "aa2d3c11" */ "@site/docs/api/notification/schemas/schema-update-subscription.mdx"), "@site/docs/api/notification/schemas/schema-update-subscription.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-update-subscription.mdx")],
  "aa4be0ad": [() => import(/* webpackChunkName: "aa4be0ad" */ "@site/docs/api/workflow/endpoints/workers/operation-get-worker.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-get-worker.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-get-worker.mdx")],
  "aa4fc109": [() => import(/* webpackChunkName: "aa4fc109" */ "@site/docs/api/lusid/schemas/schema-corporate-action-transition.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action-transition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action-transition.mdx")],
  "aa80bbf5": [() => import(/* webpackChunkName: "aa80bbf5" */ "@site/docs/api/lusid/endpoints/conventions/operation-upsert-index-convention.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-upsert-index-convention.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-upsert-index-convention.mdx")],
  "aa895b94": [() => import(/* webpackChunkName: "aa895b94" */ "@site/docs/api/lusid/schemas/schema-reference-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-reference-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-instrument.mdx")],
  "aaa0e56f": [() => import(/* webpackChunkName: "aaa0e56f" */ "@site/docs/api/lusid/schemas/schema-mapped-string.mdx"), "@site/docs/api/lusid/schemas/schema-mapped-string.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mapped-string.mdx")],
  "ab0330b8": [() => import(/* webpackChunkName: "ab0330b8" */ "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-update-custom-entity-definition.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-update-custom-entity-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-update-custom-entity-definition.mdx")],
  "ab0910ec": [() => import(/* webpackChunkName: "ab0910ec" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-get-all-legal-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-get-all-legal-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-get-all-legal-entity-access-metadata.mdx")],
  "ab0b551a": [() => import(/* webpackChunkName: "ab0b551a" */ "@site/docs/api/workflow/schemas/schema-event-handler.mdx"), "@site/docs/api/workflow/schemas/schema-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-event-handler.mdx")],
  "ab1c891c": [() => import(/* webpackChunkName: "ab1c891c" */ "@site/docs/api/lusid/schemas/schema-structured-result-data-id.mdx"), "@site/docs/api/lusid/schemas/schema-structured-result-data-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-structured-result-data-id.mdx")],
  "ab2fbf06": [() => import(/* webpackChunkName: "ab2fbf06" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-file-read-design-to-sql.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-file-read-design-to-sql.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-file-read-design-to-sql.mdx")],
  "ab7e074f": [() => import(/* webpackChunkName: "ab7e074f" */ "@site/docs/api/access/endpoints/policies/operation-update-policy-collection.mdx"), "@site/docs/api/access/endpoints/policies/operation-update-policy-collection.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-update-policy-collection.mdx")],
  "abb106c9": [() => import(/* webpackChunkName: "abb106c9" */ "@site/docs/api/insights/schemas/schema-vendor-log.mdx"), "@site/docs/api/insights/schemas/schema-vendor-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-vendor-log.mdx")],
  "abb482b4": [() => import(/* webpackChunkName: "abb482b4" */ "@site/docs/api/lusid/schemas/schema-order-flow-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-order-flow-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-flow-configuration.mdx")],
  "abb61e54": [() => import(/* webpackChunkName: "abb61e54" */ "@site/docs/api/workflow/schemas/schema-action-details.mdx"), "@site/docs/api/workflow/schemas/schema-action-details.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-action-details.mdx")],
  "abdb4fbb": [() => import(/* webpackChunkName: "abdb4fbb" */ "@site/docs/api/lusid/schemas/schema-specific-holding-pricing-info.mdx"), "@site/docs/api/lusid/schemas/schema-specific-holding-pricing-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-specific-holding-pricing-info.mdx")],
  "abe8bf01": [() => import(/* webpackChunkName: "abe8bf01" */ "@site/docs/api/lusid/schemas/schema-create-custom-entity-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-custom-entity-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-custom-entity-type-request.mdx")],
  "ac2e9298": [() => import(/* webpackChunkName: "ac2e9298" */ "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-history.mdx"), "@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-history.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/historically-executed-queries/operation-get-history.mdx")],
  "ac2fb6d7": [() => import(/* webpackChunkName: "ac2fb6d7" */ "@site/docs/api/scheduler/schemas/schema-start-job-request.mdx"), "@site/docs/api/scheduler/schemas/schema-start-job-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-start-job-request.mdx")],
  "ac304080": [() => import(/* webpackChunkName: "ac304080" */ "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-get-personalisations.mdx"), "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-get-personalisations.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-get-personalisations.mdx")],
  "ac6810fe": [() => import(/* webpackChunkName: "ac6810fe" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-delete-group-reconciliation-definition.mdx")],
  "ac84262c": [() => import(/* webpackChunkName: "ac84262c" */ "@site/docs/api/lusid/endpoints/instruments/operation-update-instrument-identifier.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-update-instrument-identifier.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-update-instrument-identifier.mdx")],
  "acb1d3f2": [() => import(/* webpackChunkName: "acb1d3f2" */ "@site/docs/api/access/endpoints/policy-templates/operation-generate-policy-from-template.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-generate-policy-from-template.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-generate-policy-from-template.mdx")],
  "ad351f2b": [() => import(/* webpackChunkName: "ad351f2b" */ "@site/docs/api/web-app/endpoints/valuations/operation-valuations-list-valuation-manifest.mdx"), "@site/docs/api/web-app/endpoints/valuations/operation-valuations-list-valuation-manifest.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/valuations/operation-valuations-list-valuation-manifest.mdx")],
  "ad6a4d41": [() => import(/* webpackChunkName: "ad6a4d41" */ "@site/docs/api/lusid/endpoints/instruments/operation-list-instruments.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-list-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-list-instruments.mdx")],
  "ad78ac11": [() => import(/* webpackChunkName: "ad78ac11" */ "@site/docs/api/access/schemas/schema-policy-update-request.mdx"), "@site/docs/api/access/schemas/schema-policy-update-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-update-request.mdx")],
  "ad7cad58": [() => import(/* webpackChunkName: "ad7cad58" */ "@site/docs/api/lusid/endpoints/address-key-definition/operation-list-address-key-definitions.mdx"), "@site/docs/api/lusid/endpoints/address-key-definition/operation-list-address-key-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/address-key-definition/operation-list-address-key-definitions.mdx")],
  "ad9fc78a": [() => import(/* webpackChunkName: "ad9fc78a" */ "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-create-cut-label-definition.mdx"), "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-create-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/cut-label-definitions/operation-create-cut-label-definition.mdx")],
  "ada34624": [() => import(/* webpackChunkName: "ada34624" */ "@site/docs/api/web-app/schemas/schema-tolerance-rules.mdx"), "@site/docs/api/web-app/schemas/schema-tolerance-rules.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tolerance-rules.mdx")],
  "adbb3fce": [() => import(/* webpackChunkName: "adbb3fce" */ "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-access-evaluation-log.mdx"), "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-access-evaluation-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-access-evaluation-log.mdx")],
  "adbb6473": [() => import(/* webpackChunkName: "adbb6473" */ "@site/docs/api/luminesce/schemas/schema-filter-term-design.mdx"), "@site/docs/api/luminesce/schemas/schema-filter-term-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-filter-term-design.mdx")],
  "adc626aa": [() => import(/* webpackChunkName: "adc626aa" */ "@site/docs/api/lusid/endpoints/scopes/operation-list-entity-scopes.mdx"), "@site/docs/api/lusid/endpoints/scopes/operation-list-entity-scopes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scopes/operation-list-entity-scopes.mdx")],
  "adc9a1bc": [() => import(/* webpackChunkName: "adc9a1bc" */ "@site/docs/api/notification/schemas/schema-create-notification-request.mdx"), "@site/docs/api/notification/schemas/schema-create-notification-request.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-create-notification-request.mdx")],
  "ae03f19e": [() => import(/* webpackChunkName: "ae03f19e" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-set.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-get-configuration-set.mdx")],
  "ae0a5911": [() => import(/* webpackChunkName: "ae0a5911" */ "@site/docs/api/lusid/endpoints/counterparties/operation-upsert-credit-support-annex.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-upsert-credit-support-annex.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-upsert-credit-support-annex.mdx")],
  "ae2529ca": [() => import(/* webpackChunkName: "ae2529ca" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-generic.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-generic.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-generic.mdx")],
  "ae30af26": [() => import(/* webpackChunkName: "ae30af26" */ "@site/docs/api/workflow/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/workflow/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "ae328d93": [() => import(/* webpackChunkName: "ae328d93" */ "@site/docs/api/lusid/endpoints/orders/operation-list-orders.mdx"), "@site/docs/api/lusid/endpoints/orders/operation-list-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/orders/operation-list-orders.mdx")],
  "ae9f97e1": [() => import(/* webpackChunkName: "ae9f97e1" */ "@site/docs/api/workflow/endpoints/workers/operation-get-worker-result.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-get-worker-result.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-get-worker-result.mdx")],
  "aeb4caf3": [() => import(/* webpackChunkName: "aeb4caf3" */ "@site/docs/api/lusid/schemas/schema-create-compliance-template-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-compliance-template-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-compliance-template-request.mdx")],
  "aebe62d6": [() => import(/* webpackChunkName: "aebe62d6" */ "@site/docs/api/lusid/endpoints/funds/operation-get-valuation-point-data.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-get-valuation-point-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-get-valuation-point-data.mdx")],
  "aec79b47": [() => import(/* webpackChunkName: "aec79b47" */ "@site/docs/api/access/endpoints/user-roles/operation-add-policy-to-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-add-policy-to-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-add-policy-to-user-role.mdx")],
  "aed0ffad": [() => import(/* webpackChunkName: "aed0ffad" */ "@site/docs/api/lusid/schemas/schema-fx-rate-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-fx-rate-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-rate-schedule.mdx")],
  "aed2f170": [() => import(/* webpackChunkName: "aed2f170" */ "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-update-tax-rule-set.mdx"), "@site/docs/api/lusid/endpoints/tax-rule-sets/operation-update-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/tax-rule-sets/operation-update-tax-rule-set.mdx")],
  "aedf4bf0": [() => import(/* webpackChunkName: "aedf4bf0" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-instance-id.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-instance-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-instance-id.mdx")],
  "af12a2cb": [() => import(/* webpackChunkName: "af12a2cb" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reconciliation.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-reconciliation.mdx")],
  "af17a766": [() => import(/* webpackChunkName: "af17a766" */ "@site/docs/api/identity/endpoints/roles/operation-delete-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-delete-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-delete-role.mdx")],
  "af3d4890": [() => import(/* webpackChunkName: "af3d4890" */ "@site/docs/api/notification/schemas/schema-notification-type-response.mdx"), "@site/docs/api/notification/schemas/schema-notification-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-notification-type-response.mdx")],
  "af4a8d1d": [() => import(/* webpackChunkName: "af4a8d1d" */ "@site/docs/api/lusid/schemas/schema-portfolio-trade-ticket.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-trade-ticket.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-trade-ticket.mdx")],
  "af54d0ac": [() => import(/* webpackChunkName: "af54d0ac" */ "@site/docs/api/access/schemas/schema-resource-list-of-user-role-response.mdx"), "@site/docs/api/access/schemas/schema-resource-list-of-user-role-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-list-of-user-role-response.mdx")],
  "af69e996": [() => import(/* webpackChunkName: "af69e996" */ "@site/docs/api/lusid/endpoints/packages/operation-delete-package.mdx"), "@site/docs/api/lusid/endpoints/packages/operation-delete-package.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/packages/operation-delete-package.mdx")],
  "af788472": [() => import(/* webpackChunkName: "af788472" */ "@site/docs/api/lusid/schemas/schema-transaction-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-request.mdx")],
  "af791157": [() => import(/* webpackChunkName: "af791157" */ "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-create-api-key.mdx"), "@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-create-api-key.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/personal-authentication-tokens/operation-create-api-key.mdx")],
  "aff02e01": [() => import(/* webpackChunkName: "aff02e01" */ "@site/docs/api/notification/schemas/schema-manual-event-request.mdx"), "@site/docs/api/notification/schemas/schema-manual-event-request.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-manual-event-request.mdx")],
  "b013994d": [() => import(/* webpackChunkName: "b013994d" */ "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-list-cut-label-definitions.mdx"), "@site/docs/api/lusid/endpoints/cut-label-definitions/operation-list-cut-label-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/cut-label-definitions/operation-list-cut-label-definitions.mdx")],
  "b0326e58": [() => import(/* webpackChunkName: "b0326e58" */ "@site/docs/api/lusid/endpoints/calendars/operation-get-dates.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-get-dates.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-get-dates.mdx")],
  "b05b1ddc": [() => import(/* webpackChunkName: "b05b1ddc" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-result.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-result.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-result.mdx")],
  "b0669a9f": [() => import(/* webpackChunkName: "b0669a9f" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-bucketed-cash-flows.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-bucketed-cash-flows.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-bucketed-cash-flows.mdx")],
  "b06d4a8d": [() => import(/* webpackChunkName: "b06d4a8d" */ "@site/docs/api/lusid/schemas/schema-complex-market-data-id.mdx"), "@site/docs/api/lusid/schemas/schema-complex-market-data-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complex-market-data-id.mdx")],
  "b079f074": [() => import(/* webpackChunkName: "b079f074" */ "@site/docs/api/drive/schemas/schema-paged-resource-list-of-storage-object.mdx"), "@site/docs/api/drive/schemas/schema-paged-resource-list-of-storage-object.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-paged-resource-list-of-storage-object.mdx")],
  "b08752c0": [() => import(/* webpackChunkName: "b08752c0" */ "@site/docs/api/lusid/endpoints/counterparties/operation-get-credit-support-annex.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-get-credit-support-annex.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-get-credit-support-annex.mdx")],
  "b08890e9": [() => import(/* webpackChunkName: "b08890e9" */ "@site/docs/api/access/endpoints/roles/operation-update-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-update-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-update-role.mdx")],
  "b08c05da": [() => import(/* webpackChunkName: "b08c05da" */ "@site/docs/api/lusid/schemas/schema-index-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-index-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-index-model-options.mdx")],
  "b090050d": [() => import(/* webpackChunkName: "b090050d" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-portfolio-to-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-portfolio-to-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-add-portfolio-to-group.mdx")],
  "b0b210dc": [() => import(/* webpackChunkName: "b0b210dc" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-quote.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-quote.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-quote.mdx")],
  "b0f0b87c": [() => import(/* webpackChunkName: "b0f0b87c" */ "@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data-request.mdx")],
  "b1305ae2": [() => import(/* webpackChunkName: "b1305ae2" */ "@site/docs/api/web-app/schemas/schema-create-domain-request.mdx"), "@site/docs/api/web-app/schemas/schema-create-domain-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-create-domain-request.mdx")],
  "b1369676": [() => import(/* webpackChunkName: "b1369676" */ "@site/docs/api/lusid/schemas/schema-fund-amount.mdx"), "@site/docs/api/lusid/schemas/schema-fund-amount.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-amount.mdx")],
  "b13d845f": [() => import(/* webpackChunkName: "b13d845f" */ "@site/docs/api/lusid/endpoints/workspace/operation-create-shared-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-create-shared-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-create-shared-item.mdx")],
  "b180690d": [() => import(/* webpackChunkName: "b180690d" */ "@site/docs/api/web-app/schemas/schema-rebalance-configuration-details.mdx"), "@site/docs/api/web-app/schemas/schema-rebalance-configuration-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rebalance-configuration-details.mdx")],
  "b1902aa2": [() => import(/* webpackChunkName: "b1902aa2" */ "@site/docs/api/notification/schemas/schema-link.mdx"), "@site/docs/api/notification/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-link.mdx")],
  "b1a15301": [() => import(/* webpackChunkName: "b1a15301" */ "@site/docs/api/workflow/schemas/schema-read-only-states.mdx"), "@site/docs/api/workflow/schemas/schema-read-only-states.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-read-only-states.mdx")],
  "b1be7041": [() => import(/* webpackChunkName: "b1be7041" */ "@site/docs/api/lusid/endpoints/conventions/operation-delete-cds-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-delete-cds-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-delete-cds-flow-conventions.mdx")],
  "b1d791f0": [() => import(/* webpackChunkName: "b1d791f0" */ "@site/docs/api/lusid/schemas/schema-fx-forward-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-curve-data.mdx")],
  "b1ec93bb": [() => import(/* webpackChunkName: "b1ec93bb" */ "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-instrument-event-instructions.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-upsert-instrument-event-instructions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-upsert-instrument-event-instructions.mdx")],
  "b201724c": [() => import(/* webpackChunkName: "b201724c" */ "@site/docs/api/workflow/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/workflow/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-access-controlled-resource.mdx")],
  "b205a2ce": [() => import(/* webpackChunkName: "b205a2ce" */ "@site/docs/api/lusid/schemas/schema-tax-rule.mdx"), "@site/docs/api/lusid/schemas/schema-tax-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-tax-rule.mdx")],
  "b245173d": [() => import(/* webpackChunkName: "b245173d" */ "@site/docs/api/lusid/endpoints/schemas/operation-get-entity-schema.mdx"), "@site/docs/api/lusid/endpoints/schemas/operation-get-entity-schema.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/schemas/operation-get-entity-schema.mdx")],
  "b26f82a1": [() => import(/* webpackChunkName: "b26f82a1" */ "@site/docs/api/lusid/schemas/schema-upsert-custom-entity-access-metadata-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-custom-entity-access-metadata-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-custom-entity-access-metadata-request.mdx")],
  "b2a1d6f0": [() => import(/* webpackChunkName: "b2a1d6f0" */ "@site/docs/api/lusid/schemas/schema-diary-entry.mdx"), "@site/docs/api/lusid/schemas/schema-diary-entry.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-diary-entry.mdx")],
  "b2b02458": [() => import(/* webpackChunkName: "b2b02458" */ "@site/docs/api/access/schemas/schema-role-update-request.mdx"), "@site/docs/api/access/schemas/schema-role-update-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-role-update-request.mdx")],
  "b2bd1750": [() => import(/* webpackChunkName: "b2bd1750" */ "@site/docs/api/web-app/schemas/schema-hubspot-support-article.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-support-article.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-support-article.mdx")],
  "b2d81c5f": [() => import(/* webpackChunkName: "b2d81c5f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-constituents-adjustment-header.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-constituents-adjustment-header.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-constituents-adjustment-header.mdx")],
  "b31c2d13": [() => import(/* webpackChunkName: "b31c2d13" */ "@site/docs/api/lusid/schemas/schema-upsert-corporate-action-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-corporate-action-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-corporate-action-request.mdx")],
  "b32c082d": [() => import(/* webpackChunkName: "b32c082d" */ "@site/docs/api/lusid/schemas/schema-version-summary-dto.mdx"), "@site/docs/api/lusid/schemas/schema-version-summary-dto.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-version-summary-dto.mdx")],
  "b32c27f7": [() => import(/* webpackChunkName: "b32c27f7" */ "@site/docs/api/horizon/schemas/schema-enrichment-response.mdx"), "@site/docs/api/horizon/schemas/schema-enrichment-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-enrichment-response.mdx")],
  "b334c6a9": [() => import(/* webpackChunkName: "b334c6a9" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-tickets.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-tickets.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-tickets.mdx")],
  "b34fef93": [() => import(/* webpackChunkName: "b34fef93" */ "@site/docs/api/lusid/schemas/schema-exotic-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-exotic-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-exotic-instrument.mdx")],
  "b35329d8": [() => import(/* webpackChunkName: "b35329d8" */ "@site/docs/api/lusid/schemas/schema-life-cycle-event-value.mdx"), "@site/docs/api/lusid/schemas/schema-life-cycle-event-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-life-cycle-event-value.mdx")],
  "b3584d6c": [() => import(/* webpackChunkName: "b3584d6c" */ "@site/docs/api/lusid/schemas/schema-weighted-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-weighted-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-weighted-instrument.mdx")],
  "b36114b1": [() => import(/* webpackChunkName: "b36114b1" */ "@site/docs/api/workflow/endpoints/tasks/operation-delete-task.mdx"), "@site/docs/api/workflow/endpoints/tasks/operation-delete-task.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/tasks/operation-delete-task.mdx")],
  "b37172ba": [() => import(/* webpackChunkName: "b37172ba" */ "@site/docs/api/lusid/schemas/schema-delete-custodian-accounts-response.mdx"), "@site/docs/api/lusid/schemas/schema-delete-custodian-accounts-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-custodian-accounts-response.mdx")],
  "b375e049": [() => import(/* webpackChunkName: "b375e049" */ "@site/docs/api/lusid/endpoints/abor/operation-get-abor.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-get-abor.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-get-abor.mdx")],
  "b3abed03": [() => import(/* webpackChunkName: "b3abed03" */ "@site/docs/api/lusid/schemas/schema-transaction-price-and-type.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-price-and-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-price-and-type.mdx")],
  "b3e3314e": [() => import(/* webpackChunkName: "b3e3314e" */ "@site/docs/api/lusid/schemas/schema-order-instruction-request.mdx"), "@site/docs/api/lusid/schemas/schema-order-instruction-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-instruction-request.mdx")],
  "b3fc31ba": [() => import(/* webpackChunkName: "b3fc31ba" */ "@site/docs/api/lusid/endpoints/abor-configuration/operation-upsert-abor-configuration-properties.mdx"), "@site/docs/api/lusid/endpoints/abor-configuration/operation-upsert-abor-configuration-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor-configuration/operation-upsert-abor-configuration-properties.mdx")],
  "b4184761": [() => import(/* webpackChunkName: "b4184761" */ "@site/docs/api/access/schemas/schema-as-at-predicate-contract.mdx"), "@site/docs/api/access/schemas/schema-as-at-predicate-contract.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-as-at-predicate-contract.mdx")],
  "b423d5cc": [() => import(/* webpackChunkName: "b423d5cc" */ "@site/docs/api/configuration/schemas/schema-update-configuration-set.mdx"), "@site/docs/api/configuration/schemas/schema-update-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-update-configuration-set.mdx")],
  "b459e95b": [() => import(/* webpackChunkName: "b459e95b" */ "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-identifier-types.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-identifier-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-get-instrument-identifier-types.mdx")],
  "b47dde1c": [() => import(/* webpackChunkName: "b47dde1c" */ "@site/docs/api/web-app/schemas/schema-shared-personalisation.mdx"), "@site/docs/api/web-app/schemas/schema-shared-personalisation.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-shared-personalisation.mdx")],
  "b481f0f2": [() => import(/* webpackChunkName: "b481f0f2" */ "@site/docs/excel/functions/Portfolio Group Properties.mdx"), "@site/docs/excel/functions/Portfolio Group Properties.mdx", require.resolveWeak("@site/docs/excel/functions/Portfolio Group Properties.mdx")],
  "b49cb5d9": [() => import(/* webpackChunkName: "b49cb5d9" */ "@site/docs/api/lusid/endpoints/application-metadata/operation-get-excel-addin.mdx"), "@site/docs/api/lusid/endpoints/application-metadata/operation-get-excel-addin.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/application-metadata/operation-get-excel-addin.mdx")],
  "b4f560d6": [() => import(/* webpackChunkName: "b4f560d6" */ "@site/docs/api/luminesce/schemas/schema-order-by-term-design.mdx"), "@site/docs/api/luminesce/schemas/schema-order-by-term-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-order-by-term-design.mdx")],
  "b5066fc0": [() => import(/* webpackChunkName: "b5066fc0" */ "@site/docs/api/lusid/schemas/schema-is-business-day-response.mdx"), "@site/docs/api/lusid/schemas/schema-is-business-day-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-is-business-day-response.mdx")],
  "b51b5d38": [() => import(/* webpackChunkName: "b51b5d38" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliation-mappings.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliation-mappings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliation-mappings.mdx")],
  "b51cb7b4": [() => import(/* webpackChunkName: "b51cb7b4" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-schedule-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-schedule-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-schedule-definition.mdx")],
  "b52bce96": [() => import(/* webpackChunkName: "b52bce96" */ "@site/docs/api/lusid/endpoints/persons/operation-set-person-properties.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-set-person-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-set-person-properties.mdx")],
  "b54a5c12": [() => import(/* webpackChunkName: "b54a5c12" */ "@site/docs/api/access/schemas/schema-template-metadata.mdx"), "@site/docs/api/access/schemas/schema-template-metadata.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-template-metadata.mdx")],
  "b5511bf6": [() => import(/* webpackChunkName: "b5511bf6" */ "@site/docs/api/lusid/schemas/schema-compliance-run-info.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-run-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-run-info.mdx")],
  "b5603d0b": [() => import(/* webpackChunkName: "b5603d0b" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-update-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-update-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-update-portfolio-group.mdx")],
  "b562fa25": [() => import(/* webpackChunkName: "b562fa25" */ "@site/docs/api/lusid/endpoints/staging-rule-set/operation-delete-staging-rule-set.mdx"), "@site/docs/api/lusid/endpoints/staging-rule-set/operation-delete-staging-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staging-rule-set/operation-delete-staging-rule-set.mdx")],
  "b57dd078": [() => import(/* webpackChunkName: "b57dd078" */ "@site/docs/api/access/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/access/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-access-controlled-resource.mdx")],
  "b57e696a": [() => import(/* webpackChunkName: "b57e696a" */ "@site/docs/api/lusid/schemas/schema-optionality-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-optionality-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-optionality-schedule.mdx")],
  "b5830473": [() => import(/* webpackChunkName: "b5830473" */ "@site/docs/api/lusid/schemas/schema-vendor-model-rule.mdx"), "@site/docs/api/lusid/schemas/schema-vendor-model-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-vendor-model-rule.mdx")],
  "b59d2416": [() => import(/* webpackChunkName: "b59d2416" */ "@site/docs/api/lusid/endpoints/staged-modifications/operation-add-decision.mdx"), "@site/docs/api/lusid/endpoints/staged-modifications/operation-add-decision.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/staged-modifications/operation-add-decision.mdx")],
  "b5d198e0": [() => import(/* webpackChunkName: "b5d198e0" */ "@site/docs/api/lusid/schemas/schema-fund-share-class.mdx"), "@site/docs/api/lusid/schemas/schema-fund-share-class.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-share-class.mdx")],
  "b5d35eb8": [() => import(/* webpackChunkName: "b5d35eb8" */ "@site/docs/api/lusid/endpoints/quotes/operation-upsert-quotes.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-upsert-quotes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-upsert-quotes.mdx")],
  "b64c4d86": [() => import(/* webpackChunkName: "b64c4d86" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-comparison-rule-operand.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-core-comparison-rule-operand.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-core-comparison-rule-operand.mdx")],
  "b6554596": [() => import(/* webpackChunkName: "b6554596" */ "@site/docs/api/web-app/schemas/schema-rounding-rules.mdx"), "@site/docs/api/web-app/schemas/schema-rounding-rules.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rounding-rules.mdx")],
  "b6562955": [() => import(/* webpackChunkName: "b6562955" */ "@site/docs/api/lusid/schemas/schema-account.mdx"), "@site/docs/api/lusid/schemas/schema-account.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-account.mdx")],
  "b65a5d6c": [() => import(/* webpackChunkName: "b65a5d6c" */ "@site/docs/api/lusid/schemas/schema-aggregated-returns-dispersion-request.mdx"), "@site/docs/api/lusid/schemas/schema-aggregated-returns-dispersion-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregated-returns-dispersion-request.mdx")],
  "b65bf27e": [() => import(/* webpackChunkName: "b65bf27e" */ "@site/docs/api/horizon/endpoints/process-history/operation-create-update-event.mdx"), "@site/docs/api/horizon/endpoints/process-history/operation-create-update-event.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/process-history/operation-create-update-event.mdx")],
  "b69afb7f": [() => import(/* webpackChunkName: "b69afb7f" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-create-trade-tickets.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-create-trade-tickets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-batch-create-trade-tickets.mdx")],
  "b6a766a4": [() => import(/* webpackChunkName: "b6a766a4" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-adjust-holdings.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-adjust-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-adjust-holdings.mdx")],
  "b6b731a3": [() => import(/* webpackChunkName: "b6b731a3" */ "@site/docs/api/lusid/endpoints/persons/operation-delete-person.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-delete-person.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-delete-person.mdx")],
  "b6cacd50": [() => import(/* webpackChunkName: "b6cacd50" */ "@site/docs/api/lusid/schemas/schema-bespoke-cash-flows-leg.mdx"), "@site/docs/api/lusid/schemas/schema-bespoke-cash-flows-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bespoke-cash-flows-leg.mdx")],
  "b6cee871": [() => import(/* webpackChunkName: "b6cee871" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-cleardown-module-response.mdx")],
  "b6ecc83b": [() => import(/* webpackChunkName: "b6ecc83b" */ "@site/docs/api/lusid/endpoints/data-types/operation-get-units-from-data-type.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-get-units-from-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-get-units-from-data-type.mdx")],
  "b6feb6ef": [() => import(/* webpackChunkName: "b6feb6ef" */ "@site/docs/api/web-app/endpoints/reports/operation-get-dashboards.mdx"), "@site/docs/api/web-app/endpoints/reports/operation-get-dashboards.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/reports/operation-get-dashboards.mdx")],
  "b71acd4e": [() => import(/* webpackChunkName: "b71acd4e" */ "@site/docs/api/lusid/schemas/schema-exercise.mdx"), "@site/docs/api/lusid/schemas/schema-exercise.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-exercise.mdx")],
  "b730aed3": [() => import(/* webpackChunkName: "b730aed3" */ "@site/docs/api/web-app/schemas/schema-ticket.mdx"), "@site/docs/api/web-app/schemas/schema-ticket.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-ticket.mdx")],
  "b749a53b": [() => import(/* webpackChunkName: "b749a53b" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-transaction-types.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-transaction-types.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-transaction-types.mdx")],
  "b7772d26": [() => import(/* webpackChunkName: "b7772d26" */ "@site/docs/api/lusid/endpoints/counterparties/operation-get-counterparty-agreement.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-get-counterparty-agreement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-get-counterparty-agreement.mdx")],
  "b79dd070": [() => import(/* webpackChunkName: "b79dd070" */ "@site/docs/api/lusid/schemas/schema-fee-transaction-template-specification.mdx"), "@site/docs/api/lusid/schemas/schema-fee-transaction-template-specification.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-transaction-template-specification.mdx")],
  "b7a222b2": [() => import(/* webpackChunkName: "b7a222b2" */ "@site/docs/api/lusid/schemas/schema-unsettled-cash.mdx"), "@site/docs/api/lusid/schemas/schema-unsettled-cash.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-unsettled-cash.mdx")],
  "b802f47a": [() => import(/* webpackChunkName: "b802f47a" */ "@site/docs/api/web-app/schemas/schema-tickets-response.mdx"), "@site/docs/api/web-app/schemas/schema-tickets-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-tickets-response.mdx")],
  "b8087d34": [() => import(/* webpackChunkName: "b8087d34" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transactions.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-transactions.mdx")],
  "b821620d": [() => import(/* webpackChunkName: "b821620d" */ "@site/docs/api/lusid/schemas/schema-amount.mdx"), "@site/docs/api/lusid/schemas/schema-amount.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-amount.mdx")],
  "b86d3524": [() => import(/* webpackChunkName: "b86d3524" */ "@site/docs/api/access/schemas/schema-attached-policy-definition-response.mdx"), "@site/docs/api/access/schemas/schema-attached-policy-definition-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-attached-policy-definition-response.mdx")],
  "b87ecaa6": [() => import(/* webpackChunkName: "b87ecaa6" */ "@site/docs/api/luminesce/schemas/schema-access-controlled-resource-identifier-part-schema-attribute.mdx"), "@site/docs/api/luminesce/schemas/schema-access-controlled-resource-identifier-part-schema-attribute.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-access-controlled-resource-identifier-part-schema-attribute.mdx")],
  "b87f4520": [() => import(/* webpackChunkName: "b87f4520" */ "@site/docs/api/lusid/schemas/schema-fx-forward-pips-curve-data.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-pips-curve-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-pips-curve-data.mdx")],
  "b88b69f9": [() => import(/* webpackChunkName: "b88b69f9" */ "@site/docs/api/lusid/schemas/schema-market-options.mdx"), "@site/docs/api/lusid/schemas/schema-market-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-options.mdx")],
  "b8b487ea": [() => import(/* webpackChunkName: "b8b487ea" */ "@site/docs/api/lusid/schemas/schema-script-map-reference.mdx"), "@site/docs/api/lusid/schemas/schema-script-map-reference.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-script-map-reference.mdx")],
  "b8c98884": [() => import(/* webpackChunkName: "b8c98884" */ "@site/docs/api/lusid/schemas/schema-exercise-event.mdx"), "@site/docs/api/lusid/schemas/schema-exercise-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-exercise-event.mdx")],
  "b8d26828": [() => import(/* webpackChunkName: "b8d26828" */ "@site/docs/api/lusid/endpoints/instruments/operation-delete-instruments.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-delete-instruments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-delete-instruments.mdx")],
  "b90aff8e": [() => import(/* webpackChunkName: "b90aff8e" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-search-result.mdx")],
  "b9119f8f": [() => import(/* webpackChunkName: "b9119f8f" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-execution-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-execution-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-execution-detail.mdx")],
  "b92a2e14": [() => import(/* webpackChunkName: "b92a2e14" */ "@site/docs/api/access/endpoints/user-roles/operation-update-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-update-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-update-user-role.mdx")],
  "b953fa7f": [() => import(/* webpackChunkName: "b953fa7f" */ "@site/docs/api/horizon/endpoints/instrument/operation-vendors.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-vendors.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-vendors.mdx")],
  "b9699812": [() => import(/* webpackChunkName: "b9699812" */ "@site/docs/api/lusid/schemas/schema-stream.mdx"), "@site/docs/api/lusid/schemas/schema-stream.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-stream.mdx")],
  "b9a1cf41": [() => import(/* webpackChunkName: "b9a1cf41" */ "@site/docs/api/identity/schemas/schema-user-schema-property.mdx"), "@site/docs/api/identity/schemas/schema-user-schema-property.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-user-schema-property.mdx")],
  "b9d25363": [() => import(/* webpackChunkName: "b9d25363" */ "@site/docs/api/lusid/endpoints/reference-portfolio/operation-list-constituents-adjustments.mdx"), "@site/docs/api/lusid/endpoints/reference-portfolio/operation-list-constituents-adjustments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-portfolio/operation-list-constituents-adjustments.mdx")],
  "b9d7be1f": [() => import(/* webpackChunkName: "b9d7be1f" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-block.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-block.mdx")],
  "b9db41ff": [() => import(/* webpackChunkName: "b9db41ff" */ "@site/docs/api/lusid/schemas/schema-reconciliation-rule.mdx"), "@site/docs/api/lusid/schemas/schema-reconciliation-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reconciliation-rule.mdx")],
  "b9e95df1": [() => import(/* webpackChunkName: "b9e95df1" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-chart-of-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-chart-of-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-chart-of-accounts.mdx")],
  "b9ea3c3e": [() => import(/* webpackChunkName: "b9ea3c3e" */ "@site/docs/api/lusid/schemas/schema-aggregation-measure-failure-detail.mdx"), "@site/docs/api/lusid/schemas/schema-aggregation-measure-failure-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregation-measure-failure-detail.mdx")],
  "ba106d5f": [() => import(/* webpackChunkName: "ba106d5f" */ "@site/docs/api/identity/endpoints/users/operation-update-user-schema.mdx"), "@site/docs/api/identity/endpoints/users/operation-update-user-schema.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-update-user-schema.mdx")],
  "ba115c93": [() => import(/* webpackChunkName: "ba115c93" */ "@site/docs/api/luminesce/schemas/schema-field-type.mdx"), "@site/docs/api/luminesce/schemas/schema-field-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-field-type.mdx")],
  "ba1ff31b": [() => import(/* webpackChunkName: "ba1ff31b" */ "@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type.mdx"), "@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-amazon-sqs-notification-type.mdx")],
  "ba23a2c9": [() => import(/* webpackChunkName: "ba23a2c9" */ "@site/docs/api/lusid/schemas/schema-compliance-rule.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule.mdx")],
  "ba258447": [() => import(/* webpackChunkName: "ba258447" */ "@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data.mdx")],
  "ba35f7e1": [() => import(/* webpackChunkName: "ba35f7e1" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense-error.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense-error.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense-error.mdx")],
  "ba3b38b8": [() => import(/* webpackChunkName: "ba3b38b8" */ "@site/docs/api/lusid/schemas/schema-corporate-action-transition-request.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action-transition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action-transition-request.mdx")],
  "ba670ad2": [() => import(/* webpackChunkName: "ba670ad2" */ "@site/docs/api/lusid/schemas/schema-dependency-source-filter.mdx"), "@site/docs/api/lusid/schemas/schema-dependency-source-filter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dependency-source-filter.mdx")],
  "ba6a61d8": [() => import(/* webpackChunkName: "ba6a61d8" */ "@site/docs/api/lusid/schemas/schema-staged-modifications-entity-hrefs.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modifications-entity-hrefs.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modifications-entity-hrefs.mdx")],
  "ba7cb586": [() => import(/* webpackChunkName: "ba7cb586" */ "@site/docs/api/drive/endpoints/files/operation-create-file.mdx"), "@site/docs/api/drive/endpoints/files/operation-create-file.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-create-file.mdx")],
  "ba7e82d4": [() => import(/* webpackChunkName: "ba7e82d4" */ "@site/docs/api/lusid/schemas/schema-bool-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-bool-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bool-compliance-parameter.mdx")],
  "ba807f3d": [() => import(/* webpackChunkName: "ba807f3d" */ "@site/docs/api/scheduler/endpoints/jobs/operation-run-job.mdx"), "@site/docs/api/scheduler/endpoints/jobs/operation-run-job.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/jobs/operation-run-job.mdx")],
  "bac0420b": [() => import(/* webpackChunkName: "bac0420b" */ "@site/docs/api/lusid/schemas/schema-transaction-property-mapping-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-property-mapping-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-property-mapping-request.mdx")],
  "bae14fe7": [() => import(/* webpackChunkName: "bae14fe7" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-generate-access-token.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-generate-access-token.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-generate-access-token.mdx")],
  "bb0c390d": [() => import(/* webpackChunkName: "bb0c390d" */ "@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-delete-shared-workspace.mdx")],
  "bb10c359": [() => import(/* webpackChunkName: "bb10c359" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-identifiers.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-identifiers.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-delete-legal-entity-identifiers.mdx")],
  "bb2b961b": [() => import(/* webpackChunkName: "bb2b961b" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-run-info.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-run-info.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-list-legacy-compliance-run-info.mdx")],
  "bb3e0669": [() => import(/* webpackChunkName: "bb3e0669" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-image-summary.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-image-summary.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-image-summary.mdx")],
  "bb4e95a1": [() => import(/* webpackChunkName: "bb4e95a1" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-relationship-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-relationship-definition.mdx")],
  "bb50f740": [() => import(/* webpackChunkName: "bb50f740" */ "@site/docs/api/drive/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/drive/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-id-selector-definition.mdx")],
  "bb652c5b": [() => import(/* webpackChunkName: "bb652c5b" */ "@site/docs/api/insights/schemas/schema-action-id.mdx"), "@site/docs/api/insights/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-action-id.mdx")],
  "bb94a3d5": [() => import(/* webpackChunkName: "bb94a3d5" */ "@site/docs/api/lusid/schemas/schema-mbs-coupon-event.mdx"), "@site/docs/api/lusid/schemas/schema-mbs-coupon-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mbs-coupon-event.mdx")],
  "bb9983ee": [() => import(/* webpackChunkName: "bb9983ee" */ "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-item.mdx"), "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-item.mdx")],
  "bbbe5dc1": [() => import(/* webpackChunkName: "bbbe5dc1" */ "@site/docs/api/lusid/endpoints/packages/operation-list-packages.mdx"), "@site/docs/api/lusid/endpoints/packages/operation-list-packages.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/packages/operation-list-packages.mdx")],
  "bbc91b79": [() => import(/* webpackChunkName: "bbc91b79" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-movements.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-movements.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-movements.mdx")],
  "bbd0925e": [() => import(/* webpackChunkName: "bbd0925e" */ "@site/docs/excel/functions/Properties.mdx"), "@site/docs/excel/functions/Properties.mdx", require.resolveWeak("@site/docs/excel/functions/Properties.mdx")],
  "bbdee307": [() => import(/* webpackChunkName: "bbdee307" */ "@site/docs/api/lusid/endpoints/relation-definitions/operation-create-relation-definition.mdx"), "@site/docs/api/lusid/endpoints/relation-definitions/operation-create-relation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relation-definitions/operation-create-relation-definition.mdx")],
  "bbe0fb2e": [() => import(/* webpackChunkName: "bbe0fb2e" */ "@site/docs/api/lusid/schemas/schema-create-relation-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-relation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-relation-request.mdx")],
  "bbf78381": [() => import(/* webpackChunkName: "bbf78381" */ "@site/docs/api/lusid/schemas/schema-result-value-life-cycle-event-value.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-life-cycle-event-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-life-cycle-event-value.mdx")],
  "bc1628f7": [() => import(/* webpackChunkName: "bc1628f7" */ "@site/docs/api/lusid/schemas/schema-create-transaction-portfolio-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-transaction-portfolio-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-transaction-portfolio-request.mdx")],
  "bc3ca628": [() => import(/* webpackChunkName: "bc3ca628" */ "@site/docs/api/access/schemas/schema-evaluation-result.mdx"), "@site/docs/api/access/schemas/schema-evaluation-result.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-evaluation-result.mdx")],
  "bc4cfb6d": [() => import(/* webpackChunkName: "bc4cfb6d" */ "@site/docs/api/lusid/schemas/schema-property-definition-entity.mdx"), "@site/docs/api/lusid/schemas/schema-property-definition-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-definition-entity.mdx")],
  "bc5aa839": [() => import(/* webpackChunkName: "bc5aa839" */ "@site/docs/api/lusid/schemas/schema-allocation.mdx"), "@site/docs/api/lusid/schemas/schema-allocation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-allocation.mdx")],
  "bc866e9d": [() => import(/* webpackChunkName: "bc866e9d" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-statement.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-statement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-statement.mdx")],
  "bca22aba": [() => import(/* webpackChunkName: "bca22aba" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-aggregation-query.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-aggregation-query.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-aggregation-query.mdx")],
  "bca6f0d7": [() => import(/* webpackChunkName: "bca6f0d7" */ "@site/docs/api/web-app/schemas/schema-cash-equitisation-details.mdx"), "@site/docs/api/web-app/schemas/schema-cash-equitisation-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-cash-equitisation-details.mdx")],
  "bca845c1": [() => import(/* webpackChunkName: "bca845c1" */ "@site/docs/api/configuration/schemas/schema-update-configuration-item.mdx"), "@site/docs/api/configuration/schemas/schema-update-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-update-configuration-item.mdx")],
  "bcb9e53a": [() => import(/* webpackChunkName: "bcb9e53a" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-participation.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-participation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-participation.mdx")],
  "bcce5b45": [() => import(/* webpackChunkName: "bcce5b45" */ "@site/docs/api/lusid/endpoints/orders/operation-get-order.mdx"), "@site/docs/api/lusid/endpoints/orders/operation-get-order.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/orders/operation-get-order.mdx")],
  "bce35545": [() => import(/* webpackChunkName: "bce35545" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-set.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-set.mdx")],
  "bcf08045": [() => import(/* webpackChunkName: "bcf08045" */ "@site/docs/api/lusid/endpoints/instrument-event-types/operation-update-transaction-template.mdx"), "@site/docs/api/lusid/endpoints/instrument-event-types/operation-update-transaction-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-event-types/operation-update-transaction-template.mdx")],
  "bcf44863": [() => import(/* webpackChunkName: "bcf44863" */ "@site/docs/api/lusid/schemas/schema-instrument-event.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event.mdx")],
  "bd07a033": [() => import(/* webpackChunkName: "bd07a033" */ "@site/docs/api/scheduler/endpoints/schedules/operation-run-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-run-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-run-schedule.mdx")],
  "bd1fed03": [() => import(/* webpackChunkName: "bd1fed03" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-holdings-adjustments.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-holdings-adjustments.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-list-holdings-adjustments.mdx")],
  "bd24e51a": [() => import(/* webpackChunkName: "bd24e51a" */ "@site/docs/api/scheduler/endpoints/images/operation-list-images.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-list-images.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-list-images.mdx")],
  "bd35d2f8": [() => import(/* webpackChunkName: "bd35d2f8" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-rule-response.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-rule-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-rule-response.mdx")],
  "bd8e3388": [() => import(/* webpackChunkName: "bd8e3388" */ "@site/docs/api/lusid/schemas/schema-upsert-cds-flow-conventions-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-cds-flow-conventions-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-cds-flow-conventions-request.mdx")],
  "bda391a9": [() => import(/* webpackChunkName: "bda391a9" */ "@site/docs/api/insights/endpoints/auditing/operation-list-entries.mdx"), "@site/docs/api/insights/endpoints/auditing/operation-list-entries.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/auditing/operation-list-entries.mdx")],
  "bdb5c7ee": [() => import(/* webpackChunkName: "bdb5c7ee" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-tax-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-tax-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-tax-rule-set.mdx")],
  "bdb66621": [() => import(/* webpackChunkName: "bdb66621" */ "@site/docs/api/access/endpoints/user-roles/operation-delete-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-delete-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-delete-user-role.mdx")],
  "bdbd8bf6": [() => import(/* webpackChunkName: "bdbd8bf6" */ "@site/docs/api/identity/endpoints/users/operation-find-users-by-id.mdx"), "@site/docs/api/identity/endpoints/users/operation-find-users-by-id.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-find-users-by-id.mdx")],
  "bdece197": [() => import(/* webpackChunkName: "bdece197" */ "@site/docs/api/lusid/schemas/schema-trade-ticket.mdx"), "@site/docs/api/lusid/schemas/schema-trade-ticket.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-trade-ticket.mdx")],
  "bdf50586": [() => import(/* webpackChunkName: "bdf50586" */ "@site/docs/api/lusid/schemas/schema-floating-leg.mdx"), "@site/docs/api/lusid/schemas/schema-floating-leg.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-floating-leg.mdx")],
  "bdf9de6f": [() => import(/* webpackChunkName: "bdf9de6f" */ "@site/docs/api/scheduler/schemas/schema-job-history.mdx"), "@site/docs/api/scheduler/schemas/schema-job-history.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-job-history.mdx")],
  "be41e955": [() => import(/* webpackChunkName: "be41e955" */ "@site/docs/api/lusid/schemas/schema-fx-forwards-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forwards-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forwards-dependency.mdx")],
  "be53a9f3": [() => import(/* webpackChunkName: "be53a9f3" */ "@site/docs/api/horizon/endpoints/instrument/operation-create-instrument.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-create-instrument.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-create-instrument.mdx")],
  "be606aa5": [() => import(/* webpackChunkName: "be606aa5" */ "@site/docs/api/access/schemas/schema-policy-collection-response.mdx"), "@site/docs/api/access/schemas/schema-policy-collection-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-collection-response.mdx")],
  "be774abf": [() => import(/* webpackChunkName: "be774abf" */ "@site/docs/api/lusid/endpoints/instrument-events/operation-query-instrument-events.mdx"), "@site/docs/api/lusid/endpoints/instrument-events/operation-query-instrument-events.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instrument-events/operation-query-instrument-events.mdx")],
  "be9454ad": [() => import(/* webpackChunkName: "be9454ad" */ "@site/docs/api/lusid/schemas/schema-fee-type.mdx"), "@site/docs/api/lusid/schemas/schema-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-type.mdx")],
  "be9d84bc": [() => import(/* webpackChunkName: "be9d84bc" */ "@site/docs/api/lusid/endpoints/counterparties/operation-list-credit-support-annexes.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-list-credit-support-annexes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-list-credit-support-annexes.mdx")],
  "be9eb8d3": [() => import(/* webpackChunkName: "be9eb8d3" */ "@site/docs/api/lusid/endpoints/data-types/operation-update-data-type.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-update-data-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-update-data-type.mdx")],
  "bea0abdb": [() => import(/* webpackChunkName: "bea0abdb" */ "@site/docs/api/identity/endpoints/roles/operation-add-user-to-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-add-user-to-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-add-user-to-role.mdx")],
  "beadb847": [() => import(/* webpackChunkName: "beadb847" */ "@site/docs/api/identity/endpoints/roles/operation-list-users-in-role.mdx"), "@site/docs/api/identity/endpoints/roles/operation-list-users-in-role.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/roles/operation-list-users-in-role.mdx")],
  "bebf008f": [() => import(/* webpackChunkName: "bebf008f" */ "@site/docs/api/lusid/endpoints/compliance/operation-run-compliance-preview.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-run-compliance-preview.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-run-compliance-preview.mdx")],
  "becc07c3": [() => import(/* webpackChunkName: "becc07c3" */ "@site/docs/api/lusid/schemas/schema-unitisation-data.mdx"), "@site/docs/api/lusid/schemas/schema-unitisation-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-unitisation-data.mdx")],
  "bed6c0b3": [() => import(/* webpackChunkName: "bed6c0b3" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-posting-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-posting-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-posting-module.mdx")],
  "beee476e": [() => import(/* webpackChunkName: "beee476e" */ "@site/docs/api/lusid/endpoints/packages/operation-get-package.mdx"), "@site/docs/api/lusid/endpoints/packages/operation-get-package.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/packages/operation-get-package.mdx")],
  "befed1a1": [() => import(/* webpackChunkName: "befed1a1" */ "@site/docs/api/lusid/endpoints/calendars/operation-create-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-create-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-create-calendar.mdx")],
  "bf28bb6a": [() => import(/* webpackChunkName: "bf28bb6a" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-general-ledger-profiles.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-general-ledger-profiles.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-general-ledger-profiles.mdx")],
  "bf542f9d": [() => import(/* webpackChunkName: "bf542f9d" */ "@site/docs/excel/functions/Portfolio Groups.mdx"), "@site/docs/excel/functions/Portfolio Groups.mdx", require.resolveWeak("@site/docs/excel/functions/Portfolio Groups.mdx")],
  "bf79d32c": [() => import(/* webpackChunkName: "bf79d32c" */ "@site/docs/api/web-app/schemas/schema-global-search-transaction-parameters.mdx"), "@site/docs/api/web-app/schemas/schema-global-search-transaction-parameters.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-global-search-transaction-parameters.mdx")],
  "bf8e53f8": [() => import(/* webpackChunkName: "bf8e53f8" */ "@site/docs/api/lusid/endpoints/relationship-definitions/operation-update-relationship-definition.mdx"), "@site/docs/api/lusid/endpoints/relationship-definitions/operation-update-relationship-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationship-definitions/operation-update-relationship-definition.mdx")],
  "bf94b24e": [() => import(/* webpackChunkName: "bf94b24e" */ "@site/docs/api/lusid/schemas/schema-fund-request.mdx"), "@site/docs/api/lusid/schemas/schema-fund-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-request.mdx")],
  "bfb8a2f3": [() => import(/* webpackChunkName: "bfb8a2f3" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-flow.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-flow.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-portfolio-cash-flow.mdx")],
  "bfbc56e2": [() => import(/* webpackChunkName: "bfbc56e2" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-result.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-group-reconciliation-comparison-result.mdx")],
  "bfc2cdc3": [() => import(/* webpackChunkName: "bfc2cdc3" */ "@site/docs/api/drive/endpoints/folders/operation-get-root-folder.mdx"), "@site/docs/api/drive/endpoints/folders/operation-get-root-folder.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/folders/operation-get-root-folder.mdx")],
  "bfc4347f": [() => import(/* webpackChunkName: "bfc4347f" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-allocation.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-allocation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-allocation.mdx")],
  "bfca26b6": [() => import(/* webpackChunkName: "bfca26b6" */ "@site/docs/api/lusid/endpoints/calendars/operation-delete-date-from-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-delete-date-from-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-delete-date-from-calendar.mdx")],
  "bfe87888": [() => import(/* webpackChunkName: "bfe87888" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-xml.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-xml.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-xml.mdx")],
  "bff1f42b": [() => import(/* webpackChunkName: "bff1f42b" */ "@site/docs/api/identity/endpoints/identity-provider/operation-add-scim.mdx"), "@site/docs/api/identity/endpoints/identity-provider/operation-add-scim.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/identity-provider/operation-add-scim.mdx")],
  "c00a8bdf": [() => import(/* webpackChunkName: "c00a8bdf" */ "@site/docs/api/luminesce/schemas/schema-link.mdx"), "@site/docs/api/luminesce/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-link.mdx")],
  "c0272f45": [() => import(/* webpackChunkName: "c0272f45" */ "@site/docs/api/identity/schemas/schema-support-access-expiry.mdx"), "@site/docs/api/identity/schemas/schema-support-access-expiry.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-access-expiry.mdx")],
  "c03fab41": [() => import(/* webpackChunkName: "c03fab41" */ "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios-for-scope.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios-for-scope.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-list-portfolios-for-scope.mdx")],
  "c04608e1": [() => import(/* webpackChunkName: "c04608e1" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-property-definition.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-property-definition.mdx")],
  "c07762ad": [() => import(/* webpackChunkName: "c07762ad" */ "@site/docs/api/lusid/schemas/schema-posting-module-rule.mdx"), "@site/docs/api/lusid/schemas/schema-posting-module-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-posting-module-rule.mdx")],
  "c0833d03": [() => import(/* webpackChunkName: "c0833d03" */ "@site/docs/api/lusid/schemas/schema-block-request.mdx"), "@site/docs/api/lusid/schemas/schema-block-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-request.mdx")],
  "c0a99533": [() => import(/* webpackChunkName: "c0a99533" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-run-rebalance-algorithm.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-run-rebalance-algorithm.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-run-rebalance-algorithm.mdx")],
  "c0e4d200": [() => import(/* webpackChunkName: "c0e4d200" */ "@site/docs/api/lusid/schemas/schema-workspace.mdx"), "@site/docs/api/lusid/schemas/schema-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace.mdx")],
  "c0e5d9cd": [() => import(/* webpackChunkName: "c0e5d9cd" */ "@site/docs/api/lusid/schemas/schema-order-graph-block-order-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-block-order-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-block-order-synopsis.mdx")],
  "c0f2b716": [() => import(/* webpackChunkName: "c0f2b716" */ "@site/docs/api/lusid/schemas/schema-loan-facility.mdx"), "@site/docs/api/lusid/schemas/schema-loan-facility.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-loan-facility.mdx")],
  "c0f5549e": [() => import(/* webpackChunkName: "c0f5549e" */ "@site/docs/api/scheduler/schemas/schema-trigger.mdx"), "@site/docs/api/scheduler/schemas/schema-trigger.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-trigger.mdx")],
  "c0fa151c": [() => import(/* webpackChunkName: "c0fa151c" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-create-configuration-transaction-type.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-create-configuration-transaction-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-create-configuration-transaction-type.mdx")],
  "c10fe394": [() => import(/* webpackChunkName: "c10fe394" */ "@site/docs/api/lusid/schemas/schema-mapping-rule.mdx"), "@site/docs/api/lusid/schemas/schema-mapping-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mapping-rule.mdx")],
  "c11f6cc5": [() => import(/* webpackChunkName: "c11f6cc5" */ "@site/docs/api/lusid/schemas/schema-create-property-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-property-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-property-definition-request.mdx")],
  "c12894ec": [() => import(/* webpackChunkName: "c12894ec" */ "@site/docs/api/lusid/endpoints/system-configuration/operation-set-transaction-configuration-source.mdx"), "@site/docs/api/lusid/endpoints/system-configuration/operation-set-transaction-configuration-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/system-configuration/operation-set-transaction-configuration-source.mdx")],
  "c139b1ef": [() => import(/* webpackChunkName: "c139b1ef" */ "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-excel.mdx"), "@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-excel.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-execution/operation-put-by-query-excel.mdx")],
  "c144d9ff": [() => import(/* webpackChunkName: "c144d9ff" */ "@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request-v-2.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request-v-2.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-reconciliation-request-v-2.mdx")],
  "c18b5ef3": [() => import(/* webpackChunkName: "c18b5ef3" */ "@site/docs/api/lusid/endpoints/entities/operation-get-instrument-by-entity-unique-id.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-instrument-by-entity-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-instrument-by-entity-unique-id.mdx")],
  "c1ceec72": [() => import(/* webpackChunkName: "c1ceec72" */ "@site/docs/api/scheduler/schemas/schema-id-selector-definition.mdx"), "@site/docs/api/scheduler/schemas/schema-id-selector-definition.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-id-selector-definition.mdx")],
  "c1f4d48b": [() => import(/* webpackChunkName: "c1f4d48b" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-general-ledger-profile.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-general-ledger-profile.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-get-general-ledger-profile.mdx")],
  "c21c0fc9": [() => import(/* webpackChunkName: "c21c0fc9" */ "@site/docs/api/lusid/endpoints/order-management/operation-book-transactions.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-book-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-book-transactions.mdx")],
  "c24b9fb4": [() => import(/* webpackChunkName: "c24b9fb4" */ "@site/docs/api/lusid/schemas/schema-share-class-details.mdx"), "@site/docs/api/lusid/schemas/schema-share-class-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-share-class-details.mdx")],
  "c26b57e5": [() => import(/* webpackChunkName: "c26b57e5" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-update-reconciliation.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-update-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-update-reconciliation.mdx")],
  "c26c06fb": [() => import(/* webpackChunkName: "c26c06fb" */ "@site/docs/api/lusid/schemas/schema-corporate-action-transition-component.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action-transition-component.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action-transition-component.mdx")],
  "c2a43175": [() => import(/* webpackChunkName: "c2a43175" */ "@site/docs/api/lusid/schemas/schema-configuration-recipe.mdx"), "@site/docs/api/lusid/schemas/schema-configuration-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-configuration-recipe.mdx")],
  "c2b6ce56": [() => import(/* webpackChunkName: "c2b6ce56" */ "@site/docs/api/lusid/schemas/schema-get-complex-market-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-complex-market-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-complex-market-data-response.mdx")],
  "c2e54bd4": [() => import(/* webpackChunkName: "c2e54bd4" */ "@site/docs/api/web-app/schemas/schema-luminesce-query-data.mdx"), "@site/docs/api/web-app/schemas/schema-luminesce-query-data.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-luminesce-query-data.mdx")],
  "c307f799": [() => import(/* webpackChunkName: "c307f799" */ "@site/docs/api/lusid/schemas/schema-instrument-definition.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-definition.mdx")],
  "c3154531": [() => import(/* webpackChunkName: "c3154531" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-group.mdx")],
  "c34bbf11": [() => import(/* webpackChunkName: "c34bbf11" */ "@site/docs/api/web-app/schemas/schema-custom-value-property.mdx"), "@site/docs/api/web-app/schemas/schema-custom-value-property.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-custom-value-property.mdx")],
  "c35fbb6b": [() => import(/* webpackChunkName: "c35fbb6b" */ "@site/docs/api/lusid/endpoints/conventions/operation-list-index-convention.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-list-index-convention.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-list-index-convention.mdx")],
  "c398dcca": [() => import(/* webpackChunkName: "c398dcca" */ "@site/docs/api/lusid/schemas/schema-described-address-key.mdx"), "@site/docs/api/lusid/schemas/schema-described-address-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-described-address-key.mdx")],
  "c3f2ae54": [() => import(/* webpackChunkName: "c3f2ae54" */ "@site/docs/api/lusid/schemas/schema-change-interval-with-order-management-detail.mdx"), "@site/docs/api/lusid/schemas/schema-change-interval-with-order-management-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-change-interval-with-order-management-detail.mdx")],
  "c4653d6d": [() => import(/* webpackChunkName: "c4653d6d" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-list-amortisation-rule-sets.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-list-amortisation-rule-sets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-list-amortisation-rule-sets.mdx")],
  "c4a2ab46": [() => import(/* webpackChunkName: "c4a2ab46" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-data.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-a-2-b-data.mdx")],
  "c4a73be7": [() => import(/* webpackChunkName: "c4a73be7" */ "@site/docs/api/lusid/schemas/schema-transaction-type-property-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type-property-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type-property-mapping.mdx")],
  "c4d13ae4": [() => import(/* webpackChunkName: "c4d13ae4" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity-access-metadata.mdx")],
  "c4d366f3": [() => import(/* webpackChunkName: "c4d366f3" */ "@site/docs/api/lusid/endpoints/relationships/operation-delete-relationship.mdx"), "@site/docs/api/lusid/endpoints/relationships/operation-delete-relationship.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationships/operation-delete-relationship.mdx")],
  "c50587e1": [() => import(/* webpackChunkName: "c50587e1" */ "@site/docs/api/lusid/endpoints/schemas/operation-get-property-schema.mdx"), "@site/docs/api/lusid/endpoints/schemas/operation-get-property-schema.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/schemas/operation-get-property-schema.mdx")],
  "c535cfd2": [() => import(/* webpackChunkName: "c535cfd2" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-block.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order-graph-block.mdx")],
  "c54c61c6": [() => import(/* webpackChunkName: "c54c61c6" */ "@site/docs/api/lusid/schemas/schema-field-schema.mdx"), "@site/docs/api/lusid/schemas/schema-field-schema.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-field-schema.mdx")],
  "c5518cd6": [() => import(/* webpackChunkName: "c5518cd6" */ "@site/docs/api/notification/schemas/schema-attempt-status.mdx"), "@site/docs/api/notification/schemas/schema-attempt-status.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-attempt-status.mdx")],
  "c555a86b": [() => import(/* webpackChunkName: "c555a86b" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-order-instruction.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-order-instruction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-order-instruction.mdx")],
  "c56d7856": [() => import(/* webpackChunkName: "c56d7856" */ "@site/docs/api/lusid/schemas/schema-touch.mdx"), "@site/docs/api/lusid/schemas/schema-touch.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-touch.mdx")],
  "c5777c88": [() => import(/* webpackChunkName: "c5777c88" */ "@site/docs/api/workflow/schemas/schema-task-state-definition.mdx"), "@site/docs/api/workflow/schemas/schema-task-state-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-state-definition.mdx")],
  "c57c0018": [() => import(/* webpackChunkName: "c57c0018" */ "@site/docs/api/lusid/endpoints/relation-definitions/operation-get-relation-definition.mdx"), "@site/docs/api/lusid/endpoints/relation-definitions/operation-get-relation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relation-definitions/operation-get-relation-definition.mdx")],
  "c583c4dc": [() => import(/* webpackChunkName: "c583c4dc" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-csv.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-csv.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-csv.mdx")],
  "c5b83a5c": [() => import(/* webpackChunkName: "c5b83a5c" */ "@site/docs/api/lusid/schemas/schema-trial-balance-query-parameters.mdx"), "@site/docs/api/lusid/schemas/schema-trial-balance-query-parameters.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-trial-balance-query-parameters.mdx")],
  "c5ebb5ac": [() => import(/* webpackChunkName: "c5ebb5ac" */ "@site/docs/api/horizon/endpoints/integrations/operation-delete-instance.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-delete-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-delete-instance.mdx")],
  "c5f648fa": [() => import(/* webpackChunkName: "c5f648fa" */ "@site/docs/api/lusid/schemas/schema-block-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-block-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-set-request.mdx")],
  "c5fb5a20": [() => import(/* webpackChunkName: "c5fb5a20" */ "@site/docs/api/workflow/endpoints/workers/operation-list-workers.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-list-workers.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-list-workers.mdx")],
  "c603e2d8": [() => import(/* webpackChunkName: "c603e2d8" */ "@site/docs/api/access/schemas/schema-match-all-selector-definition.mdx"), "@site/docs/api/access/schemas/schema-match-all-selector-definition.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-match-all-selector-definition.mdx")],
  "c6082b8a": [() => import(/* webpackChunkName: "c6082b8a" */ "@site/docs/api/lusid/endpoints/placements/operation-delete-placement.mdx"), "@site/docs/api/lusid/endpoints/placements/operation-delete-placement.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/placements/operation-delete-placement.mdx")],
  "c61f3fdb": [() => import(/* webpackChunkName: "c61f3fdb" */ "@site/docs/api/lusid/schemas/schema-percent-check-step.mdx"), "@site/docs/api/lusid/schemas/schema-percent-check-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-percent-check-step.mdx")],
  "c64edb04": [() => import(/* webpackChunkName: "c64edb04" */ "@site/docs/api/identity/schemas/schema-support-access-response.mdx"), "@site/docs/api/identity/schemas/schema-support-access-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-support-access-response.mdx")],
  "c717ff5d": [() => import(/* webpackChunkName: "c717ff5d" */ "@site/docs/api/identity/endpoints/me/operation-get-user-info.mdx"), "@site/docs/api/identity/endpoints/me/operation-get-user-info.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/me/operation-get-user-info.mdx")],
  "c750b127": [() => import(/* webpackChunkName: "c750b127" */ "@site/docs/api/lusid/endpoints/data-types/operation-update-reference-data.mdx"), "@site/docs/api/lusid/endpoints/data-types/operation-update-reference-data.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/data-types/operation-update-reference-data.mdx")],
  "c75bb794": [() => import(/* webpackChunkName: "c75bb794" */ "@site/docs/api/workflow/schemas/schema-update-event-handler-request.mdx"), "@site/docs/api/workflow/schemas/schema-update-event-handler-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-update-event-handler-request.mdx")],
  "c78e0f1a": [() => import(/* webpackChunkName: "c78e0f1a" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-patch-portfolio-details.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-patch-portfolio-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-patch-portfolio-details.mdx")],
  "c7a55a84": [() => import(/* webpackChunkName: "c7a55a84" */ "@site/docs/api/luminesce/schemas/schema-case-statement-design.mdx"), "@site/docs/api/luminesce/schemas/schema-case-statement-design.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-case-statement-design.mdx")],
  "c7ae4096": [() => import(/* webpackChunkName: "c7ae4096" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-result.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-result.mdx")],
  "c7b0da7a": [() => import(/* webpackChunkName: "c7b0da7a" */ "@site/docs/api/horizon/schemas/schema-i-integration-log-response.mdx"), "@site/docs/api/horizon/schemas/schema-i-integration-log-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-i-integration-log-response.mdx")],
  "c7c37d03": [() => import(/* webpackChunkName: "c7c37d03" */ "@site/docs/api/lusid/schemas/schema-get-structured-result-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-structured-result-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-structured-result-data-response.mdx")],
  "c7c56d7a": [() => import(/* webpackChunkName: "c7c56d7a" */ "@site/docs/api/luminesce/schemas/schema-background-query-progress-response.mdx"), "@site/docs/api/luminesce/schemas/schema-background-query-progress-response.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-background-query-progress-response.mdx")],
  "c7e4b88c": [() => import(/* webpackChunkName: "c7e4b88c" */ "@site/docs/api/access/schemas/schema-policy-template-creation-request.mdx"), "@site/docs/api/access/schemas/schema-policy-template-creation-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-template-creation-request.mdx")],
  "c7f8c71e": [() => import(/* webpackChunkName: "c7f8c71e" */ "@site/docs/api/lusid/schemas/schema-transactions-reconciliations-response.mdx"), "@site/docs/api/lusid/schemas/schema-transactions-reconciliations-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transactions-reconciliations-response.mdx")],
  "c80384b2": [() => import(/* webpackChunkName: "c80384b2" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-recipe-composer.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-recipe-composer.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-upsert-recipe-composer.mdx")],
  "c82af901": [() => import(/* webpackChunkName: "c82af901" */ "@site/docs/api/lusid/schemas/schema-custom-entity-field.mdx"), "@site/docs/api/lusid/schemas/schema-custom-entity-field.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custom-entity-field.mdx")],
  "c82bc833": [() => import(/* webpackChunkName: "c82bc833" */ "@site/docs/api/lusid/schemas/schema-forward-rate-agreement.mdx"), "@site/docs/api/lusid/schemas/schema-forward-rate-agreement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-forward-rate-agreement.mdx")],
  "c8869c71": [() => import(/* webpackChunkName: "c8869c71" */ "@site/docs/api/lusid/schemas/schema-bond-default-event.mdx"), "@site/docs/api/lusid/schemas/schema-bond-default-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond-default-event.mdx")],
  "c8956106": [() => import(/* webpackChunkName: "c8956106" */ "@site/docs/api/lusid/schemas/schema-premium.mdx"), "@site/docs/api/lusid/schemas/schema-premium.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-premium.mdx")],
  "c899b7e7": [() => import(/* webpackChunkName: "c899b7e7" */ "@site/docs/api/lusid/schemas/schema-abor-configuration-properties.mdx"), "@site/docs/api/lusid/schemas/schema-abor-configuration-properties.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor-configuration-properties.mdx")],
  "c90283a8": [() => import(/* webpackChunkName: "c90283a8" */ "@site/docs/api/lusid/schemas/schema-decorated-compliance-run-summary.mdx"), "@site/docs/api/lusid/schemas/schema-decorated-compliance-run-summary.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-decorated-compliance-run-summary.mdx")],
  "c909c359": [() => import(/* webpackChunkName: "c909c359" */ "@site/docs/api/lusid/schemas/schema-create-staging-rule-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-staging-rule-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-staging-rule-set-request.mdx")],
  "c9209de3": [() => import(/* webpackChunkName: "c9209de3" */ "@site/docs/api/lusid/schemas/schema-queryable-key.mdx"), "@site/docs/api/lusid/schemas/schema-queryable-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-queryable-key.mdx")],
  "c958f45d": [() => import(/* webpackChunkName: "c958f45d" */ "@site/docs/api/identity/intro.md"), "@site/docs/api/identity/intro.md", require.resolveWeak("@site/docs/api/identity/intro.md")],
  "c9700598": [() => import(/* webpackChunkName: "c9700598" */ "@site/docs/api/lusid/schemas/schema-market-data-specific-rule.mdx"), "@site/docs/api/lusid/schemas/schema-market-data-specific-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-data-specific-rule.mdx")],
  "c989f53e": [() => import(/* webpackChunkName: "c989f53e" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-module-rules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-module-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-posting-module-rules.mdx")],
  "c99b032d": [() => import(/* webpackChunkName: "c99b032d" */ "@site/docs/api/access/schemas/schema-when-spec.mdx"), "@site/docs/api/access/schemas/schema-when-spec.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-when-spec.mdx")],
  "c9a0a0a1": [() => import(/* webpackChunkName: "c9a0a0a1" */ "@site/docs/api/access/endpoints/user-roles/operation-add-policy-collection-to-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-add-policy-collection-to-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-add-policy-collection-to-user-role.mdx")],
  "c9a27c36": [() => import(/* webpackChunkName: "c9a27c36" */ "@site/docs/api/lusid/schemas/schema-life-cycle-event-lineage.mdx"), "@site/docs/api/lusid/schemas/schema-life-cycle-event-lineage.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-life-cycle-event-lineage.mdx")],
  "c9ca3d32": [() => import(/* webpackChunkName: "c9ca3d32" */ "@site/docs/api/lusid/schemas/schema-from-recipe.mdx"), "@site/docs/api/lusid/schemas/schema-from-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-from-recipe.mdx")],
  "c9d00bae": [() => import(/* webpackChunkName: "c9d00bae" */ "@site/docs/api/configuration/schemas/schema-create-configuration-set.mdx"), "@site/docs/api/configuration/schemas/schema-create-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-create-configuration-set.mdx")],
  "c9e3afe1": [() => import(/* webpackChunkName: "c9e3afe1" */ "@site/docs/api/luminesce/schemas/schema-options-excel.mdx"), "@site/docs/api/luminesce/schemas/schema-options-excel.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-options-excel.mdx")],
  "c9febc55": [() => import(/* webpackChunkName: "c9febc55" */ "@site/docs/api/luminesce/schemas/schema-options-csv.mdx"), "@site/docs/api/luminesce/schemas/schema-options-csv.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-options-csv.mdx")],
  "ca034dd7": [() => import(/* webpackChunkName: "ca034dd7" */ "@site/docs/api/lusid/endpoints/calendars/operation-list-calendars-in-scope.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-list-calendars-in-scope.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-list-calendars-in-scope.mdx")],
  "ca1a68da": [() => import(/* webpackChunkName: "ca1a68da" */ "@site/docs/api/horizon/endpoints/instrument/operation-get-open-figi-parameter-option.mdx"), "@site/docs/api/horizon/endpoints/instrument/operation-get-open-figi-parameter-option.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/instrument/operation-get-open-figi-parameter-option.mdx")],
  "ca310912": [() => import(/* webpackChunkName: "ca310912" */ "@site/docs/api/lusid/schemas/schema-exchange-traded-option-contract-details.mdx"), "@site/docs/api/lusid/schemas/schema-exchange-traded-option-contract-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-exchange-traded-option-contract-details.mdx")],
  "ca33d553": [() => import(/* webpackChunkName: "ca33d553" */ "@site/docs/api/identity/endpoints/users/operation-expire-password.mdx"), "@site/docs/api/identity/endpoints/users/operation-expire-password.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-expire-password.mdx")],
  "ca48ad1a": [() => import(/* webpackChunkName: "ca48ad1a" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-general-ledger-profile-mappings.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-general-ledger-profile-mappings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-set-general-ledger-profile-mappings.mdx")],
  "ca8115f4": [() => import(/* webpackChunkName: "ca8115f4" */ "@site/docs/api/lusid/schemas/schema-create-relationship-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-relationship-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-relationship-request.mdx")],
  "ca88f54e": [() => import(/* webpackChunkName: "ca88f54e" */ "@site/docs/api/lusid/schemas/schema-decimal-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-decimal-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-decimal-compliance-parameter.mdx")],
  "caa151d0": [() => import(/* webpackChunkName: "caa151d0" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-order.mdx")],
  "cadc44bd": [() => import(/* webpackChunkName: "cadc44bd" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holding-contributors.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holding-contributors.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holding-contributors.mdx")],
  "cae2b174": [() => import(/* webpackChunkName: "cae2b174" */ "@site/docs/api/access/schemas/schema-policy-type.mdx"), "@site/docs/api/access/schemas/schema-policy-type.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-type.mdx")],
  "caebd8fa": [() => import(/* webpackChunkName: "caebd8fa" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-dates-for-calendar-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-dates-for-calendar-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-dates-for-calendar-response.mdx")],
  "cafeb8aa": [() => import(/* webpackChunkName: "cafeb8aa" */ "@site/docs/api/lusid/schemas/schema-blocked-order-request.mdx"), "@site/docs/api/lusid/schemas/schema-blocked-order-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-blocked-order-request.mdx")],
  "cb5f282e": [() => import(/* webpackChunkName: "cb5f282e" */ "@site/docs/api/access/schemas/schema-policy-template-response.mdx"), "@site/docs/api/access/schemas/schema-policy-template-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-template-response.mdx")],
  "cb6b9389": [() => import(/* webpackChunkName: "cb6b9389" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-response.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-upsert-response.mdx")],
  "cb7cbd28": [() => import(/* webpackChunkName: "cb7cbd28" */ "@site/docs/api/identity/schemas/schema-action-id.mdx"), "@site/docs/api/identity/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-action-id.mdx")],
  "cb974391": [() => import(/* webpackChunkName: "cb974391" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-break-code.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-break-code.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-user-review-break-code.mdx")],
  "cb98cc5d": [() => import(/* webpackChunkName: "cb98cc5d" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-group-properties.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-group-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-upsert-group-properties.mdx")],
  "cbac34cc": [() => import(/* webpackChunkName: "cbac34cc" */ "@site/docs/api/access/schemas/schema-effective-date-relative.mdx"), "@site/docs/api/access/schemas/schema-effective-date-relative.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-effective-date-relative.mdx")],
  "cbb31a2c": [() => import(/* webpackChunkName: "cbb31a2c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-person.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-person.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-person.mdx")],
  "cbc20ac4": [() => import(/* webpackChunkName: "cbc20ac4" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-returns.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-returns.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-returns.mdx")],
  "cbd0ec7e": [() => import(/* webpackChunkName: "cbd0ec7e" */ "@site/docs/api/lusid/endpoints/calendars/operation-add-business-days-to-date.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-add-business-days-to-date.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-add-business-days-to-date.mdx")],
  "cbf347a9": [() => import(/* webpackChunkName: "cbf347a9" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-delete-custom-entity.mdx")],
  "cbff7b54": [() => import(/* webpackChunkName: "cbff7b54" */ "@site/docs/api/lusid/schemas/schema-upsert-corporate-actions-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-corporate-actions-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-corporate-actions-response.mdx")],
  "cc0282af": [() => import(/* webpackChunkName: "cc0282af" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-create-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-create-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-create-property-definition.mdx")],
  "cc07e35c": [() => import(/* webpackChunkName: "cc07e35c" */ "@site/docs/api/access/schemas/schema-entitlement-metadata.mdx"), "@site/docs/api/access/schemas/schema-entitlement-metadata.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-entitlement-metadata.mdx")],
  "cc193021": [() => import(/* webpackChunkName: "cc193021" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-item.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-item.mdx")],
  "cc693fef": [() => import(/* webpackChunkName: "cc693fef" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-posting-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-posting-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-create-posting-module.mdx")],
  "cc886119": [() => import(/* webpackChunkName: "cc886119" */ "@site/docs/api/lusid/schemas/schema-property-definition.mdx"), "@site/docs/api/lusid/schemas/schema-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-definition.mdx")],
  "cc8bbdfa": [() => import(/* webpackChunkName: "cc8bbdfa" */ "@site/docs/api/lusid/schemas/schema-order-by-spec.mdx"), "@site/docs/api/lusid/schemas/schema-order-by-spec.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-by-spec.mdx")],
  "cc913a9a": [() => import(/* webpackChunkName: "cc913a9a" */ "@site/docs/api/lusid/schemas/schema-lapse-election.mdx"), "@site/docs/api/lusid/schemas/schema-lapse-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lapse-election.mdx")],
  "ccd4236b": [() => import(/* webpackChunkName: "ccd4236b" */ "@site/docs/api/lusid/schemas/schema-translation-script-id.mdx"), "@site/docs/api/lusid/schemas/schema-translation-script-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translation-script-id.mdx")],
  "ccd44da1": [() => import(/* webpackChunkName: "ccd44da1" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-holdings.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-holdings.mdx")],
  "ccdc7417": [() => import(/* webpackChunkName: "ccdc7417" */ "@site/docs/api/lusid/schemas/schema-bond-conversion-entry.mdx"), "@site/docs/api/lusid/schemas/schema-bond-conversion-entry.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond-conversion-entry.mdx")],
  "ccf714d4": [() => import(/* webpackChunkName: "ccf714d4" */ "@site/docs/api/lusid/schemas/schema-barrier.mdx"), "@site/docs/api/lusid/schemas/schema-barrier.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-barrier.mdx")],
  "ccfb32f5": [() => import(/* webpackChunkName: "ccfb32f5" */ "@site/docs/api/horizon/endpoints/integrations/operation-list-integrations.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-list-integrations.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-list-integrations.mdx")],
  "cd06b2ff": [() => import(/* webpackChunkName: "cd06b2ff" */ "@site/docs/api/lusid/schemas/schema-check-step.mdx"), "@site/docs/api/lusid/schemas/schema-check-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-check-step.mdx")],
  "cd07826e": [() => import(/* webpackChunkName: "cd07826e" */ "@site/docs/api/lusid/schemas/schema-processed-command.mdx"), "@site/docs/api/lusid/schemas/schema-processed-command.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-processed-command.mdx")],
  "cd43f4e8": [() => import(/* webpackChunkName: "cd43f4e8" */ "@site/docs/api/identity/endpoints/applications/operation-list-applications.mdx"), "@site/docs/api/identity/endpoints/applications/operation-list-applications.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/applications/operation-list-applications.mdx")],
  "cd5355c8": [() => import(/* webpackChunkName: "cd5355c8" */ "@site/docs/api/identity/schemas/schema-update-password-policy-request-age.mdx"), "@site/docs/api/identity/schemas/schema-update-password-policy-request-age.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-password-policy-request-age.mdx")],
  "cd5cb59c": [() => import(/* webpackChunkName: "cd5cb59c" */ "@site/docs/api/horizon/schemas/schema-onboard-instrument-response.mdx"), "@site/docs/api/horizon/schemas/schema-onboard-instrument-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-onboard-instrument-response.mdx")],
  "cdc1c9f3": [() => import(/* webpackChunkName: "cdc1c9f3" */ "@site/docs/api/lusid/schemas/schema-property-list.mdx"), "@site/docs/api/lusid/schemas/schema-property-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-list.mdx")],
  "cdd20cb6": [() => import(/* webpackChunkName: "cdd20cb6" */ "@site/docs/api/access/endpoints/user-roles/operation-create-user-role.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-create-user-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-create-user-role.mdx")],
  "cdd58267": [() => import(/* webpackChunkName: "cdd58267" */ "@site/docs/api/lusid/schemas/schema-percent-check-step-request.mdx"), "@site/docs/api/lusid/schemas/schema-percent-check-step-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-percent-check-step-request.mdx")],
  "cde15e37": [() => import(/* webpackChunkName: "cde15e37" */ "@site/docs/api/lusid/schemas/schema-equity.mdx"), "@site/docs/api/lusid/schemas/schema-equity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity.mdx")],
  "cdff3463": [() => import(/* webpackChunkName: "cdff3463" */ "@site/docs/api/lusid/schemas/schema-spin-off-event.mdx"), "@site/docs/api/lusid/schemas/schema-spin-off-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-spin-off-event.mdx")],
  "ce0eceee": [() => import(/* webpackChunkName: "ce0eceee" */ "@site/docs/api/lusid/schemas/schema-level-step.mdx"), "@site/docs/api/lusid/schemas/schema-level-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-level-step.mdx")],
  "ce115f32": [() => import(/* webpackChunkName: "ce115f32" */ "@site/docs/api/lusid/schemas/schema-group-by-selector-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-group-by-selector-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-by-selector-compliance-parameter.mdx")],
  "ce1878cd": [() => import(/* webpackChunkName: "ce1878cd" */ "@site/docs/api/lusid/schemas/schema-portfolio-group-id-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group-id-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group-id-list-compliance-parameter.mdx")],
  "ce1b3920": [() => import(/* webpackChunkName: "ce1b3920" */ "@site/docs/api/web-app/schemas/schema-log-level.mdx"), "@site/docs/api/web-app/schemas/schema-log-level.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-log-level.mdx")],
  "ce481563": [() => import(/* webpackChunkName: "ce481563" */ "@site/docs/api/lusid/schemas/schema-execution-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-execution-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-execution-set-request.mdx")],
  "ce82f2df": [() => import(/* webpackChunkName: "ce82f2df" */ "@site/docs/api/horizon/schemas/schema-instance-execution-reference-id.mdx"), "@site/docs/api/horizon/schemas/schema-instance-execution-reference-id.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-instance-execution-reference-id.mdx")],
  "cee70cef": [() => import(/* webpackChunkName: "cee70cef" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-results.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-results.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-results.mdx")],
  "ceeb76b3": [() => import(/* webpackChunkName: "ceeb76b3" */ "@site/docs/api/lusid/schemas/schema-group-filter-predicate-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-group-filter-predicate-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-filter-predicate-compliance-parameter.mdx")],
  "ceee81cc": [() => import(/* webpackChunkName: "ceee81cc" */ "@site/docs/api/lusid/schemas/schema-portfolio-entity.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-entity.mdx")],
  "cef83cd4": [() => import(/* webpackChunkName: "cef83cd4" */ "@site/docs/api/insights/schemas/schema-access-evaluation-log.mdx"), "@site/docs/api/insights/schemas/schema-access-evaluation-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-access-evaluation-log.mdx")],
  "cf00e213": [() => import(/* webpackChunkName: "cf00e213" */ "@site/docs/api/configuration/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/configuration/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-identifier-part-schema.mdx")],
  "cf1a82eb": [() => import(/* webpackChunkName: "cf1a82eb" */ "@site/docs/api/access/endpoints/policy-templates/operation-list-policy-templates.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-list-policy-templates.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-list-policy-templates.mdx")],
  "cf213597": [() => import(/* webpackChunkName: "cf213597" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-create-amortisation-rule-set.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-create-amortisation-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-create-amortisation-rule-set.mdx")],
  "cf26cb06": [() => import(/* webpackChunkName: "cf26cb06" */ "@site/docs/api/workflow/schemas/schema-link.mdx"), "@site/docs/api/workflow/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-link.mdx")],
  "cf4025a1": [() => import(/* webpackChunkName: "cf4025a1" */ "@site/docs/api/luminesce/schemas/schema-action-result.mdx"), "@site/docs/api/luminesce/schemas/schema-action-result.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-action-result.mdx")],
  "cf4ba233": [() => import(/* webpackChunkName: "cf4ba233" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-sqlite.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-sqlite.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-sqlite.mdx")],
  "cf585823": [() => import(/* webpackChunkName: "cf585823" */ "@site/docs/api/lusid/endpoints/calendars/operation-generate-schedule.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-generate-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-generate-schedule.mdx")],
  "cf65af67": [() => import(/* webpackChunkName: "cf65af67" */ "@site/docs/api/lusid/schemas/schema-upsert-instrument-property-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-instrument-property-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-instrument-property-request.mdx")],
  "cf73d0b2": [() => import(/* webpackChunkName: "cf73d0b2" */ "@site/docs/api/lusid/schemas/schema-cash-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-cash-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-dependency.mdx")],
  "cfae1378": [() => import(/* webpackChunkName: "cfae1378" */ "@site/docs/api/lusid/schemas/schema-quoted-security.mdx"), "@site/docs/api/lusid/schemas/schema-quoted-security.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quoted-security.mdx")],
  "cfc6172b": [() => import(/* webpackChunkName: "cfc6172b" */ "@site/docs/api/lusid/endpoints/relationship-definitions/operation-list-relationship-definitions.mdx"), "@site/docs/api/lusid/endpoints/relationship-definitions/operation-list-relationship-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/relationship-definitions/operation-list-relationship-definitions.mdx")],
  "cfc99fb2": [() => import(/* webpackChunkName: "cfc99fb2" */ "@site/docs/api/luminesce/endpoints/application-metadata/operation-get-services-as-access-controlled-resources.mdx"), "@site/docs/api/luminesce/endpoints/application-metadata/operation-get-services-as-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/application-metadata/operation-get-services-as-access-controlled-resources.mdx")],
  "cfd09584": [() => import(/* webpackChunkName: "cfd09584" */ "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set.mdx"), "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set.mdx")],
  "cfec7e69": [() => import(/* webpackChunkName: "cfec7e69" */ "@site/docs/api/access/endpoints/policies/operation-delete-policy.mdx"), "@site/docs/api/access/endpoints/policies/operation-delete-policy.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-delete-policy.mdx")],
  "d026138c": [() => import(/* webpackChunkName: "d026138c" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-build-transactions.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-build-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-build-transactions.mdx")],
  "d0600169": [() => import(/* webpackChunkName: "d0600169" */ "@site/docs/api/lusid/schemas/schema-property-interval.mdx"), "@site/docs/api/lusid/schemas/schema-property-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-interval.mdx")],
  "d0805689": [() => import(/* webpackChunkName: "d0805689" */ "@site/docs/api/horizon/schemas/schema-integration-run-log.mdx"), "@site/docs/api/horizon/schemas/schema-integration-run-log.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-run-log.mdx")],
  "d0985eab": [() => import(/* webpackChunkName: "d0985eab" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-transaction.mdx")],
  "d0d67206": [() => import(/* webpackChunkName: "d0d67206" */ "@site/docs/api/lusid/schemas/schema-get-counterparty-agreement-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-counterparty-agreement-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-counterparty-agreement-response.mdx")],
  "d0fe24a4": [() => import(/* webpackChunkName: "d0fe24a4" */ "@site/docs/api/lusid/endpoints/executions/operation-upsert-executions.mdx"), "@site/docs/api/lusid/endpoints/executions/operation-upsert-executions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/executions/operation-upsert-executions.mdx")],
  "d10af30f": [() => import(/* webpackChunkName: "d10af30f" */ "@site/docs/api/horizon/endpoints/runs/operation-rerun-instance.mdx"), "@site/docs/api/horizon/endpoints/runs/operation-rerun-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/runs/operation-rerun-instance.mdx")],
  "d14d0189": [() => import(/* webpackChunkName: "d14d0189" */ "@site/docs/api/lusid/schemas/schema-translation-result.mdx"), "@site/docs/api/lusid/schemas/schema-translation-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translation-result.mdx")],
  "d19500c5": [() => import(/* webpackChunkName: "d19500c5" */ "@site/docs/api/drive/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/drive/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-lusid-validation-problem-details.mdx")],
  "d1a7ba82": [() => import(/* webpackChunkName: "d1a7ba82" */ "@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-request.mdx"), "@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bucketed-cash-flow-request.mdx")],
  "d1b2aa7f": [() => import(/* webpackChunkName: "d1b2aa7f" */ "@site/docs/api/lusid/endpoints/abor/operation-list-diary-entries.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-list-diary-entries.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-list-diary-entries.mdx")],
  "d1f199a8": [() => import(/* webpackChunkName: "d1f199a8" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace-item.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace-item.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-workspace-item.mdx")],
  "d2192b78": [() => import(/* webpackChunkName: "d2192b78" */ "@site/docs/api/notification/schemas/schema-event-type-schema.mdx"), "@site/docs/api/notification/schemas/schema-event-type-schema.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-event-type-schema.mdx")],
  "d22fe32e": [() => import(/* webpackChunkName: "d22fe32e" */ "@site/docs/api/lusid/schemas/schema-mbs-interest-deferral-event.mdx"), "@site/docs/api/lusid/schemas/schema-mbs-interest-deferral-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mbs-interest-deferral-event.mdx")],
  "d23eb1ae": [() => import(/* webpackChunkName: "d23eb1ae" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-amortisation-rule-set.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-amortisation-rule-set.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-amortisation-rule-set.mdx")],
  "d240d1df": [() => import(/* webpackChunkName: "d240d1df" */ "@site/docs/api/workflow/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/workflow/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-lusid-problem-details.mdx")],
  "d25911d1": [() => import(/* webpackChunkName: "d25911d1" */ "@site/docs/api/horizon/schemas/schema-audit-update-response.mdx"), "@site/docs/api/horizon/schemas/schema-audit-update-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-update-response.mdx")],
  "d25ea8e8": [() => import(/* webpackChunkName: "d25ea8e8" */ "@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-request.mdx"), "@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translate-instrument-definitions-request.mdx")],
  "d26740c8": [() => import(/* webpackChunkName: "d26740c8" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition-properties.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-delete-property-definition-properties.mdx")],
  "d29f0c28": [() => import(/* webpackChunkName: "d29f0c28" */ "@site/docs/api/horizon/schemas/schema-integration-run-version.mdx"), "@site/docs/api/horizon/schemas/schema-integration-run-version.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-run-version.mdx")],
  "d2c504d6": [() => import(/* webpackChunkName: "d2c504d6" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-rule.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-posting-module-rule.mdx")],
  "d2ed8e1e": [() => import(/* webpackChunkName: "d2ed8e1e" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-chart-of-accounts-properties.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-chart-of-accounts-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-chart-of-accounts-properties.mdx")],
  "d2f3d0a7": [() => import(/* webpackChunkName: "d2f3d0a7" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-group-reconciliation-definition.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-group-reconciliation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-update-group-reconciliation-definition.mdx")],
  "d303dbfc": [() => import(/* webpackChunkName: "d303dbfc" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-reconcile-transactions.mdx")],
  "d3188702": [() => import(/* webpackChunkName: "d3188702" */ "@site/docs/api/lusid/schemas/schema-flow-conventions.mdx"), "@site/docs/api/lusid/schemas/schema-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-flow-conventions.mdx")],
  "d345222a": [() => import(/* webpackChunkName: "d345222a" */ "@site/docs/api/horizon/endpoints/vendor/operation-get-optional-mappings-for-product-entity.mdx"), "@site/docs/api/horizon/endpoints/vendor/operation-get-optional-mappings-for-product-entity.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/vendor/operation-get-optional-mappings-for-product-entity.mdx")],
  "d3464163": [() => import(/* webpackChunkName: "d3464163" */ "@site/docs/api/access/schemas/schema-user-role-update-request.mdx"), "@site/docs/api/access/schemas/schema-user-role-update-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-user-role-update-request.mdx")],
  "d35a1109": [() => import(/* webpackChunkName: "d35a1109" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-access-token.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-access-token.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-access-token.mdx")],
  "d368d5e9": [() => import(/* webpackChunkName: "d368d5e9" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-legal-entity.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-legal-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-legal-entity.mdx")],
  "d375e497": [() => import(/* webpackChunkName: "d375e497" */ "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set-summary.mdx"), "@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set-summary.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-resource-list-of-configuration-set-summary.mdx")],
  "d3aa1788": [() => import(/* webpackChunkName: "d3aa1788" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-corporate-actions.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-corporate-actions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-get-corporate-actions.mdx")],
  "d3f82dd1": [() => import(/* webpackChunkName: "d3f82dd1" */ "@site/docs/api/lusid/schemas/schema-vendor.mdx"), "@site/docs/api/lusid/schemas/schema-vendor.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-vendor.mdx")],
  "d40f88f9": [() => import(/* webpackChunkName: "d40f88f9" */ "@site/docs/api/lusid/endpoints/quotes/operation-upsert-quote-access-metadata-rule.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-upsert-quote-access-metadata-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-upsert-quote-access-metadata-rule.mdx")],
  "d41924e5": [() => import(/* webpackChunkName: "d41924e5" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-sub-group-from-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-sub-group-from-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-sub-group-from-group.mdx")],
  "d42b89a6": [() => import(/* webpackChunkName: "d42b89a6" */ "@site/docs/api/lusid/endpoints/persons/operation-get-person.mdx"), "@site/docs/api/lusid/endpoints/persons/operation-get-person.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/persons/operation-get-person.mdx")],
  "d45b4389": [() => import(/* webpackChunkName: "d45b4389" */ "@site/docs/api/web-app/schemas/schema-hubspot-response-property.mdx"), "@site/docs/api/web-app/schemas/schema-hubspot-response-property.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-hubspot-response-property.mdx")],
  "d464c971": [() => import(/* webpackChunkName: "d464c971" */ "@site/docs/api/access/schemas/schema-metadata-selector-definition.mdx"), "@site/docs/api/access/schemas/schema-metadata-selector-definition.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-metadata-selector-definition.mdx")],
  "d471b524": [() => import(/* webpackChunkName: "d471b524" */ "@site/docs/api/lusid/schemas/schema-upsert-quotes-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-quotes-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-quotes-response.mdx")],
  "d486427c": [() => import(/* webpackChunkName: "d486427c" */ "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-worker.mdx"), "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-worker.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-paged-resource-list-of-worker.mdx")],
  "d4bb45e5": [() => import(/* webpackChunkName: "d4bb45e5" */ "@site/docs/api/lusid/schemas/schema-participation.mdx"), "@site/docs/api/lusid/schemas/schema-participation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-participation.mdx")],
  "d4cdb39c": [() => import(/* webpackChunkName: "d4cdb39c" */ "@site/docs/api/lusid/schemas/schema-a-2-b-data-record.mdx"), "@site/docs/api/lusid/schemas/schema-a-2-b-data-record.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-a-2-b-data-record.mdx")],
  "d4e32a88": [() => import(/* webpackChunkName: "d4e32a88" */ "@site/docs/api/lusid/schemas/schema-side-definition.mdx"), "@site/docs/api/lusid/schemas/schema-side-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-side-definition.mdx")],
  "d4f85482": [() => import(/* webpackChunkName: "d4f85482" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response-item.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response-item.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-portfolio-access-metadata-response-item.mdx")],
  "d5217535": [() => import(/* webpackChunkName: "d5217535" */ "@site/docs/api/workflow/schemas/schema-create-task-request.mdx"), "@site/docs/api/workflow/schemas/schema-create-task-request.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-create-task-request.mdx")],
  "d5304226": [() => import(/* webpackChunkName: "d5304226" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-add-configuration-to-set.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-add-configuration-to-set.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-add-configuration-to-set.mdx")],
  "d532e681": [() => import(/* webpackChunkName: "d532e681" */ "@site/docs/api/lusid/schemas/schema-market-context.mdx"), "@site/docs/api/lusid/schemas/schema-market-context.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-context.mdx")],
  "d53b817d": [() => import(/* webpackChunkName: "d53b817d" */ "@site/docs/api/lusid/schemas/schema-equity-vol-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-equity-vol-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-vol-dependency.mdx")],
  "d53ccd7f": [() => import(/* webpackChunkName: "d53ccd7f" */ "@site/docs/api/lusid/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/lusid/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "d53e2f19": [() => import(/* webpackChunkName: "d53e2f19" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-posting-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-posting-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-patch-posting-module.mdx")],
  "d542b18f": [() => import(/* webpackChunkName: "d542b18f" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-run-info-v-2.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-run-info-v-2.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-compliance-run-info-v-2.mdx")],
  "d5533623": [() => import(/* webpackChunkName: "d5533623" */ "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-information.mdx"), "@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-information.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-paged-resource-list-of-process-information.mdx")],
  "d56163fb": [() => import(/* webpackChunkName: "d56163fb" */ "@site/docs/api/lusid/endpoints/transaction-fees/operation-delete-transaction-fee-rule.mdx"), "@site/docs/api/lusid/endpoints/transaction-fees/operation-delete-transaction-fee-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-fees/operation-delete-transaction-fee-rule.mdx")],
  "d575b75b": [() => import(/* webpackChunkName: "d575b75b" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-histogram.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-histogram.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-fetch-query-result-histogram.mdx")],
  "d5b630a7": [() => import(/* webpackChunkName: "d5b630a7" */ "@site/docs/api/luminesce/schemas/schema-condition-attributes.mdx"), "@site/docs/api/luminesce/schemas/schema-condition-attributes.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-condition-attributes.mdx")],
  "d601c87c": [() => import(/* webpackChunkName: "d601c87c" */ "@site/docs/api/drive/endpoints/files/operation-download-file.mdx"), "@site/docs/api/drive/endpoints/files/operation-download-file.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/files/operation-download-file.mdx")],
  "d61c325a": [() => import(/* webpackChunkName: "d61c325a" */ "@site/docs/api/identity/endpoints/authentication/operation-update-password-policy.mdx"), "@site/docs/api/identity/endpoints/authentication/operation-update-password-policy.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/authentication/operation-update-password-policy.mdx")],
  "d651969a": [() => import(/* webpackChunkName: "d651969a" */ "@site/docs/api/notification/schemas/schema-resource-list-of-event-type-schema.mdx"), "@site/docs/api/notification/schemas/schema-resource-list-of-event-type-schema.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-list-of-event-type-schema.mdx")],
  "d6562cfc": [() => import(/* webpackChunkName: "d6562cfc" */ "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-get-progress-of.mdx"), "@site/docs/api/luminesce/endpoints/sql-background-execution/operation-get-progress-of.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-background-execution/operation-get-progress-of.mdx")],
  "d65eab15": [() => import(/* webpackChunkName: "d65eab15" */ "@site/docs/api/lusid/schemas/schema-typed-resource-id.mdx"), "@site/docs/api/lusid/schemas/schema-typed-resource-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-typed-resource-id.mdx")],
  "d6711225": [() => import(/* webpackChunkName: "d6711225" */ "@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments-properties.mdx"), "@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments-properties.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/instruments/operation-upsert-instruments-properties.mdx")],
  "d67ed129": [() => import(/* webpackChunkName: "d67ed129" */ "@site/docs/api/workflow/intro.md"), "@site/docs/api/workflow/intro.md", require.resolveWeak("@site/docs/api/workflow/intro.md")],
  "d6859b12": [() => import(/* webpackChunkName: "d6859b12" */ "@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent-request.mdx"), "@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-portfolio-constituent-request.mdx")],
  "d6c70a3a": [() => import(/* webpackChunkName: "d6c70a3a" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-property-time-series.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-property-time-series.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-property-time-series.mdx")],
  "d6cb76d1": [() => import(/* webpackChunkName: "d6cb76d1" */ "@site/docs/api/insights/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/insights/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-identifier-part-schema.mdx")],
  "d6cb9c04": [() => import(/* webpackChunkName: "d6cb9c04" */ "@site/docs/api/lusid/schemas/schema-expiry-event.mdx"), "@site/docs/api/lusid/schemas/schema-expiry-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-expiry-event.mdx")],
  "d6e1d637": [() => import(/* webpackChunkName: "d6e1d637" */ "@site/docs/api/lusid/schemas/schema-market-data-key-rule.mdx"), "@site/docs/api/lusid/schemas/schema-market-data-key-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-data-key-rule.mdx")],
  "d6e51b8f": [() => import(/* webpackChunkName: "d6e51b8f" */ "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-breached-orders-info.mdx"), "@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-breached-orders-info.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legacy-compliance/operation-get-legacy-breached-orders-info.mdx")],
  "d6efc5bc": [() => import(/* webpackChunkName: "d6efc5bc" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation-mapping.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-delete-reconciliation-mapping.mdx")],
  "d6f1b8f7": [() => import(/* webpackChunkName: "d6f1b8f7" */ "@site/docs/api/horizon/endpoints/logs/operation-get-integration-log-results.mdx"), "@site/docs/api/horizon/endpoints/logs/operation-get-integration-log-results.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/logs/operation-get-integration-log-results.mdx")],
  "d7062d4b": [() => import(/* webpackChunkName: "d7062d4b" */ "@site/docs/api/horizon/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/horizon/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-lusid-problem-details.mdx")],
  "d725a5f9": [() => import(/* webpackChunkName: "d725a5f9" */ "@site/docs/api/lusid/schemas/schema-posting-module-response.mdx"), "@site/docs/api/lusid/schemas/schema-posting-module-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-posting-module-response.mdx")],
  "d737afbf": [() => import(/* webpackChunkName: "d737afbf" */ "@site/docs/api/workflow/schemas/schema-run-worker-action-response.mdx"), "@site/docs/api/workflow/schemas/schema-run-worker-action-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-run-worker-action-response.mdx")],
  "d73d5d3b": [() => import(/* webpackChunkName: "d73d5d3b" */ "@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping.mdx"), "@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rebalance-portfolio-mapping.mdx")],
  "d758d78c": [() => import(/* webpackChunkName: "d758d78c" */ "@site/docs/api/luminesce/schemas/schema-certificate-type.mdx"), "@site/docs/api/luminesce/schemas/schema-certificate-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-certificate-type.mdx")],
  "d78cf614": [() => import(/* webpackChunkName: "d78cf614" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-create-task-definition.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-create-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-create-task-definition.mdx")],
  "d7ad3870": [() => import(/* webpackChunkName: "d7ad3870" */ "@site/docs/api/lusid/schemas/schema-fx-forward-model-options.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-model-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-model-options.mdx")],
  "d7af3a91": [() => import(/* webpackChunkName: "d7af3a91" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-string-value-map.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-string-value-map.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-rule-string-value-map.mdx")],
  "d7d9f558": [() => import(/* webpackChunkName: "d7d9f558" */ "@site/docs/api/lusid/schemas/schema-holdings-adjustment.mdx"), "@site/docs/api/lusid/schemas/schema-holdings-adjustment.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holdings-adjustment.mdx")],
  "d7dc339d": [() => import(/* webpackChunkName: "d7dc339d" */ "@site/docs/api/lusid/schemas/schema-simple-instrument.mdx"), "@site/docs/api/lusid/schemas/schema-simple-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-simple-instrument.mdx")],
  "d7e97c1c": [() => import(/* webpackChunkName: "d7e97c1c" */ "@site/docs/api/lusid/schemas/schema-result-value-0-d.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-0-d.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-0-d.mdx")],
  "d808378c": [() => import(/* webpackChunkName: "d808378c" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregate-returns.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregate-returns.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-aggregate-returns.mdx")],
  "d85d80f4": [() => import(/* webpackChunkName: "d85d80f4" */ "@site/docs/api/lusid/schemas/schema-create-data-type-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-data-type-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-data-type-request.mdx")],
  "d8641d43": [() => import(/* webpackChunkName: "d8641d43" */ "@site/docs/api/lusid/schemas/schema-access-metadata-operation.mdx"), "@site/docs/api/lusid/schemas/schema-access-metadata-operation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-access-metadata-operation.mdx")],
  "d868e015": [() => import(/* webpackChunkName: "d868e015" */ "@site/docs/api/lusid/schemas/schema-update-placements-response.mdx"), "@site/docs/api/lusid/schemas/schema-update-placements-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-placements-response.mdx")],
  "d8866d60": [() => import(/* webpackChunkName: "d8866d60" */ "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-statuses.mdx"), "@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-statuses.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/tickets/operation-tickets-get-statuses.mdx")],
  "d89eb558": [() => import(/* webpackChunkName: "d89eb558" */ "@site/docs/api/scheduler/schemas/schema-resource-id.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-id.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-id.mdx")],
  "d8b3b457": [() => import(/* webpackChunkName: "d8b3b457" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-comparison-ruleset-ids.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-comparison-ruleset-ids.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-definition-comparison-ruleset-ids.mdx")],
  "d90816b7": [() => import(/* webpackChunkName: "d90816b7" */ "@site/docs/api/lusid/schemas/schema-quote-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-quote-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote-dependency.mdx")],
  "d9132e78": [() => import(/* webpackChunkName: "d9132e78" */ "@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-request.mdx"), "@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-request.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-run-rebalance-algorithm-request.mdx")],
  "d92ae710": [() => import(/* webpackChunkName: "d92ae710" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-holdings.mdx")],
  "d94a53ba": [() => import(/* webpackChunkName: "d94a53ba" */ "@site/docs/api/lusid/schemas/schema-update-reference-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-reference-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-reference-data-request.mdx")],
  "d94ce172": [() => import(/* webpackChunkName: "d94ce172" */ "@site/docs/api/drive/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/drive/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-lusid-problem-details.mdx")],
  "d9bb7588": [() => import(/* webpackChunkName: "d9bb7588" */ "@site/docs/api/lusid/schemas/schema-aggregated-transactions-request.mdx"), "@site/docs/api/lusid/schemas/schema-aggregated-transactions-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregated-transactions-request.mdx")],
  "d9c10faf": [() => import(/* webpackChunkName: "d9c10faf" */ "@site/docs/api/lusid/endpoints/reference-lists/operation-list-reference-lists.mdx"), "@site/docs/api/lusid/endpoints/reference-lists/operation-list-reference-lists.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-lists/operation-list-reference-lists.mdx")],
  "d9ccc84d": [() => import(/* webpackChunkName: "d9ccc84d" */ "@site/docs/api/web-app/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/web-app/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-lusid-problem-details.mdx")],
  "d9cd219b": [() => import(/* webpackChunkName: "d9cd219b" */ "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task.mdx"), "@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-paged-resource-list-of-task.mdx")],
  "d9e706a7": [() => import(/* webpackChunkName: "d9e706a7" */ "@site/docs/api/lusid/schemas/schema-cleardown-module-rule.mdx"), "@site/docs/api/lusid/schemas/schema-cleardown-module-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cleardown-module-rule.mdx")],
  "d9fbab62": [() => import(/* webpackChunkName: "d9fbab62" */ "@site/docs/api/lusid/schemas/schema-a-2-b-category.mdx"), "@site/docs/api/lusid/schemas/schema-a-2-b-category.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-a-2-b-category.mdx")],
  "da354669": [() => import(/* webpackChunkName: "da354669" */ "@site/docs/api/lusid/schemas/schema-abor-configuration-request.mdx"), "@site/docs/api/lusid/schemas/schema-abor-configuration-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-abor-configuration-request.mdx")],
  "da37d508": [() => import(/* webpackChunkName: "da37d508" */ "@site/docs/api/lusid/schemas/schema-calculation-info.mdx"), "@site/docs/api/lusid/schemas/schema-calculation-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-calculation-info.mdx")],
  "da52117c": [() => import(/* webpackChunkName: "da52117c" */ "@site/docs/api/scheduler/endpoints/images/operation-list-repositories.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-list-repositories.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-list-repositories.mdx")],
  "da652698": [() => import(/* webpackChunkName: "da652698" */ "@site/docs/api/lusid/endpoints/portfolios/operation-list-instrument-event-instructions.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-list-instrument-event-instructions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-list-instrument-event-instructions.mdx")],
  "da69028c": [() => import(/* webpackChunkName: "da69028c" */ "@site/docs/api/lusid/schemas/schema-placement-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-placement-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-placement-set-request.mdx")],
  "da901242": [() => import(/* webpackChunkName: "da901242" */ "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-delete-personalisation.mdx"), "@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-delete-personalisation.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/personalisations/operation-personalisations-delete-personalisation.mdx")],
  "da968b63": [() => import(/* webpackChunkName: "da968b63" */ "@site/docs/api/workflow/endpoints/workers/operation-create-worker.mdx"), "@site/docs/api/workflow/endpoints/workers/operation-create-worker.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/workers/operation-create-worker.mdx")],
  "da9ae725": [() => import(/* webpackChunkName: "da9ae725" */ "@site/docs/api/notification/schemas/schema-resource-list-of-delivery.mdx"), "@site/docs/api/notification/schemas/schema-resource-list-of-delivery.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-list-of-delivery.mdx")],
  "daa583b2": [() => import(/* webpackChunkName: "daa583b2" */ "@site/docs/api/lusid/endpoints/calendars/operation-list-calendars.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-list-calendars.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-list-calendars.mdx")],
  "dadafd79": [() => import(/* webpackChunkName: "dadafd79" */ "@site/docs/api/lusid/endpoints/calendars/operation-is-business-date-time.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-is-business-date-time.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-is-business-date-time.mdx")],
  "db2cca91": [() => import(/* webpackChunkName: "db2cca91" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-derived-recipe.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-derived-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-derived-recipe.mdx")],
  "db7613e6": [() => import(/* webpackChunkName: "db7613e6" */ "@site/docs/api/lusid/endpoints/fund-configuration/operation-list-fund-configurations.mdx"), "@site/docs/api/lusid/endpoints/fund-configuration/operation-list-fund-configurations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fund-configuration/operation-list-fund-configurations.mdx")],
  "db9683c0": [() => import(/* webpackChunkName: "db9683c0" */ "@site/docs/api/lusid/schemas/schema-i-unit-definition-dto.mdx"), "@site/docs/api/lusid/schemas/schema-i-unit-definition-dto.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-i-unit-definition-dto.mdx")],
  "db97e04f": [() => import(/* webpackChunkName: "db97e04f" */ "@site/docs/api/lusid/endpoints/property-definitions/operation-update-property-definition.mdx"), "@site/docs/api/lusid/endpoints/property-definitions/operation-update-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/property-definitions/operation-update-property-definition.mdx")],
  "dbcc68a8": [() => import(/* webpackChunkName: "dbcc68a8" */ "@site/docs/api/lusid/endpoints/funds/operation-get-fund.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-get-fund.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-get-fund.mdx")],
  "dbd0a6fe": [() => import(/* webpackChunkName: "dbd0a6fe" */ "@site/docs/api/lusid/schemas/schema-upsert-persons-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-persons-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-persons-response.mdx")],
  "dbdca06b": [() => import(/* webpackChunkName: "dbdca06b" */ "@site/docs/api/web-app/schemas/schema-cash-equitisation.mdx"), "@site/docs/api/web-app/schemas/schema-cash-equitisation.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-cash-equitisation.mdx")],
  "dc2afc13": [() => import(/* webpackChunkName: "dc2afc13" */ "@site/docs/api/web-app/schemas/schema-aggregate-spec.mdx"), "@site/docs/api/web-app/schemas/schema-aggregate-spec.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-aggregate-spec.mdx")],
  "dc366139": [() => import(/* webpackChunkName: "dc366139" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-address-key-definition.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-address-key-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-address-key-definition.mdx")],
  "dc390c7e": [() => import(/* webpackChunkName: "dc390c7e" */ "@site/docs/api/access/endpoints/policy-templates/operation-update-policy-template.mdx"), "@site/docs/api/access/endpoints/policy-templates/operation-update-policy-template.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policy-templates/operation-update-policy-template.mdx")],
  "dc5fc786": [() => import(/* webpackChunkName: "dc5fc786" */ "@site/docs/api/lusid/schemas/schema-update-portfolio-group-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-portfolio-group-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-portfolio-group-request.mdx")],
  "dc6ed4be": [() => import(/* webpackChunkName: "dc6ed4be" */ "@site/docs/api/lusid/schemas/schema-fx-vol-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-fx-vol-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-vol-dependency.mdx")],
  "dc71561f": [() => import(/* webpackChunkName: "dc71561f" */ "@site/docs/api/web-app/schemas/schema-metric-value.mdx"), "@site/docs/api/web-app/schemas/schema-metric-value.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-metric-value.mdx")],
  "dc7c65ad": [() => import(/* webpackChunkName: "dc7c65ad" */ "@site/docs/api/lusid/schemas/schema-result-value-date-time-offset.mdx"), "@site/docs/api/lusid/schemas/schema-result-value-date-time-offset.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-result-value-date-time-offset.mdx")],
  "dc87f756": [() => import(/* webpackChunkName: "dc87f756" */ "@site/docs/api/lusid/endpoints/scopes/operation-list-scopes.mdx"), "@site/docs/api/lusid/endpoints/scopes/operation-list-scopes.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scopes/operation-list-scopes.mdx")],
  "dcd81fd4": [() => import(/* webpackChunkName: "dcd81fd4" */ "@site/docs/api/lusid/schemas/schema-filter-step.mdx"), "@site/docs/api/lusid/schemas/schema-filter-step.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-filter-step.mdx")],
  "dd4d72d2": [() => import(/* webpackChunkName: "dd4d72d2" */ "@site/docs/api/lusid/schemas/schema-fee.mdx"), "@site/docs/api/lusid/schemas/schema-fee.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee.mdx")],
  "dd634fe0": [() => import(/* webpackChunkName: "dd634fe0" */ "@site/docs/api/horizon/schemas/schema-audit-complete-response.mdx"), "@site/docs/api/horizon/schemas/schema-audit-complete-response.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-complete-response.mdx")],
  "dd9aad8e": [() => import(/* webpackChunkName: "dd9aad8e" */ "@site/docs/api/workflow/schemas/schema-change-item.mdx"), "@site/docs/api/workflow/schemas/schema-change-item.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-change-item.mdx")],
  "ddc5854b": [() => import(/* webpackChunkName: "ddc5854b" */ "@site/docs/api/horizon/schemas/schema-integration-property-configuration.mdx"), "@site/docs/api/horizon/schemas/schema-integration-property-configuration.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-property-configuration.mdx")],
  "ddceb421": [() => import(/* webpackChunkName: "ddceb421" */ "@site/docs/api/access/schemas/schema-add-to-policy-collection-request.mdx"), "@site/docs/api/access/schemas/schema-add-to-policy-collection-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-add-to-policy-collection-request.mdx")],
  "dde07f7d": [() => import(/* webpackChunkName: "dde07f7d" */ "@site/docs/api/lusid/endpoints/transaction-fees/operation-upsert-transaction-fee-rules.mdx"), "@site/docs/api/lusid/endpoints/transaction-fees/operation-upsert-transaction-fee-rules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-fees/operation-upsert-transaction-fee-rules.mdx")],
  "dde1bd84": [() => import(/* webpackChunkName: "dde1bd84" */ "@site/docs/api/lusid/schemas/schema-option-entry.mdx"), "@site/docs/api/lusid/schemas/schema-option-entry.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-option-entry.mdx")],
  "dde3b053": [() => import(/* webpackChunkName: "dde3b053" */ "@site/docs/api/lusid/schemas/schema-upsert-instrument-events-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-instrument-events-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-instrument-events-response.mdx")],
  "de06adb7": [() => import(/* webpackChunkName: "de06adb7" */ "@site/docs/api/lusid/endpoints/legal-entities/operation-list-all-legal-entities.mdx"), "@site/docs/api/lusid/endpoints/legal-entities/operation-list-all-legal-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/legal-entities/operation-list-all-legal-entities.mdx")],
  "de071ff6": [() => import(/* webpackChunkName: "de071ff6" */ "@site/docs/api/lusid/schemas/schema-opaque-dependency.mdx"), "@site/docs/api/lusid/schemas/schema-opaque-dependency.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-opaque-dependency.mdx")],
  "de0d9fa0": [() => import(/* webpackChunkName: "de0d9fa0" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer-resolved-inline.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer-resolved-inline.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer-resolved-inline.mdx")],
  "de278666": [() => import(/* webpackChunkName: "de278666" */ "@site/docs/api/lusid/schemas/schema-order.mdx"), "@site/docs/api/lusid/schemas/schema-order.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order.mdx")],
  "de59cab6": [() => import(/* webpackChunkName: "de59cab6" */ "@site/docs/api/workflow/endpoints/task-definitions/operation-list-tasks-for-task-definition.mdx"), "@site/docs/api/workflow/endpoints/task-definitions/operation-list-tasks-for-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/task-definitions/operation-list-tasks-for-task-definition.mdx")],
  "de5d3215": [() => import(/* webpackChunkName: "de5d3215" */ "@site/docs/api/lusid/schemas/schema-workspace-creation-request.mdx"), "@site/docs/api/lusid/schemas/schema-workspace-creation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace-creation-request.mdx")],
  "de687ff8": [() => import(/* webpackChunkName: "de687ff8" */ "@site/docs/api/drive/schemas/schema-storage-object.mdx"), "@site/docs/api/drive/schemas/schema-storage-object.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-storage-object.mdx")],
  "dea1beb5": [() => import(/* webpackChunkName: "dea1beb5" */ "@site/docs/api/workflow/endpoints/event-handlers/operation-update-event-handler.mdx"), "@site/docs/api/workflow/endpoints/event-handlers/operation-update-event-handler.mdx", require.resolveWeak("@site/docs/api/workflow/endpoints/event-handlers/operation-update-event-handler.mdx")],
  "deaf9fb0": [() => import(/* webpackChunkName: "deaf9fb0" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-fee.mdx")],
  "dec97978": [() => import(/* webpackChunkName: "dec97978" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-set.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-set.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-delete-configuration-set.mdx")],
  "decda81c": [() => import(/* webpackChunkName: "decda81c" */ "@site/docs/api/lusid/schemas/schema-interest-rate-swaption.mdx"), "@site/docs/api/lusid/schemas/schema-interest-rate-swaption.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-interest-rate-swaption.mdx")],
  "def7b017": [() => import(/* webpackChunkName: "def7b017" */ "@site/docs/api/luminesce/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/luminesce/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-access-controlled-resource.mdx")],
  "df050dad": [() => import(/* webpackChunkName: "df050dad" */ "@site/docs/api/lusid/schemas/schema-change.mdx"), "@site/docs/api/lusid/schemas/schema-change.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-change.mdx")],
  "df150f6f": [() => import(/* webpackChunkName: "df150f6f" */ "@site/docs/excel/functions/Utilities.mdx"), "@site/docs/excel/functions/Utilities.mdx", require.resolveWeak("@site/docs/excel/functions/Utilities.mdx")],
  "df4402c6": [() => import(/* webpackChunkName: "df4402c6" */ "@site/docs/api/lusid/schemas/schema-constant-volatility-surface.mdx"), "@site/docs/api/lusid/schemas/schema-constant-volatility-surface.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-constant-volatility-surface.mdx")],
  "dfb09a05": [() => import(/* webpackChunkName: "dfb09a05" */ "@site/docs/api/lusid/schemas/schema-complex-market-data.mdx"), "@site/docs/api/lusid/schemas/schema-complex-market-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complex-market-data.mdx")],
  "dfd30761": [() => import(/* webpackChunkName: "dfd30761" */ "@site/docs/api/lusid/endpoints/portfolios/operation-delete-key-from-portfolio-access-metadata.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-delete-key-from-portfolio-access-metadata.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-delete-key-from-portfolio-access-metadata.mdx")],
  "dff87221": [() => import(/* webpackChunkName: "dff87221" */ "@site/docs/api/lusid/schemas/schema-lusid-trade-ticket.mdx"), "@site/docs/api/lusid/schemas/schema-lusid-trade-ticket.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lusid-trade-ticket.mdx")],
  "e0059583": [() => import(/* webpackChunkName: "e0059583" */ "@site/docs/api/access/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/access/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/access/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "e022547f": [() => import(/* webpackChunkName: "e022547f" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-modules.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-modules.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-list-cleardown-modules.mdx")],
  "e0317c2a": [() => import(/* webpackChunkName: "e0317c2a" */ "@site/docs/api/lusid/schemas/schema-property-value.mdx"), "@site/docs/api/lusid/schemas/schema-property-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-value.mdx")],
  "e0321824": [() => import(/* webpackChunkName: "e0321824" */ "@site/docs/api/lusid/schemas/schema-reference-list-request.mdx"), "@site/docs/api/lusid/schemas/schema-reference-list-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reference-list-request.mdx")],
  "e043e8db": [() => import(/* webpackChunkName: "e043e8db" */ "@site/docs/api/luminesce/schemas/schema-options-sq-lite.mdx"), "@site/docs/api/luminesce/schemas/schema-options-sq-lite.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-options-sq-lite.mdx")],
  "e062e219": [() => import(/* webpackChunkName: "e062e219" */ "@site/docs/api/configuration/schemas/schema-lusid-validation-problem-details.mdx"), "@site/docs/api/configuration/schemas/schema-lusid-validation-problem-details.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-lusid-validation-problem-details.mdx")],
  "e08ffecb": [() => import(/* webpackChunkName: "e08ffecb" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-execution.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-execution.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-execution.mdx")],
  "e09e4c8b": [() => import(/* webpackChunkName: "e09e4c8b" */ "@site/docs/api/lusid/endpoints/quotes/operation-get-quotes-access-metadata-rule.mdx"), "@site/docs/api/lusid/endpoints/quotes/operation-get-quotes-access-metadata-rule.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/quotes/operation-get-quotes-access-metadata-rule.mdx")],
  "e0bbed55": [() => import(/* webpackChunkName: "e0bbed55" */ "@site/docs/api/lusid/schemas/schema-corporate-action-transition-component-request.mdx"), "@site/docs/api/lusid/schemas/schema-corporate-action-transition-component-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-corporate-action-transition-component-request.mdx")],
  "e11cae30": [() => import(/* webpackChunkName: "e11cae30" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-custodian-accounts.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-custodian-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-delete-custodian-accounts.mdx")],
  "e145aec0": [() => import(/* webpackChunkName: "e145aec0" */ "@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-template.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-template.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-delete-compliance-template.mdx")],
  "e165315c": [() => import(/* webpackChunkName: "e165315c" */ "@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-run-summary.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-run-summary.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-upsert-compliance-run-summary.mdx")],
  "e172b75a": [() => import(/* webpackChunkName: "e172b75a" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliations.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-list-reconciliations.mdx")],
  "e184a823": [() => import(/* webpackChunkName: "e184a823" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-synopsis.mdx")],
  "e184d9ef": [() => import(/* webpackChunkName: "e184d9ef" */ "@site/docs/api/lusid/endpoints/workspace/operation-get-personal-item.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-get-personal-item.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-get-personal-item.mdx")],
  "e1a7ac6e": [() => import(/* webpackChunkName: "e1a7ac6e" */ "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relations.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-get-portfolio-relations.mdx")],
  "e1aadc91": [() => import(/* webpackChunkName: "e1aadc91" */ "@site/docs/api/lusid/schemas/schema-amortisation-rule.mdx"), "@site/docs/api/lusid/schemas/schema-amortisation-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-amortisation-rule.mdx")],
  "e1b112d1": [() => import(/* webpackChunkName: "e1b112d1" */ "@site/docs/api/lusid/schemas/schema-equity-curve-by-prices-data.mdx"), "@site/docs/api/lusid/schemas/schema-equity-curve-by-prices-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-curve-by-prices-data.mdx")],
  "e1cfba46": [() => import(/* webpackChunkName: "e1cfba46" */ "@site/docs/api/lusid/schemas/schema-rounding-convention.mdx"), "@site/docs/api/lusid/schemas/schema-rounding-convention.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-rounding-convention.mdx")],
  "e1e39fa8": [() => import(/* webpackChunkName: "e1e39fa8" */ "@site/docs/api/lusid/endpoints/workspace/operation-list-shared-items.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-list-shared-items.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-list-shared-items.mdx")],
  "e1e4be42": [() => import(/* webpackChunkName: "e1e4be42" */ "@site/docs/api/lusid/endpoints/sequences/operation-create-sequence.mdx"), "@site/docs/api/lusid/endpoints/sequences/operation-create-sequence.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/sequences/operation-create-sequence.mdx")],
  "e1ef04ac": [() => import(/* webpackChunkName: "e1ef04ac" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-intellisense.mdx")],
  "e20d982e": [() => import(/* webpackChunkName: "e20d982e" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-actions.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-actions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-delete-corporate-actions.mdx")],
  "e20f4883": [() => import(/* webpackChunkName: "e20f4883" */ "@site/docs/api/workflow/schemas/schema-get-worker-result-response.mdx"), "@site/docs/api/workflow/schemas/schema-get-worker-result-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-get-worker-result-response.mdx")],
  "e21847ee": [() => import(/* webpackChunkName: "e21847ee" */ "@site/docs/api/lusid/schemas/schema-valuation-point-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-valuation-point-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-valuation-point-data-response.mdx")],
  "e24a7256": [() => import(/* webpackChunkName: "e24a7256" */ "@site/docs/api/web-app/schemas/schema-option-set-item.mdx"), "@site/docs/api/web-app/schemas/schema-option-set-item.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-option-set-item.mdx")],
  "e28f4c8f": [() => import(/* webpackChunkName: "e28f4c8f" */ "@site/docs/api/configuration/schemas/schema-access-controlled-resource.mdx"), "@site/docs/api/configuration/schemas/schema-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-access-controlled-resource.mdx")],
  "e2a71a1f": [() => import(/* webpackChunkName: "e2a71a1f" */ "@site/docs/api/lusid/endpoints/calendars/operation-batch-upsert-dates-for-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-batch-upsert-dates-for-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-batch-upsert-dates-for-calendar.mdx")],
  "e2b752da": [() => import(/* webpackChunkName: "e2b752da" */ "@site/docs/api/workflow/schemas/schema-parameter.mdx"), "@site/docs/api/workflow/schemas/schema-parameter.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-parameter.mdx")],
  "e2d0732c": [() => import(/* webpackChunkName: "e2d0732c" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-portfolio-group.mdx")],
  "e2ee5af3": [() => import(/* webpackChunkName: "e2ee5af3" */ "@site/docs/api/horizon/schemas/schema-audit-update-request.mdx"), "@site/docs/api/horizon/schemas/schema-audit-update-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-update-request.mdx")],
  "e32721cc": [() => import(/* webpackChunkName: "e32721cc" */ "@site/docs/api/lusid/endpoints/fee-types/operation-create-fee-type.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-create-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-create-fee-type.mdx")],
  "e33f72de": [() => import(/* webpackChunkName: "e33f72de" */ "@site/docs/api/horizon/schemas/schema-integration-description.mdx"), "@site/docs/api/horizon/schemas/schema-integration-description.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-integration-description.mdx")],
  "e351ed3a": [() => import(/* webpackChunkName: "e351ed3a" */ "@site/docs/api/lusid/schemas/schema-data-definition.mdx"), "@site/docs/api/lusid/schemas/schema-data-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-definition.mdx")],
  "e3748b0e": [() => import(/* webpackChunkName: "e3748b0e" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-upsert-accounts.mdx")],
  "e3775829": [() => import(/* webpackChunkName: "e3775829" */ "@site/docs/api/lusid/schemas/schema-accept-estimate-valuation-point-response.mdx"), "@site/docs/api/lusid/schemas/schema-accept-estimate-valuation-point-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-accept-estimate-valuation-point-response.mdx")],
  "e38fb211": [() => import(/* webpackChunkName: "e38fb211" */ "@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule-id.mdx"), "@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-quote-access-metadata-rule-id.mdx")],
  "e3ec5a73": [() => import(/* webpackChunkName: "e3ec5a73" */ "@site/docs/api/lusid/schemas/schema-complete-portfolio.mdx"), "@site/docs/api/lusid/schemas/schema-complete-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-complete-portfolio.mdx")],
  "e3f4869a": [() => import(/* webpackChunkName: "e3f4869a" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-configurations.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-configurations.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-list-rebalance-configurations.mdx")],
  "e3fe994e": [() => import(/* webpackChunkName: "e3fe994e" */ "@site/docs/api/lusid/schemas/schema-relation.mdx"), "@site/docs/api/lusid/schemas/schema-relation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-relation.mdx")],
  "e421abfe": [() => import(/* webpackChunkName: "e421abfe" */ "@site/docs/api/luminesce/schemas/schema-source.mdx"), "@site/docs/api/luminesce/schemas/schema-source.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-source.mdx")],
  "e42ab36e": [() => import(/* webpackChunkName: "e42ab36e" */ "@site/docs/api/web-app/schemas/schema-rebalance-configuration.mdx"), "@site/docs/api/web-app/schemas/schema-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-rebalance-configuration.mdx")],
  "e481e569": [() => import(/* webpackChunkName: "e481e569" */ "@site/docs/api/lusid/schemas/schema-additional-payment.mdx"), "@site/docs/api/lusid/schemas/schema-additional-payment.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-additional-payment.mdx")],
  "e48714cc": [() => import(/* webpackChunkName: "e48714cc" */ "@site/docs/api/drive/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/drive/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "e4a0d0d7": [() => import(/* webpackChunkName: "e4a0d0d7" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-item.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-item.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-update-configuration-item.mdx")],
  "e4c80972": [() => import(/* webpackChunkName: "e4c80972" */ "@site/docs/api/lusid/schemas/schema-cut-label-definition.mdx"), "@site/docs/api/lusid/schemas/schema-cut-label-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cut-label-definition.mdx")],
  "e4f8729a": [() => import(/* webpackChunkName: "e4f8729a" */ "@site/docs/api/access/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/access/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-identifier-part-schema.mdx")],
  "e50822bf": [() => import(/* webpackChunkName: "e50822bf" */ "@site/docs/api/lusid/endpoints/compliance/operation-get-decorated-compliance-run-summary.mdx"), "@site/docs/api/lusid/endpoints/compliance/operation-get-decorated-compliance-run-summary.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/compliance/operation-get-decorated-compliance-run-summary.mdx")],
  "e50834a8": [() => import(/* webpackChunkName: "e50834a8" */ "@site/docs/api/lusid/endpoints/allocations/operation-list-allocations.mdx"), "@site/docs/api/lusid/endpoints/allocations/operation-list-allocations.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/allocations/operation-list-allocations.mdx")],
  "e51efbaa": [() => import(/* webpackChunkName: "e51efbaa" */ "@site/docs/api/lusid/endpoints/entities/operation-get-custom-entity-by-entity-unique-id.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-custom-entity-by-entity-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-custom-entity-by-entity-unique-id.mdx")],
  "e58f421d": [() => import(/* webpackChunkName: "e58f421d" */ "@site/docs/api/lusid/schemas/schema-recipe-value.mdx"), "@site/docs/api/lusid/schemas/schema-recipe-value.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-recipe-value.mdx")],
  "e5979902": [() => import(/* webpackChunkName: "e5979902" */ "@site/docs/api/lusid/schemas/schema-property-key-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-property-key-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-key-list-compliance-parameter.mdx")],
  "e5980f79": [() => import(/* webpackChunkName: "e5980f79" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-configuration-recipe.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-configuration-recipe.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-delete-configuration-recipe.mdx")],
  "e5a80764": [() => import(/* webpackChunkName: "e5a80764" */ "@site/docs/api/lusid/schemas/schema-portfolio-search-result.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-search-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-search-result.mdx")],
  "e5bf9fda": [() => import(/* webpackChunkName: "e5bf9fda" */ "@site/docs/api/lusid/schemas/schema-trigger-event.mdx"), "@site/docs/api/lusid/schemas/schema-trigger-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-trigger-event.mdx")],
  "e5c3639e": [() => import(/* webpackChunkName: "e5c3639e" */ "@site/docs/api/lusid/schemas/schema-update-staging-rule-set-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-staging-rule-set-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-staging-rule-set-request.mdx")],
  "e5c73ef1": [() => import(/* webpackChunkName: "e5c73ef1" */ "@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders-and-move-remaining.mdx"), "@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders-and-move-remaining.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/order-management/operation-cancel-orders-and-move-remaining.mdx")],
  "e5d072e3": [() => import(/* webpackChunkName: "e5d072e3" */ "@site/docs/api/configuration/schemas/schema-link.mdx"), "@site/docs/api/configuration/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-link.mdx")],
  "e5e98d28": [() => import(/* webpackChunkName: "e5e98d28" */ "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-vendor-log.mdx"), "@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-vendor-log.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-resource-list-with-histogram-of-vendor-log.mdx")],
  "e6197dec": [() => import(/* webpackChunkName: "e6197dec" */ "@site/docs/api/luminesce/schemas/schema-order-by-direction.mdx"), "@site/docs/api/luminesce/schemas/schema-order-by-direction.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-order-by-direction.mdx")],
  "e635a212": [() => import(/* webpackChunkName: "e635a212" */ "@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-by-entity-unique-id.mdx"), "@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-by-entity-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/entities/operation-get-portfolio-by-entity-unique-id.mdx")],
  "e64f9d04": [() => import(/* webpackChunkName: "e64f9d04" */ "@site/docs/api/workflow/schemas/schema-stack.mdx"), "@site/docs/api/workflow/schemas/schema-stack.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-stack.mdx")],
  "e68e036a": [() => import(/* webpackChunkName: "e68e036a" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-package.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-package.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-package.mdx")],
  "e68ef3e9": [() => import(/* webpackChunkName: "e68ef3e9" */ "@site/docs/api/lusid/schemas/schema-general-ledger-profile-response.mdx"), "@site/docs/api/lusid/schemas/schema-general-ledger-profile-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-general-ledger-profile-response.mdx")],
  "e6919b3e": [() => import(/* webpackChunkName: "e6919b3e" */ "@site/docs/api/lusid/schemas/schema-upsert-instruments-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-instruments-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-instruments-response.mdx")],
  "e6bf6f72": [() => import(/* webpackChunkName: "e6bf6f72" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-placement.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-placement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-placement.mdx")],
  "e6d967b4": [() => import(/* webpackChunkName: "e6d967b4" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-rulesets.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-rulesets.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-comparison-rulesets.mdx")],
  "e6de59f8": [() => import(/* webpackChunkName: "e6de59f8" */ "@site/docs/api/lusid/schemas/schema-filter-predicate-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-filter-predicate-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-filter-predicate-compliance-parameter.mdx")],
  "e6f25853": [() => import(/* webpackChunkName: "e6f25853" */ "@site/docs/api/web-app/schemas/schema-re-captcha.mdx"), "@site/docs/api/web-app/schemas/schema-re-captcha.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-re-captcha.mdx")],
  "e6fa5d47": [() => import(/* webpackChunkName: "e6fa5d47" */ "@site/docs/api/lusid/schemas/schema-composite-dispersion.mdx"), "@site/docs/api/lusid/schemas/schema-composite-dispersion.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-composite-dispersion.mdx")],
  "e702aa7a": [() => import(/* webpackChunkName: "e702aa7a" */ "@site/docs/api/notification/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/notification/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "e7035890": [() => import(/* webpackChunkName: "e7035890" */ "@site/docs/api/lusid/schemas/schema-string-list.mdx"), "@site/docs/api/lusid/schemas/schema-string-list.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-string-list.mdx")],
  "e7072868": [() => import(/* webpackChunkName: "e7072868" */ "@site/docs/api/lusid/schemas/schema-batch-upsert-instrument-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-batch-upsert-instrument-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-batch-upsert-instrument-properties-response.mdx")],
  "e71274a3": [() => import(/* webpackChunkName: "e71274a3" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-create-rebalance-configuration.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-create-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-create-rebalance-configuration.mdx")],
  "e74e3aa3": [() => import(/* webpackChunkName: "e74e3aa3" */ "@site/docs/api/lusid/schemas/schema-get-reference-portfolio-constituents-response.mdx"), "@site/docs/api/lusid/schemas/schema-get-reference-portfolio-constituents-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-get-reference-portfolio-constituents-response.mdx")],
  "e75d3c24": [() => import(/* webpackChunkName: "e75d3c24" */ "@site/docs/api/workflow/schemas/schema-scheduler-job-response.mdx"), "@site/docs/api/workflow/schemas/schema-scheduler-job-response.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-scheduler-job-response.mdx")],
  "e7820ae3": [() => import(/* webpackChunkName: "e7820ae3" */ "@site/docs/api/workflow/schemas/schema-resource-list-of-task.mdx"), "@site/docs/api/workflow/schemas/schema-resource-list-of-task.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-resource-list-of-task.mdx")],
  "e78e0a27": [() => import(/* webpackChunkName: "e78e0a27" */ "@site/docs/api/lusid/schemas/schema-credit-support-annex.mdx"), "@site/docs/api/lusid/schemas/schema-credit-support-annex.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-credit-support-annex.mdx")],
  "e7efcdb0": [() => import(/* webpackChunkName: "e7efcdb0" */ "@site/docs/api/lusid/schemas/schema-cash-settled.mdx"), "@site/docs/api/lusid/schemas/schema-cash-settled.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-settled.mdx")],
  "e7f94adc": [() => import(/* webpackChunkName: "e7f94adc" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-share-rebalance-configuration.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-share-rebalance-configuration.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-share-rebalance-configuration.mdx")],
  "e82f94b5": [() => import(/* webpackChunkName: "e82f94b5" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-aggregated-return.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-aggregated-return.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-aggregated-return.mdx")],
  "e85a50e8": [() => import(/* webpackChunkName: "e85a50e8" */ "@site/docs/api/workflow/schemas/schema-event-matching-pattern.mdx"), "@site/docs/api/workflow/schemas/schema-event-matching-pattern.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-event-matching-pattern.mdx")],
  "e85df563": [() => import(/* webpackChunkName: "e85df563" */ "@site/docs/api/access/schemas/schema-date-unit.mdx"), "@site/docs/api/access/schemas/schema-date-unit.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-date-unit.mdx")],
  "e885552a": [() => import(/* webpackChunkName: "e885552a" */ "@site/docs/api/lusid/schemas/schema-upsert-legal-entity-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-legal-entity-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-legal-entity-request.mdx")],
  "e8c6cfb2": [() => import(/* webpackChunkName: "e8c6cfb2" */ "@site/docs/api/lusid/schemas/schema-delete-relationship-request.mdx"), "@site/docs/api/lusid/schemas/schema-delete-relationship-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-relationship-request.mdx")],
  "e8ce97e1": [() => import(/* webpackChunkName: "e8ce97e1" */ "@site/docs/api/access/endpoints/user-roles/operation-list-user-roles.mdx"), "@site/docs/api/access/endpoints/user-roles/operation-list-user-roles.mdx", require.resolveWeak("@site/docs/api/access/endpoints/user-roles/operation-list-user-roles.mdx")],
  "e910f855": [() => import(/* webpackChunkName: "e910f855" */ "@site/docs/api/lusid/schemas/schema-sequence-definition.mdx"), "@site/docs/api/lusid/schemas/schema-sequence-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-sequence-definition.mdx")],
  "e930370a": [() => import(/* webpackChunkName: "e930370a" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transaction-history.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transaction-history.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-transaction-history.mdx")],
  "e938e478": [() => import(/* webpackChunkName: "e938e478" */ "@site/docs/api/web-app/schemas/schema-absolute-rule.mdx"), "@site/docs/api/web-app/schemas/schema-absolute-rule.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-absolute-rule.mdx")],
  "e938f76f": [() => import(/* webpackChunkName: "e938f76f" */ "@site/docs/api/scheduler/schemas/schema-start-job-response.mdx"), "@site/docs/api/scheduler/schemas/schema-start-job-response.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-start-job-response.mdx")],
  "e95180a8": [() => import(/* webpackChunkName: "e95180a8" */ "@site/docs/api/lusid/schemas/schema-ir-vol-cube-data.mdx"), "@site/docs/api/lusid/schemas/schema-ir-vol-cube-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-ir-vol-cube-data.mdx")],
  "e97b36ee": [() => import(/* webpackChunkName: "e97b36ee" */ "@site/docs/api/access/endpoints/policies/operation-evaluate.mdx"), "@site/docs/api/access/endpoints/policies/operation-evaluate.mdx", require.resolveWeak("@site/docs/api/access/endpoints/policies/operation-evaluate.mdx")],
  "e9a5aa58": [() => import(/* webpackChunkName: "e9a5aa58" */ "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/api-docs/plugin-route-context-module-100.json"), "/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/api-docs/plugin-route-context-module-100.json", require.resolveWeak("/tmp/build/fbc8962a/repo-code/.docusaurus/docusaurus-plugin-content-docs/api-docs/plugin-route-context-module-100.json")],
  "e9cdbfa1": [() => import(/* webpackChunkName: "e9cdbfa1" */ "@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-configuration-movement-data.mdx")],
  "e9d43fc2": [() => import(/* webpackChunkName: "e9d43fc2" */ "@site/docs/api/drive/endpoints/application-metadata/operation-list-access-controlled-resources.mdx"), "@site/docs/api/drive/endpoints/application-metadata/operation-list-access-controlled-resources.mdx", require.resolveWeak("@site/docs/api/drive/endpoints/application-metadata/operation-list-access-controlled-resources.mdx")],
  "e9d86a5d": [() => import(/* webpackChunkName: "e9d86a5d" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-performance-return.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-performance-return.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-performance-return.mdx")],
  "ea171627": [() => import(/* webpackChunkName: "ea171627" */ "@site/docs/api/lusid/schemas/schema-instrument-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-list-compliance-parameter.mdx")],
  "ea1e87be": [() => import(/* webpackChunkName: "ea1e87be" */ "@site/docs/api/horizon/endpoints/process-history/operation-process-history-entries.mdx"), "@site/docs/api/horizon/endpoints/process-history/operation-process-history-entries.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/process-history/operation-process-history-entries.mdx")],
  "ea30d5b1": [() => import(/* webpackChunkName: "ea30d5b1" */ "@site/docs/api/web-app/schemas/schema-absolute-rules.mdx"), "@site/docs/api/web-app/schemas/schema-absolute-rules.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-absolute-rules.mdx")],
  "ea7c59f3": [() => import(/* webpackChunkName: "ea7c59f3" */ "@site/docs/api/lusid/schemas/schema-transaction-template-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-template-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-template-request.mdx")],
  "ea982151": [() => import(/* webpackChunkName: "ea982151" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-detail.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-execution-detail.mdx")],
  "eaa57602": [() => import(/* webpackChunkName: "eaa57602" */ "@site/docs/api/lusid/schemas/schema-raw-vendor-event.mdx"), "@site/docs/api/lusid/schemas/schema-raw-vendor-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-raw-vendor-event.mdx")],
  "eab3b5d4": [() => import(/* webpackChunkName: "eab3b5d4" */ "@site/docs/api/lusid/schemas/schema-portfolios-reconciliation-request.mdx"), "@site/docs/api/lusid/schemas/schema-portfolios-reconciliation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolios-reconciliation-request.mdx")],
  "eacbb45c": [() => import(/* webpackChunkName: "eacbb45c" */ "@site/docs/api/scheduler/endpoints/images/operation-get-image.mdx"), "@site/docs/api/scheduler/endpoints/images/operation-get-image.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/images/operation-get-image.mdx")],
  "ead235dd": [() => import(/* webpackChunkName: "ead235dd" */ "@site/docs/api/access/schemas/schema-link.mdx"), "@site/docs/api/access/schemas/schema-link.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-link.mdx")],
  "eae6c1ae": [() => import(/* webpackChunkName: "eae6c1ae" */ "@site/docs/api/web-app/schemas/schema-personalisation-scope.mdx"), "@site/docs/api/web-app/schemas/schema-personalisation-scope.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-personalisation-scope.mdx")],
  "eb2f341d": [() => import(/* webpackChunkName: "eb2f341d" */ "@site/docs/api/configuration/schemas/schema-action-id.mdx"), "@site/docs/api/configuration/schemas/schema-action-id.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-action-id.mdx")],
  "eb2f8c4c": [() => import(/* webpackChunkName: "eb2f8c4c" */ "@site/docs/api/lusid/endpoints/blocks/operation-list-blocks.mdx"), "@site/docs/api/lusid/endpoints/blocks/operation-list-blocks.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/blocks/operation-list-blocks.mdx")],
  "eb327266": [() => import(/* webpackChunkName: "eb327266" */ "@site/docs/api/configuration/schemas/schema-configuration-set-summary.mdx"), "@site/docs/api/configuration/schemas/schema-configuration-set-summary.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-configuration-set-summary.mdx")],
  "eb4cf174": [() => import(/* webpackChunkName: "eb4cf174" */ "@site/docs/api/access/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/access/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-lusid-problem-details.mdx")],
  "eb8f8dfb": [() => import(/* webpackChunkName: "eb8f8dfb" */ "@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-sql-to-design.mdx"), "@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-sql-to-design.mdx", require.resolveWeak("@site/docs/api/luminesce/endpoints/sql-design/operation-put-inlined-properties-design-sql-to-design.mdx")],
  "eba5cc23": [() => import(/* webpackChunkName: "eba5cc23" */ "@site/docs/api/lusid/schemas/schema-update-compliance-template-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-compliance-template-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-compliance-template-request.mdx")],
  "ebb8d476": [() => import(/* webpackChunkName: "ebb8d476" */ "@site/docs/api/lusid/schemas/schema-property.mdx"), "@site/docs/api/lusid/schemas/schema-property.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property.mdx")],
  "ebc0cfa1": [() => import(/* webpackChunkName: "ebc0cfa1" */ "@site/docs/api/lusid/schemas/schema-upsert-dialect-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-dialect-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-dialect-request.mdx")],
  "ebd8251b": [() => import(/* webpackChunkName: "ebd8251b" */ "@site/docs/api/lusid/schemas/schema-compliance-breached-order-info.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-breached-order-info.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-breached-order-info.mdx")],
  "ec5d9f89": [() => import(/* webpackChunkName: "ec5d9f89" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-list-custom-entities.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-list-custom-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-list-custom-entities.mdx")],
  "ec60020e": [() => import(/* webpackChunkName: "ec60020e" */ "@site/docs/api/lusid/schemas/schema-units-ratio.mdx"), "@site/docs/api/lusid/schemas/schema-units-ratio.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-units-ratio.mdx")],
  "ec77cf12": [() => import(/* webpackChunkName: "ec77cf12" */ "@site/docs/api/lusid/schemas/schema-bucketing-schedule.mdx"), "@site/docs/api/lusid/schemas/schema-bucketing-schedule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bucketing-schedule.mdx")],
  "eca36c6c": [() => import(/* webpackChunkName: "eca36c6c" */ "@site/docs/api/lusid/schemas/schema-staged-modification-decision-request.mdx"), "@site/docs/api/lusid/schemas/schema-staged-modification-decision-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-staged-modification-decision-request.mdx")],
  "eca8db4c": [() => import(/* webpackChunkName: "eca8db4c" */ "@site/docs/api/lusid/endpoints/participations/operation-delete-participation.mdx"), "@site/docs/api/lusid/endpoints/participations/operation-delete-participation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/participations/operation-delete-participation.mdx")],
  "ecb552e4": [() => import(/* webpackChunkName: "ecb552e4" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-property-definition.mdx")],
  "ecb7025c": [() => import(/* webpackChunkName: "ecb7025c" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-queryable-key.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-queryable-key.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-queryable-key.mdx")],
  "ecbb6134": [() => import(/* webpackChunkName: "ecbb6134" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-string.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-string.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-string.mdx")],
  "ece1b1be": [() => import(/* webpackChunkName: "ece1b1be" */ "@site/docs/api/workflow/schemas/schema-task-activity.mdx"), "@site/docs/api/workflow/schemas/schema-task-activity.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-activity.mdx")],
  "ecebfd8f": [() => import(/* webpackChunkName: "ecebfd8f" */ "@site/docs/api/identity/endpoints/users/operation-get-user-schema.mdx"), "@site/docs/api/identity/endpoints/users/operation-get-user-schema.mdx", require.resolveWeak("@site/docs/api/identity/endpoints/users/operation-get-user-schema.mdx")],
  "ed0e2f57": [() => import(/* webpackChunkName: "ed0e2f57" */ "@site/docs/api/lusid/schemas/schema-equity-swap.mdx"), "@site/docs/api/lusid/schemas/schema-equity-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-equity-swap.mdx")],
  "ed16adcc": [() => import(/* webpackChunkName: "ed16adcc" */ "@site/docs/api/lusid/schemas/schema-instrument-event-instruction-request.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-event-instruction-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-event-instruction-request.mdx")],
  "ed20508a": [() => import(/* webpackChunkName: "ed20508a" */ "@site/docs/api/lusid/schemas/schema-compliance-run-configuration.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-run-configuration.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-run-configuration.mdx")],
  "ed44fee8": [() => import(/* webpackChunkName: "ed44fee8" */ "@site/docs/api/scheduler/endpoints/schedules/operation-enabled-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-enabled-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-enabled-schedule.mdx")],
  "ed4bd3d3": [() => import(/* webpackChunkName: "ed4bd3d3" */ "@site/docs/api/lusid/schemas/schema-workspace-item-creation-request.mdx"), "@site/docs/api/lusid/schemas/schema-workspace-item-creation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace-item-creation-request.mdx")],
  "ed4ec518": [() => import(/* webpackChunkName: "ed4ec518" */ "@site/docs/api/lusid/schemas/schema-relation-definition.mdx"), "@site/docs/api/lusid/schemas/schema-relation-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-relation-definition.mdx")],
  "ed741cd9": [() => import(/* webpackChunkName: "ed741cd9" */ "@site/docs/api/lusid/schemas/schema-transaction.mdx"), "@site/docs/api/lusid/schemas/schema-transaction.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction.mdx")],
  "ed8f7314": [() => import(/* webpackChunkName: "ed8f7314" */ "@site/docs/api/scheduler/endpoints/schedules/operation-create-schedule.mdx"), "@site/docs/api/scheduler/endpoints/schedules/operation-create-schedule.mdx", require.resolveWeak("@site/docs/api/scheduler/endpoints/schedules/operation-create-schedule.mdx")],
  "edb7b45e": [() => import(/* webpackChunkName: "edb7b45e" */ "@site/docs/api/access/schemas/schema-policy-response.mdx"), "@site/docs/api/access/schemas/schema-policy-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-response.mdx")],
  "ede4ffd4": [() => import(/* webpackChunkName: "ede4ffd4" */ "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-ruleset.mdx"), "@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-group-reconciliation-comparison-ruleset.mdx")],
  "ee1eb770": [() => import(/* webpackChunkName: "ee1eb770" */ "@site/docs/api/access/schemas/schema-grant.mdx"), "@site/docs/api/access/schemas/schema-grant.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-grant.mdx")],
  "ee272679": [() => import(/* webpackChunkName: "ee272679" */ "@site/docs/api/lusid/schemas/schema-translation-script.mdx"), "@site/docs/api/lusid/schemas/schema-translation-script.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-translation-script.mdx")],
  "ee2d04a2": [() => import(/* webpackChunkName: "ee2d04a2" */ "@site/docs/api/lusid/schemas/schema-total-return-swap.mdx"), "@site/docs/api/lusid/schemas/schema-total-return-swap.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-total-return-swap.mdx")],
  "ee30c0d7": [() => import(/* webpackChunkName: "ee30c0d7" */ "@site/docs/api/web-app/schemas/schema-mixpanel.mdx"), "@site/docs/api/web-app/schemas/schema-mixpanel.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-mixpanel.mdx")],
  "ee3fd365": [() => import(/* webpackChunkName: "ee3fd365" */ "@site/docs/api/lusid/schemas/schema-delete-accounts-response.mdx"), "@site/docs/api/lusid/schemas/schema-delete-accounts-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-delete-accounts-response.mdx")],
  "ee527d91": [() => import(/* webpackChunkName: "ee527d91" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-holdings-for-portfolio-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-holdings-for-portfolio-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-holdings-for-portfolio-group.mdx")],
  "ee567dfd": [() => import(/* webpackChunkName: "ee567dfd" */ "@site/docs/api/lusid/schemas/schema-holding-context.mdx"), "@site/docs/api/lusid/schemas/schema-holding-context.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-holding-context.mdx")],
  "ee815e2c": [() => import(/* webpackChunkName: "ee815e2c" */ "@site/docs/api/lusid/endpoints/abor/operation-get-trial-balance.mdx"), "@site/docs/api/lusid/endpoints/abor/operation-get-trial-balance.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/abor/operation-get-trial-balance.mdx")],
  "eef10336": [() => import(/* webpackChunkName: "eef10336" */ "@site/docs/api/lusid/schemas/schema-portfolio-group-id-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-group-id-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-group-id-compliance-parameter.mdx")],
  "ef143a0e": [() => import(/* webpackChunkName: "ef143a0e" */ "@site/docs/api/lusid/schemas/schema-query-applicable-instrument-events-request.mdx"), "@site/docs/api/lusid/schemas/schema-query-applicable-instrument-events-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-query-applicable-instrument-events-request.mdx")],
  "ef21743b": [() => import(/* webpackChunkName: "ef21743b" */ "@site/docs/api/lusid/schemas/schema-market-quote.mdx"), "@site/docs/api/lusid/schemas/schema-market-quote.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-quote.mdx")],
  "ef226d72": [() => import(/* webpackChunkName: "ef226d72" */ "@site/docs/api/lusid/schemas/schema-reset-event.mdx"), "@site/docs/api/lusid/schemas/schema-reset-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-reset-event.mdx")],
  "ef400f99": [() => import(/* webpackChunkName: "ef400f99" */ "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-create-corporate-action-source.mdx"), "@site/docs/api/lusid/endpoints/corporate-action-sources/operation-create-corporate-action-source.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/corporate-action-sources/operation-create-corporate-action-source.mdx")],
  "ef4400e7": [() => import(/* webpackChunkName: "ef4400e7" */ "@site/docs/api/lusid/schemas/schema-fx-forward-settlement-event.mdx"), "@site/docs/api/lusid/schemas/schema-fx-forward-settlement-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-forward-settlement-event.mdx")],
  "ef65bc5d": [() => import(/* webpackChunkName: "ef65bc5d" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-result-detail.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-result-detail.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-result-detail.mdx")],
  "ef6ca105": [() => import(/* webpackChunkName: "ef6ca105" */ "@site/docs/api/lusid/schemas/schema-upsert-compliance-rule-request.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-compliance-rule-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-compliance-rule-request.mdx")],
  "ef733df4": [() => import(/* webpackChunkName: "ef733df4" */ "@site/docs/api/lusid/schemas/schema-update-portfolio-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-portfolio-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-portfolio-request.mdx")],
  "ef885398": [() => import(/* webpackChunkName: "ef885398" */ "@site/docs/api/workflow/schemas/schema-task-definition.mdx"), "@site/docs/api/workflow/schemas/schema-task-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task-definition.mdx")],
  "ef8dd074": [() => import(/* webpackChunkName: "ef8dd074" */ "@site/docs/api/lusid/endpoints/fee-types/operation-update-fee-type.mdx"), "@site/docs/api/lusid/endpoints/fee-types/operation-update-fee-type.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/fee-types/operation-update-fee-type.mdx")],
  "efaf03e8": [() => import(/* webpackChunkName: "efaf03e8" */ "@site/docs/api/luminesce/schemas/schema-luminesce-binary-type.mdx"), "@site/docs/api/luminesce/schemas/schema-luminesce-binary-type.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-luminesce-binary-type.mdx")],
  "efba8fc3": [() => import(/* webpackChunkName: "efba8fc3" */ "@site/docs/api/scheduler/schemas/schema-upload-image-request.mdx"), "@site/docs/api/scheduler/schemas/schema-upload-image-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-upload-image-request.mdx")],
  "efbe796f": [() => import(/* webpackChunkName: "efbe796f" */ "@site/docs/api/lusid/schemas/schema-transaction-type-alias.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-type-alias.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-type-alias.mdx")],
  "efcf3de8": [() => import(/* webpackChunkName: "efcf3de8" */ "@site/docs/api/access/schemas/schema-resource-list-of-policy-collection-response.mdx"), "@site/docs/api/access/schemas/schema-resource-list-of-policy-collection-response.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-list-of-policy-collection-response.mdx")],
  "efdcc04b": [() => import(/* webpackChunkName: "efdcc04b" */ "@site/docs/api/identity/schemas/schema-password-policy-response.mdx"), "@site/docs/api/identity/schemas/schema-password-policy-response.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-password-policy-response.mdx")],
  "eff4be62": [() => import(/* webpackChunkName: "eff4be62" */ "@site/docs/api/lusid/endpoints/funds/operation-create-fee.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-create-fee.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-create-fee.mdx")],
  "eff8b483": [() => import(/* webpackChunkName: "eff8b483" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-placement.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-placement.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-placement.mdx")],
  "f011a227": [() => import(/* webpackChunkName: "f011a227" */ "@site/docs/api/lusid/schemas/schema-custodian-account.mdx"), "@site/docs/api/lusid/schemas/schema-custodian-account.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-custodian-account.mdx")],
  "f01b824d": [() => import(/* webpackChunkName: "f01b824d" */ "@site/docs/api/workflow/schemas/schema-parameter-value.mdx"), "@site/docs/api/workflow/schemas/schema-parameter-value.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-parameter-value.mdx")],
  "f027f59c": [() => import(/* webpackChunkName: "f027f59c" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-upsert-reconciliation-mapping.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-upsert-reconciliation-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-upsert-reconciliation-mapping.mdx")],
  "f057eab3": [() => import(/* webpackChunkName: "f057eab3" */ "@site/docs/api/access/schemas/schema-policy-collection-id.mdx"), "@site/docs/api/access/schemas/schema-policy-collection-id.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-collection-id.mdx")],
  "f05955e7": [() => import(/* webpackChunkName: "f05955e7" */ "@site/docs/excel/functions/Reconciliation.mdx"), "@site/docs/excel/functions/Reconciliation.mdx", require.resolveWeak("@site/docs/excel/functions/Reconciliation.mdx")],
  "f0789a1f": [() => import(/* webpackChunkName: "f0789a1f" */ "@site/docs/api/access/schemas/schema-resource-details.mdx"), "@site/docs/api/access/schemas/schema-resource-details.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-resource-details.mdx")],
  "f08b9f32": [() => import(/* webpackChunkName: "f08b9f32" */ "@site/docs/api/lusid/schemas/schema-lusid-unique-id.mdx"), "@site/docs/api/lusid/schemas/schema-lusid-unique-id.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lusid-unique-id.mdx")],
  "f09796e0": [() => import(/* webpackChunkName: "f09796e0" */ "@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data-request.mdx"), "@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-transaction-set-configuration-data-request.mdx")],
  "f09c73ba": [() => import(/* webpackChunkName: "f09c73ba" */ "@site/docs/api/web-app/schemas/schema-global-search-transaction-response.mdx"), "@site/docs/api/web-app/schemas/schema-global-search-transaction-response.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-global-search-transaction-response.mdx")],
  "f0a606ab": [() => import(/* webpackChunkName: "f0a606ab" */ "@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation.mdx"), "@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reconciliations/operation-get-reconciliation.mdx")],
  "f0b7e1a4": [() => import(/* webpackChunkName: "f0b7e1a4" */ "@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituents.mdx"), "@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituents.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/reference-portfolio/operation-upsert-reference-portfolio-constituents.mdx")],
  "f0d8d503": [() => import(/* webpackChunkName: "f0d8d503" */ "@site/docs/api/horizon/schemas/schema-row-details.mdx"), "@site/docs/api/horizon/schemas/schema-row-details.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-row-details.mdx")],
  "f0e7bca6": [() => import(/* webpackChunkName: "f0e7bca6" */ "@site/docs/api/access/intro.md"), "@site/docs/api/access/intro.md", require.resolveWeak("@site/docs/api/access/intro.md")],
  "f0edf11f": [() => import(/* webpackChunkName: "f0edf11f" */ "@site/docs/api/lusid/schemas/schema-deleted-entity-response.mdx"), "@site/docs/api/lusid/schemas/schema-deleted-entity-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-deleted-entity-response.mdx")],
  "f120f26a": [() => import(/* webpackChunkName: "f120f26a" */ "@site/docs/api/notification/schemas/schema-create-subscription.mdx"), "@site/docs/api/notification/schemas/schema-create-subscription.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-create-subscription.mdx")],
  "f153d8fc": [() => import(/* webpackChunkName: "f153d8fc" */ "@site/docs/excel/functions/Allocations.mdx"), "@site/docs/excel/functions/Allocations.mdx", require.resolveWeak("@site/docs/excel/functions/Allocations.mdx")],
  "f168e9e7": [() => import(/* webpackChunkName: "f168e9e7" */ "@site/docs/api/lusid/schemas/schema-date-range.mdx"), "@site/docs/api/lusid/schemas/schema-date-range.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-date-range.mdx")],
  "f18961d8": [() => import(/* webpackChunkName: "f18961d8" */ "@site/docs/api/lusid/endpoints/structured-result-data/operation-create-data-map.mdx"), "@site/docs/api/lusid/endpoints/structured-result-data/operation-create-data-map.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/structured-result-data/operation-create-data-map.mdx")],
  "f1c41e2c": [() => import(/* webpackChunkName: "f1c41e2c" */ "@site/docs/api/scheduler/schemas/schema-scan-summary.mdx"), "@site/docs/api/scheduler/schemas/schema-scan-summary.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-scan-summary.mdx")],
  "f1dfc971": [() => import(/* webpackChunkName: "f1dfc971" */ "@site/docs/api/lusid/endpoints/search/operation-instruments-search.mdx"), "@site/docs/api/lusid/endpoints/search/operation-instruments-search.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/search/operation-instruments-search.mdx")],
  "f1e7421b": [() => import(/* webpackChunkName: "f1e7421b" */ "@site/docs/api/web-app/endpoints/logging/operation-logging-create-log-message.mdx"), "@site/docs/api/web-app/endpoints/logging/operation-logging-create-log-message.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/logging/operation-logging-create-log-message.mdx")],
  "f1fd7748": [() => import(/* webpackChunkName: "f1fd7748" */ "@site/docs/api/scheduler/schemas/schema-create-job-request.mdx"), "@site/docs/api/scheduler/schemas/schema-create-job-request.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-create-job-request.mdx")],
  "f22227bc": [() => import(/* webpackChunkName: "f22227bc" */ "@site/docs/api/lusid/schemas/schema-fx-conventions.mdx"), "@site/docs/api/lusid/schemas/schema-fx-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fx-conventions.mdx")],
  "f22a8349": [() => import(/* webpackChunkName: "f22a8349" */ "@site/docs/api/lusid/endpoints/orders/operation-upsert-orders.mdx"), "@site/docs/api/lusid/endpoints/orders/operation-upsert-orders.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/orders/operation-upsert-orders.mdx")],
  "f260d110": [() => import(/* webpackChunkName: "f260d110" */ "@site/docs/api/access/schemas/schema-how-spec.mdx"), "@site/docs/api/access/schemas/schema-how-spec.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-how-spec.mdx")],
  "f285fbe2": [() => import(/* webpackChunkName: "f285fbe2" */ "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-items.mdx"), "@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-items.mdx", require.resolveWeak("@site/docs/api/configuration/endpoints/configuration-sets/operation-get-system-configuration-items.mdx")],
  "f2d2c968": [() => import(/* webpackChunkName: "f2d2c968" */ "@site/docs/api/lusid/schemas/schema-create-calendar-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-calendar-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-calendar-request.mdx")],
  "f2ed57bb": [() => import(/* webpackChunkName: "f2ed57bb" */ "@site/docs/api/workflow/schemas/schema-worker-status-triggers.mdx"), "@site/docs/api/workflow/schemas/schema-worker-status-triggers.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-worker-status-triggers.mdx")],
  "f335063d": [() => import(/* webpackChunkName: "f335063d" */ "@site/docs/api/workflow/schemas/schema-task.mdx"), "@site/docs/api/workflow/schemas/schema-task.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-task.mdx")],
  "f35e36e9": [() => import(/* webpackChunkName: "f35e36e9" */ "@site/docs/api/lusid/schemas/schema-cash-and-security-offer-election.mdx"), "@site/docs/api/lusid/schemas/schema-cash-and-security-offer-election.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cash-and-security-offer-election.mdx")],
  "f3709837": [() => import(/* webpackChunkName: "f3709837" */ "@site/docs/api/lusid/schemas/schema-client.mdx"), "@site/docs/api/lusid/schemas/schema-client.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-client.mdx")],
  "f38e183e": [() => import(/* webpackChunkName: "f38e183e" */ "@site/docs/api/lusid/schemas/schema-counterparty-risk-information.mdx"), "@site/docs/api/lusid/schemas/schema-counterparty-risk-information.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-counterparty-risk-information.mdx")],
  "f3a3ce98": [() => import(/* webpackChunkName: "f3a3ce98" */ "@site/docs/api/lusid/endpoints/sequences/operation-list-sequences.mdx"), "@site/docs/api/lusid/endpoints/sequences/operation-list-sequences.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/sequences/operation-list-sequences.mdx")],
  "f3b6d046": [() => import(/* webpackChunkName: "f3b6d046" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-translate-entities.mdx")],
  "f3c611d1": [() => import(/* webpackChunkName: "f3c611d1" */ "@site/docs/api/lusid/schemas/schema-aggregated-returns-request.mdx"), "@site/docs/api/lusid/schemas/schema-aggregated-returns-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregated-returns-request.mdx")],
  "f3ca5141": [() => import(/* webpackChunkName: "f3ca5141" */ "@site/docs/api/lusid/schemas/schema-fund-configuration-request.mdx"), "@site/docs/api/lusid/schemas/schema-fund-configuration-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fund-configuration-request.mdx")],
  "f3cd4dd3": [() => import(/* webpackChunkName: "f3cd4dd3" */ "@site/docs/api/lusid/schemas/schema-user.mdx"), "@site/docs/api/lusid/schemas/schema-user.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-user.mdx")],
  "f3f6bd28": [() => import(/* webpackChunkName: "f3f6bd28" */ "@site/docs/api/lusid/schemas/schema-order-graph-placement-order-synopsis.mdx"), "@site/docs/api/lusid/schemas/schema-order-graph-placement-order-synopsis.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-order-graph-placement-order-synopsis.mdx")],
  "f3feed4d": [() => import(/* webpackChunkName: "f3feed4d" */ "@site/docs/api/lusid/schemas/schema-mapping.mdx"), "@site/docs/api/lusid/schemas/schema-mapping.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mapping.mdx")],
  "f40a78e6": [() => import(/* webpackChunkName: "f40a78e6" */ "@site/docs/api/lusid/schemas/schema-annul-single-structured-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-annul-single-structured-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-annul-single-structured-data-response.mdx")],
  "f41ec1f5": [() => import(/* webpackChunkName: "f41ec1f5" */ "@site/docs/api/lusid/schemas/schema-set-amortisation-rules-request.mdx"), "@site/docs/api/lusid/schemas/schema-set-amortisation-rules-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-set-amortisation-rules-request.mdx")],
  "f4296ba5": [() => import(/* webpackChunkName: "f4296ba5" */ "@site/docs/api/web-app/endpoints/config/operation-get-version.mdx"), "@site/docs/api/web-app/endpoints/config/operation-get-version.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/config/operation-get-version.mdx")],
  "f4464f6a": [() => import(/* webpackChunkName: "f4464f6a" */ "@site/docs/api/lusid/endpoints/funds/operation-patch-fee.mdx"), "@site/docs/api/lusid/endpoints/funds/operation-patch-fee.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/funds/operation-patch-fee.mdx")],
  "f45cca19": [() => import(/* webpackChunkName: "f45cca19" */ "@site/docs/excel/functions/Reference Portfolios.mdx"), "@site/docs/excel/functions/Reference Portfolios.mdx", require.resolveWeak("@site/docs/excel/functions/Reference Portfolios.mdx")],
  "f4695aab": [() => import(/* webpackChunkName: "f4695aab" */ "@site/docs/api/web-app/schemas/schema-roadmap-item.mdx"), "@site/docs/api/web-app/schemas/schema-roadmap-item.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-roadmap-item.mdx")],
  "f47a7191": [() => import(/* webpackChunkName: "f47a7191" */ "@site/docs/api/lusid/schemas/schema-instrument-models.mdx"), "@site/docs/api/lusid/schemas/schema-instrument-models.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-instrument-models.mdx")],
  "f4da1787": [() => import(/* webpackChunkName: "f4da1787" */ "@site/docs/api/lusid/schemas/schema-pricing-options.mdx"), "@site/docs/api/lusid/schemas/schema-pricing-options.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-pricing-options.mdx")],
  "f4dce551": [() => import(/* webpackChunkName: "f4dce551" */ "@site/docs/api/lusid/schemas/schema-resource-list-of-change.mdx"), "@site/docs/api/lusid/schemas/schema-resource-list-of-change.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-resource-list-of-change.mdx")],
  "f4e76597": [() => import(/* webpackChunkName: "f4e76597" */ "@site/docs/api/drive/schemas/schema-update-file.mdx"), "@site/docs/api/drive/schemas/schema-update-file.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-update-file.mdx")],
  "f50d59ef": [() => import(/* webpackChunkName: "f50d59ef" */ "@site/docs/api/insights/endpoints/auditing/operation-get-processes.mdx"), "@site/docs/api/insights/endpoints/auditing/operation-get-processes.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/auditing/operation-get-processes.mdx")],
  "f51abbf1": [() => import(/* webpackChunkName: "f51abbf1" */ "@site/docs/api/insights/endpoints/auditing/operation-create-entry.mdx"), "@site/docs/api/insights/endpoints/auditing/operation-create-entry.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/auditing/operation-create-entry.mdx")],
  "f532f7cf": [() => import(/* webpackChunkName: "f532f7cf" */ "@site/docs/api/lusid/schemas/schema-property-value-in.mdx"), "@site/docs/api/lusid/schemas/schema-property-value-in.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-property-value-in.mdx")],
  "f573e820": [() => import(/* webpackChunkName: "f573e820" */ "@site/docs/api/lusid/schemas/schema-repo.mdx"), "@site/docs/api/lusid/schemas/schema-repo.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-repo.mdx")],
  "f5b1ec93": [() => import(/* webpackChunkName: "f5b1ec93" */ "@site/docs/api/identity/schemas/schema-api-key.mdx"), "@site/docs/api/identity/schemas/schema-api-key.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-api-key.mdx")],
  "f5c6e0b5": [() => import(/* webpackChunkName: "f5c6e0b5" */ "@site/docs/api/lusid/schemas/schema-create-recipe-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-recipe-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-recipe-request.mdx")],
  "f619ef98": [() => import(/* webpackChunkName: "f619ef98" */ "@site/docs/api/lusid/schemas/schema-data-type-summary.mdx"), "@site/docs/api/lusid/schemas/schema-data-type-summary.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-data-type-summary.mdx")],
  "f627999d": [() => import(/* webpackChunkName: "f627999d" */ "@site/docs/api/lusid/endpoints/conventions/operation-list-cds-flow-conventions.mdx"), "@site/docs/api/lusid/endpoints/conventions/operation-list-cds-flow-conventions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/conventions/operation-list-cds-flow-conventions.mdx")],
  "f62d421d": [() => import(/* webpackChunkName: "f62d421d" */ "@site/docs/api/lusid/schemas/schema-period-diary-entries-reopened-response.mdx"), "@site/docs/api/lusid/schemas/schema-period-diary-entries-reopened-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-period-diary-entries-reopened-response.mdx")],
  "f64edc0e": [() => import(/* webpackChunkName: "f64edc0e" */ "@site/docs/api/lusid/schemas/schema-bond-coupon-event.mdx"), "@site/docs/api/lusid/schemas/schema-bond-coupon-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-bond-coupon-event.mdx")],
  "f656d473": [() => import(/* webpackChunkName: "f656d473" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-resolve-instrument.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-resolve-instrument.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-resolve-instrument.mdx")],
  "f67e6c1e": [() => import(/* webpackChunkName: "f67e6c1e" */ "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-portfolio-holding.mdx"), "@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-portfolio-holding.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-versioned-resource-list-of-portfolio-holding.mdx")],
  "f6a957cb": [() => import(/* webpackChunkName: "f6a957cb" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-allocation.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-allocation.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-allocation.mdx")],
  "f6b6f602": [() => import(/* webpackChunkName: "f6b6f602" */ "@site/docs/api/lusid/schemas/schema-dialect.mdx"), "@site/docs/api/lusid/schemas/schema-dialect.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-dialect.mdx")],
  "f6b81f2e": [() => import(/* webpackChunkName: "f6b81f2e" */ "@site/docs/api/lusid/schemas/schema-market-data-overrides.mdx"), "@site/docs/api/lusid/schemas/schema-market-data-overrides.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-market-data-overrides.mdx")],
  "f6e8d7e7": [() => import(/* webpackChunkName: "f6e8d7e7" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-ladder.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-ladder.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-get-portfolio-cash-ladder.mdx")],
  "f6f68552": [() => import(/* webpackChunkName: "f6f68552" */ "@site/docs/api/lusid/schemas/schema-allocation-service-run-response.mdx"), "@site/docs/api/lusid/schemas/schema-allocation-service-run-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-allocation-service-run-response.mdx")],
  "f71c91dd": [() => import(/* webpackChunkName: "f71c91dd" */ "@site/docs/api/lusid/schemas/schema-create-date-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-date-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-date-request.mdx")],
  "f741ea29": [() => import(/* webpackChunkName: "f741ea29" */ "@site/docs/api/access/schemas/schema-generated-policy-components.mdx"), "@site/docs/api/access/schemas/schema-generated-policy-components.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-generated-policy-components.mdx")],
  "f79f9495": [() => import(/* webpackChunkName: "f79f9495" */ "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer.mdx"), "@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/configuration-recipe/operation-get-recipe-composer.mdx")],
  "f7a39702": [() => import(/* webpackChunkName: "f7a39702" */ "@site/docs/api/drive/schemas/schema-identifier-part-schema.mdx"), "@site/docs/api/drive/schemas/schema-identifier-part-schema.mdx", require.resolveWeak("@site/docs/api/drive/schemas/schema-identifier-part-schema.mdx")],
  "f7a675a1": [() => import(/* webpackChunkName: "f7a675a1" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-job-history.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-job-history.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-job-history.mdx")],
  "f7daee25": [() => import(/* webpackChunkName: "f7daee25" */ "@site/docs/api/lusid/schemas/schema-cancel-order-and-move-remaining-result.mdx"), "@site/docs/api/lusid/schemas/schema-cancel-order-and-move-remaining-result.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-cancel-order-and-move-remaining-result.mdx")],
  "f7e45c1a": [() => import(/* webpackChunkName: "f7e45c1a" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-script.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-script.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-script.mdx")],
  "f8315863": [() => import(/* webpackChunkName: "f8315863" */ "@site/docs/api/access/endpoints/roles/operation-remove-policy-collection-from-role.mdx"), "@site/docs/api/access/endpoints/roles/operation-remove-policy-collection-from-role.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-remove-policy-collection-from-role.mdx")],
  "f8b51d2d": [() => import(/* webpackChunkName: "f8b51d2d" */ "@site/docs/api/access/endpoints/roles/operation-list-roles.mdx"), "@site/docs/api/access/endpoints/roles/operation-list-roles.mdx", require.resolveWeak("@site/docs/api/access/endpoints/roles/operation-list-roles.mdx")],
  "f8d3eac0": [() => import(/* webpackChunkName: "f8d3eac0" */ "@site/docs/api/lusid/schemas/schema-fee-request.mdx"), "@site/docs/api/lusid/schemas/schema-fee-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-fee-request.mdx")],
  "f93590f2": [() => import(/* webpackChunkName: "f93590f2" */ "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-delete-amortisation-ruleset.mdx"), "@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-delete-amortisation-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/amortisation-rule-sets/operation-delete-amortisation-ruleset.mdx")],
  "f940275e": [() => import(/* webpackChunkName: "f940275e" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-accounts.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-accounts.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-accounts.mdx")],
  "f95d7d8e": [() => import(/* webpackChunkName: "f95d7d8e" */ "@site/docs/api/lusid/schemas/schema-mbs-interest-shortfall-event.mdx"), "@site/docs/api/lusid/schemas/schema-mbs-interest-shortfall-event.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-mbs-interest-shortfall-event.mdx")],
  "f9627647": [() => import(/* webpackChunkName: "f9627647" */ "@site/docs/api/lusid/schemas/schema-rules-interval.mdx"), "@site/docs/api/lusid/schemas/schema-rules-interval.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-rules-interval.mdx")],
  "f9631637": [() => import(/* webpackChunkName: "f9631637" */ "@site/docs/api/luminesce/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/luminesce/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/luminesce/schemas/schema-access-controlled-action.mdx")],
  "f981b6ed": [() => import(/* webpackChunkName: "f981b6ed" */ "@site/docs/api/lusid/schemas/schema-address-key-list-compliance-parameter.mdx"), "@site/docs/api/lusid/schemas/schema-address-key-list-compliance-parameter.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-address-key-list-compliance-parameter.mdx")],
  "f9c3ac99": [() => import(/* webpackChunkName: "f9c3ac99" */ "@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-side-definitions.mdx"), "@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-side-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-configuration/operation-list-side-definitions.mdx")],
  "f9cbe25d": [() => import(/* webpackChunkName: "f9cbe25d" */ "@site/docs/api/lusid/endpoints/calendars/operation-delete-dates-from-calendar.mdx"), "@site/docs/api/lusid/endpoints/calendars/operation-delete-dates-from-calendar.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/calendars/operation-delete-dates-from-calendar.mdx")],
  "f9e2d19d": [() => import(/* webpackChunkName: "f9e2d19d" */ "@site/docs/api/lusid/schemas/schema-side-definition-request.mdx"), "@site/docs/api/lusid/schemas/schema-side-definition-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-side-definition-request.mdx")],
  "fa1f32e9": [() => import(/* webpackChunkName: "fa1f32e9" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-group-reconciliation-definitions.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-group-reconciliation-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-list-group-reconciliation-definitions.mdx")],
  "fa372a44": [() => import(/* webpackChunkName: "fa372a44" */ "@site/docs/api/lusid/schemas/schema-aggregation-query.mdx"), "@site/docs/api/lusid/schemas/schema-aggregation-query.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-aggregation-query.mdx")],
  "fa421133": [() => import(/* webpackChunkName: "fa421133" */ "@site/docs/api/web-app/intro.md"), "@site/docs/api/web-app/intro.md", require.resolveWeak("@site/docs/api/web-app/intro.md")],
  "fa5dc62c": [() => import(/* webpackChunkName: "fa5dc62c" */ "@site/docs/api/lusid/schemas/schema-list-complex-market-data-with-meta-data-response.mdx"), "@site/docs/api/lusid/schemas/schema-list-complex-market-data-with-meta-data-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-list-complex-market-data-with-meta-data-response.mdx")],
  "faa0c33c": [() => import(/* webpackChunkName: "faa0c33c" */ "@site/docs/api/insights/endpoints/requests/operation-get-request.mdx"), "@site/docs/api/insights/endpoints/requests/operation-get-request.mdx", require.resolveWeak("@site/docs/api/insights/endpoints/requests/operation-get-request.mdx")],
  "fab03ad4": [() => import(/* webpackChunkName: "fab03ad4" */ "@site/docs/api/configuration/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/configuration/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/configuration/schemas/schema-access-controlled-action.mdx")],
  "fabc1d2f": [() => import(/* webpackChunkName: "fabc1d2f" */ "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-portfolio-details.mdx"), "@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-portfolio-details.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/transaction-portfolios/operation-upsert-portfolio-details.mdx")],
  "fafd4550": [() => import(/* webpackChunkName: "fafd4550" */ "~docs/api-docs/version-current-metadata-prop-751.json"), "~docs/api-docs/version-current-metadata-prop-751.json", require.resolveWeak("~docs/api-docs/version-current-metadata-prop-751.json")],
  "fb5860de": [() => import(/* webpackChunkName: "fb5860de" */ "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-list-custom-entity-definitions.mdx"), "@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-list-custom-entity-definitions.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entity-definitions/operation-list-custom-entity-definitions.mdx")],
  "fbc186ed": [() => import(/* webpackChunkName: "fbc186ed" */ "@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio.mdx"), "@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolios/operation-patch-portfolio.mdx")],
  "fbc6cd96": [() => import(/* webpackChunkName: "fbc6cd96" */ "@site/docs/api/lusid/schemas/schema-workspace-item.mdx"), "@site/docs/api/lusid/schemas/schema-workspace-item.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-workspace-item.mdx")],
  "fbd0b83f": [() => import(/* webpackChunkName: "fbd0b83f" */ "@site/docs/api/access/schemas/schema-remove-from-policy-collection-request.mdx"), "@site/docs/api/access/schemas/schema-remove-from-policy-collection-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-remove-from-policy-collection-request.mdx")],
  "fbd25b6b": [() => import(/* webpackChunkName: "fbd25b6b" */ "@site/docs/api/workflow/schemas/schema-result-field.mdx"), "@site/docs/api/workflow/schemas/schema-result-field.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-result-field.mdx")],
  "fbf3fe83": [() => import(/* webpackChunkName: "fbf3fe83" */ "@site/docs/api/lusid/endpoints/counterparties/operation-delete-credit-support-annex.mdx"), "@site/docs/api/lusid/endpoints/counterparties/operation-delete-credit-support-annex.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/counterparties/operation-delete-credit-support-annex.mdx")],
  "fc08e448": [() => import(/* webpackChunkName: "fc08e448" */ "@site/docs/api/horizon/schemas/schema-audit-complete-request.mdx"), "@site/docs/api/horizon/schemas/schema-audit-complete-request.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-audit-complete-request.mdx")],
  "fc3ba33c": [() => import(/* webpackChunkName: "fc3ba33c" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-from-group.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-from-group.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-delete-portfolio-from-group.mdx")],
  "fc57c31a": [() => import(/* webpackChunkName: "fc57c31a" */ "@site/docs/api/insights/schemas/schema-access-controlled-action.mdx"), "@site/docs/api/insights/schemas/schema-access-controlled-action.mdx", require.resolveWeak("@site/docs/api/insights/schemas/schema-access-controlled-action.mdx")],
  "fc879c35": [() => import(/* webpackChunkName: "fc879c35" */ "@site/docs/api/scheduler/schemas/schema-required-resources.mdx"), "@site/docs/api/scheduler/schemas/schema-required-resources.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-required-resources.mdx")],
  "fc954e29": [() => import(/* webpackChunkName: "fc954e29" */ "@site/docs/api/web-app/schemas/schema-entity-id.mdx"), "@site/docs/api/web-app/schemas/schema-entity-id.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-entity-id.mdx")],
  "fc9ee4ba": [() => import(/* webpackChunkName: "fc9ee4ba" */ "@site/docs/api/lusid/schemas/schema-lusid-problem-details.mdx"), "@site/docs/api/lusid/schemas/schema-lusid-problem-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-lusid-problem-details.mdx")],
  "fca440b3": [() => import(/* webpackChunkName: "fca440b3" */ "@site/docs/api/access/schemas/schema-as-at-range-for-spec.mdx"), "@site/docs/api/access/schemas/schema-as-at-range-for-spec.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-as-at-range-for-spec.mdx")],
  "fcb9d9ca": [() => import(/* webpackChunkName: "fcb9d9ca" */ "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-cleardown-module.mdx"), "@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-cleardown-module.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/chart-of-accounts/operation-delete-cleardown-module.mdx")],
  "fd01cb70": [() => import(/* webpackChunkName: "fd01cb70" */ "@site/docs/api/lusid/schemas/schema-create-corporate-action-source-request.mdx"), "@site/docs/api/lusid/schemas/schema-create-corporate-action-source-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-create-corporate-action-source-request.mdx")],
  "fd0b177d": [() => import(/* webpackChunkName: "fd0b177d" */ "@site/docs/api/lusid/schemas/schema-compliance-template-variation-request.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-template-variation-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-template-variation-request.mdx")],
  "fd167182": [() => import(/* webpackChunkName: "fd167182" */ "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-relationships.mdx"), "@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-relationships.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/custom-entities/operation-get-custom-entity-relationships.mdx")],
  "fd1d052b": [() => import(/* webpackChunkName: "fd1d052b" */ "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-portfolio-mapping.mdx"), "@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-portfolio-mapping.mdx", require.resolveWeak("@site/docs/api/web-app/endpoints/rebalance/operation-rebalance-delete-rebalance-portfolio-mapping.mdx")],
  "fd285ce1": [() => import(/* webpackChunkName: "fd285ce1" */ "@site/docs/api/lusid/schemas/schema-portfolio-result-data-key-rule.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-result-data-key-rule.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-result-data-key-rule.mdx")],
  "fd2a6b29": [() => import(/* webpackChunkName: "fd2a6b29" */ "@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-dialect.mdx"), "@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-dialect.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/scripted-translation/operation-get-translation-dialect.mdx")],
  "fd3236a1": [() => import(/* webpackChunkName: "fd3236a1" */ "@site/docs/api/notification/schemas/schema-update-notification-request.mdx"), "@site/docs/api/notification/schemas/schema-update-notification-request.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-update-notification-request.mdx")],
  "fdc4041c": [() => import(/* webpackChunkName: "fdc4041c" */ "@site/docs/api/lusid/schemas/schema-block-and-orders-create-request.mdx"), "@site/docs/api/lusid/schemas/schema-block-and-orders-create-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block-and-orders-create-request.mdx")],
  "fdc607cd": [() => import(/* webpackChunkName: "fdc607cd" */ "@site/docs/api/lusid/schemas/schema-value-type.mdx"), "@site/docs/api/lusid/schemas/schema-value-type.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-value-type.mdx")],
  "fdcfb679": [() => import(/* webpackChunkName: "fdcfb679" */ "@site/docs/api/horizon/schemas/schema-open-figi-parameter-option-name.mdx"), "@site/docs/api/horizon/schemas/schema-open-figi-parameter-option-name.mdx", require.resolveWeak("@site/docs/api/horizon/schemas/schema-open-figi-parameter-option-name.mdx")],
  "fdd1e13d": [() => import(/* webpackChunkName: "fdd1e13d" */ "@site/docs/api/scheduler/schemas/schema-time-trigger.mdx"), "@site/docs/api/scheduler/schemas/schema-time-trigger.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-time-trigger.mdx")],
  "fddd5b44": [() => import(/* webpackChunkName: "fddd5b44" */ "@site/docs/api/lusid/schemas/schema-portfolio-details.mdx"), "@site/docs/api/lusid/schemas/schema-portfolio-details.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-portfolio-details.mdx")],
  "fdeedb8a": [() => import(/* webpackChunkName: "fdeedb8a" */ "@site/docs/api/lusid/schemas/schema-block.mdx"), "@site/docs/api/lusid/schemas/schema-block.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-block.mdx")],
  "fdf40608": [() => import(/* webpackChunkName: "fdf40608" */ "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-response.mdx"), "@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-response.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-upsert-reference-portfolio-constituent-properties-response.mdx")],
  "fe30289d": [() => import(/* webpackChunkName: "fe30289d" */ "@site/docs/api/access/schemas/schema-policy-template-update-request.mdx"), "@site/docs/api/access/schemas/schema-policy-template-update-request.mdx", require.resolveWeak("@site/docs/api/access/schemas/schema-policy-template-update-request.mdx")],
  "fe5e299a": [() => import(/* webpackChunkName: "fe5e299a" */ "@site/docs/api/web-app/schemas/schema-global-search-instrument-parameters.mdx"), "@site/docs/api/web-app/schemas/schema-global-search-instrument-parameters.mdx", require.resolveWeak("@site/docs/api/web-app/schemas/schema-global-search-instrument-parameters.mdx")],
  "fe9af6fb": [() => import(/* webpackChunkName: "fe9af6fb" */ "@site/docs/api/identity/schemas/schema-update-user-schema-request.mdx"), "@site/docs/api/identity/schemas/schema-update-user-schema-request.mdx", require.resolveWeak("@site/docs/api/identity/schemas/schema-update-user-schema-request.mdx")],
  "fead6a49": [() => import(/* webpackChunkName: "fead6a49" */ "~docs/excel-add-in-docs/version-current-metadata-prop-751.json"), "~docs/excel-add-in-docs/version-current-metadata-prop-751.json", require.resolveWeak("~docs/excel-add-in-docs/version-current-metadata-prop-751.json")],
  "fec48ffc": [() => import(/* webpackChunkName: "fec48ffc" */ "@site/docs/api/lusid/schemas/schema-update-unit-request.mdx"), "@site/docs/api/lusid/schemas/schema-update-unit-request.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-update-unit-request.mdx")],
  "fedd3a2b": [() => import(/* webpackChunkName: "fedd3a2b" */ "@site/docs/api/lusid/schemas/schema-related-entity.mdx"), "@site/docs/api/lusid/schemas/schema-related-entity.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-related-entity.mdx")],
  "fede1205": [() => import(/* webpackChunkName: "fede1205" */ "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-access-metadata-by-key.mdx"), "@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-access-metadata-by-key.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/portfolio-groups/operation-get-portfolio-group-access-metadata-by-key.mdx")],
  "fee4e6d3": [() => import(/* webpackChunkName: "fee4e6d3" */ "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-address-key-definition.mdx"), "@site/docs/api/lusid/schemas/schema-paged-resource-list-of-address-key-definition.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-paged-resource-list-of-address-key-definition.mdx")],
  "fef50659": [() => import(/* webpackChunkName: "fef50659" */ "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-ruleset.mdx"), "@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-ruleset.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/group-reconciliations/operation-get-comparison-ruleset.mdx")],
  "fefab669": [() => import(/* webpackChunkName: "fefab669" */ "@site/docs/api/lusid/schemas/schema-compliance-rule-result-v-2.mdx"), "@site/docs/api/lusid/schemas/schema-compliance-rule-result-v-2.mdx", require.resolveWeak("@site/docs/api/lusid/schemas/schema-compliance-rule-result-v-2.mdx")],
  "ff27b55d": [() => import(/* webpackChunkName: "ff27b55d" */ "@site/docs/api/horizon/endpoints/integrations/operation-execute-instance.mdx"), "@site/docs/api/horizon/endpoints/integrations/operation-execute-instance.mdx", require.resolveWeak("@site/docs/api/horizon/endpoints/integrations/operation-execute-instance.mdx")],
  "ff3e3de0": [() => import(/* webpackChunkName: "ff3e3de0" */ "@site/docs/api/lusid/endpoints/workspace/operation-update-personal-workspace.mdx"), "@site/docs/api/lusid/endpoints/workspace/operation-update-personal-workspace.mdx", require.resolveWeak("@site/docs/api/lusid/endpoints/workspace/operation-update-personal-workspace.mdx")],
  "ff7ab283": [() => import(/* webpackChunkName: "ff7ab283" */ "@site/docs/api/scheduler/schemas/schema-resource-list-of-access-controlled-resource.mdx"), "@site/docs/api/scheduler/schemas/schema-resource-list-of-access-controlled-resource.mdx", require.resolveWeak("@site/docs/api/scheduler/schemas/schema-resource-list-of-access-controlled-resource.mdx")],
  "ff818fa6": [() => import(/* webpackChunkName: "ff818fa6" */ "@site/docs/api/notification/schemas/schema-azure-service-bus-type-response.mdx"), "@site/docs/api/notification/schemas/schema-azure-service-bus-type-response.mdx", require.resolveWeak("@site/docs/api/notification/schemas/schema-azure-service-bus-type-response.mdx")],
  "ff92c8bf": [() => import(/* webpackChunkName: "ff92c8bf" */ "@site/docs/api/workflow/schemas/schema-action-definition.mdx"), "@site/docs/api/workflow/schemas/schema-action-definition.mdx", require.resolveWeak("@site/docs/api/workflow/schemas/schema-action-definition.mdx")],};
